import { _list } from '../../utils/state/model/implementations/ImplementationFactory';
import { ListDO } from '../../utils/state/model/interfaces/displayObjects';
import { GenerateTasksInput, generateTasksForList } from './listApi';

const useListGeneration = () => {
  const generateList = async (input: GenerateTasksInput): Promise<ListDO> => {
    try {
      const taskData = await generateTasksForList(input);
      const list = await _list.createListAndTasksFromGeneratedTaskData(input.listName, taskData);
      return list;
    } catch (error) {
      console.error('Failed to generate list', error);
      throw error;
    }
  };

  return {
    generateList,
  };
};

export default useListGeneration;
