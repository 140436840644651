import { IonIcon, IonInput, IonItem, IonToolbar, useIonViewDidLeave } from '@ionic/react';
import { closeOutline, search } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';

interface HeaderSearchBarProps {
  onSearchbarBlur: React.Dispatch<React.SetStateAction<boolean>>;
  onSearchbarValueChange: (value: string) => void;
  customPlaceholder?: string;
  value: string;
}

const HeaderSearchBar: React.FC<HeaderSearchBarProps> = ({
  onSearchbarBlur,
  customPlaceholder,
  onSearchbarValueChange,
  value,
}) => {
  const searchbarInputRef = useRef<HTMLIonInputElement>(null);
  const [searchbarValue, setSearchbarValue] = useState<string>(value);

  useIonViewDidLeave(() => {
    setSearchbarValue('');
    onSearchbarBlur(false);
  });

  useEffect(() => {
    setTimeout(() => {
      searchbarInputRef.current!.setFocus();
    }, 50);
  }, []);

  const debounceRequest = useDebounce(() => {
    onSearchbarValueChange(searchbarValue);
  });

  const onSearchbarValueChangeHandler = (value: string) => {
    setSearchbarValue(value);
    debounceRequest();
  };

  const onSearchbarBlurHandler = () => {
    setSearchbarValue('');
    onSearchbarBlur(false);
  };

  const onCancelClickHandler = () => {
    setSearchbarValue('');
    onSearchbarBlur(false);
    onSearchbarValueChange('');
  };

  return (
    <IonToolbar style={{ padding: '1rem 0.75rem', '--background': 'transparent' }}>
      <IonItem
        className="no-min-height"
        style={{
          '--background': 'var(--ion-custom-searchbar-background)',
          '--padding': '1rem',
          '--border-radius': '3rem',
        }}
      >
        <IonIcon style={{ fontSize: '1.75rem', marginLeft: '1rem' }} color="light" icon={search} />
        <IonInput
          value={searchbarValue}
          ref={searchbarInputRef}
          class="custom-search-input"
          placeholder={customPlaceholder}
          onIonInput={(e) => onSearchbarValueChangeHandler(e.detail.value! as string)}
          onIonBlur={() => onSearchbarBlurHandler()}
        ></IonInput>
        <IonIcon
          style={{ fontSize: '2rem', marginRight: '1rem' }}
          color="light"
          icon={closeOutline}
          onClick={() => onCancelClickHandler()}
        />
      </IonItem>
    </IonToolbar>
  );
};

export default HeaderSearchBar;
