import { IonIcon, IonLabel } from '@ionic/react';
import { _task, _user } from '../../../utils/state/model/implementations/ImplementationFactory';
import SyncingIndicator from '../../general/SyncingIndicator';
import UserAvatar from './UserAvatar';
import { logFriendlyObject } from '@otuvy/common-utils';
import useGetLocalData, { SubscriptionsType } from '../../../hooks/useGetLocalData';
import addUserIcon from '../../../assets/images/icons/Add_User_Icon.svg';

interface HeaderUserCardProps {
  listId: string;
  taskId: string;
  openAssignMenu: (listId: string, taskIds: string[], mode: 'assign' | 'share') => void;
  isListOwner: boolean;
}

const HeaderUserCard: React.FC<HeaderUserCardProps> = ({ listId, taskId, openAssignMenu, isListOwner = false }) => {
  const { data: userData } = useGetLocalData(fetchUserData, [], SubscriptionsType.TaskChanges, taskId, listId);

  async function fetchUserData() {
    try {
      const taskData = await _task.getTaskById(taskId);
      if (!taskData.assignedTo) return undefined;

      const user = await _user.getById(taskData.assignedTo);
      if (!user) throw new Error('User not found');

      return user;
    } catch (err) {
      console.log('fetch user data - header user card', logFriendlyObject(err));
    }
  }

  function attemptAssign() {
    if (!isListOwner) return;
    openAssignMenu(listId, [taskId], 'assign');
  }

  return (
    <div slot="end" style={{ padding: '8px 12px', marginRight: '8px', paddingTop: 0 }} onClick={() => attemptAssign()}>
      <SyncingIndicator />
      {userData && (
        <div className="d-flex ion-align-items-center" style={{ position: 'relative', top: '-6px' }}>
          <IonLabel class="font-16" style={{ marginRight: '8px' }}>
            {userData.firstName} {userData.lastName}
          </IonLabel>
          <UserAvatar size="medium" img={userData.profilePhoto} userId={userData.userId} />
        </div>
      )}
      {isListOwner && !userData && (
        <div style={{ position: 'relative', top: '-4px' }}>
          <IonIcon icon={addUserIcon} color="primary" className={`font-24`} />
        </div>
      )}
    </div>
  );
};

export default HeaderUserCard;
