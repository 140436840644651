import { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { errorPageState } from '../pages/ErrorPage';
import { logFriendlyObject } from '@otuvy/common-utils';
import { _list, _task, _timeTracking } from '../utils/state/model/implementations/ImplementationFactory';

type AsyncFunction<T> = () => Promise<T>;

export enum SubscriptionsType {
  ListChanges = 'watchForChangesInList',
  TaskChanges = 'watchForChangesInTasks',
  AnyListChanges = 'watchForAnyListChanges',
  TimeTrackingChanges = 'watchForTimeTrackingChanges',
}

interface AsyncHookReturn<T> {
  loading: boolean;
  data?: T;
}

export default function useGetLocalData<T>(
  callback: AsyncFunction<T>,
  dependencies: any[] = [],
  subscriptions: SubscriptionsType | null = null,
  taskId?: string,
  listId?: string
): AsyncHookReturn<T> {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T>();

  const setShowErrorPage = useSetRecoilState(errorPageState);

  const callbackMemoized = useCallback(() => {
    callback()
      .then(setData)
      .catch((error: any) => {
        console.warn('Get Page Data - Error', logFriendlyObject(error));
        setShowErrorPage({ showErrorPage: true, errorDetails: `Failed to get data for the page\n` + error });
      })
      .finally(() => setLoading(false));
  }, dependencies);

  useEffect(() => {
    callbackMemoized();

    if (subscriptions === null) return;

    if (subscriptions === SubscriptionsType.AnyListChanges) {
      const listSubscriptions = _list.watchForAnyChanges(callbackMemoized, 'Any changes Subscription');

      return () => {
        if (subscriptions === null) return;
        _list.unwatch(listSubscriptions, 'Any changes Subscription');
      };
    }

    if (subscriptions === SubscriptionsType.ListChanges) {
      const listSubscriptions = _list.watchForChangesInList(
        listId!,
        callbackMemoized,
        `List changes Subscription List: ${listId}`
      );

      return () => {
        if (subscriptions === null) return;
        _list.unwatch(listSubscriptions, `List changes Subscription List: ${listId}`);
      };
    }

    if (subscriptions === SubscriptionsType.TaskChanges) {
      const taskSubscriptions = _task.watchForChangesInTask(
        taskId!,
        callbackMemoized,
        `Task changes Subscription List: ${listId} Task:  ${taskId}`
      );

      return () => {
        if (subscriptions === null) return;
        _task.unwatch(taskSubscriptions, `Task changes Subscription List: ${listId} Task:  ${taskId}`);
      };
    }

    if (subscriptions === SubscriptionsType.TimeTrackingChanges) {
      const workLogSubscriptionsPromise = _timeTracking.watchForWorkLogChanges(callbackMemoized);

      return () => {
        workLogSubscriptionsPromise.then((workLogSubscriptions) => {
          _timeTracking.unwatch(workLogSubscriptions);
        });
      };
    }
  }, [callbackMemoized]);

  return { loading, data };
}
