import { IonButton, IonIcon, IonSpinner, IonText } from '@ionic/react';
import { timeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import ClockOutConfirmation from './ClockOutConfirmation';
import { _timeTracking } from '../../../utils/state/model/implementations/ImplementationFactory';
import { syncData, syncState } from '../../../sync/sync';
import { addTimePunch } from '../utils/timePunchUtils';
import { differenceInHours } from 'date-fns';
import { useRecoilValue } from 'recoil';

interface ClockInButtonProps {}

const ClockInButton: React.FC<ClockInButtonProps> = () => {
  const [isClockedIn, setIsClockedIn] = useState<boolean>(false);
  const [isClockInConfirmationBoxOn, setIsClockInConfirmationBoxOn] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const isSyncing = useRecoilValue(syncState);

  useEffect(() => {
    if (!isSyncing) {
      _timeTracking.getCurrentWorkLog().then((currentWorkLog) => {
        const clockedIn: boolean =
          currentWorkLog !== undefined &&
          currentWorkLog !== null &&
          (!currentWorkLog.punchOut ||
            currentWorkLog.punchOut == null ||
            !currentWorkLog.punchOut.punchTime ||
            currentWorkLog.punchOut.punchTime === null);
        setIsClockedIn(clockedIn);
        setIsLoading(false);
      });
    }
  }, [isSyncing]);

  const handleButtonClick = async () => {
    const checkIfUserHasBeenClockedInForMoreThan12Hours = async () => {
      const currentWorkLog = await _timeTracking.getCurrentWorkLog();
      if (!currentWorkLog) return false;
      if (!currentWorkLog.punchIn?.punchTime) return false;
      const wasTwelveOrMoreHoursAgo = differenceInHours(new Date(), currentWorkLog.punchIn.punchTime) >= 12;

      return wasTwelveOrMoreHoursAgo;
    };

    const hasBeenClockedInFor12Hours = await checkIfUserHasBeenClockedInForMoreThan12Hours();

    if (hasBeenClockedInFor12Hours && isClockedIn) {
      // Show Confirmation Dialog
      setIsLoading(true);
      setIsClockInConfirmationBoxOn(true);
      return;
    }

    handleClockInOut();
  };

  const handleClockInOut = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await addTimePunch(isClockedIn);
      setIsClockedIn((prev) => !prev);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      console.error('Error clocking in/out:', error);
      setError('Failed to clock in/out. Please try again.');
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    syncData();
  };

  return (
    <>
      <IonButton
        fill={isClockedIn ? 'clear' : 'solid'}
        style={{
          width: '245px',
          '--border-radius': '100px',
          '--border-color': 'var(--ion-color-primary)',
          '--border-style': 'solid',
          '--border-width': '2px',
          opacity: '1',
        }}
        onClick={handleButtonClick}
        disabled={isLoading}
      >
        <div className="d-flex ion-align-items-center" style={{ padding: '18px 24px' }}>
          {isLoading ? (
            <IonSpinner style={{ padding: '19px 24px' }} />
          ) : (
            <>
              <IonIcon className="font-40" style={{ marginRight: '8px' }} icon={timeOutline} />
              <IonText className="font-36 font-weight-500">{isClockedIn ? 'Clock Out' : 'Clock In'}</IonText>
            </>
          )}
        </div>
      </IonButton>
      {error && (
        <div style={{ flexBasis: '100%', textAlign: 'center' }}>
          <IonText color="danger">{error}</IonText>
        </div>
      )}
      <ClockOutConfirmation
        isOpen={isClockInConfirmationBoxOn}
        setIsOpen={setIsClockInConfirmationBoxOn}
        setIsClockedIn={setIsClockedIn}
        setIsLoading={setIsLoading}
      />
    </>
  );
};

export default ClockInButton;
