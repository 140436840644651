import { IonContent, IonHeader, IonPage, IonToolbar, IonButtons, IonBackButton } from '@ionic/react';
import { RouteComponentProps } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { writeAccessRestrictedState } from '../App';
import TaskDetailsView from '../features/checklist/tasks/TaskDetailsView';
import AssignMenu from '../features/peopleorg/components/AssignMenu';
import HeaderUserCard from '../features/peopleorg/components/HeaderUserCard';

import { useAssignMenu } from '../features/peopleorg/hooks/useAssignMenu';
import useIsListOwner from '../features/peopleorg/hooks/useIsListOwner';
import { _list, _task } from '../utils/state/model/implementations/ImplementationFactory';
import { logFriendlyObject } from '@otuvy/common-utils';
import PullToRefresh from '../features/general/PullToRefresh';
import useGetLocalData, { SubscriptionsType } from '../hooks/useGetLocalData';
import { useState } from 'react';
import { DisablePointerState } from '../features/checklist/tasks/Field';

interface TaskDetailsPageProps
  extends RouteComponentProps<{
    listId: string;
    taskId: string;
  }> {}

const TaskDetailsPage: React.FC<TaskDetailsPageProps> = ({
  match: {
    params: { listId, taskId },
  },
}) => {
  const [disbablePointerEvets, setPointerEvents] = useState<DisablePointerState>({
    disableEvents: false,
    inputFieldOpened: null,
  });

  const { isRestricted: isWriteAccessRestricted } = useRecoilValue(writeAccessRestrictedState);
  const { data } = useGetTaskDetailsPageData(listId, taskId);
  const { listFromDB: list, taskFromDB: task } = data || { listFromDB: undefined, taskFromDB: undefined };

  const { isListOwner } = useIsListOwner(list);

  const {
    isOpen: isAssignMenuOpen,
    list: assignMenuList,
    task: assignMenuTasks,
    mode: assignMenuMode,
    openMenu: openAssignMenu,
    closeMenu: closeAssignMenu,
    key: assignMenuKey,
  } = useAssignMenu();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="md"
          class={`ion-padding-top ${disbablePointerEvets.disableEvents && 'disable-pointer-events'}`}
          style={{ '--ion-safe-area-top': '0px' }}
        >
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/list/${listId}`} />
          </IonButtons>
          <HeaderUserCard
            listId={listId}
            taskId={taskId}
            openAssignMenu={!isWriteAccessRestricted ? openAssignMenu : () => {}}
            isListOwner={isListOwner}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <PullToRefresh taskId={taskId} />
        {task && (
          <TaskDetailsView
            task={task}
            list={list}
            isListOwner={isListOwner}
            disbablePointerEvets={disbablePointerEvets}
            setPointerEvents={setPointerEvents}
          />
        )}
        <AssignMenu
          key={assignMenuKey}
          isOpen={isAssignMenuOpen}
          listId={assignMenuList}
          taskIds={assignMenuTasks}
          menuType={assignMenuMode}
          onDismiss={closeAssignMenu}
        />
      </IonContent>
    </IonPage>
  );
};

export default TaskDetailsPage;

function useGetTaskDetailsPageData(listId: string, taskId: string) {
  const { data, loading } = useGetLocalData(getTaskDetailsData, [], SubscriptionsType.TaskChanges, taskId, listId);

  async function getTaskDetailsData() {
    try {
      //TODO: combine into one state object?
      const taskFromDB = await _task.getTaskById(taskId);
      if (!taskFromDB) throw new Error('Failed to get the task data!');

      const listFromDB = await _list.getListById(listId);
      if (!listFromDB) throw new Error('Failed to get the task data!');

      return { taskFromDB, listFromDB };
    } catch (error) {
      console.log('getTaskDetailsData', logFriendlyObject(error));
    }
  }

  return { data, loading };
}
