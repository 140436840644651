import { IonPage, IonToolbar, IonContent, IonFooter, IonButton, IonIcon, IonButtons } from '@ionic/react';
import trashDark from './../assets/images/icons/Dark_Mode_Delete_Icon.svg';
import trashLight from './../assets/images/icons/Light_Mode_Delete_Icon.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import TaskPhotoView from '../features/checklist/photos/TaskPhotoView';
import { settingsState } from '../features/settings/settingsState';
import { writeAccessRestrictedState } from '../App';

interface TaskPhotoPageProps
  extends RouteComponentProps<{
    taskId: string;
    photoId: string;
  }> {}

const TaskPhotoPage: React.FC<TaskPhotoPageProps> = ({
  match: {
    params: { taskId, photoId },
  },
}) => {
  const { isRestricted: isWriteAccessRestricted } = useRecoilValue(writeAccessRestrictedState);
  const { darkMode } = useRecoilValue(settingsState);

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false);

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonContent>
        <TaskPhotoView
          taskId={taskId}
          photoId={photoId}
          isDeleteConfirmationOpen={isDeleteConfirmationOpen}
          setIsDeleteConfirmationOpen={setIsDeleteConfirmationOpen}
        />
      </IonContent>
      <IonFooter>
        <IonToolbar class="ion-padding-top ion-padding-bottom">
          <IonButtons slot="start">
            <IonButton style={{ textTransform: 'none' }} onClick={() => history.goBack()}>
              {t('cancel')}
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            {!isWriteAccessRestricted && (
              <IonButton
                className="circle-button"
                fill="solid"
                color="danger"
                onClick={() => setIsDeleteConfirmationOpen(true)}
              >
                <IonIcon size="large" icon={darkMode ? trashDark : trashLight} />
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default TaskPhotoPage;
