import JSZip from 'jszip';
import { ZipBlobParameters } from '../databaseDebug';

export const createZipFileFromBlob = async (params: ZipBlobParameters, fileName: string): Promise<Blob> => {
  try {
    const zip = new JSZip();
    zip.file(fileName, params.blob);
    const content: Blob = await zip.generateAsync({ type: 'blob' });
    return content;
  } catch (e) {
    console.error('Error creating Zip file', e);
    params.errorPageState({
      showErrorPage: true,
      errorDetails: e ? e : 'Unknown error creating Zip file',
    });
  }

  console.warn('Zipping file failed, returning input');
  return params.blob; //TODO: return something other than the original file?  Perhaps throw an error
};
