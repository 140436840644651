// This file has some constants that need to be maintained in sync with the other projects

export class MaxFieldLengths {
  public static readonly LIST_NAME = 500;
  public static readonly LIST_NOTES = 500;
  public static readonly TASK_NAME = 500;
  public static readonly TASK_INSTRUCTIONS = 1000;
  public static readonly TASK_INSTRUCTIONS_LINK_URL = 1000;
  public static readonly TASK_INSTRUCTIONS_LINK_TEXT = 250;
  public static readonly TASK_NOTES = 1000;
  public static readonly TASK_GENERATION_TYPE_OF_TASKS = 500;
  public static readonly TASK_GENERATION_LIST_MAIN_OBJECTIVE = 500;
  public static readonly REPORT_NAME = 100;
  public static readonly REPORT_COMMENT = 500;
}

export const WARNING_SIZE_FOR_PROFILE_PHOTO = 3000000;

export const LONG_STRING_MAX_LENGTH = 80;

// boolean is not indexable in Dexie, so we use constants
export const COMPLETE = 1;
export const NOT_COMPLETE = 0;
export type CompletionType = 0 | 1;

/**
 * @deprecated Use the user groups from the auth package instead.
 */
export enum LegacyUserRole {
  EMPLOYEE = 1,
  ADMIN = 3,
  SUPER_ADMIN = 4,
}

export const enum AgreementTypes {
  PURCHASE_AGREEMENT = 'Purchase Agreement',
  TERMS_AND_CONDITIONS = 'Terms and Conditions',
  PRIVACY_POLICY = 'Privacy Policy',
}

export const enum AgreementStatusTypes {
  UNACCEPTED = 'UNACCEPTED',
  ACCEPTED = 'ACCEPTED',
  REACCEPTANCE_REQUIRED = 'REACCEPTANCE_REQUIRED',
}

export interface AgreementStatus {
  agreementType: AgreementTypes;
  agreementStatus: AgreementStatusTypes;
}

export interface SoftwareUpdateInfo {
  currentVersion: string;
  currentReleaseNotes: string;

  lastRequiredVersion: string; //"required" is implied and Update notes <= current version
}

export const noSoftwareUpdates: SoftwareUpdateInfo = {
  currentVersion: '0.0.0',
  currentReleaseNotes: '',
  lastRequiredVersion: '0.0.0',
};

//Since we are currently uploading everything through the API, which is backed by a Lambda, we have to make sure images fit inside less than 6mb
//These values are used at certain points along the pipeline in order to reduce that file size
//Once S3 uploads are in place we can remove these restrictions
export const defaultImageMaxDimension = 800;
//High quality from the camera since other libraries will be down-scaling it for size requirements
export const defaultJpegQuality = 100;
export const cameraJpegQuality = defaultJpegQuality;
export const cropperJpegQuality = defaultJpegQuality;

export enum ValidationErrors {
  OPEN_AI_CONTENT_FILTER = 'Content flagged as inappropriate',
}

export const enum QrObjectTypes {
  LIST = 'list',
  TASK = 'task',
}

export const enum QrActionTypes {
  SHARE = 'sh',
}

export enum Status {
  UNKNOWN = -1,
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum PhotoTag {
  BEFORE = 'before',
  AFTER = 'after',
  DEFICIENCY = 'qm/deficiency',
}
