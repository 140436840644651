import { Device } from '@capacitor/device';
import { db, hasCurrentDb } from '../state/model/implementations/dexie/db';
import { _changeLog } from '../state/model/implementations/ImplementationFactory';
import { Clipboard } from '@capacitor/clipboard';
import { logFriendlyObject } from '@otuvy/common-utils';
import isPermittedNetworkConnected, {
  connectionType,
  isNetworkConnected,
  wifiOnlySetting,
} from '../../sync/network/network';
import { AuthCache, UserPropertyKeys, isCurrentUserAuthenticated } from '@otuvy/auth';

const dataLoader = async (): Promise<string> => {
  //TODO: load device info

  const p = require('../../../package.json');
  const d = require('../../devBuildInfo.json');
  const productVersion = p.version;

  var debugString = `Now: ${Date.now().toString()}        

-----------------
Version
-----------------
Version: ${productVersion}
Env: ${process.env.NODE_ENV}
`;

  if (d) {
    debugString += `
--------------------
Build Info
--------------------
Build: ${d.build}
Build Date: ${d.bdate}
Commit: ${d.commit}
`;
    /*Also available, but not used:
      d.branch :  "master"
      d.system :  "myComputerName"
      d.devname : "Joe Developer"
      */
  }

  debugString += `
---------------------
User Info
---------------------
`;

  if (await isCurrentUserAuthenticated()) {
    const email = AuthCache.getCachedUserAttribute(UserPropertyKeys.email);
    const currentUserID = AuthCache.getCurrentUserId();

    //TODO, also get org info if the user is logged in
    debugString += `Email: ${email}
ID: ${currentUserID}  
`;
  } else {
    debugString += `No authenticated User\n`;
  }

  const device = await Device.getInfo();

  debugString += `
---------------------
Device Info
---------------------
`;
  if (device) {
    debugString += `Model: ${device.model}
OS: ${device.operatingSystem}
OS Version: ${device.osVersion}
Manufacturer: ${device.manufacturer}
Web View Version:${device.webViewVersion}
Platform: ${device.platform}
Virtual: ${device.isVirtual}
`;
  } else {
    debugString += 'Unable to get device info\n';
  }

  debugString += `
---------------------
Connection Info
---------------------
Permitted connection: ${await isPermittedNetworkConnected()}
Online: ${await isNetworkConnected()}
Connection type: ${await connectionType()}
WifiOnly setting: ${await wifiOnlySetting()}
`;

  debugString += `
---------------------
DB Info
---------------------
`;

  try {
    //attempting items that require the DB and might fail
    if (hasCurrentDb()) {
      if (db().isOpen()) {
        /*
                            I do each of these as a separate statement in case any single one fails
                            If so the previous statements should at least make it

                             */
        debugString += `DB Version: ${db().verno}\n`;
        debugString += `Has changes: ${await _changeLog.hasChanges()}\n`;

        debugString += `Has unuploaded changes: ${await _changeLog.hasUnuploadedChanges()}\n`;
        //TODO: # of unuploaded changes
        debugString += `Has unconfirmed changes: ${await _changeLog.hasUnconfirmedChanges()}\n`;
        //TODO: # of unconfirmed changes

        //TODO: last sync date
      } else {
        debugString += `Database is not open\n`;
      }
    } else {
      //This is ok for the pre-authentication screen
      debugString += `No user database detected\n`;
    }
  } catch (e) {
    console.error('Exception getting debug info', logFriendlyObject(e));
    debugString += `Error Retrieving debug info`;
  }

  return debugString;
};

export const copyBasicDebug = () => {
  const load = async () => {
    const debugString = await dataLoader();
    await Clipboard.write({ string: debugString ? debugString : 'ERROR: No debug info loaded' });
  };
  load();
};
