import { useEffect, useState } from 'react';
import isPermittedNetworkConnected from './network';
import { isPlatform } from '@ionic/react';
import { Network } from '@capacitor/network';

/**
 * WARNING: Be careful using this hook to check the current network status!
 * This hook takes a moment to check if the network is connected.
 * In the meantime, it shows as connected, even if it's not.
 *
 * This hook is mostly useful for things that need to change when the network status changes (e.g. the offline banner).
 * If you want to check current network status, use the isPermittedNetworkConnected function instead.
 */
const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState<boolean>(true);

  useEffect(() => {
    const checkPermittedNetworkState = async () => {
      setIsOnline(await isPermittedNetworkConnected());
    };

    checkPermittedNetworkState();

    /**
     * Android doesn't fire networkStatusChange event when going offline
     * so we need to check the status every 5 seconds
     */
    if (isPlatform('android') && !isOnline) {
      const networkStatusInterval = setInterval(() => {
        checkPermittedNetworkState();
      }, 5000);

      return () => {
        clearInterval(networkStatusInterval);
      };
    } else {
      const listenerPromise = Network.addListener('networkStatusChange', checkPermittedNetworkState);

      return () => {
        listenerPromise.then((resolvedListener) => resolvedListener.remove());
      };
    }
  }, []);

  return {
    isOnline,
  };
};

export default useNetworkStatus;
