import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToolbar,
} from '@ionic/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RRule, Weekday } from 'rrule';
import { slideFromRightEnterAnimation, slideFromRightLeaveAnimation } from '../utils/calendarAnimation';
import { createCustomRecurrenceRule } from '../utils/recurrenceUtils';
import MonthMenu from './MonthMenu';
import WeekMenu from './WeekMenu';
import YearMenu from './YearMenu';

interface CustomRecurrenceSelectProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCustomSelect: (rule: RRule) => void;
  calendarDate: Date | null;
  recurrenceValue: RRule | null;
}

type CustomMenu = 'day' | 'week' | 'month' | 'year';
export type CustomValues = null | number[] | string[] | string | RRule | Weekday[];
const customOptions = new Map<CustomMenu, RRule>([
  ['day', new RRule({ freq: RRule.DAILY })],
  ['week', new RRule({ freq: RRule.WEEKLY })],
  ['month', new RRule({ freq: RRule.MONTHLY })],
  ['year', new RRule({ freq: RRule.YEARLY })],
]);

const initialCustomModeState = (rule: RRule | null): CustomMenu => {
  if (!rule || !rule.origOptions.interval) return 'day';
  const ruleFreq = rule.origOptions.freq;
  switch (ruleFreq) {
    case RRule.DAILY:
      return 'day';
    case RRule.WEEKLY:
      return 'week';
    case RRule.MONTHLY:
      return 'month';
    case RRule.YEARLY:
      return 'year';
  }
  return 'day';
};

const initialIntervalValueState = (rule: RRule | null) => {
  if (!rule || !rule.origOptions.interval) return 1;
  return rule.origOptions.interval;
};

const CustomRecurrenceSelect: React.FC<CustomRecurrenceSelectProps> = ({
  isOpen,
  setIsOpen,
  onCustomSelect,
  recurrenceValue,
}) => {
  const [customMode, setCustomMode] = useState<CustomMenu>(initialCustomModeState(recurrenceValue));
  const [intervalValue, setIntervalValue] = useState(initialIntervalValueState(recurrenceValue));
  const [customValues, setCustomValues] = useState<CustomValues>();

  const { t } = useTranslation();

  const onModeSelectChangeHandler = (mode: CustomMenu) => setCustomMode(mode);
  const onRepeatValueSelectChangeHandler = (value: number) => setIntervalValue(value);
  const onDoneClickHandler = () => {
    const rule = createCustomRecurrenceRule(customOptions.get(customMode), intervalValue, customValues);
    if (!rule) return;

    onCustomSelect(rule);
  };

  const customValueChangeHandler = (value: CustomValues) => {
    setCustomValues(value);
  };

  useEffect(() => {
    if (intervalValue > customModeIntervalOptions.get(customMode)?.length!) {
      setIntervalValue(1);
    }
  }, [customMode]);

  return (
    <IonModal
      isOpen={isOpen}
      enterAnimation={slideFromRightEnterAnimation}
      leaveAnimation={slideFromRightLeaveAnimation}
    >
      <IonContent class="ion-padding-horizontal" style={{ '--background': 'var(--toolbar-background)' }}>
        <IonGrid>
          <IonRow>
            <IonLabel class="font-32 font-weight-600 ion-margin-top">{t('repeatMenu.custom.title')}</IonLabel>
          </IonRow>
          <IonCol>
            <IonRow class="ion-justify-content-around">
              <IonSelect
                style={{ width: '40%', borderBottom: '2px solid var(--ion-color-light)' }}
                interface="popover"
                value={intervalValue}
                onIonChange={(e) => onRepeatValueSelectChangeHandler(e.detail.value)}
              >
                <CustomRecurrenceIntervalSelect customMode={customMode} />
              </IonSelect>
              <IonSelect
                style={{ width: '40%', borderBottom: '2px solid var(--ion-color-light)' }}
                interface="popover"
                placeholder="Select Mode"
                selectedText={t(customRecurrenceModeSelectPlaceholder(customMode, intervalValue))}
                value={customMode}
                onIonChange={(e) => onModeSelectChangeHandler(e.detail.value)}
              >
                <IonSelectOption value={'day'}>
                  {intervalValue > 1 ? t('repeatMenu.custom.days') : t('repeatMenu.custom.day')}
                </IonSelectOption>
                <IonSelectOption value={'week'}>
                  {intervalValue > 1 ? t('repeatMenu.custom.weeks') : t('repeatMenu.custom.week')}
                </IonSelectOption>
                <IonSelectOption value={'month'}>
                  {intervalValue > 1 ? t('repeatMenu.custom.months') : t('repeatMenu.custom.month')}
                </IonSelectOption>
                <IonSelectOption value={'year'}>
                  {intervalValue > 1 ? t('repeatMenu.custom.years') : t('repeatMenu.custom.year')}
                </IonSelectOption>
              </IonSelect>
            </IonRow>
          </IonCol>
          <IonGrid class="ion-no-padding">
            {customMode === 'week' && (
              <WeekMenu onCustomValueChange={customValueChangeHandler} recurrenceValue={recurrenceValue} />
            )}
            {customMode === 'month' && (
              <MonthMenu onCustomValueChange={customValueChangeHandler} recurrenceValue={recurrenceValue} />
            )}
            {customMode === 'year' && (
              <YearMenu onCustomValueChange={customValueChangeHandler} recurrenceValue={recurrenceValue} />
            )}
          </IonGrid>
        </IonGrid>
      </IonContent>
      <IonFooter class="ion-no-border">
        <IonToolbar>
          <IonButton
            slot="start"
            fill="clear"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <IonText color="primary">{t('cancel')}</IonText>
          </IonButton>
          <IonButton slot="end" fill="clear" onClick={onDoneClickHandler}>
            <IonText color="primary">{t('done')}</IonText>
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default CustomRecurrenceSelect;

interface CustomRecurrenceIntervalSelectProps {
  customMode: CustomMenu;
}

const customModeIntervalOptions = new Map<CustomMenu, number[]>([
  ['day', new Array(31).fill(1).map((_, i) => i + 1)],
  ['week', new Array(52).fill(1).map((_, i) => i + 1)],
  ['month', new Array(36).fill(1).map((_, i) => i + 1)],
  ['year', new Array(10).fill(1).map((_, i) => i + 1)],
]);

const CustomRecurrenceIntervalSelect: React.FC<CustomRecurrenceIntervalSelectProps> = ({ customMode }) => {
  return (
    <>
      {customModeIntervalOptions.get(customMode)?.map((option) => (
        <IonSelectOption key={option} value={option}>
          {option}
        </IonSelectOption>
      ))}
    </>
  );
};

const customRecurrenceModeSelectPlaceholder = (customMode: CustomMenu, intervalValue: number) => {
  switch (customMode) {
    case 'day':
      return intervalValue > 1 ? 'repeatMenu.custom.days' : 'repeatMenu.custom.day';
    case 'week':
      return intervalValue > 1 ? 'repeatMenu.custom.weeks' : 'repeatMenu.custom.week';
    case 'month':
      return intervalValue > 1 ? 'repeatMenu.custom.months' : 'repeatMenu.custom.month';
    case 'year':
      return intervalValue > 1 ? 'repeatMenu.custom.years' : 'repeatMenu.custom.year';
  }
};
