import { IonRow } from '@ionic/react';
import React from 'react';

interface CharacterCountInputProps {
  currentLength: number;
  maxLength: number;
}

const CharacterCount: React.FC<CharacterCountInputProps> = ({ currentLength, maxLength }) => {
  return (
    <>
      {
        //The spec says to not show this until we hit 80% of the max, rather than all the time
        currentLength / maxLength >= 0.8 ? (
          <IonRow
            class={'ion-margin-bottom ion-padding-bottom ion-justify-content-end'}
            style={{
              fontSize: 12,
            }}
          >
            {currentLength}/{maxLength}
          </IonRow>
        ) : (
          ''
        )
      }
    </>
  );
};

export default CharacterCount;
