import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonFooter,
  IonButton,
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonIcon,
  IonicSlides,
  IonSpinner,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { fullscreenModeState } from '../features/menu/MenuToolbar';
import { trashOutline } from 'ionicons/icons';
import { writeAccessRestrictedState } from '../App';
import useGetLocalData, { SubscriptionsType } from '../hooks/useGetLocalData';
import { _list, _photo, _task } from '../utils/state/model/implementations/ImplementationFactory';
import { useEffect, useState } from 'react';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Pagination, Zoom } from 'swiper/modules';
import { errorPageState } from './ErrorPage';
import DeleteConfirmation from '../features/general/DeleteConfirmation';
import { logFriendlyObject } from '@otuvy/common-utils';
import useIsListOwner from '../features/peopleorg/hooks/useIsListOwner';
import PhotoTagButton from '../features/checklist/photos/PhotoTagButton';
import { PhotoTag } from '../constants/constants';

interface TaskPhotosPageProps
  extends RouteComponentProps<{
    listId: string;
    taskId: string;
  }> {}

const TaskPhotosPage: React.FC<TaskPhotosPageProps> = ({
  match: {
    params: { listId, taskId },
  },
}) => {
  const { data, loading } = useGetTaskPhotosPageData(listId, taskId);
  const { list, taskPhotosIds } = data || { listFromDB: undefined };

  const { isListOwner } = useIsListOwner(list);
  const { isRestricted: isWriteAccessRestricted } = useRecoilValue(writeAccessRestrictedState);
  const history = useHistory();
  const { state: historyState }: any = history.location;

  // We use the activeSlideIndex to keep track of the current photo being viewed
  // We set it to 0 by default / If the photoId is passed in the history state, the onSlideChange event will set it to the correct index
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false);
  const [photoTagButtonFade, setPhotoTagButtonFade] = useState(false);
  const [photoTagsLookupMap, setPhotoTagsLookupMap] = useState<Map<string, PhotoTag | undefined>>(new Map());

  const { t } = useTranslation();

  // Set the fullscreen mode to true when the page is entered
  // We do this to hide the menu toolbar when viewing the photos
  // Using ionic lifecycle events to set the fullscreen mode
  const setIsFullscreenMode = useSetRecoilState(fullscreenModeState);

  useIonViewWillEnter(() => {
    setIsFullscreenMode(true);
  });

  useIonViewWillLeave(() => {
    setIsFullscreenMode(false);
  });

  useEffect(() => {
    console.log('activeSlideIndex', activeSlideIndex);
  }, [activeSlideIndex]);

  const handleSlideChange = (swiper: SwiperClass) => {
    setPhotoTagButtonFade(false);
    setTimeout(() => {
      setActiveSlideIndex(swiper.activeIndex);
      setPhotoTagButtonFade(true);
    }, 200);
  };

  const deleteCurrentPhoto = async () => {
    try {
      await _photo.removeCompletionPhotoFromTask(taskId, taskPhotosIds![activeSlideIndex]);
      const newPhotosIDs = await _photo.getCompletionPhotoIdsForTask(taskId);
      if (newPhotosIDs.length === 0) history.goBack(); //If there are no more photos, go back
      // Another option would be to show a no photos message instead and make the user go back manually
      // Or give them the option to add a new photo from the same screen
    } catch (e) {
      console.error('Exception deleting photo', e);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar></IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-vertical">
        {/* Slider */}
        {taskPhotosIds && (
          <Swiper
            style={{ height: '90%' }}
            slidesPerView={1}
            modules={[Pagination, Zoom, IonicSlides]}
            zoom
            pagination={{ clickable: true, el: '.swiper-pagination', type: 'bullets' }}
            initialSlide={taskPhotosIds?.indexOf(historyState.photoId)}
            onSlideChange={handleSlideChange}
            onInit={() => {
              setPhotoTagButtonFade(true);
            }}
          >
            {taskPhotosIds?.map((photoId: string, index: number) => (
              <SwiperSlide key={`fullScreenViewPhotoId_${photoId}`} virtualIndex={index} zoom>
                <PhotoCarouselTile photoId={photoId} taskId={taskId} setPhotoTagsLookupMap={setPhotoTagsLookupMap} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {/* Slider Nav */}
        <div>
          <div className="swiper-pagination"></div>
        </div>

        {/* Delete Confirmation Dialog */}
        <DeleteConfirmation
          isOpen={isDeleteConfirmationOpen}
          setIsOpen={setIsDeleteConfirmationOpen}
          deleteMe={deleteCurrentPhoto}
          confirmButtonText="confirmation.delete.image.confirm"
          header="confirmation.delete.image.header"
          message="confirmation.delete.image.message"
        />
      </IonContent>
      <IonFooter>
        <div
          style={{ background: 'var(--ion-background-color)' }}
          className="ion-padding d-flex ion-justify-content-between ion-align-items-center"
        >
          <IonButton fill="clear" className="font-weight-500" onClick={() => history.goBack()}>
            {t('back')}
          </IonButton>

          {!loading && (
            <div
              style={{
                opacity: photoTagButtonFade ? 1 : 0,
                transition: 'opacity 0.5s',
              }}
            >
              <PhotoTagButton
                tag={photoTagsLookupMap.get(taskPhotosIds ? taskPhotosIds[activeSlideIndex] : '')}
                photoId={taskPhotosIds ? taskPhotosIds[activeSlideIndex] : ''}
                taskId={taskId}
                size="large"
              />
            </div>
          )}

          <IonButton
            style={{
              opacity: !isWriteAccessRestricted && isListOwner ? 1 : 0,
              pointerEvents: !isWriteAccessRestricted && isListOwner ? 'auto' : 'none',
            }}
            className="circle-button"
            fill="clear"
            color="danger"
            disabled={loading || !taskPhotosIds || taskPhotosIds.length === 0}
            onClick={() => setIsDeleteConfirmationOpen(true)}
          >
            <IonIcon size="large" icon={trashOutline} />
          </IonButton>
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default TaskPhotosPage;

enum downloadStateType {
  notStarted,
  attempting,
  failed,
  completed,
}

interface PhotoCarouselTileProps {
  photoId: string;
  taskId: string;
  setPhotoTagsLookupMap: React.Dispatch<React.SetStateAction<Map<string, PhotoTag | undefined>>>;
}

const PhotoCarouselTile: React.FC<PhotoCarouselTileProps> = ({ photoId, taskId, setPhotoTagsLookupMap }) => {
  const [photoBase64Data, setPhotoBase64Data] = useState<string>('');
  const [downloadState, setDownloadState] = useState<downloadStateType>(downloadStateType.notStarted);
  const setShowErrorPage = useSetRecoilState(errorPageState);

  const fetchPhotoData = async (id: string) => {
    try {
      setDownloadState(downloadStateType.attempting);
      const photoDataResult = await _photo.getPhoto(id);

      if (!photoDataResult || photoDataResult.data === '') {
        // If the photo data is empty, try to download the photo
        const downloadPhotoResult = await _photo.downloadPhoto(id);

        if (!downloadPhotoResult || downloadPhotoResult.data === '') throw new Error('Failed to download photo');

        // Set the downloaded photo data
        setPhotoBase64Data(downloadPhotoResult.data);

        // Set the photo tag in the lookup map if it exists
        setPhotoTagsLookupMap((prev: Map<string, PhotoTag | undefined>) => {
          const newMap = new Map<string, PhotoTag | undefined>(prev);
          newMap.set(id, downloadPhotoResult.tag ?? undefined);
          return newMap;
        });

        // Set the download state to completed
        setDownloadState(downloadStateType.completed);
        return;
      }

      // Set the downloaded photo data
      setPhotoBase64Data(photoDataResult.data);

      // Set the photo tag in the lookup map if it exists
      setPhotoTagsLookupMap((prev: Map<string, PhotoTag | undefined>) => {
        const newMap = new Map<string, PhotoTag | undefined>(prev);
        newMap.set(id, photoDataResult.tag ?? undefined);
        return newMap;
      });

      // Set the download state to completed
      setDownloadState(downloadStateType.completed);
    } catch (e) {
      setDownloadState(downloadStateType.failed);
      console.error('Exception getting photo', e);
    }
  };

  useEffect(() => {
    fetchPhotoData(photoId);
  }, []);

  useEffect(() => {
    // subcribe to photo changes
    const syncPhotoTags = async () => {
      try {
        const photoTag = await _photo.getPhoto(photoId);
        setPhotoTagsLookupMap((prev: Map<string, PhotoTag | undefined>) => {
          const newMap = new Map<string, PhotoTag | undefined>(prev);
          newMap.set(photoId, photoTag.tag ?? undefined);
          return newMap;
        });
      } catch (e) {
        console.error('Error updating photo tag!', e);
      }
    };

    const subscription = _task.watchForChangesInTask(taskId, syncPhotoTags, `Task changes SubscriptionTask: ${taskId}`);

    return () => {
      _task.unwatch(subscription, `Task changes SubscriptionTask: ${taskId}`);
    };
  }, []);

  if (downloadState === downloadStateType.failed) {
    console.error('Failed to download photo');
    setShowErrorPage({
      showErrorPage: true,
      errorDetails: `Failed to download photo: taskId = ${taskId}, photoId = ${photoId}`,
    }); //We were told to show them the error page, but due to the content on it, specifically the "lets get back into it" link, does not fit well in this place

    throw new Error();
  }

  if (downloadState === downloadStateType.attempting)
    return (
      <div
        style={{ height: '100%', width: '100%', flexDirection: 'column' }}
        className="d-flex ion-align-items-center ion-justify-content-center"
      >
        <IonSpinner name="dots"></IonSpinner>
      </div>
    );

  // if (downloadState === downloadStateType.notStarted) return <div>Loading...</div>;

  if (downloadState === downloadStateType.completed)
    return (
      <div>
        <img style={{ objectFit: 'cover', height: '100%', width: '100%' }} src={photoBase64Data!} alt="Task" />
      </div>
    );

  return null;
};

function useGetTaskPhotosPageData(listId: string, taskId: string) {
  const { data, loading } = useGetLocalData(
    getTaskPhotosPageData,
    [],
    SubscriptionsType.AnyListChanges,
    taskId,
    listId
  );

  async function getTaskPhotosPageData() {
    try {
      const list = await _list.getListById(listId);
      if (!list) throw new Error('Failed to get the list data!');

      const taskPhotosIds = await _photo.getCompletionPhotoIdsForTask(taskId);

      return { list, taskPhotosIds };
    } catch (error) {
      console.log('getTaskPhotosData', logFriendlyObject(error));
    }
  }

  return { data, loading };
}
