import { ZipBlobParameters } from '../databaseDebug';
import { Clipboard } from '@capacitor/clipboard';

export const clipboardDownloader = async (params: ZipBlobParameters) => {
  console.log('Attempting clipboardDownloader download');
  try {
    const data = await params.blob.text();
    await Clipboard.write({
      string: data ? data : 'No data to copy to clipboard (clipboardDownloader)',
    });
  } catch (e) {
    console.error('Error copying data to clipboard', e);
    params.errorPageState({
      showErrorPage: true,
      errorDetails: e ? 'error copying data to clipboard' + e : 'Unknown error copying data to clipboard',
    });
  }

  //TODO: provide some graphical feedback that data was copied to the clipboard
};
