import { useRef, useEffect } from 'react';

// https://stackoverflow.com/a/56267719/5423329
const useIsFirstRender = () => {
    const isFirstRenderRef = useRef(true);
    useEffect(() => {
        isFirstRenderRef.current = false;
    }, []);
    return isFirstRenderRef.current;
};

export default useIsFirstRender