import { UserInterface } from '../interfaces/UserInterface';
import { TaskInterface } from '../interfaces/TaskInterface';
import { ListInterface } from '../interfaces/ListInterface';
import { DataSourceInterface } from '../interfaces/DataSourceInterface';
import { PhotoInterface } from '../interfaces/PhotoInterface';

import { UserDexieImplementation } from './dexie/userDexieImplementation';
import { DataSourceDexieImplementation } from './dexie/dataSourceDexieImplementation';
import { TaskDexieImplementation } from './dexie/taskDexieImplementation';
import { ListDexieImplementation } from './dexie/listDexieImplementation';
import { PhotoDexieImplementation } from './dexie/photoDexieImplementation';
import { ChangeLoggerInterface } from '../interfaces/changeLoggerInterface';
import { DexieChangeLogger } from './dexie/dexieChangeLogger';
import { SystemFlagInterface } from '../interfaces/systemFlagInterface';
import { DexieSystemFlag } from './dexie/systemFlagDexieImplementation';
import { AnalyticsInterface } from '../interfaces/analyticsInterface';
import { DexieAnalytics } from './dexie/analyticsDexieImplementation';
import { SettingInterface } from '../interfaces/settingInterface';
import { DexieSettingImplementation } from './dexie/dexieSettings';
import { TimeTrackingInterface } from '../interfaces/timeTrackingInterface';
import { DexieTimeTracking } from './dexie/timeTrackingDexieImplementation';

//In order to swap implementations of the interfaces just change what constructors are used here

export const _list: ListInterface = new ListDexieImplementation();
export const _task: TaskInterface = new TaskDexieImplementation();
export const _photo: PhotoInterface = new PhotoDexieImplementation();
export const _user: UserInterface = new UserDexieImplementation();

/**
 * Should this be purely internal to the implementation layer?
 * The UI layer should never need to use it.
 */
export const _changeLog: ChangeLoggerInterface = new DexieChangeLogger();
export const _analytics: AnalyticsInterface = new DexieAnalytics();
export const _timeTracking: TimeTrackingInterface = new DexieTimeTracking();

export const _datasource: DataSourceInterface = new DataSourceDexieImplementation();

export const _systemFlag: SystemFlagInterface = new DexieSystemFlag();
export const _setting: SettingInterface = new DexieSettingImplementation();
