import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { errorPageState } from '../../../pages/ErrorPage';
import ExpandableInput from '../../general/ExpandableInput';
import { MaxFieldLengths } from '../../../constants/constants';
import { _list } from '../../../utils/state/model/implementations/ImplementationFactory';
import { ListDO } from '../../../utils/state/model/interfaces/displayObjects';
import { logFriendlyObject } from '@otuvy/common-utils';
import { useState } from 'react';

interface RenameListInputProps {
  list: ListDO | null;
  isListOwner: boolean;
  setIsRenameList: React.Dispatch<React.SetStateAction<boolean>>;
  disableExpandIcon?: boolean;
}

const RenameListInput: React.FC<RenameListInputProps> = ({
  list,
  isListOwner,
  setIsRenameList,
  disableExpandIcon = false,
}) => {
  const setShowErrorPage = useSetRecoilState(errorPageState);
  const [listName, setListName] = useState<string | undefined>(list?.listName);

  const { t } = useTranslation();

  const handleBlur = async () => {
    try {
      if (list) {
        if (listName && listName?.length > 0) {
          await _list.renameList(list.listId, listName ? listName : t('default.list.name'));
        }
        if (listName?.trim().length === 0) {
          await _list.renameList(list.listId, t('default.list.name'));
          list.listName = t('default.list.name');
        }
        setIsRenameList(false);
      }
    } catch (error) {
      console.error('Failed to update list name', logFriendlyObject(error));
      setShowErrorPage({
        showErrorPage: true,
        errorDetails: `Failed to update list name: listId = ${list?.listId}, listName = ${list?.listName}\n` + error,
      });
    }
  };

  return (
    <ExpandableInput
      value={listName ? listName : t('default.list.name')}
      accessibilityLabel="List Input"
      inputMaxlength={MaxFieldLengths.LIST_NAME}
      lineClamp={1}
      onClick={() => setIsRenameList(true)}
      onIonBlur={handleBlur}
      onIonChange={(event) => {
        if (isListOwner && list) {
          setListName(event.detail.value || (t('default.list.name') as string));
        }
      }}
      doesEnterKeyBlur={true}
      placeholder={t('default.list.name') ?? undefined}
      editable={isListOwner}
      disableExpandIcon={disableExpandIcon}
    />
  );
};
export default RenameListInput;
