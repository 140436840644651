import { ZipBlobParameters } from '../databaseDebug';
import { createZipFileFromBlob } from './blobZipUtil';

export const objectURLWindowDownload = async (params: ZipBlobParameters) => {
  console.log('Attempting to urlObj download');

  try {
    //TODO: So far I have not found a way to give this a specific file name.  Some searching says it is not possible

    const zipBlob = await createZipFileFromBlob(params, 'db');

    var urlObj = URL.createObjectURL(zipBlob);

    console.log('Attempting to open urlObj', urlObj);
    window.open(urlObj, '_system');
  } catch (e) {
    console.error('Error opening Download window', e);
    params.errorPageState({
      showErrorPage: true,
      errorDetails: e ? 'Error opening Download window' + e : 'Unknown error opening Download window',
    });
  }
};
