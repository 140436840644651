import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonActionSheet } from '@ionic/react';
import { SortOption } from '../features/sort/useSortOptions';

interface SortOptionsProps {
  isOpen: boolean;
  setSortOption: React.Dispatch<React.SetStateAction<SortOption | undefined>>;
  setAreSortOptionsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  excludedSortOptions?: SortOption[];
}
const SortOptions: React.FC<SortOptionsProps> = ({
  isOpen,
  setSortOption,
  setAreSortOptionsOpen,
  excludedSortOptions = [],
}) => {
  const { t } = useTranslation();

  const options = [
    {
      key: SortOption.DUE_DATE,
      button: {
        text: t('option.sort.due'),
        handler: () => setSortOption(SortOption.DUE_DATE),
      },
    },
    {
      key: SortOption.RECENT_ACTIVITY,
      button: {
        text: t('option.sort.activity'),
        handler: () => setSortOption(SortOption.RECENT_ACTIVITY),
      },
    },
    {
      key: SortOption.CREATION_DATE,
      button: {
        text: t('option.sort.created'),
        handler: () => setSortOption(SortOption.CREATION_DATE),
      },
    },
    {
      key: SortOption.NAME,
      button: {
        text: t('option.sort.name'),
        handler: () => setSortOption(SortOption.NAME),
      },
    },
    //this one only applies to tasks, not lists.  It is excludeds using the "excludedSortOptions" property
    {
      key: SortOption.ASSIGNED_TO,
      button: {
        text: t('option.sort.assignedTo'),
        handler: () => setSortOption(SortOption.ASSIGNED_TO),
      },
    },
  ];

  return (
    <IonActionSheet
      cssClass="custom-action-sheet"
      isOpen={isOpen}
      header={t('menu.header.sort')!}
      buttons={[
        ...options.filter((option) => !excludedSortOptions.includes(option.key)).map((option) => option.button),
        {
          text: t('cancel')!,
          role: 'cancel',
        },
      ]}
      onWillDismiss={() => setAreSortOptionsOpen(false)}
    />
  );
};

export default SortOptions;
