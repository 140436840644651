// Make sure to keep this in sync among projects
export enum AnalyticsEventType {
  UNKNOWN = 'unknown',
  APP_STATE_CHANGE = 'appStateChange',
  PAGE_CHANGE = 'pageChange',
  SETTINGS_CHANGE_DARK_MODE = 'settingsChange/darkMode',
  SETTINGS_CHANGE_WIFI_ONLY = 'settingsChange/wifiOnly',
  SETTINGS_CHANGE_LANGUAGE = 'settingsChange/language',
  SETTINGS_CHANGE_NOTIFICATIONS = 'settingsChange/notifications',
  NETWORK_STATUS_CHANGE = 'networkStatusChange',
  CLICK_AI_LIST_GENERATION_BUTTON_1 = 'aiListGeneration/button1',
  CLICK_AI_LIST_GENERATION_BUTTON_2 = 'aiListGeneration/button2',
  QR_SHARE_LIST = 'qr/shareList',
}
