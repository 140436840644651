import { atom, DefaultValue, selector, useSetRecoilState } from 'recoil';
import { ListDO, TaskDO } from '../../../../utils/state/model/interfaces/displayObjects';
import { errorPageState } from '../../../../pages/ErrorPage';
import { _list } from '../../../../utils/state/model/implementations/ImplementationFactory';
import { logFriendlyObject } from '@otuvy/common-utils';

//"tasks" state as opposed to "task" state
export const tasksState = selector<TaskDO[]>({
  key: 'tasksState',
  get: ({ get }) => {
    return get(internalTasksState);
  },
  set: ({ get, set }, newTasks) => {
    if (newTasks instanceof DefaultValue) {
      throw new Error('No updated tasks were provided');
    }
    set(internalTasksState, newTasks);
  },
});

export const usePostUpdateEffect = () => {
  const setShowErrorPage = useSetRecoilState(errorPageState);

  const postUpdateEffect = async (listId: string) => {
    try {
      const list: ListDO | undefined = await _list.getListById(listId);

      if (!list) {
        throw new Error(`Could not find a list with ID ${listId}`);
      }
    } catch (error) {
      console.error('Failed to update list on server', logFriendlyObject(error));
      setShowErrorPage({
        showErrorPage: true,
        errorDetails: `Failed to update list on server: listId = ${listId}\n` + error,
      });
    }
  };

  return {
    postUpdateEffect,
  };
};

export const internalTasksState = atom<TaskDO[]>({
  key: 'internalTasksState',
  default: [],
});

export const taskMultiSelectState = atom<boolean>({
  key: 'taskMultiSelectState',
  default: false,
});

export const selectedTasksState = atom<TaskDO[]>({
  key: 'selectedTasksState',
  default: [],
});
