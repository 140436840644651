import { IonContent, IonGrid, IonHeader, IonLabel, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';

import '../style/accordion.css';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { errorPageState } from './ErrorPage';

import { Clipboard } from '@capacitor/clipboard';
import { copyBasicDebug } from '../utils/debug/basicDebug';
import { export_BasicTables, export_ChangeLog, export_Database } from '../utils/debug/databaseDebug';
import { useEffect, useState } from 'react';
import { getCurrentUserStringAttribute } from '@otuvy/auth/dist/utils/authUtils';
import { AuthCache, UserPropertyKeys } from '@otuvy/auth';
import { EnvironmentConfig, getString } from '../utils/environmentUtils';

const DebugPage: React.FC = () => {
  const currentErrorState = useRecoilValue(errorPageState);
  const setShowErrorPage = useSetRecoilState(errorPageState);

  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [organizationId, setOrganizationId] = useState<string | undefined>();
  const [userId, setUserId] = useState<string | undefined>();

  useEffect(() => {
    getCurrentUserStringAttribute(UserPropertyKeys.firstName).then((value) => {
      setFirstName(value);
    });
    getCurrentUserStringAttribute(UserPropertyKeys.lastName).then((value) => {
      setLastName(value);
    });
    getCurrentUserStringAttribute(UserPropertyKeys.organizationId).then((value) => {
      setOrganizationId(value);
    });
    getCurrentUserStringAttribute(UserPropertyKeys.userID).then((value) => {
      setUserId(value);
    });
    console.log('User Groups: ' + AuthCache.getCurrentUserGroups());
  }, []);

  const copyLastErrorState = () => {
    const load = async () => {
      await Clipboard.write({
        string: currentErrorState.errorDetails
          ? currentErrorState.errorDetails
          : 'No current Error details (DebugPage)',
      });
    };
    load();
  };

  const testLastErrorState = () => {
    console.error('Testing the error page');
    setShowErrorPage({
      showErrorPage: true,
      errorDetails: 'Hello World',
    });
  };

  return (
    <IonPage>
      <IonGrid class="ion-padding" fixed={true}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              <IonLabel class="font-40">Debug Info</IonLabel>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent class="ion-padding no-hover">
          <IonGrid>
            <IonRow>
              <IonLabel class="font-32 font-weight-600 ion-margin-top" onClick={copyBasicDebug}>
                Copy basic debug info to your clipboard
              </IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel class="font-32 font-weight-600 ion-margin-top" onClick={copyLastErrorState}>
                Copy "Current" Error Details
              </IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel class="font-32 font-weight-600 ion-margin-top">---------------------</IonLabel>
            </IonRow>

            <IonRow>
              <IonLabel
                class="font-32 font-weight-600 ion-margin-top"
                onClick={() => {
                  console.warn('Attempting to export the DB');
                  export_Database({ errorPageState: setShowErrorPage });
                }}
              >
                Export the whole database
              </IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel
                class="font-32 font-weight-600 ion-margin-top"
                onClick={() => {
                  console.warn('Attempting to export the basic info from the DB');
                  export_BasicTables({ errorPageState: setShowErrorPage });
                }}
              >
                Export the basic db
              </IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel
                class="font-32 font-weight-600 ion-margin-top"
                onClick={() => {
                  export_ChangeLog({ errorPageState: setShowErrorPage });
                }}
              >
                Export the changelog
              </IonLabel>
            </IonRow>

            <IonRow>
              <IonLabel class="font-32 font-weight-600 ion-margin-top">---------------------</IonLabel>
            </IonRow>

            <IonRow>
              <IonLabel class="font-32 font-weight-600 ion-margin-top" onClick={testLastErrorState}>
                Test "Current" Error Details
              </IonLabel>
            </IonRow>

            <IonRow>
              <IonLabel class="font-32 font-weight-600 ion-margin-top">---------------------</IonLabel>
            </IonRow>

            <IonRow>
              <IonLabel class="font-32 font-weight-600 ion-margin-top">URL: {window.location.href}</IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel class="font-32 font-weight-600 ion-margin-top">
                Environment: {getString(EnvironmentConfig.ENVIRONMENT_NAME)}
              </IonLabel>
            </IonRow>

            <IonRow>
              <IonLabel class="font-32 font-weight-600 ion-margin-top">---------------------</IonLabel>
            </IonRow>

            <IonRow>
              <IonLabel class="font-32 font-weight-600 ion-margin-top">Organization ID: {organizationId}</IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel class="font-32 font-weight-600 ion-margin-top">User ID: {userId}</IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel class="font-32 font-weight-600 ion-margin-top">Name: {firstName + ' ' + lastName}</IonLabel>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonGrid>
    </IonPage>
  );
};
export default DebugPage;
