import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { syncingIndicatorState } from '../App';
import { EnvironmentConfig, getFlag } from '../utils/environmentUtils';
import { syncState } from './sync';

const useSyncIndicator = () => {
  const setIsSyncingIndicator = useSetRecoilState(syncingIndicatorState);
  const isSyncing = useRecoilValue(syncState);

  useEffect(() => {
    if (!getFlag(EnvironmentConfig.DISPLAY_SYNC_INDICATOR)) return;

    if (isSyncing === false) {
      setIsSyncingIndicator(false);
      return;
    }

    const syncingTimeout = setTimeout(() => {
      setIsSyncingIndicator(true);
    }, 500);

    return () => {
      clearTimeout(syncingTimeout);
      setIsSyncingIndicator(false);
    };
  }, [isSyncing]);
};

export default useSyncIndicator;
