import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonContent, IonLoading } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import HomePage from './pages/HomePage';
import MenuToolbar from './features/menu/MenuToolbar';
import ErrorPage, { errorPageState } from './pages/ErrorPage';

import { atom, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import usePollForChanges from './sync/usePollForChanges';
import useCheckOrgGovernors, { WriteAccessRestriction } from './utils/useCheckOrgGovernors';
import { useEffect } from 'react';
// import FaqPage from './pages/FaqPage';
// import DebugPage from './pages/DebugPage';
// import ReportsPage from './pages/ReportsPage';
import Banner from './features/status/Banner';
import useSyncIndicator from './sync/useSyncIndicator';
import Agreement from './features/legal/Agreement';
import { AgreementStatus, SoftwareUpdateInfo, noSoftwareUpdates } from './constants/constants';
import SoftwareUpdateAvailable from './features/updates/SoftwareUpdateAvailable';
import React, { lazy } from 'react';
import { useHasDownloadedFirstBatchState, useSyncState } from './sync/sync';
import NoPageFound from './pages/NoPageFound';
import QrLandingPage from './pages/QrLandingPage';
import TimeTrackingPage from './pages/TimeTrackingPage';
import useOrganizationSettings, {
  OrganizationSettingsState,
} from './features/settings/orgSettings/useOrganizationSettings';
import { EnvironmentConfig, getFlag } from './utils/environmentUtils';

// const HomePage = lazy(() => import('./pages/HomePage'));
const FaqPage = lazy(() => import('./pages/FaqPage'));
const DebugPage = lazy(() => import('./pages/DebugPage'));
const ReportsPage = lazy(() => import('./pages/ReportsPage'));

const MenuComponent = lazy(() => import('./features/menu/MenuSidebar'));

export const loadingShownState = atom<boolean>({
  key: 'loadingShownState',
  default: false,
});

export const writeAccessRestrictedState = atom<WriteAccessRestriction>({
  key: 'writeAccessRestricted',
  default: {
    isRestricted: false,
    reason: null,
  },
});

export const agreementState = atom<AgreementStatus[]>({
  key: 'agreementState',
  default: [],
});

export const latestSoftwareVersionState = atom<SoftwareUpdateInfo>({
  key: 'latestSoftwareVersion',
  default: noSoftwareUpdates,
});

export const acknowledgedVersionState = atom<string>({
  key: 'acknowledgedVersion',
  default: '0.0.0',
});

export const syncingIndicatorState = atom<boolean>({
  key: 'syncingIndicatorState',
  default: false,
});

export const organizationSettingsState = atom<OrganizationSettingsState>({
  key: 'organizationSettingsState',
  default: {
    timeTrackingEnabled: false,
    linkedToQM: false,
  },
});

const App: React.FC = () => {
  const currentErrorState = useRecoilValue(errorPageState);
  const isLoadingShown = useRecoilValue(loadingShownState);
  const organizationSettings = useRecoilValue(organizationSettingsState);

  useSyncState();
  useHasDownloadedFirstBatchState();
  usePollForChanges();
  useSyncIndicator();
  useOrganizationSettings();

  const { t } = useTranslation();
  const { checkOrgGovernors } = useCheckOrgGovernors(); //usePollForChanges also triggers this

  useEffect(checkOrgGovernors, []);

  return (
    <IonApp>
      {currentErrorState && currentErrorState.showErrorPage ? (
        <ErrorPage />
      ) : (
        <>
          <IonReactRouter>
            <Banner />
            <MenuComponent />
            <IonContent>
              <IonRouterOutlet id="main" mode="ios">
                <Route exact path="/">
                  <Redirect to="/list" />
                </Route>
                <Route path="/list">
                  <HomePage />
                </Route>
                <Route exact path="/faq">
                  <FaqPage />
                </Route>
                <Route exact path="/debug">
                  <DebugPage />
                </Route>
                <Route exact path="/reports">
                  <ReportsPage />
                </Route>
                <Route exact path="/timetracking">
                  {getFlag(EnvironmentConfig.TIME_TRACKING) && organizationSettings.timeTrackingEnabled ? (
                    <TimeTrackingPage />
                  ) : (
                    <Redirect to="/list" />
                  )}
                </Route>
                <Route exact path={`/qr/:action/:idType/:id`} component={QrLandingPage} />
                <Route>
                  <NoPageFound />
                </Route>
              </IonRouterOutlet>
            </IonContent>
            <MenuToolbar />
          </IonReactRouter>
          <IonLoading isOpen={isLoadingShown} message={t('loading.generic')!} />
          <Agreement />
          <SoftwareUpdateAvailable />
        </>
      )}
    </IonApp>
  );
};

export default App;
