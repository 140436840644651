/**
 * The back end (service) has a copy of these constants that needs to be manually synced :P
 *
 * When adding a new change type, make sure to update anywhere that uses similar types that might need to include the new one
 */
export enum ChangeType {
  /**
   * Used for variable initialization and unimplemented changes
   */
  UNKNOWN = 'unknown',

  USER_EDIT = 'edit/user',

  /**
   * Should we separate duplicate from create?
   */
  LIST_CREATE = 'create/list',
  TASK_CREATE = 'create/task',

  LIST_DELETE = 'delete/list',
  TASK_DELETE = 'delete/task',

  LIST_EDIT = 'edit/list',
  LIST_EDIT_SHARED_WITH = 'edit/list/sharedWith',
  TASK_EDIT = 'edit/task',
  TASK_EDIT_RECURRENCE = 'edit/task/recurrence',

  COMPLETION_EDIT_NOTE = 'edit/completion/note',
  COMPLETION_ADD_PHOTO = 'addPhoto/completion',
  COMPLETION_REMOVE_PHOTO = 'removePhoto/completion',
  COMPLETION_COMPLETE = 'completion/complete',
  COMPLETION_UNCOMPLETE = 'completion/uncomplete',
  COMPLETION_ADD_PENDING_PHOTOS = 'addPendingPhotos/completion',

  COMPLETION_PHOTO_EDIT = 'edit/completion/photo',

  QM_ADD_PHOTO = 'addPhoto/qm',
}

export enum ChangeStatus {
  UN_UPLOADED = '',
  UPLOADED = 'UPLOADED',
}

export class ChangeLog {
  changeId: string = '';
  changeType: ChangeType = ChangeType.UNKNOWN;
  recordId: string = '';
  payload?: string; //TODO make this "string | Blob;"
  createdOn: number = 0;

  uploaded: ChangeStatus = ChangeStatus.UN_UPLOADED;
}

export interface ArrayPayload {
  toAdd: string[];
  toRemove: string[];
}

export interface ChangeLoggerInterface {
  queueChange(changes: Pick<ChangeLog, 'changeType' | 'recordId' | 'payload'>, triggerSync?: boolean): Promise<void>;
  queueMultipleChanges(changes: Partial<ChangeLog>[]): Promise<void>;

  hasUnuploadedChanges(): Promise<boolean>;
  hasUnconfirmedChanges(): Promise<boolean>;
  hasChanges(): Promise<boolean>;

  markChangeAsUnUploaded(id: string): Promise<void>;
  markChangeAsUploaded(id: string): Promise<void>;
  getChangeById(id: string): Promise<ChangeLog | undefined>;

  getChangeIdsNeedingUploaded(): Promise<string[]>;
  getChangeIdsNeedingConfirmed(): Promise<string[]>;

  markChangeAsConfirmed(id: string): Promise<void>;
  getAllPendingChanges(): Promise<ChangeLog[]>;
  getListIdsWithPendingChanges(): Promise<string[]>;
  getTaskIdsWithPendingChanges(): Promise<string[]>;
}
