import { IonTextarea, TextareaChangeEventDetail } from '@ionic/react';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import useInputFocus from '../../utils/useInputFocus';
import CharacterCount from './CharacterCount';
import ScrollIntoView from './ScrollIntoView';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface CustomTextareaProps {
  value: string;
  accessibilityLabel: string;
  name?: string;
  placeholder?: string;
  fontSize?: string;
  maxlength?: number;
  marginBottom?: string;
  onIonChange?: (event: CustomEvent<TextareaChangeEventDetail>) => void;
  onIonBlur?: (event: React.FocusEvent<HTMLIonTextareaElement, Element>) => void;
  /** `doesEnterKeyBlur` and `onEnterKey` are mutually exlcusive. Only set one or the other. */
  doesEnterKeyBlur?: boolean;
  /** `doesEnterKeyBlur` and `onEnterKey` are mutually exlcusive. Only set one or the other. */
  onEnterKey?: () => void;
  shouldNotFocus?: boolean;
  shouldNotScroll?: boolean;
}

export interface CustomTextareaRef {
  focus: () => void;
}

const CustomTextarea: React.ForwardRefRenderFunction<CustomTextareaRef, CustomTextareaProps> = (
  {
    value,
    name,
    placeholder,
    fontSize,
    maxlength,
    marginBottom,
    onIonChange,
    onIonBlur,
    doesEnterKeyBlur,
    onEnterKey,
    shouldNotFocus,
    shouldNotScroll,
  },
  ref
) => {
  const [cursorPosition, setCursorPosition] = useState(value?.length ?? 0);
  const inputRef = useRef<HTMLIonTextareaElement | null>(null);
  useInputFocus(inputRef, shouldNotFocus);
  const { t } = useTranslation();

  const location = useLocation();
  const currentPath = location.pathname;
  const isListPage = currentPath === '/lists';
  const isTaskPage = currentPath.startsWith('/lists/');

  useEffect(() => {
    if (isListPage && name?.includes('addInputType')) {
      if (inputRef.current) {
        inputRef.current.getInputElement().then((input) => {
          input.setSelectionRange(cursorPosition, cursorPosition);
        });
      }
      return;
    }

    if (isTaskPage && name?.includes('addInputType')) {
      if (inputRef.current) {
        inputRef.current.getInputElement().then((input) => {
          input.setSelectionRange(cursorPosition, cursorPosition);
        });
      }
      return;
    }

    if (inputRef.current) {
      inputRef.current.getInputElement().then((input) => {
        input.setSelectionRange(cursorPosition, cursorPosition);
      });
    }
  }, [cursorPosition]);

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) {
        inputRef.current.getInputElement().then((input) => inputRef.current?.setFocus());
      }
    },
  }));

  const handleBlur = (event: React.FocusEvent<HTMLIonTextareaElement, Element>) => {
    if (onIonBlur) {
      onIonBlur(event);
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLIonTextareaElement> = (event) => {
    if ((doesEnterKeyBlur || onEnterKey) && event.key === 'Enter') {
      event.preventDefault();
      if (doesEnterKeyBlur) {
        inputRef.current?.getInputElement().then((input) => input.blur());
      } else if (onEnterKey) {
        onEnterKey();
      }
    }
  };

  return (
    <>
      <IonTextarea
        mode="ios"
        style={{ marginBottom: marginBottom ? marginBottom : '1rem' }}
        className={`ion-padding-horizontal custom-input ${fontSize ?? ''} `}
        ref={inputRef}
        name={name}
        value={value === t('default.list.name') || value === t('default.task.name') ? '' : value}
        placeholder={placeholder}
        inputmode="text"
        spellcheck={true}
        autoGrow={true}
        rows={name?.includes('addInputType') ? 1 : 2}
        autocapitalize="on"
        maxlength={maxlength}
        onIonInput={(e) => {
          if (onIonChange) {
            e.target.getInputElement().then((input) => {
              setCursorPosition(input.selectionStart);
              onIonChange(e);
            });
          }
        }}
        onBlur={(e) => {
          handleBlur(e);
        }}
        onKeyDown={handleKeyDown}
        enterkeyhint={doesEnterKeyBlur || onEnterKey ? 'done' : undefined}
      />
      {maxlength ? <CharacterCount currentLength={value.length} maxLength={maxlength} /> : null}
      {!shouldNotScroll && <ScrollIntoView value={value} scrollToEnd />}
    </>
  );
};

export default forwardRef<CustomTextareaRef, CustomTextareaProps>(CustomTextarea);
