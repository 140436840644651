import { _user } from '../../../utils/state/model/implementations/ImplementationFactory';
import imageCompression from 'browser-image-compression';
import { logFriendlyObject } from '@otuvy/common-utils';
import { EnvironmentConfig, getFlag } from '../../../utils/environmentUtils';
import { cameraJpegQuality } from '../../../constants/constants';

export const saveCustomProfilePhoto = async (newProfilePhoto: string): Promise<void> => {
  makeImageFilesizeSafe(newProfilePhoto).then((processedImage) => {
    _user.updateCurrentUser({ profilePhoto: processedImage, isDefaultProfilePhoto: false });
  });
};

export const removeImage = async (firstName: string, lastName: string) => {
  const defaultImage = generateDefaultProfilePicture(firstName, lastName);
  return _user.updateCurrentUser({ profilePhoto: defaultImage, isDefaultProfilePhoto: true });
};

const possibleBackgrounds = ['#886D6D', '#887F6D', '#7F886D', '#6D886D', '#6D887F', '#6D7F88', '#6D6D88', '#7F6D88'];

function generateProfilePictureBackground(): string {
  const random = Math.floor(Math.random() * possibleBackgrounds.length);
  return possibleBackgrounds[random];
}

/**
 * TODO: unify the front and back end profile picture code so it is not a divergent copy and paste, but a single unified method
 */
export const generateDefaultProfilePicture = (firstName: string, lastName: string) => {
  const firstInitial = firstName.trim() !== '' ? firstName.trim()[0] : '';
  const lastInitial = lastName.trim() !== '' ? lastName.trim()[0] : '';
  const userInitials = firstInitial.concat(lastInitial).toUpperCase();

  return createImageFromInitials(userInitials);
};

const createImageFromInitials = (name: string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = canvas.height = 280;
  if (context) {
    //generate random background color for image initials so they don't all look the same.
    const imageBackgroundColor = generateProfilePictureBackground();
    context.fillStyle = imageBackgroundColor;
    context.fillRect(0, 0, 280, 280);

    context.textBaseline = 'middle';
    context.textAlign = 'center';
    context.fillStyle = '#FFFFFF';
    context.font = '140px BalooThambi2';
    // was having an issue centering the text, so I had to adjust the y-axis to create a better center.
    context.fillText(name, 140, 154);
  }

  return canvas.toDataURL();
};

const progressIndicator = (progress: number) => {
  console.log('Progress compressing image', progress);
};

/**
 * reduces the file size of an image to fit inside the bounds we have for a Lambda 6MB
 *
 * In case of an exception the image returned may not be correctly resized
 *
 * 6291456 is the lambda limit we need to get below, but we need to allow for URL encoding (which adds 25%-50%), headers (including JWT) and other metadata
 */
export const makeImageFilesizeSafe = async (dataUrl: string): Promise<string> => {
  console.groupCollapsed('Image processing');

  let processedImage = dataUrl;

  try {
    console.log('Attempting to measure size of image');

    const sizeBefore = new TextEncoder().encode(dataUrl).length;
    console.log('Byte length of photo before resize', sizeBefore);

    console.log('Converting dataURL to File');
    const imageConvertedToFile = await imageCompression.getFilefromDataUrl(dataUrl, 'dummy.jpg');
    console.log('After converting image to File we have a file size of:', imageConvertedToFile.size);

    console.log('Compressing image');
    const compressedFile = await imageCompression(imageConvertedToFile, {
      maxSizeMB: 3.5, //For our final URL Data size multiply this number by 1.4 to get our actual result size, which is what we need to get under the Lambda limit of 6291456 bytes.
      onProgress: getFlag(EnvironmentConfig.SHOW_IMAGE_COMPRESSION_PROGRESS)
        ? progressIndicator
        : (progress: number) => {},
      useWebWorker: true, //TODO: find out what the performance differences are with each version
      initialQuality: cameraJpegQuality / 100, //technically for cropped profile photos it is even lower
    });
    console.log('Size of file after compression', compressedFile.size);

    console.log('converting back to Data URL');
    processedImage = await imageCompression.getDataUrlFromFile(compressedFile);

    const sizeAfter = new TextEncoder().encode(processedImage).length;
    console.log('Byte length of photo after resizing', sizeAfter);
  } catch (e) {
    console.error('Error trying to resize image to be safe for API usage', logFriendlyObject(e));
  }

  console.groupEnd();

  return processedImage;
};
