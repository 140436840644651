import { IonButton, IonIcon, IonRow, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { goToDeviceAppropriateAppStore } from '../../updates/SoftwareUpdateAvailable';
import { lockClosedOutline } from 'ionicons/icons';

const UpdateRequiredIndicator: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <IonRow className="ion-justify-content-center ion-align-items-center">
        <IonIcon size="large" icon={lockClosedOutline} />
        <IonText className="ion-padding-start">{t('banner.missedRequiredUpdate.main')}</IonText>
        <IonButton className="ion-padding-start" onClick={goToDeviceAppropriateAppStore}>
          {t('button.update')}
        </IonButton>
      </IonRow>
    </>
  );
};

export default UpdateRequiredIndicator;
