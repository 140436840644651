import { LONG_STRING_MAX_LENGTH } from '../constants/constants';

export const createCopyString = (previousString: string, maxLength: number): string => {
  const postfix: string = ' (copy)';
  if (previousString.endsWith(postfix)) {
    return previousString;
  }
  const maxCharsToKeep: number = maxLength - postfix.length;
  return previousString.slice(0, maxCharsToKeep) + postfix;
};

export const formatLongString = (name: string) => {
  if (name.length > LONG_STRING_MAX_LENGTH) {
    return name.substring(0, 60) + '...' + name.substring(name.length - 20, name.length);
  }
  return name;
};
