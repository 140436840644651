import { isPlatform } from '@ionic/core';
import { useState, useEffect } from 'react';

// The capacitor plugin on Andriod was causing the app to loose focus when the scanner was active.
// This was causing the app to force a sync after the scanner was closed. (Even if the scanner was not used to scan anything)
// The bardcode scanner plugin for Android does not have a method to check if the scanner is active or not.
// So, we need to listen to an event to check if the scanner is active or not.

// This hook listens to the 'scanQrCode' event that is fired when the scanner is active or not.
const useIsAndroidDeviceScanning = () => {
  const [isScanning, setIsScanning] = useState(false);

  useEffect(() => {
    if (isPlatform('android')) {
      const callback = ((e: CustomEvent) => {
        if (e.detail.isScanning) {
          setIsScanning(true);
        } else {
          setIsScanning(false);
        }
      }) as EventListener;

      document.addEventListener('scanQrCode', callback);
      return () => {
        document.removeEventListener('scanQrCode', callback);
      };
    }
  }, []);

  return { isScanning };
};

export default useIsAndroidDeviceScanning;
