import { IonRow, IonCol, IonText } from '@ionic/react';
import { format } from 'date-fns';
import { WorkLogData } from '../../../timetracking/timeTrackingConstants';
import { formatTime } from '../utils/timeUtils';

interface WeeklyBreakdownItemProps {
  index: number;
  timeTrackingRecord: WorkLogData;
}

const WeeklyBreakdownItem: React.FC<WeeklyBreakdownItemProps> = ({ timeTrackingRecord, index }) => {
  return (
    <IonRow style={{ background: index % 2 === 1 ? 'var(--toolbar-background)' : '' }}>
      <IonCol className="ion-text-center">
        <IonText style={{ textTransform: 'capitalize' }}>
          {timeTrackingRecord.punchIn && timeTrackingRecord.punchIn.punchTime
            ? format(new Date(timeTrackingRecord.punchIn.punchTime), 'EEE, MM/dd')
            : '--'}
        </IonText>
      </IonCol>
      <IonCol className="ion-text-center" style={{ textTransform: 'uppercase' }}>
        {timeTrackingRecord.punchIn && timeTrackingRecord.punchIn.punchTime ? (
          <IonText>{formatTime(timeTrackingRecord.punchIn.punchTime)}</IonText>
        ) : (
          <IonText>--</IonText>
        )}
      </IonCol>
      <IonCol className="ion-text-center">
        <IonText style={{ textTransform: 'capitalize' }}>
          {timeTrackingRecord.punchOut && timeTrackingRecord.punchOut.punchTime
            ? format(new Date(timeTrackingRecord.punchOut.punchTime), 'EEE, MM/dd')
            : '--'}
        </IonText>
      </IonCol>
      <IonCol className="ion-text-center" style={{ textTransform: 'uppercase' }}>
        {timeTrackingRecord.punchOut && timeTrackingRecord.punchOut.punchTime ? (
          <IonText>{formatTime(timeTrackingRecord.punchOut.punchTime)}</IonText>
        ) : (
          <IonText>--</IonText>
        )}
      </IonCol>
    </IonRow>
  );
};

export default WeeklyBreakdownItem;
