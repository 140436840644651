import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Preferences } from '@capacitor/preferences';
import { settingsState } from '../../features/settings/settingsState';
import { errorPageState } from '../../pages/ErrorPage';
import { logFriendlyObject } from '@otuvy/common-utils';

export const useLocalStorageEffect = () => {
  const setShowErrorPage = useSetRecoilState(errorPageState);
  const settings = useRecoilValue(settingsState);

  const localStorageEffect = async (storageKey: string) => {
    try {
      await Preferences.set({ key: storageKey, value: JSON.stringify(settings) });
    } catch (error) {
      console.error('Failed to save state to device', error);
      setShowErrorPage({
        showErrorPage: true,
        errorDetails: 'Failed to save state to device\n' + error,
      });
    }
  };

  return {
    localStorageEffect,
  };
};

export const initializeState = async <T>(
  storageKey: string,
  restorer: (savedState: any) => T,
  initFromSavedState: (savedState: T) => void,
  defaultState: T
): Promise<T> => {
  try {
    const { value } = await Preferences.get({ key: storageKey });
    if (!value) {
      console.log('No state saved in device storage, initializing to default state');
      initFromSavedState(defaultState);
      return defaultState;
    }
    const savedState: T = restorer(JSON.parse(value));
    initFromSavedState(savedState);
    return savedState;
  } catch (error) {
    console.error('Failed to initialize state from device storage', logFriendlyObject(error));
    initFromSavedState(defaultState);
    return defaultState;
  }
};
