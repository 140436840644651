import { CompletionType, NOT_COMPLETE } from '../../../../../../constants/constants';
import { List, Photo, Task, User } from '../../../interfaces/objects';
import { OrgSettingKey } from '../../../../../../features/settings/orgSettings/orgSettingsConstants';

export interface SyncedRecord {
  syncedOn?: Date;
}

export class DexieList extends List implements SyncedRecord {
  public syncedOn?: Date;
}

export class DexieTask extends Task implements SyncedRecord {
  public syncedOn?: Date;
}

export class DexieCompletionCache {
  public taskId: string = '';
  public listId: string = '';

  public cacheId: string = '';

  public dueDate?: Date | null;
  public notes?: string;
  public completedOn?: Date;
  public completedBy?: string;
  public isCompleted: CompletionType = NOT_COMPLETE; // boolean is not indexable in IndexedDB, though they do have a "binary" type that is an unsigned byte values (https://www.w3.org/TR/IndexedDB/#key-construct)

  public completionPhotoIds?: string[];
  public pendingPhotos?: string[];
}

export class DexiePhoto extends Photo {
  public cacheTime?: Date;
}

export class DexieUser extends User implements SyncedRecord {
  public syncedOn?: Date;
}

export class DexieSetting {
  public key: OrgSettingKey = OrgSettingKey.UNDEFINED;
  public value: string | null = null;
}
