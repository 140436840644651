import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonPopover,
  IonRow,
  IonText,
  IonToolbar,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { create, createOutline, ellipsisHorizontal, search } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import AddInputType, { showInputState } from '../features/checklist/AddInputType';
import MultiSelectActionSheet from '../features/checklist/MultiSelectActionSheet';
import ListDetailsViewSkeletonText from '../features/checklist/lists/ListDetailsViewSkeletonText';
import { useDisplayListPageSections, useTogglePopover } from '../features/checklist/lists/ListsView';
import RenameListInput from '../features/checklist/lists/RenameListInput';
import PullToRefresh from '../features/general/PullToRefresh';
import AssignMenu from '../features/peopleorg/components/AssignMenu';
import useIsListOwner from '../features/peopleorg/hooks/useIsListOwner';
import useSortOptions, { SortOption } from '../features/sort/useSortOptions';
import { _list, _task } from '../utils/state/model/implementations/ImplementationFactory';

import { CSSTransition } from 'react-transition-group';
import { writeAccessRestrictedState } from '../App';
import SortOptions from '../components/SortOptions';
import ListNotes from '../features/checklist/lists/ListNotes';
import ListProgressBar from '../features/checklist/lists/ListProgressBar';
import { multiSelectItemsState } from '../features/checklist/lists/state/useMultiSelectTasks';
import {
  filterTasksAndHeaders,
  justTheTasks,
  prepareQMTasks,
  prepareTasks,
} from '../features/checklist/tasks/taskUtils';
import HeaderSearchBar from '../features/general/HeaderSearchBar';
import SyncingIndicator from '../features/general/SyncingIndicator';
import { useAssignMenu } from '../features/peopleorg/hooks/useAssignMenu';
import { logFriendlyObject } from '@otuvy/common-utils';

import KeyboardSpacer from '../components/KeyboardSpacer';
import ListDetailsView from '../features/checklist/lists/ListDetailsView';
import '../features/checklist/lists/ListNotes.css';
import AddItemFab from '../features/general/AddItemFab';
import useGetLocalData, { SubscriptionsType } from '../hooks/useGetLocalData';
import { EnvironmentConfig, getFlag } from '../utils/environmentUtils';

interface ListDetailsPageProps
  extends RouteComponentProps<{
    listId: string;
  }> {}

export const multiSelectModeAtom = atom<boolean>({ key: 'multiSelectModeAtom', default: false });

const ListDetailsPage: React.FC<ListDetailsPageProps> = ({
  match: {
    params: { listId },
  },
}) => {
  const { isRestricted: isWriteAccessRestricted } = useRecoilValue(writeAccessRestrictedState);
  const [multiSelectedItems, setMultiSelectedItems] = useRecoilState(multiSelectItemsState);
  const [showInput, setShowInput] = useRecoilState(showInputState);
  const ionToolbarRef = React.useRef<HTMLIonToolbarElement>(null);

  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const [searchbarValue, setSearchbarValue] = useState<string>('');
  const [isListNotesInputOpen, setIsListNotesInputOpen] = useState<boolean>(false);
  const ionContentRef = React.useRef<HTMLIonContentElement>(null);
  const ionHeaderRef = React.useRef<HTMLIonHeaderElement>(null);

  const [didViewEnter, setDidViewEnter] = useState(false);
  const { isCompletedSectionOn, toggleCompletedSection } = useDisplayListPageSections('tasks');
  const [isMultiSelectMode, setIsMultiSelectMode] = useRecoilState(multiSelectModeAtom);
  const [isRenameList, setIsRenameList] = useState<boolean>(false);
  const {
    isOpen: isAssignMenuOpenHook,
    list: assignMenuList,
    task: assignMenuTask,
    mode: assignMenuMode,
    key: assignMenuKey,
    openMenu: openAssignMenu,
    closeMenu: closeAssignMenu,
  } = useAssignMenu();

  const { popoverOpen, setPopoverOpen, openPopover, popoverRef } = useTogglePopover();

  const {
    sortOption: incompleteSortOption,
    setSortOption: setIncompleteSortOption,
    areSortOptionsOpen: areIncompleteSortOptionsOpen,
    setAreSortOptionsOpen: setAreIncompleteSortOptionsOpen,
  } = useSortOptions('tasks/incomplete');

  const {
    sortOption: completedSortOption,
    setSortOption: setCompletedSortOption,
    areSortOptionsOpen: areCompletedSortOptionsOpen,
    setAreSortOptionsOpen: setAreCompletedSortOptionsOpen,
  } = useSortOptions('tasks/completed');

  const { data: listAndTasks, loading } = useGetListDetailsPageData(listId, completedSortOption, incompleteSortOption);

  const { t } = useTranslation();
  const { isListOwner } = useIsListOwner(listAndTasks.list);

  useIonViewWillEnter(() => setDidViewEnter(true));
  useIonViewWillLeave(() => {
    showInput && setShowInput(false);
  }, [showInput]);

  const justIncompleteTasksArr = justTheTasks(listAndTasks.incompleteTasks);
  const justCompletedTasksArr = justTheTasks(listAndTasks.completedTasks);

  useEffect(() => {
    setTimeout(() => {
      if (!ionToolbarRef.current) return;
      if (!ionToolbarRef.current.shadowRoot) return;
      const toolbar = ionToolbarRef.current.shadowRoot.querySelector('.toolbar-container');
      toolbar?.setAttribute('style', 'align-items: start');
    }, 50);
  }, [listAndTasks, isSearchBarOpen]);

  const isSharable = !isWriteAccessRestricted && isListOwner;
  const hasMoreThanOneTask = listAndTasks.completedTasks.length > 1 || listAndTasks.incompleteTasks.length > 1;
  const isEllipsisMenuShown = isSharable || hasMoreThanOneTask;

  const searchbarValueChangeHandler = (value: string) => {
    setSearchbarValue(value);
  };

  const endMultiSelect = () => {
    setMultiSelectedItems([]);
    setIsMultiSelectMode(false);
  };

  const headerStartButton = (): JSX.Element => {
    const selectAllButton: JSX.Element = (
      <IonButton
        mode="ios"
        fill="clear"
        onClick={() => setMultiSelectedItems([...justIncompleteTasksArr, ...justCompletedTasksArr])}
      >
        <IonText style={{ paddingTop: '16px' }} class="text-transform-none" color="primary">
          {t('selectAll')}
        </IonText>
      </IonButton>
    );
    const deselectAllButton: JSX.Element = (
      <IonButton mode="ios" fill="clear" onClick={() => setMultiSelectedItems([])}>
        <IonText style={{ paddingTop: '16px' }} class="text-transform-none" color="primary">
          {t('deselectAll')}
        </IonText>
      </IonButton>
    );

    if (isMultiSelectMode) {
      if (multiSelectedItems.length === justIncompleteTasksArr.length + justCompletedTasksArr.length) {
        return deselectAllButton;
      } else {
        return selectAllButton;
      }
    }
    return <IonBackButton className="no-height" style={{ paddingTop: '2px' }} defaultHref="/list" />;
  };

  const headerEndButton = (): JSX.Element => {
    const doneButton: JSX.Element = (
      <IonButton style={{ paddingTop: '14px' }} mode="ios" fill="clear" onClick={endMultiSelect}>
        <IonText color="primary">{t('done')}</IonText>
      </IonButton>
    );

    if (isMultiSelectMode) {
      return doneButton;
    } else if (isEllipsisMenuShown) {
      return (
        <>
          <IonIcon className="font-24 ion-padding-end" icon={search} onClick={() => setIsSearchBarOpen(true)} />
          <IonIcon className="font-32 ion-padding-end" icon={ellipsisHorizontal} onClick={openPopover} />
        </>
      );
    } else {
      return (
        <IonIcon
          key={`ionHeaderEndButtonIcon${listId}`}
          icon={undefined}
          style={{ paddingTop: '0.3rem' }}
          className="font-32 ion-padding-end"
        ></IonIcon>
      );
    }
  };

  if (loading)
    return (
      <IonPage>
        <IonHeader></IonHeader>
        <IonContent scrollY={true} ref={ionContentRef} forceOverscroll>
          <ListDetailsViewSkeletonText />
        </IonContent>
      </IonPage>
    );

  return (
    <IonPage>
      <IonHeader key={`ionHeader${listId}`} ref={ionHeaderRef} className={`${showInput && 'disable-pointer-events'}`}>
        {isSearchBarOpen ? (
          <HeaderSearchBar
            customPlaceholder={t('searchForTasks') as string}
            onSearchbarBlur={setIsSearchBarOpen}
            onSearchbarValueChange={searchbarValueChangeHandler}
            value={searchbarValue}
          />
        ) : (
          <IonToolbar ref={ionToolbarRef} mode="md" class="ion-padding-top" style={{ '--ion-safe-area-top': '0px' }}>
            <IonButtons className={`${isRenameList && 'disable-pointer-events'}`} slot="start">
              {headerStartButton()}
            </IonButtons>
            <div className="d-flex ion-align-items-center ion-justify-content-between">
              <div style={{ minWidth: '0', width: '100%' }}>
                <RenameListInput
                  list={listAndTasks.list}
                  isListOwner={isListOwner}
                  setIsRenameList={setIsRenameList}
                  disableExpandIcon
                />
              </div>
              <div>
                <SyncingIndicator />
              </div>
            </div>
            {!isMultiSelectMode && (
              <IonRow
                className={`ion-justify-content-center ion-align-items-end ion-no-padding ${
                  isRenameList && 'disable-pointer-events'
                }`}
              >
                {
                  <IonIcon
                    className="font-24"
                    color={listAndTasks.list?.notes ? 'primary' : 'medium'}
                    icon={listAndTasks.list?.notes ? create : createOutline}
                    style={{ paddingRight: '0.5rem' }}
                    onClick={() => setIsListNotesInputOpen((prev) => !prev)}
                  />
                }
                <div style={{ flexGrow: '100' }}>
                  <ListProgressBar
                    numItems={listAndTasks.list ? listAndTasks.list.numTasks : 0}
                    numItemsCompleted={listAndTasks.list ? listAndTasks.list.numCompletedTasks : 0}
                  />
                </div>
              </IonRow>
            )}
            <IonButtons className={`${isRenameList && 'disable-pointer-events'}`} slot="end">
              {headerEndButton()}
            </IonButtons>
            <IonPopover
              className="popover-menu"
              mode="md"
              ref={popoverRef}
              arrow={false}
              isOpen={popoverOpen}
              onClick={() => setPopoverOpen(false)}
              onDidDismiss={() => setPopoverOpen(false)}
            >
              <IonContent>
                {!isWriteAccessRestricted && hasMoreThanOneTask && isListOwner && (
                  <IonItem onClick={() => setIsMultiSelectMode(true)} lines="none">
                    <IonText>{t('menu.action.select.tasks')}</IonText>
                  </IonItem>
                )}
                {isSharable && (
                  <IonItem lines="none" onClick={() => openAssignMenu(listId, null, 'share')}>
                    <IonText>
                      {getFlag(EnvironmentConfig.LIST_OWNER_UI)
                        ? t('menu.action.shareListOrAddOwners')
                        : t('menu.action.share.list')}
                    </IonText>
                  </IonItem>
                )}
                {hasMoreThanOneTask && !listAndTasks.list?.qmInspectionId && (
                  <IonItem lines="none" onClick={() => setAreIncompleteSortOptionsOpen(true)}>
                    <IonText>{t('menu.action.sort')}</IonText>
                  </IonItem>
                )}
                {hasMoreThanOneTask && (
                  <IonItem lines="none" onClick={() => toggleCompletedSection()}>
                    <IonText>
                      {!isCompletedSectionOn
                        ? t('menu.action.showCompletedTasks')
                        : t('menu.action.hideCompletedTasks')}
                    </IonText>
                  </IonItem>
                )}
              </IonContent>
            </IonPopover>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent
        scrollY={true}
        ref={ionContentRef}
        class={isRenameList ? 'disable-pointer-events' : ''}
        forceOverscroll
      >
        <PullToRefresh listId={listId} />

        <CSSTransition in={isListNotesInputOpen} classNames="list-notes-section" timeout={500} unmountOnExit>
          <div>
            <ListNotes
              isListOwner={isListOwner}
              isWriteAccessRestricted={isWriteAccessRestricted}
              listId={listAndTasks.list?.listId!}
              value={listAndTasks.list?.notes}
              onDismiss={setIsListNotesInputOpen}
              contentRef={ionContentRef}
              ionHeaderRef={ionHeaderRef}
            />
            {/* New list owners component */}
            <div style={{ padding: '0 2rem', marginTop: '12px' }}>
              <div>
                <IonLabel className="ion-margin-top" style={{ fontWeight: '500', '--ion-label-font-size': '22px' }}>
                  {t('list.owner')}:
                </IonLabel>
              </div>
              <div style={{ marginTop: '8px' }}>
                <IonLabel>
                  <IonText className="font-20 font-weight-400">{listAndTasks.list?.ownerName}</IonText>
                </IonLabel>
              </div>
            </div>
          </div>
        </CSSTransition>

        {listAndTasks.list && (
          <ListDetailsView
            ionContentRef={ionContentRef.current?.shadowRoot?.querySelector('.inner-scroll') as HTMLElement}
            list={listAndTasks.list}
            completeTasks={filterTasksAndHeaders(listAndTasks.completedTasks, searchbarValue)}
            incompleteTasks={filterTasksAndHeaders(listAndTasks.incompleteTasks, searchbarValue)}
            isCompletedSectionOn={isCompletedSectionOn}
            openAssignMenu={openAssignMenu}
            isListOwner={isListOwner}
            setAreCompletedSortOptionsOpen={setAreCompletedSortOptionsOpen}
          />
        )}
        {!isWriteAccessRestricted &&
        isListOwner &&
        !showInput &&
        !isMultiSelectMode &&
        !isSearchBarOpen &&
        !isRenameList ? (
          <AddItemFab type="task" />
        ) : null}
      </IonContent>
      <KeyboardSpacer />
      {listAndTasks.list && showInput && didViewEnter && isListOwner && (
        <>
          <AddInputType inputType="addTask" listID={listAndTasks.list.listId} />
        </>
      )}
      <MultiSelectActionSheet
        endMultiSelect={endMultiSelect}
        openAssignMenu={openAssignMenu}
        listId={listId}
        isListOwner={isListOwner}
      />
      <SortOptions
        isOpen={areIncompleteSortOptionsOpen}
        setSortOption={setIncompleteSortOption}
        setAreSortOptionsOpen={setAreIncompleteSortOptionsOpen}
      />
      <SortOptions
        isOpen={areCompletedSortOptionsOpen}
        setSortOption={setCompletedSortOption}
        setAreSortOptionsOpen={setAreCompletedSortOptionsOpen}
        excludedSortOptions={[SortOption.DUE_DATE]}
      />
      {isListOwner && (
        <AssignMenu
          key={assignMenuKey}
          isOpen={isAssignMenuOpenHook}
          menuType={assignMenuMode}
          listId={assignMenuList}
          taskIds={assignMenuTask}
          onDismiss={closeAssignMenu}
        />
      )}
    </IonPage>
  );
};

export default ListDetailsPage;

function useGetListDetailsPageData(
  listId: string,
  completedSortOption: SortOption | undefined,
  incompleteSortOption: SortOption | undefined
) {
  const { data = { loaded: false, list: null, completedTasks: [], incompleteTasks: [] }, loading } = useGetLocalData(
    getListDetailsPageData,
    [listId, completedSortOption, incompleteSortOption],
    SubscriptionsType.ListChanges,
    undefined,
    listId
  );

  async function getListDetailsPageData() {
    try {
      const listData = await _list.getListById(listId);
      if (!listData) throw new Error('Failed to load the list data.');

      const tasksData = await _task.getTasksByListId(listId);
      if (!tasksData) throw new Error('Failed to load tasks data.');

      let completedTasks;
      let incompleteTasks;

      if (listData.qmInspectionId) {
        completedTasks = await prepareQMTasks(tasksData, completedSortOption, true);
        incompleteTasks = await prepareQMTasks(tasksData, incompleteSortOption, false);
      } else {
        //Default task preperation code
        completedTasks = await prepareTasks(tasksData, completedSortOption, true);
        incompleteTasks = await prepareTasks(tasksData, incompleteSortOption, false);
      }

      return {
        list: listData,
        completedTasks,
        incompleteTasks,
        loaded: true,
      };
    } catch (err) {
      console.error('Error loading tasks and list', logFriendlyObject(err));
    }
  }

  return { data, loading };
}
