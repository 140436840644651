import { IonAvatar, IonText } from '@ionic/react';

interface ExtraAssigendUsersCounterProps {
  count: number;
}

const ExtraAssigendUsersCounter: React.FC<ExtraAssigendUsersCounterProps> = ({ count }) => {
  return (
    <IonAvatar
      class="d-flex ion-justify-content-center ion-align-items-center ion-text-center"
      style={{ width: '32px', height: '32px', background: '#C0C9C0', color: '#404943' }}
    >
      <IonText style={{ lineHeight: '8px', fontSize: '14px' }}>
        +{count}
        <br></br>
        <IonText class="font-8">more</IonText>
      </IonText>
    </IonAvatar>
  );
};

export default ExtraAssigendUsersCounter;
