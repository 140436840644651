import {
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RRule, Weekday } from 'rrule';
import { rruleByWeekdayOptions } from '../utils/recurrenceUtils';
import { CustomValues } from './CustomRecurrenceSelect';

const dateOptions = Array.from({ length: 31 }, (_, i) => i + 1);

type SegmentOptions = 'date' | 'day';
interface MonthMenuProps {
  onCustomValueChange: (value: CustomValues) => void;
  recurrenceValue: RRule | null;
}

const initialSegmentState = (rule: RRule | null): SegmentOptions => {
  if (!rule) return 'date';
  if (!rule.origOptions.interval) return 'date';
  if (rule.options.freq !== RRule.MONTHLY) return 'date';
  return !rule.origOptions.byweekday ? 'date' : 'day';
};

const initialSelectedDays = (rule: RRule | null) => {
  if (!rule?.options?.bymonthday) return [];
  if (!rule.origOptions.interval) return [];
  if (rule.options.freq !== RRule.MONTHLY) return [];
  return rule.options.bymonthday;
};

const MonthMenu: React.FC<MonthMenuProps> = ({ onCustomValueChange, recurrenceValue }) => {
  const [selectedDays, setSelectedDays] = useState<number[]>(() => initialSelectedDays(recurrenceValue));
  const [selectedSegment, setSelectedSegment] = useState<SegmentOptions>(() => initialSegmentState(recurrenceValue));
  // TODO: I'm not sure yet how to initialize this state.
  // It looks like the RRule library does not expose the Nth value,
  const [ordinalNumber, setOrdinalNumber] = useState(1);
  const [dayOfTheWeek, setDayOfTheWeek] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    if (selectedSegment === 'date') return onCustomValueChange(selectedDays);

    if (selectedSegment === 'day') {
      const byweekdayValue = rruleByWeekdayOptions.get(dayOfTheWeek)?.nth(ordinalNumber);
      return onCustomValueChange([byweekdayValue] as Weekday[]);
    }
  }, [selectedDays, ordinalNumber, dayOfTheWeek, selectedSegment]);

  const optionClickHandler = (value: number) => {
    if (selectedDays.includes(value)) {
      const update = [...selectedDays].filter((element) => element !== value);
      setSelectedDays(update);
    } else {
      const update = [...selectedDays, value];
      setSelectedDays(update);
    }
  };

  return (
    <IonGrid class="ion-no-padding">
      <IonRow>
        <IonLabel class="font-32 font-weight-500">{t('repeatMenu.custom.on')}:</IonLabel>
      </IonRow>
      <IonRow class="d-flex" style={{ justifyContent: 'center' }}>
        <IonSegment value={selectedSegment}>
          <IonSegmentButton value="date" onClick={() => setSelectedSegment('date')}>
            <IonLabel>{t('date')}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="day" onClick={() => setSelectedSegment('day')}>
            <IonLabel>{t('dayOfTheWeek')}</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </IonRow>
      {selectedSegment === 'date' ? (
        <div className="date-grid ion-margin-top ion-text-center">
          {dateOptions.map((element) => (
            <IonButton
              className="font-18 calendar-button font-weight-600"
              color={selectedDays.includes(element) ? 'primary' : 'none'}
              key={element}
              onClick={() => optionClickHandler(element)}
            >
              {element}
            </IonButton>
          ))}
        </div>
      ) : (
        <IonGrid class="ion-no-padding ion-margin-vertical">
          <IonCol>
            <IonRow class="ion-justify-content-around">
              <IonSelect
                style={{ width: '40%', borderBottom: '2px solid var(--ion-color-light)' }}
                interface="popover"
                placeholder="Select"
                value={ordinalNumber}
                onIonChange={(e) => setOrdinalNumber(e.detail.value)}
              >
                <IonSelectOption value={1}>{t('repeatMenu.custom.first')}</IonSelectOption>
                <IonSelectOption value={2}>{t('repeatMenu.custom.second')}</IonSelectOption>
                <IonSelectOption value={3}>{t('repeatMenu.custom.third')}</IonSelectOption>
                <IonSelectOption value={4}>{t('repeatMenu.custom.fourth')}</IonSelectOption>
                <IonSelectOption value={-1}>{t('repeatMenu.custom.fifth')}</IonSelectOption>
              </IonSelect>
              <IonSelect
                style={{ width: '40%', borderBottom: '2px solid var(--ion-color-light)' }}
                interface="popover"
                placeholder="Select Day"
                value={dayOfTheWeek}
                onIonChange={(e) => setDayOfTheWeek(e.detail.value)}
              >
                <IonSelectOption value={0}>{t('repeatMenu.custom.sunday')}</IonSelectOption>
                <IonSelectOption value={1}>{t('repeatMenu.custom.monday')}</IonSelectOption>
                <IonSelectOption value={2}>{t('repeatMenu.custom.tuesday')}</IonSelectOption>
                <IonSelectOption value={3}>{t('repeatMenu.custom.wednesday')}</IonSelectOption>
                <IonSelectOption value={4}>{t('repeatMenu.custom.thursday')}</IonSelectOption>
                <IonSelectOption value={5}>{t('repeatMenu.custom.friday')}</IonSelectOption>
                <IonSelectOption value={6}>{t('repeatMenu.custom.saturday')}</IonSelectOption>
              </IonSelect>
            </IonRow>
          </IonCol>
        </IonGrid>
      )}
    </IonGrid>
  );
};

export default MonthMenu;
