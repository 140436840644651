import { ConnectionStatus, ConnectionType, Network } from '@capacitor/network';
import { selector } from 'recoil';
import { isPlatform } from '@ionic/react';
import { EnvironmentConfig, getFlag } from '../../utils/environmentUtils';
import { Preferences } from '@capacitor/preferences';
import { SETTINGS_STORAGE_KEY, Settings } from '../../features/settings/settingsState';

/**
 * Only exported for the debug page, otherwise we would keep this private
 */
export async function connectionType(): Promise<string> {
  const status = await Network.getStatus();
  return status.connectionType;
}

/**
 * Only exported for the debug page, otherwise we would keep this private
 */
export async function isNetworkConnected(): Promise<boolean> {
  const status: ConnectionStatus = await Network.getStatus();
  return status.connected;
}

/**
 * Only exported for the debug page, otherwise we would keep this private
 */
export async function wifiOnlySetting(): Promise<boolean> {
  const { value } = await Preferences.get({ key: SETTINGS_STORAGE_KEY });

  if (!value) {
    return false;
  }
  const parsedSettings: Settings = JSON.parse(value);

  return parsedSettings.wifiOnly ?? false;
}

/**
 * WARNING: This only works properly on mobile. On web, it will always show as connected through wifi.
 */
export default async function isPermittedNetworkConnected(): Promise<boolean> {
  if (!getFlag(EnvironmentConfig.SERVER_SYNC)) {
    return false;
  }

  const wifiOnly = await wifiOnlySetting();
  //Only fetch status once rather than in multiple helper functions to make sure we are checking the same connection
  const status: ConnectionStatus = await Network.getStatus();

  return (wifiOnly && status.connectionType === 'wifi' && status.connected) || (!wifiOnly && status.connected);
}

/**
 * WARNING: This only works properly on mobile. On web, it will always show as connected.
 */
export const isPermittedNetworkConnectedState = selector<boolean>({
  key: 'isPermittedNetworkConnected',
  get: ({ get }) => {
    return isPermittedNetworkConnected();
  },
});

export const getIsConnected = async (): Promise<boolean> => {
  // Since the Network plugin appears to only really work on mobile, we default to true on web
  if (!isPlatform('hybrid')) {
    return true;
  }
  try {
    return await isPermittedNetworkConnected();
  } catch {
    return false;
  }
};

export const getConnectionType = async (): Promise<ConnectionType> => {
  // Since the Network plugin appears to only really work on mobile, we default to 'wifi' on web
  if (!isPlatform('hybrid')) {
    return 'wifi';
  }
  try {
    return (await Network.getStatus()).connectionType;
  } catch {
    return 'unknown';
  }
};
