import { IonButton, IonFooter, IonIcon, IonToolbar, IonButtons, IonModal, IonActionSheet } from '@ionic/react';
import { calendarNumberOutline, cameraOutline, copyOutline, ellipsisHorizontal, trashOutline } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import DeleteConfirmation from '../general/DeleteConfirmation';
import addUserIcon from '../../assets/images/icons/Add_User_Icon.svg';
import { _task } from '../../utils/state/model/implementations/ImplementationFactory';
import CalendarView from '../calendar/components/CalendarView';
import { multiSelectItemsState } from './lists/state/useMultiSelectTasks';

import { t } from 'i18next';

export interface MultiSelectActionSheetProps {
  endMultiSelect: () => void;
  openAssignMenu: (list: string | null, task: string[] | null, mode: 'assign' | 'share') => void;
  listId: string;
  isListOwner: boolean;
}

const MultiSelectActionSheet: React.FC<MultiSelectActionSheetProps> = ({
  endMultiSelect,
  openAssignMenu,
  listId,
  isListOwner,
}) => {
  const selectedItems = useRecoilValue(multiSelectItemsState);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false);
  const [wasIonButtonClicked, setWasIonButtonClicked] = useState<boolean>(false);
  const [isEllipsisMenuOpen, setIsEllipsisMenuOpen] = useState<boolean>(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState<boolean>(false);

  const calendarModalRef = useRef<HTMLIonModalElement>(null);

  const deleteUs = async () => {
    setWasIonButtonClicked(true);
    await _task.deleteMultipleTasks(selectedItems, true);
    setIsDeleteConfirmationOpen(false);
    endMultiSelect();
    setWasIonButtonClicked(false);
    setIsEllipsisMenuOpen(false);
  };
  const handleDuplicate = async () => {
    setWasIonButtonClicked(true);
    await _task.duplicateMultipleTasks(selectedItems);
    endMultiSelect();
    setWasIonButtonClicked(false);
    setIsEllipsisMenuOpen(false);
  };

  const onCalendarCancelClickHandler = async () => {
    await calendarModalRef.current?.dismiss();
    setIsCalendarModalOpen(false);
    endMultiSelect();
    setWasIonButtonClicked(false);
    setIsEllipsisMenuOpen(false);
  };

  const onUserAssignClickHandler = () => {
    setWasIonButtonClicked(true);
    openAssignMenu(
      listId,
      selectedItems.map((task) => task.taskId),
      'assign'
    );
    setTimeout(() => {
      setWasIonButtonClicked(false);
    }, 3000);
  };

  const onRequirePhotoClickHandler = async () => {
    setWasIonButtonClicked(true);
    const areAllPhotoRequired = selectedItems.every((task) => task.isPhotoRequired);
    await _task.updateMultipleTasksIsPhotoRequired(
      selectedItems.map((task) => task.taskId),
      areAllPhotoRequired ? false : true
    );
    endMultiSelect();
    setWasIonButtonClicked(false);
    setIsEllipsisMenuOpen(false);
  };

  if (selectedItems.length === 0) return null;

  return (
    <>
      <IonFooter class="action-buttons">
        <IonToolbar className={`${wasIonButtonClicked ? 'disable-pointer-events' : ''}`}>
          <IonButtons class="ion-justify-content-center ion-align-items-center ion-padding-vertical">
            <IonButton
              disabled={!isListOwner}
              className="circle-button ion-text-center custom-button-radius"
              fill="solid"
              color="primary"
              onClick={() => {
                setIsCalendarModalOpen(true);
              }}
            >
              <IonIcon slot="icon-only" size="large" icon={calendarNumberOutline} />
            </IonButton>
            <IonButton
              className="circle-button ion-text-center custom-button-radius"
              fill="solid"
              color="primary"
              disabled={!isListOwner || selectedItems.some((task) => task.isCompleted)}
              onClick={() => {
                onUserAssignClickHandler();
              }}
            >
              <IonIcon slot="icon-only" size="medium" icon={addUserIcon} />
            </IonButton>
            <IonButton
              className="circle-button ion-text-center custom-button-radius"
              fill="solid"
              color="primary"
              // disabled={!isListOwner || selectedItems.some((task) => task.isCompleted)}
              onClick={() => setIsEllipsisMenuOpen(true)}
            >
              <IonIcon slot="icon-only" size="large" icon={ellipsisHorizontal} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
      <IonActionSheet
        isOpen={isEllipsisMenuOpen}
        buttons={[
          {
            text: `${t('multiSelectMenu.dueDateAndRepeat')}`,
            icon: calendarNumberOutline,
            handler: () => {
              setIsCalendarModalOpen(true);
            },
          },
          {
            text: `${t('assign')} ${t('task', { count: selectedItems.length })}`,
            icon: addUserIcon,
            handler: onUserAssignClickHandler,
          },
          {
            text: `${
              selectedItems.every((task) => task.isPhotoRequired)
                ? t('multiSelectMenu.photoRequired.makeOptional')
                : t('multiSelectMenu.photoRequired')
            }`,
            icon: cameraOutline,
            handler: onRequirePhotoClickHandler,
          },
          {
            text: `${t('duplicate')} ${t('task', { count: selectedItems.length })}`,
            icon: copyOutline,
            handler: handleDuplicate,
          },
          {
            text: `${t('delete')} ${t('task', { count: selectedItems.length })}`,
            role: 'destructive',
            icon: trashOutline,
            handler: deleteUs,
          },
          {
            text: t('cancel')!,
            role: 'cancel',
          },
        ]}
        onWillDismiss={() => setIsEllipsisMenuOpen(false)}
      />
      <DeleteConfirmation
        isOpen={isDeleteConfirmationOpen}
        setIsOpen={setIsDeleteConfirmationOpen}
        deleteMe={deleteUs}
        confirmButtonText="confirmation.delete.task.generic.confirm"
        header="confirmation.delete.task.generic.header"
        message="confirmation.delete.task.generic.message"
      />
      {isListOwner && (
        <IonModal ref={calendarModalRef} isOpen={isCalendarModalOpen}>
          <CalendarView onCancel={onCalendarCancelClickHandler} tasks={selectedItems} />
        </IonModal>
      )}
    </>
  );
};

export default MultiSelectActionSheet;
