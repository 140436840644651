import { IonIcon } from '@ionic/react';
import { star, starOutline } from 'ionicons/icons';

interface PushPinIconProps {
  isActive?: boolean;
}

const PushPinIcon: React.FC<PushPinIconProps> = ({ isActive = false }) => {
  return <IonIcon icon={isActive ? star : starOutline} className="font-24" />;
};

export default PushPinIcon;
