import { IonAvatar, IonIcon } from '@ionic/react';

import addUserIcon from '../../../assets/images/icons/Add_User_Icon.svg';

const AssignUserButton: React.FC = () => {
  return (
    <IonAvatar
      class="d-flex ion-justify-content-center ion-align-items-center"
      style={{ width: '32px', height: '32px' }}
    >
      <IonIcon icon={addUserIcon} color="primary" className={`font-24`} />
    </IonAvatar>
  );
};

export default AssignUserButton;
