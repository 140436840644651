import { ZipBlobParameters } from '../databaseDebug';
import { clipboardDownloader } from './clipboardDownloader';
import { objectURLWindowDownload } from './objectURLWindowDownloader';
import { Device } from '@capacitor/device';

export const debugDataDownloader = async (params: ZipBlobParameters) => {
  console.log('Attempting download based on platform');

  const device = await Device.getInfo();

  if (device.platform === 'web') {
    objectURLWindowDownload(params);
  } else {
    clipboardDownloader(params);
  }
};
