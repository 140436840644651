import { sortByAssignedTo, sortByCreatedOn, sortByDueDate, sortByUpdatedOn } from '../../sort/sortUtils';
import { HeaderDO, TaskDO } from '../../../utils/state/model/interfaces/displayObjects';
import { SortOption } from '../../sort/useSortOptions';

export const prepareQMTasks = async (
  tasks: TaskDO[],
  sortOption: SortOption | undefined,
  isCompletedGroup: boolean
): Promise<(TaskDO | HeaderDO)[]> => {
  return prepareTasks(tasks, sortOption, isCompletedGroup);
};

const sortTasksByOption = async (
  arr: TaskDO[],
  sortOption: SortOption | undefined,
  isCompletedGroup: boolean
): Promise<TaskDO[]> => {
  switch (sortOption) {
    case SortOption.NAME:
      return [...arr].sort((a, b) => a.taskName.localeCompare(b.taskName));
    case SortOption.CREATION_DATE:
      return sortByCreatedOn(arr);
    case SortOption.DUE_DATE:
      return sortByDueDate(arr);
    case SortOption.RECENT_ACTIVITY:
      return sortByUpdatedOn(arr);
    case SortOption.ASSIGNED_TO:
      return await sortByAssignedTo(arr);
    default:
      return isCompletedGroup ? sortByCreatedOn(arr) : sortByDueDate(arr, 'ascending');
  }
};

/**
 * From our friends at ChatGPT
 */
function compareArrays(arr1: string[] | undefined, arr2: string[] | undefined): number {
  // Handle cases where one array is undefined
  if (!arr1) {
    return -1;
  }
  if (!arr2) {
    return 1;
  }

  const minLength = Math.min(arr1.length, arr2.length);

  // Compare elements until a difference is found
  for (let i = 0; i < minLength; i++) {
    const comparison = arr1[i].localeCompare(arr2[i]);
    if (comparison !== 0) {
      return comparison; // Return the result of string comparison
    }
    // If elements are equal, continue to the next element
  }

  // If all elements are equal up to the length of the shorter array,
  // the shorter array should come first
  if (arr1.length < arr2.length) {
    return -1; // arr1 should come before arr2
  } else if (arr1.length > arr2.length) {
    return 1; // arr1 should come after arr2
  }

  // If arrays are identical, return 0
  return 0;
}

const flattenArray = async (
  arr: TaskDO[],
  sortOption: SortOption | undefined,
  isCompletedGroup: boolean
): Promise<(TaskDO | HeaderDO)[]> => {
  if (!arr || arr.length === 0) return arr;

  const result: (TaskDO | HeaderDO)[] = [];

  const task = arr[0];
  if (task.header && task.header.length > 0 && task.header.some((header) => header !== '')) {
    const headerObject: HeaderDO = { headers: task.header };
    result.push(headerObject);
  }

  result.push(...(await sortTasksByOption(arr, sortOption, isCompletedGroup)));

  return result;
};

export const prepareTasks = async (
  tasks: TaskDO[],
  sortOption: SortOption | undefined,
  isCompletedGroup: boolean
): Promise<(TaskDO | HeaderDO)[]> => {
  if (tasks && tasks.length === 0) return tasks;
  const filteredTasks: TaskDO[] = tasks.filter((t) => t.isCompleted === isCompletedGroup);

  const groups = new Map<string | null, TaskDO[]>();

  filteredTasks.forEach((task) => {
    const key = task.header?.join('$') || null;

    if (!groups.has(key)) groups.set(key, []);
    groups.get(key)?.push(task);
  });

  const mapEntries = Array.from(groups.entries());

  mapEntries.filter((entry) => {
    //TODO: CC-1546 goes here
    return 1;
  });

  mapEntries.sort((a, b) => {
    const taskA = a[1][0];
    const taskB = b[1][0];
    return compareArrays(taskA.header, taskB.header);
  });

  /*
    flatten the array, 
    generate headers, 
    sort inside of header
  */

  const result: (TaskDO | HeaderDO)[] = [];

  for (const entry of mapEntries) {
    const tasks = entry[1];

    result.push(...(await flattenArray(tasks, sortOption, isCompletedGroup)));
  }

  return result;
};

export const justTheTasks = (arr: (TaskDO | HeaderDO)[]): TaskDO[] => {
  const result: TaskDO[] = [];
  arr.forEach((item) => {
    if ('taskId' in item) result.push(item);
  });
  return result;
};

export const filterTasksAndHeaders = (tasks: (TaskDO | HeaderDO)[], filter: string): (TaskDO | HeaderDO)[] => {
  const filteredTasks = justTheTasks(tasks).filter((task) => {
    return task.taskName.toLowerCase().includes(filter.toLowerCase());
  });

  const filteredTasksAndeHeaders = tasks.filter((task) => {
    if ('taskId' in task) {
      const taskInstance = task as TaskDO;
      const taskNameMatches = taskInstance.taskName.toLowerCase().includes(filter.toLowerCase());
      const headerMatches =
        taskInstance.header &&
        taskInstance.header.some((header) => header.toLowerCase().includes(filter.toLowerCase()));
      return !!taskNameMatches || !!headerMatches;
    }

    if ('headers' in task) {
      const headerInstance = task as HeaderDO;
      return filteredTasks.some((justTask) => justTask.header?.join('') === headerInstance.headers.join(''));
    }

    return false;
  });
  return filteredTasksAndeHeaders;
};
