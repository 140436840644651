import { IonCol, IonGrid, IonItem, IonLabel, IonRow, IonText, IonIcon, IonPopover, IonContent } from '@ionic/react';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { showInputState } from '../AddInputType';
import ListPreview, { isRenameListInputOnState } from './ListPreview';
import { ListDO } from '../../../utils/state/model/interfaces/displayObjects';
import { chevronUpOutline, ellipsisHorizontal, qrCodeOutline, search } from 'ionicons/icons';
import { Preferences } from '@capacitor/preferences';
import AssignMenu from '../../peopleorg/components/AssignMenu';
import { useAssignMenu } from '../../peopleorg/hooks/useAssignMenu';
import SyncingIndicator from '../../general/SyncingIndicator';
import HeaderSearchBar from '../../general/HeaderSearchBar';
import useVirtualScroll, {
  VISIBLE_ITEM_SECTION,
  VIRTUAL_SCROLL_TYPE,
} from '../components/virtualScroll/useVirtualScroll';
import { Virtuoso } from 'react-virtuoso';
import { listMultiSelectModeAtom } from '../../../pages/ListsPage';
import { useMultiSelectLists } from './state/useMultiSelectLists';
import SelectAllSectionButton from '../components/SelectAllSectionButton';

import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { isPlatform } from '@ionic/core';
import { useHistory } from 'react-router-dom';

interface ListsViewProps {
  completedLists: ListDO[];
  incompleteLists: ListDO[];
  archivedLists: ListDO[];
  onSearchListChange: (value: string) => void;
  searchListValue: string;
  setAreIncompleteSortOptionsOpen: Dispatch<SetStateAction<boolean>>;
  setAreCompletedSortOptionsOpen: Dispatch<SetStateAction<boolean>>;
  setAreArchivedSortOptionsOpen: Dispatch<SetStateAction<boolean>>;
  ionContentRef: any;
}

const ListsView: React.FC<ListsViewProps> = ({
  completedLists,
  incompleteLists,
  archivedLists,
  onSearchListChange,
  searchListValue,
  setAreIncompleteSortOptionsOpen,
  setAreCompletedSortOptionsOpen,
  setAreArchivedSortOptionsOpen,
  ionContentRef,
}) => {
  const history = useHistory();
  const [isMultiSelectMode, setIsMultiSelectMode] = useRecoilState(listMultiSelectModeAtom);

  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(false);
  const showInput = useRecoilValue(showInputState);
  const isRenameListInputOn = useRecoilValue(isRenameListInputOnState);

  const { isCompletedSectionOn, toggleCompletedSection, isArchivedSectionOn, toggleArchivedSection } =
    useDisplayListPageSections('lists');

  const { isOpen, list: menuList, mode: assignMenuMode, openMenu, closeMenu, key: assignMenuKey } = useAssignMenu();

  const { onItemClickHandler, isListSelected, onSelectAllCheckboxHandler, getAllListsByCompletionStatus } =
    useMultiSelectLists(incompleteLists, completedLists, archivedLists);

  const { visibleItems, virtuosoListRef, handleToggleCollapse, collapsedSections } = useVirtualScroll(
    incompleteLists,
    completedLists,
    showInput,
    VIRTUAL_SCROLL_TYPE.LISTS,
    isMultiSelectMode ? true : isCompletedSectionOn,
    isMultiSelectMode ? true : isArchivedSectionOn,
    archivedLists
  );

  const { t } = useTranslation();

  const messageToAddFirstList: JSX.Element = (
    <div className="p-absolute v-center h-center">
      <IonText color="light" class="ion-text-center">
        <h1 className="ion-no-margin">
          <Trans t={t} i18nKey="addFirstList" />
        </h1>
      </IonText>
    </div>
  );

  const onScanQRCodeButtonClickHandler = async () => {
    try {
      // Check if the device supports barcode scanning or not
      const { supported } = await BarcodeScanner.isSupported();
      if (!supported) throw new Error('QR Code Scanner is not supported on this device');

      // Check if the device has the Google Play Services for AR installed (Android only  - required for ML Kit to work)
      if (isPlatform('android')) {
        const { available: isBardcodeModuleAvailable } = await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable();
        if (!isBardcodeModuleAvailable) {
          // If not, then install the Google Play Services for AR
          await BarcodeScanner.installGoogleBarcodeScannerModule();
          throw new Error(
            'Google Play Services for AR is not installed, updating now. Please try again in a few seconds.'
          );
        }
      }
      // Dispatch a custom event to let the usePollForChanges hook know that the Android scanner is active
      if (isPlatform('android'))
        document.dispatchEvent(new CustomEvent('scanQrCode', { detail: { isScanning: true } }));
      const { barcodes } = await BarcodeScanner.scan();
      // Dispatch a custom event to let the usePollForChanges hook know that the Android scanner is inactive
      if (isPlatform('android'))
        document.dispatchEvent(new CustomEvent('scanQrCode', { detail: { isScanning: false } }));

      const url = new URL(barcodes[0].displayValue);
      history.push(url.pathname);
    } catch (error: any) {
      // Dispatch a custom event to let the usePollForChanges hook know that the Android scanner is inactive
      if (isPlatform('android'))
        document.dispatchEvent(new CustomEvent('scanQrCode', { detail: { isScanning: false } }));

      // If the user cancels the scan the plugin will throw an error with the message 'scan canceled, so we can ignore it
      // There might be a better way to handle this, but this works for now
      if (error?.message === 'scan canceled.') return;
      alert(error);
    }
  };

  return (
    <>
      <IonGrid
        style={{
          height: '100%',
          paddingInlineStart: 'var(--ion-grid-padding-xs, var(--ion-grid-padding, 5px))',
          paddingInlineEnd: 'var(--ion-grid-padding-xs, var(--ion-grid-padding, 5px))',
        }}
        className="ion-no-padding"
        fixed={true}
      >
        {completedLists.length < 1 &&
        incompleteLists.length < 1 &&
        archivedLists.length < 1 &&
        !showInput &&
        searchListValue === '' ? (
          messageToAddFirstList
        ) : (
          <IonRow style={{ height: '100%' }} class="ion-justify-content-center">
            <IonCol size="12">
              <Virtuoso
                useWindowScroll
                customScrollParent={ionContentRef}
                className="ion-content-window-scroll-host ion-padding-top ion-padding-bottom"
                ref={virtuosoListRef}
                increaseViewportBy={400}
                overscan={15}
                style={{ height: '100%' }}
                totalCount={visibleItems.length}
                itemContent={(index) => {
                  const item = visibleItems[index];

                  if ('header' in item) {
                    const isCompletedGroup = item.header === VISIBLE_ITEM_SECTION.COMPLETE;
                    const isArchivedGroup = item.header === VISIBLE_ITEM_SECTION.ARCHIVED;
                    const isIncompleteGroup = item.header === VISIBLE_ITEM_SECTION.INCOMPLETE;
                    const accordionHeader: string = isArchivedGroup
                      ? t('archived')
                      : isCompletedGroup
                      ? t('completed')
                      : t('lists');

                    return (
                      <>
                        {((isIncompleteGroup && !isSearchBarOpen && !isMultiSelectMode) ||
                          isCompletedGroup ||
                          isArchivedGroup) && (
                          <IonRow
                            class={`ion-align-items-center ion-justify-content-between ${
                              isMultiSelectMode ? 'ion-padding-horizontal' : 'ion-padding'
                            } ${showInput || isRenameListInputOn ? 'disable-pointer-events' : ''}`}
                          >
                            <div
                              onClick={() => {
                                if (isMultiSelectMode) return;
                                handleToggleCollapse(item.header);
                              }}
                            >
                              <IonItem className="ion-no-padding" style={{ maxWidth: 'fit-content' }} lines="none">
                                <IonLabel class="font-32 font-weight-600 ion-margin-top">{accordionHeader}</IonLabel>
                                {!isMultiSelectMode && (
                                  <IonIcon
                                    className={`font-32 transform-100 ${
                                      collapsedSections[item.header] ? '-rotate180' : ''
                                    }`}
                                    icon={chevronUpOutline}
                                  />
                                )}
                              </IonItem>
                            </div>
                            <div className="d-flex ion-align-items-center">
                              {isIncompleteGroup && <SyncingIndicator />}
                              {isIncompleteGroup && (
                                <IonIcon
                                  className="font-32 ion-margin-end"
                                  icon={search}
                                  onClick={() => {
                                    setIsSearchBarOpen((prev) => !prev);
                                  }}
                                />
                              )}
                              <IonIcon
                                style={{ display: isMultiSelectMode ? 'none' : '' }}
                                className="font-32"
                                icon={ellipsisHorizontal}
                                id={`click-popover-${item.header}`}
                              />
                            </div>
                            <IonPopover
                              className="popover-menu"
                              trigger={`click-popover-${item.header}`}
                              mode="md"
                              arrow={false}
                              dismissOnSelect
                            >
                              <IonContent>
                                {isIncompleteGroup && (
                                  <IonItem lines="none" onClick={() => onScanQRCodeButtonClickHandler()}>
                                    <IonIcon style={{ paddingRight: '0.5rem' }} icon={qrCodeOutline} />
                                    <IonText>{t('menu.action.scanQRCodes')}</IonText>
                                  </IonItem>
                                )}
                                <IonItem
                                  onClick={() => {
                                    if (isCompletedGroup) setAreCompletedSortOptionsOpen(true);
                                    if (isIncompleteGroup) setAreIncompleteSortOptionsOpen(true);
                                    if (isArchivedGroup) setAreArchivedSortOptionsOpen(true);
                                  }}
                                  lines="none"
                                >
                                  <IonText>{t('menu.action.sort')}</IonText>
                                </IonItem>
                                {isIncompleteGroup ? (
                                  <>
                                    <IonItem lines="none" onClick={() => toggleCompletedSection()}>
                                      <IonText>
                                        {!isCompletedSectionOn
                                          ? t('menu.action.showCompletedLists')
                                          : t('menu.action.hideCompletedLists')}
                                      </IonText>
                                    </IonItem>
                                    <IonItem lines="none" onClick={() => toggleArchivedSection()}>
                                      <IonText>
                                        {!isArchivedSectionOn
                                          ? t('menu.action.showArchivedLists')
                                          : t('menu.action.hideArchivedLists')}
                                      </IonText>
                                    </IonItem>
                                    <IonItem
                                      lines="none"
                                      onClick={() => {
                                        setIsMultiSelectMode(true);
                                      }}
                                    >
                                      <IonText>{t('menu.action.select.lists')}</IonText>
                                    </IonItem>
                                  </>
                                ) : null}
                              </IonContent>
                            </IonPopover>
                          </IonRow>
                        )}
                        {isIncompleteGroup && isSearchBarOpen && (
                          <HeaderSearchBar
                            value={searchListValue}
                            customPlaceholder={t('searchForLists') as string}
                            onSearchbarBlur={setIsSearchBarOpen}
                            onSearchbarValueChange={onSearchListChange}
                          />
                        )}
                        {isMultiSelectMode &&
                          (item.header === VISIBLE_ITEM_SECTION.ARCHIVED ||
                            item.header === VISIBLE_ITEM_SECTION.COMPLETE) && (
                            <div className="ion-padding-bottom">
                              <SelectAllSectionButton
                                section={item.header}
                                onClick={onSelectAllCheckboxHandler}
                                isSectionSelected={getAllListsByCompletionStatus(item.header)?.areAllSelected ?? false}
                                sectionItemsCount={getAllListsByCompletionStatus(item.header)?.itemCount}
                              />
                            </div>
                          )}
                        {/* Virtuoso requires all elements to have a height */}
                        {isMultiSelectMode && <div style={{ height: '1px' }}></div>}
                      </>
                    );
                  }

                  if ('listId' in item)
                    return (
                      <div className={`${showInput || isRenameListInputOn ? 'disable-pointer-events' : ''}`}>
                        <ListPreview
                          key={item.listId}
                          list={item as ListDO}
                          onUserAvatarClick={openMenu}
                          onMultiSelectClick={onItemClickHandler}
                          isSelected={isListSelected(item as ListDO)}
                        />
                      </div>
                    );
                }}
              />
              {!showInput && (
                <div>
                  <IonItem lines="none"></IonItem>
                </div>
              )}
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
      <AssignMenu
        key={assignMenuKey}
        isOpen={isOpen}
        listId={menuList}
        onDismiss={closeMenu}
        menuType={assignMenuMode}
      />
    </>
  );
};

export default ListsView;

export const useToggleAccordions = () => {
  const accordionGroupRef = useRef<null | any>(null);
  const chevronRef = useRef<null | HTMLIonIconElement>(null);

  const toggleAccordion = (accordionType: 'incompleted' | 'completed') => {
    if (!accordionGroupRef.current) return;
    if (!chevronRef.current) return;

    const nativeElement = accordionGroupRef.current;
    nativeElement.value = nativeElement?.value === accordionType ? undefined : accordionType;
    chevronRef.current.className =
      nativeElement.value === accordionType ? 'transform-100 font-32' : 'font-32 transform-100 -rotate180';
  };

  return {
    accordionGroupRef,
    chevronRef,
    toggleAccordion,
  };
};

export const useTogglePopover = () => {
  const popoverRef = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const openPopover = (e: any) => {
    popoverRef.current!.event = e;
    setPopoverOpen(true);
  };

  return { popoverOpen, setPopoverOpen, openPopover, popoverRef };
};

export const useDisplayListPageSections = (pageType: 'lists' | 'tasks') => {
  const [isCompletedSectionOn, setIsCompletedSectionOn] = useState(true);
  const [isArchivedSectionOn, setIsArchivedSectionOn] = useState(false);

  useEffect(() => {
    const getLocalStorageValue = async () => {
      const { value: completedValue } = await Preferences.get({ key: `displayCompleted/${pageType}` });
      const { value: archivedValue } = await Preferences.get({ key: `displayArchived/${pageType}` });

      setIsCompletedSectionOn(() => {
        if (!completedValue) return true;
        return completedValue === 'true' ? true : false;
      });

      setIsArchivedSectionOn(() => {
        if (!archivedValue) return false;
        return archivedValue === 'true' ? true : false;
      });
    };
    getLocalStorageValue();
  }, []);

  const toggleCompletedSection = async () => {
    await Preferences.set({ key: `displayCompleted/${pageType}`, value: isCompletedSectionOn ? 'false' : 'true' });
    setIsCompletedSectionOn((prev) => !prev);
  };

  const toggleArchivedSection = async () => {
    await Preferences.set({ key: `displayArchived/${pageType}`, value: isArchivedSectionOn ? 'false' : 'true' });
    setIsArchivedSectionOn((prev) => !prev);
  };

  return { isCompletedSectionOn, toggleCompletedSection, isArchivedSectionOn, toggleArchivedSection };
};
