import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { RRule } from 'rrule';
import { Language } from 'rrule/dist/esm/nlp/i18n';
import i18n from '../../../i18n';

const spanish: Language = {
  dayNames: ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'],
  monthNames: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  tokens: {},
};

const spanishText: any = {
  every: 'cada',
  day: 'dia',
  days: 'dias',
  week: 'semana',
  weeks: 'semanas',
  st: '°',
  nd: '°',
  rd: '°',
  th: '°',
  weekday: 'dia laborable',
  weekdays: 'dias laborables',
  month: 'mes',
  months: 'meses',
  year: 'año',
  on: 'el',
  'on the': 'el',
  and: 'y',
  tomorrow: 'mañana',
  yesterday: 'ayer',
};

const getSpanishText = function (id: any) {
  return spanishText[id] || id;
};
//getText(getSpanishText, spanish)

const naturalLanguageDetails = (rule: RRule, language: 'en' | 'es' = 'es') => {
  const dtstartValue = rule.options.dtstart;
  switch (rule.options.freq) {
    case RRule.YEARLY:
      return `${i18n.t('rrule.on.yearly')} ${format(dtstartValue, ' MMM. do', {
        locale: language === 'en' ? undefined : es,
      })}`;
    case RRule.MONTHLY:
      if (rule.origOptions.byweekday) return '';
      return `${i18n.t('rrule.on.yearly')} ${format(dtstartValue, language === 'en' ? 'do' : 'd', {
        locale: language === 'en' ? undefined : es,
      })}`;
    case RRule.WEEKLY:
      if (rule.options.byweekday.length > 1) return ` ${i18n.t('rrule.weekdays')}`;
      return `${i18n.t('rrule.on.weekly')} ${format(dtstartValue, ' EEEE', {
        locale: language === 'en' ? undefined : es,
      })}`;
    case RRule.DAILY:
      return '';
  }
};

export function recurrenceRuleToNaturalLanguage(
  recurrenceRule: string | RRule | null | undefined,
  language: 'en' | 'es' = 'en'
) {
  if (!recurrenceRule) return;

  const ruleObject = typeof recurrenceRule === 'string' ? RRule.fromString(recurrenceRule) : recurrenceRule;
  const naturalLanguageText = language === 'en' ? ruleObject.toText() : ruleObject.toText(getSpanishText, spanish);
  const capitalizedText = naturalLanguageText.charAt(0).toUpperCase() + naturalLanguageText.slice(1);

  if (ruleObject.origOptions.interval)
    return `${i18n.t('repeatMenu.custom')} (${capitalizedText.replace('(~ approximate)', '')})`;
  return `${capitalizedText.replace('(~ approximate)', '')} ${naturalLanguageDetails(ruleObject, language)}`;
}

export function monthlyByWeekdayToNaturalLanguage(date: Date | null, language: 'en' | 'es' = 'en') {
  if (!date) return;
  const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });

  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]);

  const formatOrdinals = (n: number) => {
    const rule = pr.select(n);
    const suffix = suffixes.get(rule);
    return `${n}${language === 'en' ? suffix : '°'}`;
  };

  const nth = formatOrdinals(Math.ceil(date.getDate() / 7));
  return `${i18n.t('repeatMenu.monthlyByWeekday')} ${nth} ${format(date, ' EEEE', {
    locale: language === 'en' ? undefined : es,
  })}`;
}
