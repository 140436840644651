import { IonAlert, IonicSafeString } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { AgreementStatus, AgreementStatusTypes, AgreementTypes } from '../../constants/constants';
import { useRecoilValue } from 'recoil';
import { agreementState } from '../../App';
import { useEffect, useState } from 'react';
import { graphql } from '../../utils/api/apiUtils';
import { logOutWithTeardown } from '../../utils/auth/authTearDown';

const Agreement: React.FC = () => {
  const { t } = useTranslation();
  const agreements: AgreementStatus[] = useRecoilValue(agreementState);
  //TODO: have some sort of "alreadyAgreed" kill switch to prevent the popup from reopening in case of poor internet
  const [updated, setUpdated] = useState<boolean>();
  const [showAgreement, setShowAgreement] = useState<boolean>(false);

  useEffect(() => {
    setShowAgreement(needToAgree());
  }, [agreements]);

  const needToAgree = () => {
    let result = false;
    if (agreements) {
      agreements.forEach((a) => {
        //TODO: handle reacceptance slightly different than first time acceptance?
        result =
          result ||
          a.agreementStatus === AgreementStatusTypes.UNACCEPTED ||
          a.agreementStatus === AgreementStatusTypes.REACCEPTANCE_REQUIRED;
        if (a.agreementStatus === AgreementStatusTypes.REACCEPTANCE_REQUIRED) setUpdated(true);
      });
    }
    return result;
  };

  const agree = async () => {
    const agreementMutation = `
        mutation signAgreementMut(
            $agreementTypes:[String!]!
            $source:String
        ){
          signAgreement(
              agreementTypes: $agreementTypes
              source: $source
          )      
        }
    `;

    const variables = {
      agreementTypes: [`${AgreementTypes.TERMS_AND_CONDITIONS}`, `${AgreementTypes.PRIVACY_POLICY}`],
      source: 'Mobile', //TODO: add app version and OS type?
    };

    const success = await graphql<boolean>(agreementMutation, variables);
    //TODO: handle failure
  };

  const dontAgree = () => {
    logOutWithTeardown();
  };

  return (
    <IonAlert
      backdropDismiss={false}
      cssClass="agreement-confirmation"
      header={
        (updated ? t('confirmation.agreement.header.updated') : t('confirmation.agreement.header.default')) ?? undefined
      }
      message={
        updated
          ? new IonicSafeString(t('confirmation.agreement.body.updated'))
          : new IonicSafeString(t('confirmation.agreement.body.default'))
      }
      isOpen={showAgreement}
      buttons={[
        {
          text: t('confirmation.agreement.button.reject'),
          role: 'cancel',
          handler: dontAgree,
          cssClass: 'agreement-confirmation-do-not-accept',
        },
        {
          text: t('confirmation.agreement.button.accept'),
          role: 'destructive',
          handler: agree,
          cssClass: 'agreement-confirmation-accept',
        },
      ]}
    />
  );
};

export default Agreement;
