import { IonPage, IonRouterOutlet } from '@ionic/react';
import { Redirect, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import ListsPage from './ListsPage';
import ListDetailsPage from './ListDetailsPage';
import TaskDetailsPage from './TaskDetailsPage';
import DebugPage from './DebugPage';
import TaskPhotoPage from './TaskPhotoPage';
import { useEffect, lazy } from 'react';
import { AnalyticsEventType } from '../analytics/analyticsConstants';
import { AnalyticsEvent } from '../utils/state/model/interfaces/analyticsInterface';
import { _analytics, _list } from '../utils/state/model/implementations/ImplementationFactory';
import NoPageFound from './NoPageFound';
// import ReportsPage from './ReportsPage';
import { Device } from '@capacitor/device';
import OneSignal from 'onesignal-cordova-plugin';
import { OpenedEvent } from 'onesignal-cordova-plugin/dist/models/NotificationOpened';
import { getFlag, EnvironmentConfig, getString } from '../utils/environmentUtils';
import { syncData } from '../sync/sync';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { errorPageState } from './ErrorPage';
import QrLandingPage from './QrLandingPage';
import TaskPhotosPage from './TaskPhotosPage';
import TimeTrackingPage from './TimeTrackingPage';
import { organizationSettingsState } from '../App';

// const ListDetailsPage = lazy(() => import('./ListDetailsPage'));
// const TaskDetailsPage = lazy(() => import('./TaskDetailsPage'));
// const TaskPhotoPage = lazy(() => import('./TaskPhotoPage'));
const ReportsPage = lazy(() => import('./ReportsPage'));

const HomePage: React.FC = () => {
  const setCurrentErrorState = useSetRecoilState(errorPageState);
  const organizationSettings = useRecoilValue(organizationSettingsState);

  const { path } = useRouteMatch();

  const { pathname } = useLocation();
  useEffect(() => {
    const event: Partial<AnalyticsEvent> = {
      eventType: AnalyticsEventType.PAGE_CHANGE,
      payload: JSON.stringify({
        pathname,
      }),
    };
    _analytics.queueEvent(event);
  }, [pathname]);

  const history = useHistory();

  useEffect(() => {
    const init = async () => {
      const device = await Device.getInfo();

      //To prevent issues accessing OneSignal from the web
      if (device && device.platform !== 'web') OneSignalInit();
    };

    if (getFlag(EnvironmentConfig.NOTIFICATIONS)) {
      init();
    }
  }, []);

  const OneSignalInit = () => {
    console.log('OneSignal - Attempting to initialize');
    const oneSignalAppId = getString(EnvironmentConfig.ONESIGNAL_APP_ID);

    console.log(`oneSignalAppId from environment: ${oneSignalAppId}`);

    if (!oneSignalAppId) {
      console.warn('OneSignal - No App ID found!  Notifications will be disabled');
      return;
    }

    try {
      //TODO import the actual OneSignal LOG_LEVEL object and use references into it rather than hardcoding the values
      OneSignal.setLogLevel(6, 0); //OneSignal VERBOSE device logging

      OneSignal.setAppId(oneSignalAppId);
      OneSignal.setNotificationWillShowInForegroundHandler(async function (notificationReceivedEvent) {
        console.log('OneSignal - Notification will show in foreground:', notificationReceivedEvent);
        let notification = notificationReceivedEvent.getNotification();
        console.log('notification: ', notification);
        const data = notification.additionalData;
        console.log('additionalData: ', data);
        notificationReceivedEvent.complete(notification);
      });

      OneSignal.setNotificationOpenedHandler(async function (openedEvent: OpenedEvent) {
        const listID = (openedEvent.notification.additionalData as any).listId;
        const taskID = (openedEvent.notification.additionalData as any).taskId;
        console.log(`Notification was for list ${listID} and task ${taskID}`);

        //TODO: perform different actions based on the different notification types
        if (listID) {
          try {
            await syncData();

            const list = await _list.getListById(listID);

            history.push(`/list/${list.listId}`);
          } catch (error) {
            console.error(`Could not find list ${listID}`, error);
            setCurrentErrorState({
              showErrorPage: true,
              errorDetails: `Could not find list when loading tasks page: listId = ${listID}\n` + error,
            });
          }
        }
      });
    } catch (error) {
      console.error(`OneSignal - Error initializing: ${error}`);
    }
  };

  return (
    <IonPage>
      <IonRouterOutlet mode="ios">
        <Route exact path={path} component={ListsPage} />
        <Route exact path={`${path}/:listId`} component={ListDetailsPage} />
        <Route exact path={`${path}/:listId/task/:taskId`} component={TaskDetailsPage} />
        <Route exact path={`${path}/:listId/task/:taskId/photo/:photoId`} component={TaskPhotoPage} />
        <Route exact path={`${path}/:listId/task/:taskId/photos`} component={TaskPhotosPage} />
        <Route exact path="/reports" component={ReportsPage} />
        <Route exact path="/timetracking">
          {getFlag(EnvironmentConfig.TIME_TRACKING) && organizationSettings.timeTrackingEnabled ? (
            <TimeTrackingPage />
          ) : (
            <Redirect to="/list" />
          )}
        </Route>
        <Route exact path="/debug" component={DebugPage} />
        <Route exact path={`/qr/:action/:idType/:id`} component={QrLandingPage} />
        <Route component={NoPageFound} />
      </IonRouterOutlet>
    </IonPage>
  );
};

export default HomePage;
