import { graphql } from '../../utils/api/apiUtils';
import { EnvironmentConfig, getFlag } from '../../utils/environmentUtils';
import { ListResult } from './downloadChanges';

/**
 * A wrapper that is public to a private function.  Only adds some logging
 */
export async function downloadListsWithTasks(listIds: string[]): Promise<ListResult> {
  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('downloadListsWithTasks - start', listIds.length);

  const listResult: ListResult = await getListsWithTasksFromServer(listIds);

  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('downloadListsWithTasks - end', listResult);

  return listResult;
}

const getListsWithTasksFromServer = async (listIds: string[]): Promise<ListResult> => {
  const query = `
    query getListsWithTasks($listIds: [ID!]!) {
      getListsWithTasks(listIds: $listIds) {
        listsToUpdate {
          listId
          listName
          sortId
          createdOn
          updatedOn
          owner
          sharedWith
          notes
          status
          isArchived
          syncedOn
          qmInspectionId
          tasks {
            taskId
            taskName
            ${/* EXPLICITLY no listId since this is nested inside a list object that has that information */ ''}
            instructions
            linkToInstructionsUrl
            linkToInstructionsText
            isPhotoRequired
            sortId
            recurrence
            nonRecurringDueDate
            timezone
            dueDate
            assignedTo
            assignedBy
            completedOn
            completedBy
            notes
            completionPhotoIds
            pendingPhotos
            updatedOn
            syncedOn
            createdOn
            header
          }
        }
        listsToDelete
        deletedTaskIds
      }
    }
  `;

  const variables = { listIds };
  const apiResult = await graphql<ListResult>(query, variables);

  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log(`API sync of lists returned:`, apiResult);

  return apiResult;
};
