import { IonButton, IonCol, IonGrid, IonLabel, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RRule, Weekday } from 'rrule';
import { rruleByWeekdayOptions } from '../utils/recurrenceUtils';
import { CustomValues } from './CustomRecurrenceSelect';

const options = [
  {
    value: 0,
    text: 'repeatMenu.custom.sunday',
  },
  {
    value: 1,
    text: 'repeatMenu.custom.monday',
  },
  {
    value: 2,
    text: 'repeatMenu.custom.tuesday',
  },
  {
    value: 3,
    text: 'repeatMenu.custom.wednesday',
  },
  {
    value: 4,
    text: 'repeatMenu.custom.thursday',
  },
  {
    value: 5,
    text: 'repeatMenu.custom.friday',
  },
  {
    value: 6,
    text: 'repeatMenu.custom.saturday',
  },
];

const initialSelectedDays = (rule: RRule | null) => {
  if (!rule || !rule.options.byweekday) return null;
  if (!rule.origOptions.interval) return null;
  if (rule.options.freq !== RRule.WEEKLY) return null;
  const days = rule.options.byweekday.map((day) => (day <= 5 ? day + 1 : 0));
  return days;
};

interface WeekMenuProps {
  onCustomValueChange: (value: CustomValues) => void;
  recurrenceValue: RRule | null;
}

const WeekMenu: React.FC<WeekMenuProps> = ({ onCustomValueChange, recurrenceValue }) => {
  const [selectedDays, setSelectedDays] = useState<null | number[]>(() => initialSelectedDays(recurrenceValue));

  const { t } = useTranslation();

  useEffect(() => {
    if (!selectedDays) return;
    const getWeekdayRules = selectedDays.map((day) => {
      const weekdayObject = rruleByWeekdayOptions.get(day);
      return weekdayObject ? weekdayObject : null;
    });
    onCustomValueChange(getWeekdayRules as Weekday[]);
  }, [selectedDays]);

  const optionClickHandler = (value: number) => {
    if (selectedDays?.includes(value)) {
      const update = [...selectedDays].filter((element) => element !== value);
      setSelectedDays(update);
    } else {
      const update = selectedDays ? [...selectedDays, value] : [value];
      setSelectedDays(update);
    }
  };

  return (
    <IonGrid class="ion-no-padding">
      <IonRow>
        <IonLabel class="font-32 font-weight-500">{t('repeatMenu.custom.on')}:</IonLabel>
      </IonRow>
      <IonRow class="ion-justify-content-around">
        {options.map((option, index) => (
          <IonCol
            key={index}
            class="date-grid ion-margin-top ion-text-center"
            style={{ justifyContent: 'center' }}
            onClick={() => optionClickHandler(option.value)}
          >
            <IonButton
              className="font-18 calendar-button font-weight-600"
              color={selectedDays?.includes(option.value) ? 'primary' : 'none'}
            >
              {t(option.text).charAt(0)}
            </IonButton>
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default WeekMenu;
