import { Keyboard } from '@capacitor/keyboard';
import { isPlatform } from '@ionic/react';
import { useEffect, useRef } from 'react';
import { ListDO, TaskDO } from '../../utils/state/model/interfaces/displayObjects';

interface ScrollIntoViewProps {
  lists?: ListDO[];
  tasks?: TaskDO[]; //only used as a dependency for an onEffect
  value?: string;
  scrollToEnd?: boolean;
}

const ScrollIntoView: React.FC<ScrollIntoViewProps> = ({ lists, tasks, value, scrollToEnd }) => {
  const scrollIntoViewRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const scrollIntoView = () => {
      scrollIntoViewRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: `${scrollToEnd ? 'end' : 'center'}`,
        inline: 'nearest',
      });
    };

    setTimeout(() => scrollIntoView(), 50);
    if (isPlatform('hybrid')) {
      const test = Keyboard.addListener('keyboardDidShow', () => {
        setTimeout(() => scrollIntoView(), 50);
      });
      return () => {
        test.remove();
      };
    }
  }, [lists, tasks, value]);

  return <div ref={scrollIntoViewRef} />;
};

export default ScrollIntoView;
