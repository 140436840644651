import { IonButton, IonIcon, IonPopover, IonContent, IonItem, IonText } from '@ionic/react';
import { pricetagOutline } from 'ionicons/icons';
import { useTogglePopover } from '../lists/ListsView';
import pricetagUncheckedOutline from '../../../assets/images/icons/Pricetag_Unchecked_Outline_Icon.svg';
import { useTranslation } from 'react-i18next';
import { PhotoTag } from '../../../constants/constants';
import { useEffect, useState } from 'react';
import { _photo } from '../../../utils/state/model/implementations/ImplementationFactory';

interface PhotoTagButtonProps {
  tag: PhotoTag | undefined;
  photoId: string;
  taskId: string;
  size?: 'small' | 'large';
}

const PhotoTagButton: React.FC<PhotoTagButtonProps> = ({ tag, photoId, taskId, size = 'small' }) => {
  const { popoverOpen, setPopoverOpen, openPopover, popoverRef } = useTogglePopover();
  const [selectedTag, setSelectedTag] = useState<PhotoTag | undefined>(tag);

  const { t } = useTranslation();

  useEffect(() => {
    setSelectedTag(tag);
  }, [tag]);

  const onTagOptionClickHandler = async (newTagValue: PhotoTag | null) => {
    try {
      await _photo.updateTag(taskId, photoId, newTagValue);
      setSelectedTag(newTagValue ?? undefined);
      setPopoverOpen(false);
    } catch (e) {
      console.error('Error updating photo tag!', e);
    }
  };

  return (
    <>
      {selectedTag ? (
        <IonButton
          disabled={tag === PhotoTag.DEFICIENCY}
          onClick={openPopover}
          shape="round"
          size={size}
          style={{
            fontSize: size === 'small' ? '.9rem' : '1.125rem',
            '--padding-top': '.75rem',
            '--padding-bottom': '.75rem',
            '--padding-start': '1.5rem',
            '--padding-end': '1.5rem',
          }}
        >
          {selectedTag === PhotoTag.BEFORE
            ? t('before')
            : selectedTag === PhotoTag.AFTER
            ? t('after')
            : selectedTag === PhotoTag.DEFICIENCY
            ? 'Deficiency'
            : t('none')}
        </IonButton>
      ) : (
        <IonButton onClick={openPopover} color="clear" className="ion-no-padding ion-no-margin">
          <IonIcon
            style={{ padding: '.5rem 0rem', fontSize: size === 'small' ? '2rem' : '2.125rem' }}
            icon={pricetagOutline}
            color="primary"
          />
        </IonButton>
      )}
      <IonPopover
        className="popover-menu popover-menu__tags"
        arrow={false}
        mode="md"
        ref={popoverRef}
        triggerAction="click"
        isOpen={popoverOpen}
        onClick={() => setPopoverOpen(false)}
        onDidDismiss={() => setPopoverOpen(false)}
      >
        <IonContent class="ion-padding">
          <IonItem key="tagOption-before" lines="none" onClick={() => onTagOptionClickHandler(PhotoTag.BEFORE)}>
            <IonIcon color="primary" icon={pricetagOutline} />
            <IonText color="primary" className="ion-padding-start font-weight-500">
              {t('before')}
            </IonText>
          </IonItem>
          <IonItem key="tagOption-after" lines="none" onClick={() => onTagOptionClickHandler(PhotoTag.AFTER)}>
            <IonIcon color="primary" icon={pricetagOutline} />
            <IonText color="primary" className="ion-padding-start font-weight-500">
              {t('after')}
            </IonText>
          </IonItem>
          <IonItem key="tagOption-none" lines="none" onClick={() => onTagOptionClickHandler(null)}>
            <IonIcon color="primary" style={{ opacity: '.3' }} icon={pricetagUncheckedOutline} />
            <IonText color="primary" className="ion-padding-start font-weight-500">
              {t('none')}
            </IonText>
          </IonItem>
          <div className="d-flex ion-align-items-center ion-justify-content-center ion-padding-top">
            <IonButton fill="clear">
              <IonText color="primary" className="font-weight-600">
                {t('cancel')}
              </IonText>
            </IonButton>
          </div>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default PhotoTagButton;
