import { getConsoleLogs } from '../../captureConsole';
import { graphql } from '../../utils/api/apiUtils';
import packageJson from '../../../package.json';

export const excludedErrorMessages = ['Network error'];

export class NonReportedError extends Error {
  constructor(message?: any) {
    super(message);
    this.name = this.constructor.name;
  }
}

export const sendErrorEmail = async (subject: string, body?: string) => {
  const query = `
    mutation sendErrorEmailMutation($subject: String!, $body: String) {
      sendErrorEmail(application: "${packageJson.name} v${packageJson.version}", subject: $subject, body: $body)
    }
  `;
  const variables = {
    subject,
    body: (body ?? '') + `\n\nConsole logs:\n\n${getConsoleLogs()}`,
  };
  await graphql(query, variables);
  console.warn('error email sent');
};
