import { AuthCache, isCurrentUserAuthenticated } from '@otuvy/auth';
import { UserInterface } from '../../interfaces/UserInterface';
import { DexieUser } from './implementationModel/dexieObjects';
import { db } from './db';
import { _changeLog } from '../ImplementationFactory';
import { ChangeType } from '../../interfaces/changeLoggerInterface';
import { SyncedOn } from '../../../../../sync/syncFromServer/downloadChanges';
import { UserDO } from '../../interfaces/displayObjects';
import { convertToSyncedOns } from '../../syncUtil';

export const getUserSyncedOns = async (
  excludedUserIds: string[] = [],
  requireUserIds: string[] = []
): Promise<SyncedOn[]> => {
  const users: DexieUser[] = await db().user.toArray();
  return convertToSyncedOns({
    recordsToConvert: users,
    excludedIds: excludedUserIds,
    requiredIds: requireUserIds,
    idExtractionMethod: (user: DexieUser) => {
      return user.userId;
    },
  });
};

export const replaceUpdatedUsers = async (deletedUserIds: string[], updatedUsers: DexieUser[]) => {
  await db().transaction('rw', db().user, async () => {
    const updatedUserIds: string[] = updatedUsers.map((u) => u.userId);

    await db()
      .user.where('userId')
      .anyOf([...deletedUserIds, ...updatedUserIds])
      .delete();

    await db().user.bulkAdd(updatedUsers);
  });
};

/**
 * A function to remove duplicates taken from
 * @param array https://www.technicalfeeder.com/2021/07/8-ways-to-remove-duplicates-from-an-array/#toc13
 */
const uniqBySetWithArrayFrom = (array: any[]): any[] => {
  return Array.from(new Set(array));
};

export class UserDexieImplementation implements UserInterface {
  private static async getRawUserById(userId: string): Promise<DexieUser> {
    const matchingUsers: DexieUser[] = await db().user.where('userId').equals(userId).toArray();

    if (!matchingUsers || matchingUsers.length === 0) {
      throw new Error('No users matching id ' + userId);
    }
    if (matchingUsers.length > 1) {
      throw new Error('Multiple users matching id ' + userId);
    }
    return matchingUsers[0];
  }

  private castDexieToUser(dexieUser: DexieUser): UserDO {
    return {
      ...dexieUser,
    };
  }

  async getById(userId: string): Promise<UserDO> {
    return this.castDexieToUser(await UserDexieImplementation.getRawUserById(userId));
  }

  async deleteById(objectId: string): Promise<void> {
    console.error('Not implemented (Dexie user deleteById)');
    throw new Error('Deleting users from device is not implemented');
  }

  async fetchUser(userId: string): Promise<UserDO | undefined> {
    return this.getById(userId);
  }

  async fetchCurrentUser(): Promise<UserDO | undefined> {
    const isUserAuthenticated: boolean = await isCurrentUserAuthenticated();

    if (!isUserAuthenticated) {
      return undefined;
    }

    const userId: string | undefined = AuthCache.getCurrentUserId();
    if (!userId) {
      throw new Error('The current user does not have an ID!');
    }

    return this.getById(userId);
  }

  async doesUserExist(userId: string): Promise<boolean> {
    const matchingUsers: DexieUser[] = await db().user.where('userId').equals(userId).toArray();

    if (!matchingUsers || matchingUsers.length === 0) {
      return false;
    }
    if (matchingUsers.length > 0) {
      return true;
    }
    return false;
  }

  async updateUser(userId: string, changes: Partial<UserDO>): Promise<UserDO> {
    await db().user.update(userId, changes);

    await _changeLog.queueChange({
      changeType: ChangeType.USER_EDIT,
      recordId: userId,
      payload: JSON.stringify(changes),
    });

    return this.getById(userId);
  }

  async getUserDownloadPriorty(): Promise<[string[], string[]]> {
    const userswithoutPhotos: string[] = (
      await db()
        .user.filter((u) => {
          return u.profilePhoto === null || u.profilePhoto === undefined || '' === u.profilePhoto;
        })
        .toArray()
    ).map((u) => {
      return u.userId;
    });

    const assignedUsers: string[] = [];

    (
      await db()
        .task.filter((t) => {
          return t.assignedTo !== undefined && t.assignedTo !== null && '' !== t.assignedTo;
        })
        .toArray()
    ).forEach((t) => {
      if (t.assignedTo)
        //TypeScript does not recognize the filter we did above as removing the nulls
        assignedUsers.push(t.assignedTo);
    });

    //We need to remove duplicates since a single user may be assigned to multiple tasks
    return Promise.all([uniqBySetWithArrayFrom(assignedUsers), uniqBySetWithArrayFrom(userswithoutPhotos)]);
  }

  async updateUserWithRemoteChanges(userId: string, changes: Partial<UserDO>): Promise<void> {
    await db().user.update(userId, changes);

    //We intentionally do not queue changes.  These are remote updates we are fetching so the server already knows about them
  }

  async updateCurrentUser(changes: Partial<UserDO>): Promise<UserDO> {
    const userId: string | undefined = AuthCache.getCurrentUserId();
    if (!userId) {
      throw new Error("Could not get current user's ID");
    }
    return this.updateUser(userId, changes);
  }

  async getAllUsers(): Promise<UserDO[]> {
    const users = await db().user.toArray();
    return Promise.all(users.map(this.castDexieToUser));
  }

  async getAllUsersAsMap(): Promise<Map<string, UserDO>> {
    const users = await db().user.toArray();
    const results: Map<string, UserDO> = new Map<string, UserDO>();

    await Promise.all(
      users.map(async (user) => {
        results.set(user.userId, this.castDexieToUser(user));
      })
    );

    return results;
  }
}
