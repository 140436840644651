import { IonRow, IonLabel, IonButton } from '@ionic/react';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { _task } from '../../../utils/state/model/implementations/ImplementationFactory';
import { TaskDO } from '../../../utils/state/model/interfaces/displayObjects';
import ExpandableInput from '../../general/ExpandableInput';
import InstructionsLink from './InstructionsLink';

export type DisablePointerState = {
  disableEvents: boolean;
  inputFieldOpened: null | 'taskName' | 'instructions' | 'notes';
};

interface FieldProps {
  taskPropKey: 'taskName' | 'instructions' | 'notes';
  inputMaxLength?: number;
  fontSize?: string;
  labelFontWeight?: string;
  setShowCompleteButton: React.Dispatch<React.SetStateAction<boolean>>;
  setDisablePointerEvents: React.Dispatch<React.SetStateAction<DisablePointerState>>;
  doesEnterKeyBlur?: boolean;
  lineClamp?: number;
  task: TaskDO;
  editable?: boolean;
}

const Field: React.FC<FieldProps> = ({
  taskPropKey,
  inputMaxLength,
  fontSize,
  labelFontWeight,
  setShowCompleteButton,
  setDisablePointerEvents,
  doesEnterKeyBlur,
  lineClamp,
  task,
  editable = true,
}) => {
  const [value, setValue] = useState<string | undefined>(getPropertyOfTask(task, taskPropKey));
  const [displayLinkToInstructions, setDisplayLinkToInstructions] = useState<boolean>(() =>
    task.linkToInstructionsUrl ? task.linkToInstructionsUrl !== '' : false
  );
  const { t } = useTranslation();

  const prevTaskValue = useRef<string>();

  useEffect(() => {
    if (prevTaskValue.current !== getPropertyOfTask(task, taskPropKey)) {
      setValue(getPropertyOfTask(task, taskPropKey));
    }

    prevTaskValue.current = getPropertyOfTask(task, taskPropKey);
  }, [task]);

  const handleClick = () => {
    setShowCompleteButton(false);
    setDisablePointerEvents(() => {
      return { disableEvents: true, inputFieldOpened: taskPropKey };
    });
  };

  const handleBlur = () => {
    setShowCompleteButton(true);
    setDisablePointerEvents(() => {
      return { disableEvents: false, inputFieldOpened: null };
    });

    switch (taskPropKey) {
      case 'taskName':
        if (task.taskName !== value) {
          if (!value) {
            _task.updateTaskName(task.taskId, t('default.task.name'));
            return setValue(t('default.task.name') as string);
          }

          return _task.updateTaskName(task.taskId, value);
        }
        break;
      case 'instructions':
        if (task.instructions !== value) {
          if (!value) return _task.updateTaskInstructions(task.taskId, '');
          return _task.updateTaskInstructions(
            task.taskId,
            value.trim().replace(/^\s*|\s*$|\s*(\r?\n)\s*|(\s)\s+/g, '$1$2')
          );
        }
        break;
      case 'notes':
        if (task.notes !== value) {
          if (!value) return _task.updateTaskNotes(task.taskId, '');
          return _task.updateTaskNotes(task.taskId, value.trim().replace(/^\s*|\s*$|\s*(\r?\n)\s*|(\s)\s+/g, '$1$2'));
        }
        break;
    }
  };

  return (
    <>
      <IonRow className="ion-justify-content-between ion-align-items-center">
        <IonLabel class={`${fontSize} ${labelFontWeight}`}>{t('label.task.' + taskPropKey)}:</IonLabel>
        {!displayLinkToInstructions && editable && (
          <div>
            {taskPropKey === 'instructions' && (
              <IonButton
                fill="clear"
                size="small"
                className="ion-no-padding ion-no-margin"
                style={{ fontSize: '1.25rem' }}
                onClick={() => setDisplayLinkToInstructions((prev) => !prev)}
              >
                {t('instructions.link.add')}
              </IonButton>
            )}
          </div>
        )}
      </IonRow>
      {displayLinkToInstructions && taskPropKey === 'instructions' && (
        <InstructionsLink
          task={task}
          editable={editable}
          setDisplayLink={setDisplayLinkToInstructions}
          handleFieldClickEvent={handleClick}
          handleFieldBlurEvent={handleBlur}
        />
      )}
      <div>
        <ExpandableInput
          accessibilityLabel={t('label.task.' + taskPropKey)}
          value={value ?? ''}
          onClick={handleClick}
          onIonBlur={handleBlur}
          onIonChange={(event) => {
            if (!event.detail.value) return setValue('');
            setValue(event.detail.value?.trim().length > 0 ? event.detail.value : '');
          }}
          inputMaxlength={inputMaxLength}
          fontSize={fontSize}
          doesEnterKeyBlur={doesEnterKeyBlur}
          lineClamp={lineClamp}
          placeholder={taskPropKey === 'taskName' ? t('default.task.name') ?? undefined : ''}
          editable={editable}
        />
      </div>
    </>
  );
};

function getPropertyOfTask(task: TaskDO, propertyName: String) {
  if (task) {
    switch (propertyName) {
      //No "break" statements to make the linter happy
      case 'taskName':
        return task.taskName;
      case 'instructions':
        return task.instructions ? task.instructions : '';
      case 'notes':
        return task.notes ? task.notes : '';
    }
  }

  return undefined;
}

export default Field;
