import { useSetRecoilState } from 'recoil';
import { agreementState, latestSoftwareVersionState, writeAccessRestrictedState } from '../App';
import { graphql } from './api/apiUtils';
import { _systemFlag } from './state/model/implementations/ImplementationFactory';
import { isCurrentUserAuthenticated } from '@otuvy/auth';
import { parseSingleUserFromData } from '../sync/syncFromServer/parsers';
import { DexieUser } from './state/model/implementations/dexie/implementationModel/dexieObjects';
import { logFriendlyObject } from '@otuvy/common-utils';
import {
  AgreementStatus,
  AgreementStatusTypes,
  AgreementTypes,
  SoftwareUpdateInfo,
  noSoftwareUpdates,
} from '../constants/constants';
import { EnvironmentConfig, getFlag } from './environmentUtils';
import { compareVersions } from 'compare-versions';
import isPermittedNetworkConnected from '../sync/network/network';
import { logOutWithTeardown } from './auth/authTearDown';

export enum WriteAccessRestrictedReason {
  NON_PAYMENT = 'nonPayment',
  MISSED_REQUIRED_UPDATE = 'missedRequiredUpdate',
}

export interface WriteAccessRestriction {
  isRestricted: boolean;
  reason: WriteAccessRestrictedReason | null;
}

interface Governors {
  isWriteAccessRestricted: boolean;
  restrictionReason?: WriteAccessRestrictedReason | null;
  isLocked: boolean; //what is locked?
  agreementState: AgreementStatus[];
  softwareUpdateInfo?: SoftwareUpdateInfo;
}

const downloadCurrentUserData = async (): Promise<any> => {
  const isUserAuthenticated: boolean = await isCurrentUserAuthenticated();

  if (!isUserAuthenticated) {
    console.log('No authenticated user, so we are not downloading the current user');
    return;
  }

  const query = `
      query getCurrentUserQuery {
        getCurrentUser {
            userId
            firstName
            lastName
            email
            language
            role
            profilePhoto
            isDefaultProfilePhoto

            status
            
            isRegistered
            agreementStatus {
              agreementType,
              agreementStatus
            }            

            syncedOn
        }
      }
    `;

  return await graphql<any>(query);
};

const downloadCurrentUserAgreementStatus = async (): Promise<AgreementStatus[]> => {
  if (!getFlag(EnvironmentConfig.CHECK_AGREEMENTS)) {
    return [
      {
        agreementType: AgreementTypes.TERMS_AND_CONDITIONS,
        agreementStatus: AgreementStatusTypes.ACCEPTED,
      },
      {
        agreementType: AgreementTypes.PRIVACY_POLICY,
        agreementStatus: AgreementStatusTypes.ACCEPTED,
      },
    ];
  }

  const isUserAuthenticated: boolean = await isCurrentUserAuthenticated();

  if (!isUserAuthenticated) {
    console.log('No authenticated user, so we are not downloading the current user');
    return [];
  }

  const query = `
      query getCurrentUserQuery {
        getCurrentUser {
            agreementStatus {
              agreementType,
              agreementStatus
            }            
        }
      }
    `;

  try {
    const agreementData = await graphql<any>(query);
    return agreementData.agreementStatus;
  } catch (e) {
    console.error('Error fetching current user agreement status', logFriendlyObject(e));
  }
  return [];
};

const getSoftwareUpdateInfo = async (): Promise<SoftwareUpdateInfo> => {
  if (!getFlag(EnvironmentConfig.CHECK_FOR_SOFTWARE_UPDATES)) {
    return noSoftwareUpdates;
  }

  const isUserAuthenticated: boolean = await isCurrentUserAuthenticated();

  if (!isUserAuthenticated) {
    console.log(
      'No authenticated user.  One is needed to make the GraphQL call, though this endpoint should probably be public'
    );
    return noSoftwareUpdates;
  }

  const query = `
      query currentSoftwareVersionQuery {
        getCurrentSoftwareVersion {
          currentVersion
          currentReleaseNotes
          lastRequiredVersion
        }
      }
    `;

  try {
    const versionData = await graphql<any>(query);
    return versionData;
  } catch (e) {
    console.error('Error fetching current software version', logFriendlyObject(e));
  }
  return noSoftwareUpdates;
};

export const downloadCurrentDexieUser = async (): Promise<DexieUser> => {
  const userData = await downloadCurrentUserData();
  return parseSingleUserFromData(userData);
};

const useCheckOrgGovernors = () => {
  const setIsWriteAccessRestricted = useSetRecoilState(writeAccessRestrictedState);
  const setAgreementState = useSetRecoilState(agreementState);
  const setSoftwareVersion = useSetRecoilState(latestSoftwareVersionState);

  const getGovernors = async (): Promise<Governors> => {
    if (!getFlag(EnvironmentConfig.CHECK_GOVERNORS)) {
      return {
        isWriteAccessRestricted: false,
        isLocked: false,
        agreementState: [
          {
            agreementType: AgreementTypes.TERMS_AND_CONDITIONS,
            agreementStatus: AgreementStatusTypes.ACCEPTED,
          },
          {
            agreementType: AgreementTypes.PRIVACY_POLICY,
            agreementStatus: AgreementStatusTypes.ACCEPTED,
          },
        ],
      };
    }

    const isUserAuthenticated: boolean = await isCurrentUserAuthenticated();

    if (!isUserAuthenticated) {
      console.log('No authenticated user, so we are not checking the governors');
      return {
        isWriteAccessRestricted: false,
        restrictionReason: null,
        isLocked: false,
        agreementState: [],
      };
    }

    const query = `
      query getGovernors {
        getOrganizationGovernors {
          isWriteAccessRestricted
          isLocked
        }
      }
    `;
    console.log('Querying Governors');
    const governors = await graphql<Governors>(query);

    governors.agreementState = await downloadCurrentUserAgreementStatus();
    governors.softwareUpdateInfo = await getSoftwareUpdateInfo();
    governors.restrictionReason = governors.isWriteAccessRestricted ? WriteAccessRestrictedReason.NON_PAYMENT : null;
    return governors;
  };

  const setWriteAccessFromDatabase = async () => {
    const isWriteAccessRestricted = await _systemFlag.getIsWriteAccessRestricted();
    setIsWriteAccessRestricted(isWriteAccessRestricted);
  };

  const checkOrgGovernors = () => {
    isPermittedNetworkConnected()
      .then((isConnected) => {
        if (!isConnected) {
          if (getFlag(EnvironmentConfig.SERVER_SYNC)) {
            console.warn('Device is offline; using local database value for write access');
          }
          setWriteAccessFromDatabase();
          return;
        }

        getGovernors()
          .then(
            ({
              isWriteAccessRestricted: isWriteAccessRestrictedByGovernors,
              restrictionReason,
              isLocked,
              agreementState,
              softwareUpdateInfo,
            }) => {
              setAgreementState(agreementState);
              setSoftwareVersion(softwareUpdateInfo ?? noSoftwareUpdates);

              if (isLocked) {
                console.log('*** Organization is locked. Logging out. ***');
                logOutWithTeardown();
                return;
              }

              const missingRequiredUpdate: boolean = softwareUpdateInfo
                ? determineIfUpdateNeeded(softwareUpdateInfo.lastRequiredVersion)
                : false;

              const isRestricted = isWriteAccessRestrictedByGovernors || missingRequiredUpdate;
              const isWriteAccessRestricted: WriteAccessRestriction = {
                isRestricted,
                reason: getRestrictionReason(isRestricted, missingRequiredUpdate, restrictionReason ?? null),
              };

              setIsWriteAccessRestricted(isWriteAccessRestricted);

              _systemFlag
                .setIsWriteAccessRestricted(isWriteAccessRestricted)
                .catch((error) =>
                  console.warn('Failed to set write access restricted value in database', logFriendlyObject(error))
                );
            }
          )
          .catch(setWriteAccessFromDatabase)
          .finally(() => {});
      })
      .catch(setWriteAccessFromDatabase);
  };

  return {
    checkOrgGovernors,
  };
};

const getRestrictionReason = (
  isRestricted: boolean,
  missingRequiredUpdate: boolean,
  reason: WriteAccessRestrictedReason | null
): WriteAccessRestrictedReason | null => {
  if (!isRestricted) return null;
  if (missingRequiredUpdate) return WriteAccessRestrictedReason.MISSED_REQUIRED_UPDATE;
  if (reason) return reason;
  return null; //Per requirements, if we don't have a reason for restricting them, don't restrict them
};

export const determineIfUpdateNeeded = (checkingVersion: string | undefined): boolean => {
  if (!checkingVersion || checkingVersion === '0.0.0') return false;

  const p = require('../../package.json');
  const currentlyInstalledVersion = p.version;

  try {
    return compareVersions(currentlyInstalledVersion, checkingVersion) < 0;
  } catch (e) {
    console.log(e);
  }

  return false;
};

export default useCheckOrgGovernors;
