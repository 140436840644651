import { App, AppState } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { _analytics } from '../utils/state/model/implementations/ImplementationFactory';
import { AnalyticsEvent } from '../utils/state/model/interfaces/analyticsInterface';
import { AnalyticsEventType } from './analyticsConstants';

export const setUpAnalyticsListeners = () => {
  setUpAppStateChangeListener();
  setUpNetworkStatusChangeListener();
};

const setUpAppStateChangeListener = () => {
  // For web browser, this only detects if the window is visible to the user,
  // so if another window gains focus but the app is still visible, it will not register an update
  App.addListener('appStateChange', (state: AppState) => {
    const event: Partial<AnalyticsEvent> = {
      eventType: AnalyticsEventType.APP_STATE_CHANGE,
      payload: JSON.stringify(state),
    };
    _analytics.queueEvent(event);
  });
};

const setUpNetworkStatusChangeListener = () => {
  // This event only appears to be sent on a mobile device
  Network.addListener('networkStatusChange', (status) => {
    const event: Partial<AnalyticsEvent> = {
      eventType: AnalyticsEventType.NETWORK_STATUS_CHANGE,
      payload: JSON.stringify(status),
    };
    _analytics.queueEvent(event);
  });
};
