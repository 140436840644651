import { useRecoilValue } from 'recoil';
import { writeAccessRestrictedState } from '../../App';
import { OfflineIndicator } from './BannerContent/OfflineIndicator';
import useNetworkStatus from '../../sync/network/useNetworkStatus';
import WriteAccessRestrictedIndicator from './BannerContent/WriteAccessRestrictedIndicator';
import { WriteAccessRestrictedReason } from '../../utils/useCheckOrgGovernors';
import UpdateRequiredIndicator from './BannerContent/UpdateRequiredIndicator';

const Banner: React.FC = () => {
  const isWriteAccessRestricted = useRecoilValue(writeAccessRestrictedState);

  const { isOnline } = useNetworkStatus();

  const loadBannerContent = () => {
    if (isWriteAccessRestricted.isRestricted && isWriteAccessRestricted.reason) {
      if (isWriteAccessRestricted.reason === WriteAccessRestrictedReason.MISSED_REQUIRED_UPDATE) {
        return <UpdateRequiredIndicator />;
      } else {
        return <WriteAccessRestrictedIndicator reason={isWriteAccessRestricted.reason} />;
      }
    } else if (!isOnline) {
      return <OfflineIndicator />;
    } else {
      return null;
    }
  };

  const content = loadBannerContent();

  /*
    1. The IonToolbars in the Banner will act as the element that handles the mobile top safe area (padding)
      so we need to set the height to 0px when there is no content
    2. We also needed to disable the safe areas in the IonToolbars whithing the other pages
      since ionic would add the safe areas to both IonToolbars (the ones in the Banner and the one in the page)
  */
  if (!content) return <div style={{ marginBottom: 'var(--ion-safe-area-top)' }}></div>;

  return (
    <div style={{ background: 'var(--ion-color-custom-overlay)', paddingTop: 'var(--ion-safe-area-top' }}>
      <div color="custom-overlay" className="d-flex ion-justify-content-center ion-align-items-center ion-padding">
        {content}
      </div>
    </div>
  );
};

export default Banner;
