import { PhotoTag, LegacyUserRole } from '../../../../constants/constants';
import { ReportCriteria, ReportType } from '../../../../features/reports/reportConstants';
import { UserSettings } from '../../../../features/settings/settingsState';

export class List {
  public listId: string = '';
  public listName: string = '';
  public updatedOn: Date = new Date(1970, 1, 1, 0, 0, 0, 0);
  public sortId: number = 0;
  public createdOn: Date = new Date();

  public owner: string = '';
  public sharedWith?: string[];

  public notes?: string;

  public isArchived: boolean = false;
  public qmInspectionId?: number = undefined;
}

export class Task {
  public taskId: string = '';
  public taskName: string = '';

  public listId: string = '';

  //For sorting features
  public sortId: number = 0;

  public assignedTo?: string | null;
  public assignedBy?: string | null;

  public createdOn: Date = new Date();
  public updatedOn: Date = new Date(1970, 1, 1, 0, 0, 0, 0);

  public instructions?: string;
  public isPhotoRequired: boolean = false;
  public linkToInstructionsUrl?: string;
  public linkToInstructionsText?: string;

  public header?: string[];

  /*
   nonRecurringDueDate is not necessarily the current due date of the task.

   If a task is recurring:
   - nonRecurringDueDate is NULL
   - recurrence contains the RRULE, including dstart
   - the due date and reset time are calculated from the RRULE in recurrence
   If a task has a due date, but no recurrence:
   - nonRecurringDueDate is the date/time of that one due date
   - recurrence contains NULL
   - the due date is the value of nonRecurringDueDate
   - resetTime is NULL
   If a task has neither due date, nor recurrence:
   - nonRecurringDueDate is NULL
   - recurrence is NULL
   - resetTime is NULL
   */
  public nonRecurringDueDate?: Date | null;
  public recurrence?: string | null;
  //TODO: add this back in when resetTime is being finished
  // public resetTime?: Date | null; //This could go on DexieTask except for the fact that some functions in taskDexieImplementation take a TaskDO
  public timezone?: string;
}

export class Photo {
  public photoId: string = '';
  public data: string = '';
  public tag?: PhotoTag | null;
}

export class User {
  public userId: string = ''; //The user's "internal" Veritask ID, not their Cognito ID
  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';
  public language: string = '';
  /**
   * @deprecated Use the groups from the auth package instead.
   */
  public role: LegacyUserRole = LegacyUserRole.EMPLOYEE;
  public profilePhoto?: string;
  public isDefaultProfilePhoto: boolean = true;
  public settings?: Omit<UserSettings, 'language'>; //`language` is stored in the `language` property above
}

export interface Report {
  id: string;
  name: string;
  reportType: ReportType;
  toEmails: string[];
  reportCriteria: ReportCriteria;

  createdOn: Date;
  createdBy: string;
}
