import { IonGrid, IonItem, IonList, IonListHeader, IonRow, IonSkeletonText } from "@ionic/react"
import React from "react"

const ListDetailsViewSkeletonText: React.FC = () => {
    return (
        <IonList class="skeleton-list">
            <IonItem lines="none" style={{ marginBottom: '0' }}>
                <IonGrid>
                    <IonRow class="ion-justify-content-between">
                        <IonSkeletonText animated style={{ width: '70%' }} />
                        <IonSkeletonText animated style={{ width: '10%' }} />
                        <IonSkeletonText animated style={{ width: '10%' }} />
                    </IonRow>
                </IonGrid>
            </IonItem>
            <IonListHeader>
                <IonGrid>
                    <IonRow class="ion-justify-content-between">
                        <IonSkeletonText animated style={{ width: '20%' }} />
                        <IonSkeletonText animated style={{ width: '10%' }} />
                    </IonRow>
                </IonGrid>
            </IonListHeader>
            {[...Array(10)].map((_, index) =>
                <IonItem key={index} lines="none">
                    <IonGrid>
                        <IonRow class="ion-justify-content-between ion-align-items-center">
                            <IonSkeletonText animated class="skeleton-checkbox" />
                            <IonSkeletonText animated style={{ width: '75%', height: '100%' }} />
                        </IonRow>
                    </IonGrid>
                </IonItem>
            )}
        </IonList>
    )
}

export default React.memo(ListDetailsViewSkeletonText)