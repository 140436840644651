import { useEffect, useState } from 'react';
import { ListDO } from '../../../utils/state/model/interfaces/displayObjects';
import { AuthCache } from '@otuvy/auth';
import { UserGroups } from '@otuvy/auth/dist/utils/UserGroups';

const useIsListOwner = (list: ListDO | null | undefined) => {
  const [isListOwner, setIsListOwner] = useState<boolean>(false);

  useEffect(() => {
    async function getCurrentUserData() {
      //TODO: fall back to the legacy system if the user has not signed into the new system yet
      //const legacyCanSeeList = await legacyCanSeeLists()

      setIsListOwner(
        AuthCache.getCurrentUserId() === list?.owner ||
          AuthCache.isCurrentUserInAnyGroup([UserGroups.SUPER_ADMIN, UserGroups.LIST_MANAGER])
      );
    }

    getCurrentUserData();
  }, [list]);

  return {
    isListOwner,
  };
};

export default useIsListOwner;
