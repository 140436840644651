import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { Dispatch, SetStateAction } from 'react';
import { t } from 'i18next';
import removeUserIcon from '../../../assets/images/icons/Remove_User_Icon.svg';

interface DeselectUsersProps {
  onClick: Dispatch<SetStateAction<string[]>>;
  menuType: 'assign' | 'share' | null;
}

const DeselectUsers: React.FC<DeselectUsersProps> = ({ onClick, menuType }) => {
  const onClickHanlder = () => {
    onClick([]);
  };
  return (
    <div className="d-flex ion-justify-content-between ion-align-items-center">
      <IonItem
        className="ion-margin-top ion-padding-horizontal"
        onClick={onClickHanlder}
        lines="none"
        style={{ marginBottom: '0.5rem' }}
      >
        <div
          style={{ width: '40px', height: '40px' }}
          className="d-flex ion-justify-content-center ion-align-items-center"
        >
          <IonIcon size="large" icon={removeUserIcon} color="medium" />
        </div>
        <IonLabel class="ion-padding-start" color="medium">
          {menuType === 'assign' ? t('assignMenu.deselectUsers.assign') : t('assignMenu.deselectUsers.share')}
        </IonLabel>
      </IonItem>
      <IonLabel className="ion-padding-end font-16" style={{ paddingTop: '8px' }}>
        {t('favorites')}
      </IonLabel>
    </div>
  );
};

export default DeselectUsers;
