import { IonCol, IonIcon, IonLabel, useIonViewWillEnter } from '@ionic/react';
import React, { useMemo } from 'react';
import { useEffect, useRef, useState } from 'react';
import { _task, _user } from '../../../utils/state/model/implementations/ImplementationFactory';
import { ListDO } from '../../../utils/state/model/interfaces/displayObjects';
import AssignUserButton from './AssignUserButton';
import ExtraAssigendUsersCounter from './ExtraAssignedUsersCounter';
import UserAvatar from './UserAvatar';
import useGetLocalData from '../../../hooks/useGetLocalData';
import qmlogo from '../../../assets/images/icons/Qmlogo-outline.svg';
import { useTranslation } from 'react-i18next';

interface ListPreviewUsersProps {
  isEditable: boolean;
  list: ListDO;
  onUserAvatarClick: (list: string | null, task: string[] | null, mode: 'assign' | 'share') => void;
}

const ListPreviewUsers: React.FC<ListPreviewUsersProps> = ({ isEditable, list, onUserAvatarClick }) => {
  const [avatarCount, setAvatarCount] = useState<null | number>(null);
  const { data: assignedUsers = [] } = useGetListPreviewData(list);
  const componentRef = useRef<HTMLIonColElement>(null);

  const { t } = useTranslation();

  useIonViewWillEnter(() => {
    handleResize();
  });

  useEffect(() => {
    // FIXME: This is a hacky way to get it working, might need to find a better way to do this
    setTimeout(() => handleResize(), 0);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [assignedUsers]);

  const handleResize = () => {
    if (!componentRef.current) return;
    //TODO: Dynamic width is needed when we detect if the list is a QM list or not
    //180 is the width of the QM logo and the assign user button combined | 72 is the width of the assign user button by itself.
    // This calculation might not be accurate if the width of the QM logo changes or if we start adding more tags to the list preview
    const totalWidth = componentRef.current.offsetWidth - 180; // TODO: - 118 when we detect if the list is a QM list or not;
    const maxAvatarCount = Math.floor(totalWidth / 40);
    if (maxAvatarCount !== avatarCount) setAvatarCount(maxAvatarCount);
  };

  return (
    <IonCol ref={componentRef} size="12" class="ion-no-padding d-flex ion-justify-content-end ion-align-items-center">
      {list.qmInspectionId && (
        <div style={{ marginRight: 'auto' }}>
          <ListPreviewTag>
            <IonIcon
              src={qmlogo}
              className="ion-no-padding ion-no-margin"
              style={{ height: '25px', width: '44px', minWidth: '44px' }}
            />
            <IonLabel
              className="ion-no-padding ion-no-margin font-16"
              style={{ color: '#92949c', paddingLeft: '.25rem', paddingRight: '1rem' }}
            >
              {t('deficiencies')}
            </IonLabel>
          </ListPreviewTag>
        </div>
      )}
      {avatarCount && assignedUsers.length !== 0 && assignedUsers.length > avatarCount ? (
        <div className="d-flex">
          {assignedUsers.slice(0, avatarCount).map((user, index) => (
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (isEditable) onUserAvatarClick(list.listId, null, 'assign');
              }}
              key={user?.userId ?? index}
              style={{ marginRight: isEditable || (!isEditable && index !== assignedUsers.length - 1) ? '8px' : '' }}
            >
              <UserAvatar size="medium" img={user?.profilePhoto} userId={user?.userId ?? ''} />
            </div>
          ))}
          <ExtraAssigendUsersCounter count={Math.abs(-assignedUsers.length + avatarCount)} />
        </div>
      ) : (
        assignedUsers.map((user, index) => (
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (isEditable) onUserAvatarClick(list.listId, null, 'assign');
            }}
            key={user?.userId ?? index}
            style={{
              marginRight:
                index !== assignedUsers.length - 1 &&
                (isEditable || (!isEditable && index !== assignedUsers.length - 1))
                  ? '8px'
                  : '',
            }}
          >
            <UserAvatar size="medium" img={user?.profilePhoto} userId={user?.userId ?? ''} />
          </div>
        ))
      )}
      {isEditable && assignedUsers.length === 0 && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (isEditable) onUserAvatarClick(list.listId, null, 'assign');
          }}
        >
          <AssignUserButton />
        </div>
      )}
    </IonCol>
  );
};

export default ListPreviewUsers;

interface ListPreviewTagProps {
  children: React.ReactNode;
}

const ListPreviewTag: React.FC<ListPreviewTagProps> = ({ children }) => {
  return (
    <div className="d-flex ion-align-items-center" style={{ border: '1px solid #92949c', borderRadius: '1rem' }}>
      {children}
    </div>
  );
};

function useGetListPreviewData(list: ListDO) {
  const recentActivityMemo = useMemo(() => list.mostRecentActivity, [list]);
  const { data, loading } = useGetLocalData(fetchUserData, [recentActivityMemo]);

  async function fetchUserData() {
    try {
      const tasks = await _task.getTasksByListId(list.listId);

      const users = tasks.filter((task) => task.assignedTo).map((task) => task.assignedTo);

      const uniqueUsers = Array.from(new Set(users));
      const usersData = await Promise.all(uniqueUsers.map((user) => _user.fetchUser(user as string)));

      return usersData;
    } catch {
      console.log('Error fetching users for list preview');
    }
  }

  return { data, loading };
}
