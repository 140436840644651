import { exportWholeDb, exportTextTables, exportChangeLog } from '../state/model/implementations/dexie/db';
import { SetterOrUpdater } from 'recoil';
import { errorState } from '../../pages/ErrorPage';
import { debugDataDownloader } from './downloaders/platformSpecificDownloader';

export interface errorPageAccess {
  errorPageState: SetterOrUpdater<errorState>;
}

export interface ZipBlobParameters extends errorPageAccess {
  blob: Blob;
}

export const export_Database = (params: errorPageAccess) => {
  console.log('Exporting DB');
  const load = async () => {
    try {
      const dataDump: Blob | null = await exportWholeDb();

      if (dataDump === null) return;

      debugDataDownloader({ ...params, blob: dataDump });
    } catch (e) {
      console.error('Error exporting the whole DB', e);
      params.errorPageState({
        showErrorPage: true,
        errorDetails: e ? e : 'Unknown error exporting whole DB',
      });
    }
  };
  load();
};

export const export_ChangeLog = (params: errorPageAccess) => {
  console.log('Exporting changeLog from the DB');
  const load = async () => {
    try {
      const dataDump: Blob | null = await exportChangeLog();

      if (dataDump === null) return;

      debugDataDownloader({ ...params, blob: dataDump });
    } catch (e) {
      console.error('Error exporting the change log', e);
      params.errorPageState({
        showErrorPage: true,
        errorDetails: e ? e : 'Unknown error exporting Change log',
      });
    }
  };
  load();
};

export const export_BasicTables = (params: errorPageAccess) => {
  console.log('Exporting DB');
  const load = async () => {
    try {
      const dataDump: Blob | null = await exportTextTables();

      if (dataDump === null) return;

      debugDataDownloader({ ...params, blob: dataDump });
    } catch (e) {
      console.error('Error exporing the basic tables from the DB', e);
      params.errorPageState({
        showErrorPage: true,
        errorDetails: e ? e : 'Unknown error exporting basic tables',
      });
    }
  };
  load();
};
