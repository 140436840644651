import { IonRow, IonText, IonProgressBar } from '@ionic/react';
import React from 'react';

interface ListProgressBarProps {
  numItems: number;
  numItemsCompleted: number;
  onClick?: () => void;
  completed?: () => boolean;
}

const ListProgressBar: React.FC<ListProgressBarProps> = ({ numItems, numItemsCompleted, onClick }) => {
  const calculatePercentage = () => {
    if (numItemsCompleted === 0 && numItems === 0) return 0;
    return numItemsCompleted / numItems;
  };

  return (
    <IonRow
      class="ion-align-items-center no-vertical-padding d-flex"
      style={{ marginTop: 'calc(-7/ var(--root-size) * 1rem)', flexWrap: 'nowrap' }}
      onClick={onClick}
    >
      {/* <IonCol class="no-padding-start" sizeXs="7" sizeSm="8" sizeLg="10"> */}
      <IonProgressBar style={{ widht: 'auto' }} color="primary" value={calculatePercentage()} />
      {/* </IonCol> */}
      {/* <IonCol size='auto' class="ion-text-center ion-no-padding"> */}
      <IonText style={{ fontWeight: '700', margin: '0px 7px' }} color="primary">
        {(calculatePercentage() * 100).toFixed(0)}%
      </IonText>
      {/* </IonCol> */}
      {/* <IonCol size='auto' class="ion-text-center ion-no-padding"> */}
      <IonText style={{ opacity: '.6', fontWeight: '100', whiteSpace: 'nowrap' }} color="primary">
        {numItemsCompleted} / {numItems}
      </IonText>
      {/* </IonCol> */}
    </IonRow>
  );
};

export default React.memo(ListProgressBar);
