import React from 'react';
import { Helmet } from 'react-helmet';
import { EnvironmentConfig, getFlag } from '../utils/environmentUtils';

const OnDeviceConsole: React.FC = () => {
  // If onDeviceConsole feature is enabled, we'll add that in using Helmet.
  // See documentation links found in Readme for mobileConsole v2

  return getFlag(EnvironmentConfig.ON_DEVICE_CONSOLE) ? (
    <Helmet>
      <script src="https://cdn.jsdelivr.net/gh/c-kick/mobileConsole/hnl.mobileconsole.min.js" type="text/javascript" />
    </Helmet>
  ) : null;
};

export default OnDeviceConsole;
