import { createAnimation, IonIcon, IonItem, IonLabel, IonText, useIonAlert, useIonModal } from '@ionic/react';
import { UserDO } from '../../../utils/state/model/interfaces/displayObjects';
import UserAvatar from './UserAvatar';
import PushPinIcon from './PushPinIcon';
import { checkmarkOutline, chevronDownOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { EnvironmentConfig, getFlag } from '../../../utils/environmentUtils';
import { useTranslation } from 'react-i18next';
import { UserDOWithRefs } from './AssignMenu';

interface AssignMenuItemProps {
  user: UserDOWithRefs;
  isSelected: boolean;
  isPinned: boolean;
  sharedWithUsers: string[];
  listOwners: string[];
  onUserAssignClick: (userId: string) => void;
  onUserShareClick: (userId: string, type: 'owners' | 'sharedWith' | 'remove') => void;
  onPushPinClick: (userId: string) => void;
  menuType: 'assign' | 'share' | null; //TODO: use an enum rather than a magic string
}

const AssignMenuItem: React.FC<AssignMenuItemProps> = ({
  user,
  isSelected,
  onUserAssignClick,
  onUserShareClick,
  onPushPinClick,
  isPinned,
  sharedWithUsers,
  listOwners,
  menuType,
}) => {
  const isTheOnlyOwner = listOwners.length === 1 && listOwners.includes(user.userId);

  // TODO: Remove after LIST_OWNER_UI flag is removed
  if (!getFlag(EnvironmentConfig.LIST_OWNER_UI)) {
    return (
      <AssignMenuSingleActionItem
        user={user}
        isSelected={isSelected}
        onPushPinClick={onPushPinClick}
        onClick={onUserAssignClick}
        onShareClick={menuType === 'share' ? onUserShareClick : undefined}
        isPinned={isPinned}
      />
    );
  }

  return (
    <>
      {menuType === 'assign' ? (
        <AssignMenuSingleActionItem
          user={user}
          isSelected={isSelected}
          onPushPinClick={onPushPinClick}
          onClick={onUserAssignClick}
          isPinned={isPinned}
        />
      ) : (
        <AssignMenuMultiActionItem
          user={user}
          isSelected={isSelected}
          onPushPinClick={onPushPinClick}
          onClick={onUserShareClick}
          isPinned={isPinned}
          isShared={sharedWithUsers.includes(user.userId)}
          isListOwner={listOwners.includes(user.userId)}
          isTheOnlyOwner={isTheOnlyOwner}
        />
      )}
    </>
  );
};

export default AssignMenuItem;

interface AssignMenuSingleActionItemProps {
  user: UserDOWithRefs;
  isSelected: boolean;
  isPinned: boolean;
  onClick: (userId: string) => void;
  onShareClick?: (userId: string, type: 'owners' | 'sharedWith' | 'remove') => void | undefined; //TODO: Remove after LIST_OWNER_UI flag is removed
  onPushPinClick: (userId: string) => void;
}

const AssignMenuSingleActionItem: React.FC<AssignMenuSingleActionItemProps> = ({
  user,
  onClick,
  onShareClick,
  isSelected,
  onPushPinClick,
  isPinned,
}) => {
  const divElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!divElementRef.current) return;
    if (!user.dataModifiedTimeStamps) return;

    // This is another clever way to animate this list item
    // This animation will only be triggered if the user data has been modified in the last 500ms
    // This is to prevent the animation from being triggered when the user data is being loaded
    // Similar setup to what we have in the TaskPreview component

    if (Date.now() - user.dataModifiedTimeStamps < 1000) {
      assignMenuItemEnterAnimation(divElementRef.current);
    }
  }, []);

  const onItemClickHandler = async () => {
    if (!divElementRef.current) return;
    // await assignMenuItemExitAnimation(divElementRef.current); //Animation does not show when assigning a task to a user
    if (onShareClick) await assignMenuItemExitAnimation(divElementRef.current); // Animation shows when sharing a task with a user
    onShareClick ? onShareClick(user.userId, 'sharedWith') : onClick(user.userId);
  };

  const onPushPinClickHandler = async () => {
    if (!divElementRef.current) return;
    await assignMenuItemExitAnimation(divElementRef.current);
    onPushPinClick(user.userId);
  };

  return (
    <div className="d-flex ion-align-items-center ion-padding-end" ref={divElementRef}>
      <IonItem
        onClick={() => onItemClickHandler()}
        style={{ padding: '0px 16px', flex: '1', '--border-color': `${!isSelected ? 'var(--ion-color-light)' : ''}` }}
        lines="none"
        className={`custom-checkbox ${isSelected && 'multi-select-selected'}`}
      >
        <UserAvatar size="large" img={user.profilePhoto} userId={user.userId} />
        <IonLabel class="ion-padding-start">
          {user.firstName} {user.lastName}
        </IonLabel>
      </IonItem>
      <div
        className="ion-padding-end"
        onClick={(e) => {
          e.stopPropagation();
          onPushPinClickHandler();
        }}
      >
        <PushPinIcon isActive={isPinned} />
      </div>
    </div>
  );
};

interface AssignMenuMultiActionItemProps extends Omit<AssignMenuSingleActionItemProps, 'onClick'> {
  isShared: boolean;
  isListOwner: boolean;
  isTheOnlyOwner: boolean;
  onClick: (userId: string, type: 'owners' | 'sharedWith' | 'remove') => void;
}

const AssignMenuMultiActionItem: React.FC<AssignMenuMultiActionItemProps> = ({
  user,
  onClick,
  isSelected,
  onPushPinClick,
  isPinned,
  isShared,
  isListOwner,
  isTheOnlyOwner,
}) => {
  const divElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!divElementRef.current) return;
    if (!user.dataModifiedTimeStamps) return;

    // This is another clever way to animate this list item
    // This animation will only be triggered if the user data has been modified in the last 500ms
    // This is to prevent the animation from being triggered when the user data is being loaded
    // Similar setup to what we have in the TaskPreview component

    if (Date.now() - user.dataModifiedTimeStamps < 1000) {
      assignMenuItemEnterAnimation(divElementRef.current);
    }
  }, []);

  const { t } = useTranslation();
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const [presentModalActionSheet, dismissModalActionSheet] = useIonModal(AssignMenuActionSheet, {
    userData: user,
    isShared,
    isListOwner,
    isTheOnlyOwner,
    onShareClick: async (userId: string) => {
      // if (divElementRef.current) await assignMenuItemExitAnimation(divElementRef.current);
      onClick(userId, 'sharedWith');
      dismissModalActionSheet(null, 'submit');
    },
    onAssignCoOwnerClick: async (userId: string) => {
      // if (divElementRef.current) await assignMenuItemExitAnimation(divElementRef.current);
      onClick(userId, 'owners');
      dismissModalActionSheet(null, 'submit');
    },
    onRemoveClick: async (userId: string) => {
      // if (divElementRef.current) await assignMenuItemExitAnimation(divElementRef.current);
      onClick(userId, 'remove');
      dismissModalActionSheet(null, 'submit');
    },
  });

  const openModalActionSheet = () => {
    presentModalActionSheet({
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      cssClass: 'assign-menu-action-sheet-modal',
      onWillPresent: () => {
        setIsActionSheetOpen(true);
      },
      onDidDismiss: () => {
        setIsActionSheetOpen(false);
      },
    });
  };

  return (
    <div ref={divElementRef} className="d-flex ion-align-items-center ion-padding-end">
      <IonItem
        onClick={() => openModalActionSheet()}
        style={{ padding: '0px 16px', flex: '1', '--border-color': `${!isSelected ? 'var(--ion-color-light)' : ''}` }}
        lines="none"
        className={`custom-checkbox ${isSelected && 'multi-select-selected'}`}
      >
        <UserAvatar size="large" img={user.profilePhoto} userId={user.userId} />
        <IonLabel className="ion-padding-start">
          <div>
            <IonText>
              {user.firstName} {user.lastName}
            </IonText>
          </div>
          {(isListOwner || isShared) && (
            <div>
              <IonText className="font-16" color="medium">
                {isListOwner ? t('owner') : t('shared')}
              </IonText>
            </div>
          )}
        </IonLabel>
        <div>
          <IonIcon
            icon={chevronDownOutline}
            className={`font-24 ${isActionSheetOpen ? 'rotate180' : ''}`}
            style={{ transition: 'transform 250ms' }}
          />
        </div>
      </IonItem>
      <div
        className="ion-padding-end"
        onClick={(e) => {
          e.stopPropagation();
          onPushPinClick(user.userId);
        }}
      >
        <PushPinIcon isActive={isPinned} />
      </div>
    </div>
  );
};

interface AssignMenuActionSheetProps {
  onAssignCoOwnerClick: (userId: string) => void;
  onShareClick: (userId: string) => void;
  onRemoveClick: (userId: string) => void;
  userData: UserDO;
  isShared: boolean;
  isListOwner: boolean;
  isTheOnlyOwner: boolean;
}

const AssignMenuActionSheet: React.FC<AssignMenuActionSheetProps> = ({
  userData,
  isShared,
  isListOwner,
  isTheOnlyOwner,
  onAssignCoOwnerClick,
  onShareClick,
  onRemoveClick,
}) => {
  const { currentUserId } = useCurrentUser();
  const [presentOwnerRemoveAlert] = useIonAlert();

  const { t } = useTranslation();

  const onRemoveClickHandler = () => {
    if (isListOwner && userData.userId === currentUserId) {
      presentOwnerRemoveAlert({
        mode: 'md',
        cssClass: 'remove-owner-confirmation',
        header: 'Remove Yourself as Owner',
        message: 'You will no longer have permission to edit this list.',
        buttons: [
          {
            cssClass: 'remomve-owner-confirmation__submit-button',
            text: 'Yes, remove me as an owner.',
            handler: () => {
              onRemoveClick(userData.userId);
            },
          },
          {
            cssClass: 'remomve-owner-confirmation__cancel-button',
            text: 'Cancel',
          },
        ],
      });
    } else {
      onRemoveClick(userData.userId);
    }
  };

  const onAssignCoOwnerClickHandler = () => {
    if (isListOwner && userData.userId === currentUserId) {
      presentOwnerRemoveAlert({
        mode: 'md',
        cssClass: 'remove-owner-confirmation',
        header: 'Remove Yourself as Owner',
        message: 'You will no longer have permission to edit this list.',
        buttons: [
          {
            cssClass: 'remomve-owner-confirmation__submit-button',
            text: 'Yes, remove me as an owner.',
            handler: () => {
              onAssignCoOwnerClick(userData.userId);
            },
          },
          {
            cssClass: 'remomve-owner-confirmation__cancel-button',
            text: 'Cancel',
          },
        ],
      });
    } else {
      onAssignCoOwnerClick(userData.userId);
    }
  };

  return (
    <div style={{ padding: '0px 16px' }}>
      {/* Main content box */}
      <div style={{ background: 'var(--ion-color-overlay)', borderRadius: '12px', marginBottom: '8px' }}>
        {/* User Info */}
        <div style={{ borderBottom: '1px solid var(--ion-color-light)' }}>
          <IonItem lines="none" style={{ '--background': 'var(--ion-color-overlay)', borderRadius: '12px' }}>
            <div className="ion-margin">
              <UserAvatar size="medium" img={userData.profilePhoto} userId={userData.userId} />
            </div>
            <IonLabel color="light" className="font-22">
              {userData.firstName} {userData.lastName}
            </IonLabel>
          </IonItem>
        </div>

        {/* Action Items */}
        <div>
          <IonItem
            lines="none"
            style={{ '--background': 'var(--ion-color-overlay)', '--min-height': '56px' }}
            onClick={() => onAssignCoOwnerClickHandler()}
            disabled={isTheOnlyOwner}
          >
            <IonIcon
              color="primary"
              slot="start"
              className="ion-no-margin ion-margin font-32"
              icon={checkmarkOutline}
              style={{ opacity: isListOwner ? '1' : '0' }}
            />
            <IonLabel color="primary" className="font-weight-500 font-22">
              {t('owner')}
            </IonLabel>
          </IonItem>
          <IonItem
            lines="none"
            style={{
              '--background': 'var(--ion-color-overlay)',
              borderRadius: '0 0 12px 12px',
              '--min-height': '56px',
            }}
            onClick={() => onShareClick(userData.userId)}
            disabled={isTheOnlyOwner}
          >
            <IonIcon
              color="primary"
              slot="start"
              className="ion-no-margin ion-margin font-32"
              style={{ opacity: isShared ? '1' : '0' }}
              icon={checkmarkOutline}
            />
            <IonLabel color="primary" className="font-weight-500 font-22">
              {t('share')}
            </IonLabel>
          </IonItem>
        </div>
      </div>

      {/* Remove box */}
      <div className="ion-margin-bottom" style={{ background: 'var(--ion-color-overlay)', borderRadius: '12px' }}>
        <IonItem
          lines="none"
          style={{ '--background': 'var(--ion-color-overlay', borderRadius: '12px', '--min-height': '56px' }}
          onClick={() => onRemoveClickHandler()}
          disabled={isTheOnlyOwner}
        >
          <IonIcon color="primary" slot="start" className="ion-no-margin ion-margin font-32" icon={closeOutline} />
          <IonLabel color="primary" className="font-weight-500 font-22">
            {t('remove')}
          </IonLabel>
        </IonItem>
      </div>
    </div>
  );
};

const assignMenuItemEnterAnimation = async (divElement: HTMLDivElement) => {
  const animation = createAnimation()
    .addElement(divElement)
    .duration(500)
    .fromTo('opacity', '0', '1')
    .fromTo('maxHeight', '1px', '500px');
  await animation.play();
};

const assignMenuItemExitAnimation = async (divElement: HTMLDivElement) => {
  const animation = createAnimation()
    .addElement(divElement)
    .duration(500)
    .fromTo('opacity', '1', '0')
    .fromTo('maxHeight', '500px', '1px');
  await animation.play();
};
