import { IonGrid, IonItem, IonList, IonListHeader, IonRow, IonSkeletonText } from "@ionic/react"
import React from "react"

const ListsViewSkeletonText: React.FC = () => {
    return (
        <IonList class="skeleton-list">
            <IonListHeader>
                <IonGrid>
                    <IonRow class="ion-justify-content-between">
                        <IonSkeletonText animated style={{ width: '20%' }} />
                        <IonSkeletonText animated style={{ width: '10%' }} />
                    </IonRow>
                </IonGrid>
            </IonListHeader>
            {[...Array(10)].map((_, index) =>
                <IonItem key={index} lines="none">
                    <IonGrid>
                        <IonRow>
                            <IonSkeletonText animated style={{ width: '80%' }} />
                        </IonRow>
                        <IonRow class="ion-justify-content-between">
                            <IonSkeletonText animated style={{ width: '70%' }} />
                            <IonSkeletonText animated style={{ width: '10%' }} />
                            <IonSkeletonText animated style={{ width: '10%' }} />
                        </IonRow>
                    </IonGrid>
                </IonItem>
            )}
        </IonList>
    )
}

export default React.memo(ListsViewSkeletonText)