import { PhotoInterface } from '../../interfaces/PhotoInterface';
import { DexieCompletionCache, DexiePhoto } from './implementationModel/dexieObjects';
import { db } from './db';
import { Photo } from '../../interfaces/objects';
import { v4 as uuid } from 'uuid';
import { TaskDexieImplementation } from './taskDexieImplementation';
import { _changeLog, _task } from '../ImplementationFactory';
import { ChangeType } from '../../interfaces/changeLoggerInterface';
import { graphql } from '../../../../api/apiUtils';
import { EnvironmentConfig, getNumber } from '../../../../environmentUtils';
import { makeImageFilesizeSafe } from '../../../../../features/checklist/photos/imageUtils';
import { PhotoTag } from '../../../../../constants/constants';

const TIME_TO_KEEP_CACHE_IN_MS = getNumber(EnvironmentConfig.TIME_TO_KEEP_CACHE_IN_MS, 1000 * 60 * 60 * 24 * 7);

export class PhotoDexieImplementation implements PhotoInterface {
  private async getRawPhotoById(photoId: string): Promise<DexiePhoto> {
    const matchingPhotos = await db().photo.where({ photoId }).toArray();

    if (!matchingPhotos || matchingPhotos.length === 0) {
      console.warn(`No photos matching ID ${photoId}`);
      return {
        photoId,
        data: '',
      };
    }
    if (matchingPhotos.length > 1) {
      console.error('Multiple photos matching id ' + photoId, matchingPhotos);
      throw new Error('Multiple photos matching id ' + photoId);
    }

    return matchingPhotos[0];
  }

  private castDexieToPhoto(dexiePhoto: DexiePhoto): Photo {
    const { cacheTime, ...rest } = dexiePhoto;
    return {
      ...rest,
    };
  }

  private castPhotoToDexie(photo: Photo): DexiePhoto {
    return {
      ...photo,
    };
  }

  async getById(photoId: string): Promise<Photo> {
    const photo: DexiePhoto = await this.getRawPhotoById(photoId);
    return this.castDexieToPhoto(photo);
  }

  async getPhoto(photoId: string): Promise<Photo> {
    return this.getById(photoId);
  }

  async getThumbnail(photoId: string): Promise<Photo> {
    const matchingThumbNails = await db().thumbnail.where({ photoId }).toArray();

    if (!matchingThumbNails || matchingThumbNails.length === 0) {
      console.warn(`No thumb nails matching ID ${photoId}`);
      return {
        photoId: '',
        data: '',
      };
    }
    if (matchingThumbNails.length > 1) {
      throw new Error('Multiple thumb nails matching id ' + photoId);
    }

    return this.castDexieToPhoto(matchingThumbNails[0]);
  }

  async addCompletionPhotoToTask(taskId: string, photoData: string): Promise<Photo> {
    const newPhotoID = uuid();
    console.log('addCompletionPhotoToTask - start');

    console.log('addCompletionPhotoToTask - building local photo');
    const localPhoto: Photo = {
      photoId: newPhotoID,
      data: photoData,
    };
    console.log('addCompletionPhotoToTask - saving local photo');
    await this.savePhoto(localPhoto);

    await _task.addCompletionPhotoToTask(taskId, newPhotoID);
    console.log('addCompletionPhotoToTask - _task call');

    console.log('addCompletionPhotoToTask - resizing');
    //We explicitly do not "await" here so that the user gets feedback quicker while we work on resizing the image
    makeImageFilesizeSafe(photoData).then((processedImage) => {
      const serverFriendlyPhoto: Photo = {
        photoId: newPhotoID,
        data: processedImage,
      };

      console.log('addCompletionPhotoToTask - saving change log');
      _changeLog.queueChange({
        changeType: ChangeType.COMPLETION_ADD_PHOTO,
        recordId: taskId,
        payload: JSON.stringify(serverFriendlyPhoto),
      });
      //TODO: also update the on device unscaled version with this resized version?
    });

    console.log('addCompletionPhotoToTask - returning');
    return localPhoto;
  }

  async getCompletionPhotoIdsForTask(taskId: string): Promise<string[]> {
    const matchingCompletion: DexieCompletionCache =
      await new TaskDexieImplementation().getCurrentCompletionForTaskById(taskId);

    return matchingCompletion.completionPhotoIds ? matchingCompletion.completionPhotoIds : [];
  }

  private async savePhoto(photo: Photo) {
    const dexiePhoto: DexiePhoto = this.castPhotoToDexie(photo);
    dexiePhoto.cacheTime = new Date();
    await db().photo.add(dexiePhoto);
    await db().thumbnail.delete(dexiePhoto.photoId); //Once we have the full photo, no longer store the thumbnail
  }

  async removeCompletionPhotoFromTask(taskId: string, photoId: string) {
    await db().photo.delete(photoId);
    await _task.removeCompletionPhotoFromTask(taskId, photoId);

    _changeLog.queueChange({
      changeType: ChangeType.COMPLETION_REMOVE_PHOTO,
      recordId: taskId,
      payload: JSON.stringify({ photoId: photoId }),
    });
  }

  async downloadThumbnail(photoId: string) {
    if (!photoId) {
      console.error('No photoID supplied trying to download Thumbnails');
    }

    const query = `
      query getThumbnailsFromServer($photoIds: [ID!]!) {
        getThumbnails(photoIds: $photoIds) {
          photoId
          data
          tag
        }
      }
    `;

    const variables = {
      photoIds: [photoId],
    };

    const apiResults = await graphql<Photo[]>(query, variables);
    if (!apiResults) return null;

    const thumbnailsWithCacheInfo = apiResults.map<DexiePhoto>((thumbNail) => {
      return {
        ...thumbNail,
        cacheTime: new Date(),
      };
    });

    await db().thumbnail.bulkPut(thumbnailsWithCacheInfo);

    return apiResults[0];
  }

  async updatePhotoAndThumbnail(
    taskId: string,
    photoId: string,
    changes: Partial<DexiePhoto>,
    changeType: ChangeType = ChangeType.COMPLETION_PHOTO_EDIT
  ): Promise<Photo> {
    await db().photo.update(photoId, changes);
    await db().thumbnail.update(photoId, changes);

    const task = await db().task.where({ taskId }).first();
    if (!task) {
      throw new Error('Task not found for taskId ' + taskId);
    }

    const updatedOn = new Date();
    await db().task.update(taskId, { updatedOn });
    await db().list.update(task.listId, { updatedOn });

    await _changeLog.queueChange({
      changeType,
      recordId: photoId,
      payload: JSON.stringify(changes),
    });

    const result = await this.getById(photoId);
    return result;
  }

  async updateTag(taskId: string, photoId: string, tag: PhotoTag | null) {
    return this.updatePhotoAndThumbnail(taskId, photoId, { tag });
  }

  async downloadPhoto(photoId: string) {
    if (!photoId) {
      console.error('No photoID supplied trying to download Photos');
    }

    const query = `
      query getPhotosFromServer($photoIds: [ID!]!) {
        getPhotos(photoIds: $photoIds) {
          photoId
          data
          tag
        }
      }
    `;
    const variables = {
      photoIds: [photoId],
    };
    const apiResults = await graphql<Photo[]>(query, variables);
    if (!apiResults) return null;

    const photosWithCacheInfo: DexiePhoto[] = apiResults.map<DexiePhoto>((photo) => ({
      ...photo,
      cacheTime: new Date(),
    }));
    await db().photo.bulkPut(photosWithCacheInfo);

    //delete thumbnails now that we have the full photos
    const thumbNailReferences: string[] = photosWithCacheInfo.map((photo) => {
      return photo.photoId;
    });
    await db().thumbnail.bulkDelete(thumbNailReferences);

    return apiResults[0];
  }

  async cleanUpImageCache() {
    //TODO: If the user is offline more than TIME_TO_KEEP_CACHE we will be removing photos that they took and that are not uploaded yet.  Since that data is stored in the change log this should be ok
    const cacheTime = new Date(new Date().getTime() - TIME_TO_KEEP_CACHE_IN_MS);

    await db().thumbnail.where('cacheTime').below(cacheTime).delete();
    await db().photo.where('cacheTime').below(cacheTime).delete();
  }
}
