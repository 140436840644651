import { IonAlert, IonButton, IonCol, IonIcon, IonLabel, IonRow, IonText } from '@ionic/react';
import { cameraOutline, cloudDownload, cloudOfflineOutline } from 'ionicons/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCamera from './state/useCamera';
import { TaskDO } from '../../../utils/state/model/interfaces/displayObjects';
import { ThumbnailTile } from './ThumbnailTile';
import { writeAccessRestrictedState } from '../../../App';
import { useRecoilValue } from 'recoil';
import { _task } from '../../../utils/state/model/implementations/ImplementationFactory';

import 'swiper/css/bundle';
import '@ionic/react/css/ionic-swiper.css';
import PendingThumbnail from './PendingThumbnail';
import { isPermittedNetworkConnectedState } from '../../../sync/network/network';

interface PhotoTilesProps {
  task: TaskDO;
  isListOwner: boolean;
}

const PhotosTile: React.FC<PhotoTilesProps> = ({ task, isListOwner }) => {
  const { isRestricted: isWriteAccessRestricted } = useRecoilValue(writeAccessRestrictedState);
  const isOnline = useRecoilValue(isPermittedNetworkConnectedState);

  const [isOfflineDialogOpen, setIsOfflineDialogOpen] = useState<boolean>(false);

  const { takePhoto } = useCamera(task.taskId);
  const { t } = useTranslation();

  const handleTakePhoto = async () => {
    await takePhoto();
  };

  return (
    <>
      {/* Header */}
      <IonRow>
        <IonCol class="d-flex ion-justify-content-between ion-align-items-center">
          <IonLabel>
            {t('picturesAbbreviated')}:{' '}
            {task.isPhotoRequired && (
              <IonText color="medium" style={{ paddingLeft: '.25rem' }}>
                ({t('required')})
              </IonText>
            )}
          </IonLabel>
          {isListOwner && (
            <IonButton
              fill="clear"
              size="small"
              className="ion-no-padding ion-no-margin"
              style={{ fontSize: '1.25rem' }}
              onClick={() => {
                _task.updateTaskIsPhotoRequired(task.taskId, !task.isPhotoRequired);
              }}
            >
              {task.isPhotoRequired ? t('requirePhoto.makeOptional') : t('requirePhoto')}
            </IonButton>
          )}
        </IonCol>
      </IonRow>

      {/* Photos */}
      <div style={{ marginBottom: '32px', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px' }}>
        {task?.completionPhotoIds
          ? task?.completionPhotoIds.map((photoId) => {
              return (
                <ThumbnailTile listId={task.listId} taskId={task.taskId} photoId={photoId} key={'pTile' + photoId} />
              );
            })
          : ''}
        {task?.pendingPhotos
          ? task?.pendingPhotos.map((pendingPhotoId) => {
              if (isOnline) {
                return (
                  <PendingThumbnail
                    key={'pendingPhotoTile' + pendingPhotoId}
                    icon={cloudDownload}
                    title={'Waiting for photo from another source'}
                    includeSpinner
                  />
                );
              } else {
                return <PendingThumbnail key={'pendingPhotoTile' + pendingPhotoId} icon={cloudOfflineOutline} />;
              }
            })
          : ''}

        <div
          className="d-flex ion-align-items-center ion-justify-content-center"
          style={{
            gridColumn:
              (task.completionPhotoIds && task.completionPhotoIds.length % 3 === 0) || !task.completionPhotoIds
                ? 'span 3'
                : '',
          }}
        >
          <IonIcon
            style={{ fontSize: '72px', paddingBottom: '1rem' }}
            color="primary"
            icon={cameraOutline}
            onClick={!isWriteAccessRestricted ? handleTakePhoto : undefined}
          />
        </div>
      </div>

      {/* New Photos Slider */}
      {/* <div> */}
      {/* <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={10}
          slidesPerView={task.completionPhotoIds && task.completionPhotoIds.length > 0 ? (task.completionPhotoIds.length < 3 ? 3 : 3) : 1}
          centeredSlides={true}
          centeredSlidesBounds={true}
          // navigation={{
          //   nextEl: '.swiper-button-next',
          //   prevEl: '.swiper-button-prev',
          // }}
          // pagination={{ 
          //   clickable: false,
          //   el: '.swiper-pagination',
          // }}
        >
          {task?.completionPhotoIds
            ? task?.completionPhotoIds.map((photoId) => {
                return (
                  <SwiperSlide key={'pSlide' + photoId}>
                    <ThumbnailTile
                      listId={task.listId}
                      taskId={task.taskId}
                      photoId={photoId}
                      key={'pTile' + photoId}
                    />
                  </SwiperSlide>
                );
              })
            : ''} */}

      {/* <SwiperSlide>
            <div className="d-flex ion-align-items-center ion-justify-content-center" style={{ height: '120px' }}>
              <IonIcon
                style={{ fontSize: '72px' }}
                color="primary"
                icon={cameraOutline}
                onClick={!isWriteAccessRestricted ? handleTakePhoto : undefined}
              />
            </div>
          </SwiperSlide>
        </Swiper> */}
      {/* <div style={{position: 'relative', minHeight: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div className="swiper-button-prev"></div>
          <div className="swiper-preview-pagination swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div> */}
      {/* </div> */}

      {/* Offline Dialog */}
      <IonAlert
        isOpen={isOfflineDialogOpen}
        header={t('alert.offline.photo.header')!}
        message={t('alert.offline.photo.message')!}
        buttons={[t('button.acknowledge')!]}
        onWillDismiss={() => setIsOfflineDialogOpen(false)}
      />
    </>
  );
};

export default PhotosTile;
