import { graphql } from '../../utils/api/apiUtils';
import { EnvironmentConfig, getFlag } from '../../utils/environmentUtils';
import { _changeLog, _user } from '../../utils/state/model/implementations/ImplementationFactory';
import {
  replaceUpdatedUsers,
  getUserSyncedOns,
} from '../../utils/state/model/implementations/dexie/userDexieImplementation';
import { ChangeLog } from '../../utils/state/model/interfaces/changeLoggerInterface';
import { SyncedOn } from './downloadChanges';
import { parseUsersFromData } from './parsers';

export interface UpdatedUserData {
  deletedUserIds: string[];
  updatedUsers: any[];
}

interface UserProfilePicture {
  userId: string;
  profilePicture: string;
  isDefaultProfilePicture: boolean;
}

const generateUserUpdateInput = async (forceRecords: string[] = []): Promise<SyncedOn[]> => {
  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('generateUserUpdateInput - start');

  //The intent of excluding certain records is to make sure we do not overwrite locally made changes
  const excludedRecordIds: string[] = await getUsersWithPendingChangeLogs(forceRecords);

  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS))
    console.log('generateUserUpdateInput - Have exclusions, calculating sync times');

  const userSyncedOns: SyncedOn[] = await getUserSyncedOns(excludedRecordIds, forceRecords);

  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('generateUserUpdateInput - return');

  return userSyncedOns;
};

/**
 * We are only ready to update a record once all of our own changes for that records that have been processed.
 * We do this to make sure our local changes are not overwritten by changes on the server.
 */
const getUsersWithPendingChangeLogs = async (forcedRecords: string[]): Promise<string[]> => {
  const pendingChangeLogs: ChangeLog[] = await _changeLog.getAllPendingChanges();
  const userIdsWithPendingChanges: string[] = pendingChangeLogs
    .filter((change) => change.changeType.includes('user'))
    .map((change) => change.recordId);
  return userIdsWithPendingChanges;
};

export const getUpdatedUsersFromServer = async function (people: SyncedOn[]): Promise<UpdatedUserData> {
  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('getUpdatedUsersFromServer - start');
  const query = `
        query getUpdatedUsers($people: [SyncedOn!]!) {
            getUpdatedUsers(people: $people) {
                deletedUserIds
                updatedUsers {
                    userId
                    firstName
                    lastName
                    email
                    language
                    role
                    settings
                    syncedOn
                }
            }
        }
    `;
  const variables = { people };
  const result = await graphql<UpdatedUserData>(query, variables);
  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('getUpdatedUsersFromServer - end', result);
  return result;
};

export async function syncUsers(forceRecords: string[]) {
  const userIds = await generateUserUpdateInput(forceRecords);

  const updatedUserData = await getUpdatedUsersFromServer(userIds);
  const updatedUsers = parseUsersFromData(updatedUserData);
  await replaceUpdatedUsers(updatedUserData.deletedUserIds, updatedUsers);

  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log(`We have ${updatedUsers.length} updated users`);

  return updatedUsers;
}

const getProfilePictureForUser = async (userId: string): Promise<UserProfilePicture> => {
  const query = `
        query getPersonProfilePictureQuery($personId: ID!) {
            getPersonProfilePicture(personId: $personId) {
                userId
                profilePicture
                isDefaultProfilePicture
            }
        }
    `;
  const variables = { personId: userId };
  return graphql<UserProfilePicture>(query, variables);
};

export async function downloadProfilePictures(updatedUsers: any[]) {
  if (updatedUsers) {
    let promises = [];
    const [assignedUsers, userswithoutPhotos] = await _user.getUserDownloadPriorty();

    for (var i = 0; i < assignedUsers.length; i++) {
      const userId = assignedUsers[i];
      const userInUpdateList = updatedUsers.find((user) => user.userId === userId);
      if (userInUpdateList) {
        promises.push(updateSingleUserProfilePicture(userInUpdateList.userId));
      }
    }

    for (var j = 0; j < userswithoutPhotos.length; j++) {
      const userId = userswithoutPhotos[j];
      const userAlreadyUpdated = assignedUsers.find((id) => id === userId);
      if (!userAlreadyUpdated) {
        promises.push(updateSingleUserProfilePicture(userId));
      }
    }

    await Promise.all(promises);
  }
}

const updateSingleUserProfilePicture = async (userId: string) => {
  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log(`Fetching profile picture for user ${userId}`);
  const profilePicture: UserProfilePicture = await getProfilePictureForUser(userId);
  if (profilePicture) {
    if (profilePicture.userId !== userId) {
      console.warn('User Ids do not match for profile picture and user', profilePicture.userId, userId);
    } else {
      await _user.updateUserWithRemoteChanges(userId, {
        profilePhoto: profilePicture.profilePicture,
        isDefaultProfilePhoto: profilePicture.isDefaultProfilePicture,
      });
    }
  } else {
    //TODO: delete the locally cached image
  }
};
