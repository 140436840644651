import { IonButton, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { t } from 'i18next';
import { EnvironmentConfig, getFlag } from '../../../utils/environmentUtils';

interface AssignMenuHeaderProps {
  isDoneButtonDisabled: boolean;
  onCancelClickHandler: () => void;
  onDoneClickHandler: () => void;
  menuType: 'assign' | 'share' | null;
  tasksCount?: number;
}

const AssignMenuHeader: React.FC<AssignMenuHeaderProps> = ({
  isDoneButtonDisabled,
  onCancelClickHandler,
  onDoneClickHandler,
  menuType,
  tasksCount,
}) => {
  const recordType = !tasksCount ? 'list' : 'task';
  return (
    <IonHeader class="ion-padding-top ion-no-border">
      <IonToolbar style={{ '--ion-safe-area-top': '0px' }}>
        <IonButton onClick={onCancelClickHandler} slot="start" fill="clear">
          {t('cancel')}
        </IonButton>
        <IonTitle class="ion-text-center" style={{ fontSize: '22px' }}>
          {getFlag(EnvironmentConfig.LIST_OWNER_UI)
            ? t('assignMenu.header.list.shareOwner')
            : t(`assignMenu.header.${recordType}.${menuType}`, { count: tasksCount })}
        </IonTitle>
        <IonButton onClick={onDoneClickHandler} slot="end" fill="clear" disabled={isDoneButtonDisabled}>
          {t('done')}
        </IonButton>
      </IonToolbar>
    </IonHeader>
  );
};

export default AssignMenuHeader;
