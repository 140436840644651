import { useEffect, useState } from 'react';
// import { CSSTransition, TransitionGroup } from 'react-transition-group'; //TODO: Remove this lib and use IonAnimation
// The main reason for using TransitionGroup is to animate the removal of an item from the list
// This was really useful when this list was not virtualized and we were not sure what kind of animation we wanted to use
// Now that the list is virtualized, we can use IonAnimation to animate the removal of an item
// Since virtualization is not supported by TransitionGroup, we will have to remove it and use IonAnimation
import AssignMenuItem from './AssignMenuItem';

import './AssignMenuUserList.css';
import { Virtuoso } from 'react-virtuoso';
import { UserDOWithRefs } from './AssignMenu';

interface AssignMenuUserListProps {
  filteredUsers: UserDOWithRefs[];
  pinnedUsers: string[];
  selectedUsers: string[];
  sharedWithUsers: string[];
  listOwners: string[];
  onUserAssignClickHandler: (userId: string) => void;
  onUserShareClickHandler: (userId: string, type: 'owners' | 'sharedWith' | 'remove') => void;
  onPushPinClickHandler: (userId: string) => void;
  menuType: 'assign' | 'share' | null; //TODO: use an enum rather than a magic string
  ionContentRef: HTMLElement | null;
}

const AssignMenuUserList: React.FC<AssignMenuUserListProps> = ({
  filteredUsers,
  pinnedUsers,
  selectedUsers,
  sharedWithUsers,
  listOwners,
  onPushPinClickHandler,
  onUserAssignClickHandler,
  onUserShareClickHandler,
  menuType,
  ionContentRef,
}) => {
  const [pinnedAndSelectedUsers, setPinnedAndSelectedUsers] = useState<UserDOWithRefs[]>([]);
  const [unpinnedAndSelectedUsers, setUnpinnedAndSelectedUsers] = useState<UserDOWithRefs[]>([]);
  const [pinnedAndUnselectedUsers, setPinnedAndUnselectedUsers] = useState<UserDOWithRefs[]>([]);
  const [unpinnedAndUnselectedUsers, setUnpinnedAndUnselectedUsers] = useState<UserDOWithRefs[]>([]);

  useEffect(() => {
    const pinnedAndSelected: UserDOWithRefs[] = [];
    const unpinnedAndSelected: UserDOWithRefs[] = [];
    const pinnedAndUnselected: UserDOWithRefs[] = [];
    const unpinnedAndUnselected: UserDOWithRefs[] = [];

    filteredUsers.forEach((user) => {
      if (selectedUsers.includes(user.userId) && pinnedUsers.includes(user.userId)) return pinnedAndSelected.push(user);
      if (selectedUsers.includes(user.userId) && !pinnedUsers.includes(user.userId))
        return unpinnedAndSelected.push(user);
      if (pinnedUsers.includes(user.userId)) return pinnedAndUnselected.push(user);
      return unpinnedAndUnselected.push(user);
    });

    setPinnedAndSelectedUsers((prev) => [...pinnedAndSelected]);
    setUnpinnedAndSelectedUsers((prev) => [...unpinnedAndSelected]);
    setPinnedAndUnselectedUsers((prev) => [...pinnedAndUnselected]);
    setUnpinnedAndUnselectedUsers((prev) => [...unpinnedAndUnselected]);
  }, [filteredUsers, selectedUsers, pinnedUsers, ionContentRef]);

  return (
    <>
      {/* Pinned and Selected Users */}
      <Virtuoso
        useWindowScroll
        customScrollParent={ionContentRef || undefined}
        className="ion-content-window-scroll-host"
        style={{ height: pinnedAndSelectedUsers.length > 0 ? '100%' : '' }}
        totalCount={pinnedAndSelectedUsers.length}
        increaseViewportBy={400}
        overscan={15}
        itemContent={(index) => {
          const user = pinnedAndSelectedUsers[index];
          return (
            <AssignMenuItem
              key={`${user.userId}-pinned-selected`}
              user={user}
              isPinned={pinnedUsers.includes(user.userId)}
              isSelected={selectedUsers.includes(user.userId)}
              sharedWithUsers={sharedWithUsers}
              listOwners={listOwners}
              onUserAssignClick={onUserAssignClickHandler}
              onUserShareClick={onUserShareClickHandler}
              onPushPinClick={onPushPinClickHandler}
              menuType={menuType}
            />
          );
        }}
      />

      {/* Unpinned and Selected Users */}
      <Virtuoso
        useWindowScroll
        customScrollParent={ionContentRef || undefined}
        className="ion-content-window-scroll-host"
        style={{ height: unpinnedAndSelectedUsers.length > 0 ? '100%' : '' }}
        increaseViewportBy={400}
        overscan={15}
        totalCount={unpinnedAndSelectedUsers.length}
        itemContent={(index) => {
          const user = unpinnedAndSelectedUsers[index];
          return (
            <AssignMenuItem
              key={`${user.userId}-unpinned-selected`}
              user={user}
              isPinned={pinnedUsers.includes(user.userId)}
              isSelected={selectedUsers.includes(user.userId)}
              sharedWithUsers={sharedWithUsers}
              listOwners={listOwners}
              onUserAssignClick={onUserAssignClickHandler}
              onUserShareClick={onUserShareClickHandler}
              onPushPinClick={onPushPinClickHandler}
              menuType={menuType}
            />
          );
        }}
      />

      {/* Separator */}
      <div
        style={{ height: unpinnedAndSelectedUsers.length > 0 || pinnedAndSelectedUsers.length > 0 ? '32px' : '0px' }}
      ></div>

      {/* Pinned and Unselected Users */}
      <Virtuoso
        useWindowScroll
        customScrollParent={ionContentRef || undefined}
        className="ion-content-window-scroll-host"
        style={{ height: pinnedAndUnselectedUsers.length > 0 ? '100%' : '' }}
        increaseViewportBy={400}
        overscan={15}
        totalCount={pinnedAndUnselectedUsers.length}
        itemContent={(index) => {
          const user = pinnedAndUnselectedUsers[index];
          return (
            <AssignMenuItem
              key={`${user.userId}-pinned-unselected`}
              user={user}
              isPinned={pinnedUsers.includes(user.userId)}
              isSelected={selectedUsers.includes(user.userId)}
              sharedWithUsers={sharedWithUsers}
              listOwners={listOwners}
              onUserAssignClick={onUserAssignClickHandler}
              onUserShareClick={onUserShareClickHandler}
              onPushPinClick={onPushPinClickHandler}
              menuType={menuType}
            />
          );
        }}
      />

      {/* Unpinned and Unselected Users */}
      <Virtuoso
        useWindowScroll
        customScrollParent={ionContentRef || undefined}
        className="ion-content-window-scroll-host"
        style={{ height: unpinnedAndUnselectedUsers.length > 0 ? '100%' : '' }}
        increaseViewportBy={400}
        overscan={15}
        totalCount={unpinnedAndUnselectedUsers.length}
        itemContent={(index) => {
          const user = unpinnedAndUnselectedUsers[index];
          return (
            <AssignMenuItem
              key={`${user.userId}-unpinned-unselected`}
              user={user}
              isPinned={pinnedUsers.includes(user.userId)}
              isSelected={selectedUsers.includes(user.userId)}
              sharedWithUsers={sharedWithUsers}
              listOwners={listOwners}
              onUserAssignClick={onUserAssignClickHandler}
              onUserShareClick={onUserShareClickHandler}
              onPushPinClick={onPushPinClickHandler}
              menuType={menuType}
            />
          );
        }}
      />
    </>
  );
};

export default AssignMenuUserList;
