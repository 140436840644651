import { _changeLog } from '../../utils/state/model/implementations/ImplementationFactory';
import isPermittedNetworkConnected from '../network/network';
import { graphql } from '../../utils/api/apiUtils';
import { isCurrentUserAuthenticated } from '@otuvy/auth';
import { logFriendlyObject } from '@otuvy/common-utils';
import { EnvironmentConfig, getFlag } from '../../utils/environmentUtils';

interface ConfirmationResult {
  changeId: string;
  status: ConfirmationStatus;
  message?: string;
}

enum ConfirmationStatus {
  PROCESSED = 'PROCESSED',
  UNPROCESSED = 'UNPROCESSED',
  ERROR_PROCCESSING = 'ERROR_PROCCESSING',
  NOT_FOUND = 'NOT_FOUND',
}

const confirmChangeProcessedQuery = `
        query confirm(
            $changeId: ID!
        ){
          confirmChangeProcessed(
            changeId: $changeId 
          ) 
            {
            changeId
            status
            message
          }        
        }
    `;

async function confirmSingleChange(changeId: string): Promise<void> {
  const variables = { changeId };
  const { status } = await graphql<ConfirmationResult>(confirmChangeProcessedQuery, variables);

  if (status === ConfirmationStatus.PROCESSED) {
    await _changeLog.markChangeAsConfirmed(changeId);
  } else if (status === ConfirmationStatus.NOT_FOUND) {
    await _changeLog.markChangeAsUnUploaded(changeId);
  } else if (status === ConfirmationStatus.ERROR_PROCCESSING) {
    // If it's hit the max number of failures, get rid of the local change log and let the record receive updates
    await _changeLog.markChangeAsConfirmed(changeId);
  }
}

export async function confirmChanges(): Promise<void> {
  if (!(await isPermittedNetworkConnected())) {
    console.log('Not permitted to confirm in this network state! (confirmChanges)');
    return;
  }

  const isUserAuthenticated: boolean = await isCurrentUserAuthenticated();

  if (!isUserAuthenticated) {
    return;
  }

  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('confirming changes');

  const changeIds: string[] = await _changeLog.getChangeIdsNeedingConfirmed();

  if (changeIds.length === 0) {
    if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('No changes found to confirm');
    return;
  }

  for (var id of changeIds) {
    try {
      await confirmSingleChange(id);
    } catch (error) {
      console.error(`Error when confirming change ${id} on server: `, logFriendlyObject(error));
    }
  }
}
