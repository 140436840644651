import { IonInput } from '@ionic/react';
import { useRef } from 'react';
import { t } from 'i18next';

interface AssignMenuSearchInputProps {
  onInputChangedHandler: (searchValue: string) => void;
}

const AssignMenuSearchInput: React.FC<AssignMenuSearchInputProps> = ({ onInputChangedHandler }) => {
  const searchInputRef = useRef<HTMLIonInputElement>(null);

  return (
    <IonInput
      mode="ios"
      ref={searchInputRef}
      value={searchInputRef.current?.value}
      debounce={300}
      style={{ width: 'auto', minHeight: '44px' }}
      class="custom-input ion-margin-horizontal"
      placeholder={t('searchInput.placeholder')!}
      onIonChange={(e) => onInputChangedHandler(e.detail.value! as string)}
    />
  );
};

export default AssignMenuSearchInput;
