import { IonLabel } from '@ionic/react';
import { t } from 'i18next';
interface Props {
  isCompleted: boolean;
  areAllSelected: boolean;
  sectionItemsCount: number;
  onClick: (isCompletedGroup: boolean, isCheckboxChecked: boolean) => void;
}
const SelectAllTaskCheckbox: React.FC<Props> = ({ isCompleted, areAllSelected, sectionItemsCount, onClick }) => {
  const onCheckboxClickHandler = (checkboxValue: boolean) => {
    onClick(isCompleted, !checkboxValue);
  };
  const text = () => {
    if (isCompleted && areAllSelected) return t('multiSelectMenu.deselect.completed');
    if (isCompleted && !areAllSelected) return t('multiSelectMenu.select.completed');
    if (!isCompleted && areAllSelected) return t('multiSelectMenu.deselect.incomplete');
    if (!isCompleted && !areAllSelected) return t('multiSelectMenu.select.incomplete');
    return '';
  };

  if (sectionItemsCount === 0) return null;

  return (
    <IonLabel
      color="primary"
      class="ion-padding-start font-16 font-weight-500"
      onClick={() => {
        onCheckboxClickHandler(areAllSelected);
      }}
    >
      {text()}
    </IonLabel>
  );
};

export default SelectAllTaskCheckbox;
