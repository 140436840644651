import { Keyboard } from '@capacitor/keyboard';
import { isPlatform } from '@ionic/core';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { showInputState } from '../features/checklist/AddInputType';

const KeyboardSpacer: React.FC = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const isAddInputTypeDisplayed = useRecoilValue(showInputState);

  useEffect(() => {
    if (isPlatform('hybrid') && isPlatform('ios')) {
      const keyboardSpacerDidShowEvent = Keyboard.addListener('keyboardDidShow', (e) =>
        setKeyboardHeight(e.keyboardHeight - 25)
      );
      const keyboardSpacerWillShowEvent = Keyboard.addListener('keyboardWillHide', () => {
        if (isAddInputTypeDisplayed) return;
        setKeyboardHeight(0);
      });

      return () => {
        keyboardSpacerDidShowEvent.remove();
        keyboardSpacerWillShowEvent.remove();
      };
    }
  }, []);

  return <div style={{ height: isAddInputTypeDisplayed ? keyboardHeight : '0' }}></div>;
};

export default KeyboardSpacer;
