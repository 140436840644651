import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
} from '@ionic/react';
import errorImageLight from '../assets/images/error-icons-light-mode.svg';
import errorImageDark from '../assets/images/error-icons-dark-mode.svg';
import { arrowDown, happyOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilValue } from 'recoil';
import { settingsState } from '../features/settings/settingsState';
import MenuToolbar from '../features/menu/MenuToolbar';
import React, { useEffect, useState } from 'react';
import VersionStamp from '../features/settings/VersionStamp';
import { AnalyticsEventType } from '../analytics/analyticsConstants';
import { AnalyticsEvent } from '../utils/state/model/interfaces/analyticsInterface';
import { _analytics } from '../utils/state/model/implementations/ImplementationFactory';
import { Clipboard } from '@capacitor/clipboard';
import { logFriendlyObject } from '@otuvy/common-utils';

export interface errorState {
  showErrorPage: boolean;
  errorDetails: any;
}

export const errorPageState = atom<errorState>({
  key: 'showErrorPageState',
  default: {
    showErrorPage: false,
    errorDetails: 'No "current" error details',
  },
});

const ErrorPage: React.FC = () => {
  const currentErrorState = useRecoilValue(errorPageState);
  const settings = useRecoilValue(settingsState);
  const { t } = useTranslation();
  const [clickCount, setClickCount] = useState<number>(0);

  const copyLastErrorState = (e: React.MouseEvent<HTMLIonTextElement, globalThis.MouseEvent>) => {
    const load = async () => {
      try {
        console.log(
          'Attempting to write these error details to the clipboard',
          logFriendlyObject(currentErrorState.errorDetails)
        );

        const errorDetails =
          currentErrorState.errorDetails && currentErrorState.errorDetails !== ''
            ? currentErrorState.errorDetails
            : 'No current Error details (ErrorPage)';

        if (errorDetails) {
          await Clipboard.write({
            string: errorDetails,
          });
        } else {
          console.warn(
            'errorDetails was empty so we are not writing to the clipboard',
            logFriendlyObject(errorDetails),
            logFriendlyObject(currentErrorState)
          );
        }
      } catch (e) {
        console.log('Error writing the error state to the clipboard', logFriendlyObject(e));
      }
    };

    e.preventDefault();
    if (clickCount >= 2) {
      load();
    }
    setClickCount(clickCount + 1);

    setTimeout(function () {
      setClickCount(0);
    }, 1000);
  };

  useEffect(() => {
    try {
      const event: Partial<AnalyticsEvent> = {
        eventType: AnalyticsEventType.PAGE_CHANGE,
        payload: JSON.stringify({
          page: 'Error Page',
          details: currentErrorState.errorDetails,
        }),
      };
      _analytics.queueEvent(event);
    } catch (e) {
      //catch to make sure our error handling code does not generate errors
      console.error('Error logging analytics about the error page', logFriendlyObject(e));
    }
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid fixed={true} class="h-100 d-flex flex-direction-column ion-justify-content-between">
          <IonRow class="h-100 ion-align-items-center">
            <IonCol>
              <IonRow class="ion-align-items-end ion-justify-content-center">
                <IonCol size="4">
                  <IonImg src={settings.darkMode ? errorImageDark : errorImageLight} />
                </IonCol>
              </IonRow>
              <IonRow class="ion-align-items-start">
                <IonCol class="ion-text-center">
                  <IonText color="dark" onClick={copyLastErrorState}>
                    <h1>{t('errorPage.header')}</h1>
                  </IonText>
                  <p>
                    {currentErrorState.errorDetails === 'Could not find list'
                      ? t('errorPage.unavailableList.message')
                      : t('errorPage.message')}
                  </p>
                  <p>
                    <a href="mailto:support@otuvy.com">{t('link.feedback')}</a>
                  </p>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow class="ion-justify-content-center ion-align-items-center">
            <IonCol pull="1" class="ion-text-center">
              <div
                className="d-flex ion-justify-content-center ion-align-items-center"
                style={{ marginLeft: '1.5rem' }}
              >
                <div>{t('errorPage.backIntoIt')}</div> <IonIcon icon={happyOutline} />
              </div>
              <IonIcon icon={arrowDown} size="large" />
            </IonCol>
            <IonCol pull="3" class="ion-text-center ion-align-self-end">
              <VersionStamp authenticated={false} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <MenuToolbar isErrorPage={true} />
    </IonPage>
  );
};
export default ErrorPage;
