import { IonCol, IonRow, TextareaChangeEventDetail } from '@ionic/react';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { writeAccessRestrictedState } from '../../App';
import CustomTextarea from './CustomTextarea';
import ExpandableLabel from './ExpandableLabel';
import { useTranslation } from 'react-i18next';

interface ExpandableInputProps {
  value: string;
  accessibilityLabel: string;
  inputName?: string;
  fontSize?: string;
  inputMaxlength?: number;
  lineClamp?: number;
  onClick?: () => void;
  onIonChange?: (event: CustomEvent<TextareaChangeEventDetail>) => void;
  onIonBlur?: (event: React.FocusEvent<HTMLIonTextareaElement, Element>) => void;
  doesEnterKeyBlur?: boolean;
  placeholder?: string;
  editable: boolean;
  disableExpandIcon?: boolean;
}

const ExpandableInput: React.FC<ExpandableInputProps> = ({
  value,
  accessibilityLabel,
  inputName,
  fontSize,
  inputMaxlength,
  lineClamp,
  onClick,
  onIonChange,
  onIonBlur,
  doesEnterKeyBlur,
  placeholder,
  editable = true,
  disableExpandIcon,
}) => {
  const { isRestricted: isWriteAccessRestricted } = useRecoilValue(writeAccessRestrictedState);
  const [isInputDisplayed, setIsInputDisplayed] = useState<boolean>(false);

  const { t } = useTranslation();
  const handleClick = () => {
    if (isWriteAccessRestricted || !editable) return;
    if (onClick) {
      onClick();
    }
    setIsInputDisplayed(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLIonTextareaElement, Element>) => {
    if (onIonBlur) {
      onIonBlur(event);
    }
    setIsInputDisplayed(false);
  };

  return (
    <>
      <IonRow class="ion-justify-content-between ion-align-items-start">
        <IonCol class="no-vertical-padding ion-no-padding">
          {isWriteAccessRestricted || !editable || !isInputDisplayed ? (
            <ExpandableLabel
              value={value || (editable ? t('placeholder.generic') : '')}
              color={!value ? 'light' : undefined}
              fontSize={fontSize}
              lineClamp={lineClamp}
              onClick={handleClick}
              disableExpandIcon={disableExpandIcon}
            />
          ) : (
            <CustomTextarea
              accessibilityLabel={accessibilityLabel}
              name={inputName}
              value={value}
              fontSize={fontSize}
              maxlength={inputMaxlength}
              onIonChange={onIonChange}
              onIonBlur={handleBlur}
              doesEnterKeyBlur={doesEnterKeyBlur}
              placeholder={placeholder ? placeholder : ''}
            />
          )}
        </IonCol>
      </IonRow>
    </>
  );
};
export default ExpandableInput;
