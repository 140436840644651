import { IonFab, IonFabButton, IonIcon, IonLabel } from '@ionic/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { settingsState } from '../settings/settingsState';

import plusDark from '../../assets/images/icons/Dark_Mode_Plus_Icon.svg';
import plusLight from '../../assets/images/icons/Light_Mode_Plus_Icon.svg';
import { t } from 'i18next';
import { showInputState } from '../checklist/AddInputType';

interface AddItemFabProps {
  type: 'task' | 'list';
}

const AddItemFab: React.FC<AddItemFabProps> = ({ type }) => {
  const [showInput, setShowInput] = useRecoilState(showInputState);
  const { darkMode } = useRecoilValue(settingsState);

  return (
    <IonFab vertical="bottom" horizontal="end" slot="fixed" class="ion-margin-end">
      <IonFabButton
        className="custom-button-radius"
        color="primary"
        style={{ minWidth: '8rem' }}
        onClick={() => setShowInput(true)}
      >
        <IonIcon icon={darkMode ? plusDark : plusLight} />
        <IonLabel style={{ marginLeft: '0.5rem', '--ion-label-font-size': '1.5rem' }}>{t(type)}</IonLabel>
      </IonFabButton>
    </IonFab>
  );
};

export default AddItemFab;
