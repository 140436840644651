import { Preferences } from '@capacitor/preferences';
import { useEffect, useState } from 'react';

export enum SortOption {
  NAME = 'name',
  CREATION_DATE = 'createdOn',
  DUE_DATE = 'dueDate',
  RECENT_ACTIVITY = 'updatedOn',
  ASSIGNED_TO = 'assignedTo',
}

const STORAGE_KEY_PREFIX = 'sort/';

const useSortOptions = (storageKey: string) => {
  const [sortOption, setSortOption] = useState<SortOption | undefined>();
  const [areSortOptionsOpen, setAreSortOptionsOpen] = useState<boolean>(false);

  const fullStorageKey = `${STORAGE_KEY_PREFIX}${storageKey}`;

  useEffect(() => {
    if (!sortOption) {
      Preferences.get({ key: fullStorageKey }).then(({ value }) => {
        if (!value) return;
        const parsedValue = JSON.parse(value);
        setSortOption(parsedValue);
      });
    } else {
      Preferences.set({
        key: fullStorageKey,
        value: JSON.stringify(sortOption),
      });
    }
  }, [sortOption]);

  return {
    sortOption,
    setSortOption,
    areSortOptionsOpen,
    setAreSortOptionsOpen,
  };
};

export default useSortOptions;
