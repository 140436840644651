import { graphql } from '../../utils/api/apiUtils';

export interface GenerateTasksInput {
  listName: string;
  typeOfTasks?: string;
  listMainObjective?: string;
}

export interface GenerateTasksResponse {
  step: string;
  duration: number; // in minutes
}

export const generateTasksForList = async (input: GenerateTasksInput): Promise<GenerateTasksResponse[]> => {
  const query = `
        mutation createTasksForMeMutation($input: GenerateTasksInput!) {
            generateTasksForList(input: $input)
        }
    `;
  const variables = {
    input,
  };
  const response = await graphql<string>(query, variables);
  try {
    const taskData: GenerateTasksResponse[] = JSON.parse(response);
    return taskData;
  } catch (error) {
    console.error('Failed to parse response for generating tasks', error);
    throw error;
  }
};

export interface ReportListSelectionList {
  listId: string;
  listName: string;
  owner: string;
  ownerName: string;
}

export const getListsForDateRange = async (
  startDate: Date,
  endDate: Date,
  userId?: string | undefined
): Promise<ReportListSelectionList[]> => {
  const query = `
    query getListsForDateRangeQuery($startDate: String!, $endDate: String!, $userId: String) {
      getListsForDateRange(startDate: $startDate, endDate: $endDate, userId: $userId) {
        listId
        listName
        owner
        ownerName
      }
    }
  `;
  const variables = {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    userId,
  };
  try {
    const lists = await graphql<ReportListSelectionList[]>(query, variables);
    return lists;
  } catch (error) {
    console.error('Failed to get lists for date range', error);
    throw error;
  }
};

export const qrRequestShareList = async (listId: string): Promise<boolean> => {
  const query = `
    mutation qrShareListMutation($listId: ID!) {
      qrShareList(listId: $listId)
    }
  `;

  try {
    const success = await graphql<boolean>(query, { listId });
    return success;
  } catch (error) {
    console.error('Failed to request share list', error);
    throw error;
  }
};
