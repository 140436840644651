import { IonButton, IonIcon, IonItem, IonModal } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SoftwareUpdateInfo } from '../../constants/constants';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { determineIfUpdateNeeded } from '../../utils/useCheckOrgGovernors';
import { Preferences } from '@capacitor/preferences';
import { latestSoftwareVersionState } from '../../App';
import { EnvironmentConfig, getFlag } from '../../utils/environmentUtils';
import { Device } from '@capacitor/device';
import { rocketOutline } from 'ionicons/icons';

export const goToDeviceAppropriateAppStore = async () => {
  const device = await Device.getInfo();

  if (!device) {
    console.warn('No device info retrieved');
    return;
  }

  if (device.operatingSystem.toLowerCase() === 'android') {
    window.open('https://play.google.com/store/apps/details?id=com.otuvy.frontline');
  } else if (device.operatingSystem.toLowerCase() === 'ios') {
    window.open('https://apps.apple.com/app/otuvy-frontline/id6461824661');
  } else if (device.operatingSystem.toLowerCase() === 'windows') {
    //This usually happens when running inside a browser rather than actually ondevice
    console.log("OS 'windows' detected. Not taking any actions, though the user should CTL+F5");
  } else if (device.operatingSystem.toLowerCase() === 'web') {
    //TODO: what should we do here? Prompt to refresh?
    //This should be an edge case so I am just logging it for now
    console.log("OS 'web' detected. Not taking any actions, though the user should CTL+F5");
  } else {
    console.warn('Unhandled OS', device.operatingSystem);
  }
};

const SoftwareUpdateAvailable: React.FC = () => {
  const { t } = useTranslation();

  const updateInfo: SoftwareUpdateInfo = useRecoilValue(latestSoftwareVersionState);

  const [lastAcknowledgedVersion, setLastAcknowledgedVersion] = useState<string>('0.0.0');
  const [missingRequiredUpdate, setMissingRequiredUpdate] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const LAST_ACKNOWLEDGED_VERSION = 'last_Acknowledged_SoftwareUpdate_Version';
  const LAST_ACKNOWLEDGED_DATE = 'last_Acknowledged_SoftwareUpdate_Date';

  const loadLastAcknowledgedVersion = async () => {
    const acknowlegedVersion = (await Preferences.get({ key: LAST_ACKNOWLEDGED_VERSION })).value;

    if (acknowlegedVersion === updateInfo.currentVersion) {
      const acknowlegedDateInMillis = Number((await Preferences.get({ key: LAST_ACKNOWLEDGED_DATE })).value);
      if (acknowlegedDateInMillis) {
        const reacceptanceRequiredThreshold = new Date().valueOf() - 3 * 30 * 24 * 60 * 60 * 1000;

        //console.log(`Checking the dates of the last acknowledgement (${acknowlegedDateInMillis}) vs a bit ago (${awhileAgo})`);
        //If they have not acknowledged in a while then require reapproval
        if (acknowlegedDateInMillis < reacceptanceRequiredThreshold) {
          setLastAcknowledgedVersion('0.0.0');
          return;
        }
      }
    }

    //console.log(`Setting last acknowledged version ${acknowlegedVersion}`)
    setLastAcknowledgedVersion(acknowlegedVersion ?? '0.0.0');
  };

  const saveLastAcknowledgedVersion = async (version: string) => {
    //console.log(`Setting last acknowledged version to ${version}`)
    await Promise.all([
      Preferences.set({
        key: LAST_ACKNOWLEDGED_VERSION,
        value: version,
      }),
      Preferences.set({
        key: LAST_ACKNOWLEDGED_DATE,
        value: '' + new Date().valueOf(),
      }),
    ]);
    setLastAcknowledgedVersion(version);
  };

  useEffect(() => {
    if (!getFlag(EnvironmentConfig.CHECK_FOR_SOFTWARE_UPDATES)) return;

    if (!updateInfo) {
      //console.log("No update info found so aborting")
      setShow(false);
      return;
    }
    loadLastAcknowledgedVersion();

    setMissingRequiredUpdate(updateInfo ? determineIfUpdateNeeded(updateInfo.lastRequiredVersion) : false);

    //console.log(`Checking last acknowledged version (${lastAcknowledgedVersion}) against current latest version (${updateInfo.currentVersion}).  Last required: ${updateInfo.lastRequiredVersion}.  Missing Required: ${missingRequiredUpdate}`);
    if (updateInfo.currentVersion === lastAcknowledgedVersion && !missingRequiredUpdate) {
      //console.log("Attempting to dismiss from useEffect");
      if (show === true) setShow(false);
      return;
    }

    const behindCurrentVersion = determineIfUpdateNeeded(updateInfo.currentVersion);
    //console.log(`behindCurrentVersion: ${behindCurrentVersion} (${updateInfo.currentVersion}) missingRequiredUpdate: ${missingRequiredUpdate}`);
    setShow(behindCurrentVersion);
  }, [updateInfo]);

  const cancelClick = async () => {
    //console.log('Attempting to acknowledge version', updateInfo.currentVersion);
    if (updateInfo) await saveLastAcknowledgedVersion(updateInfo.currentVersion);
    setShow(false);
  };
  const updateClick = async () => {
    goToDeviceAppropriateAppStore();

    if (updateInfo) await saveLastAcknowledgedVersion(updateInfo.currentVersion);
    //Don't hide the popup here.
    //If they update, it will reload the app and check again to see if the popup should be shown.
    //If they don't actually update, continue showing the popup until they do.
  };

  return (
    <IonModal
      role="alertdialog"
      canDismiss={!show}
      isOpen={show}
      style={{
        '--height': 'fit-content',
        '--width': '85%',
        alignItems: 'center',
      }}
    >
      <div className="ion-padding">
        <div
          className="ion-text-center ion-icon-thin"
          style={{
            marginTop: '2rem',
            marginBottom: '2rem',
          }}
        >
          <IonIcon
            color="primary"
            style={{
              fontSize: '128px',
              margin: 'auto',
              fontWeight: 'normal',
            }}
            icon={rocketOutline}
          />
        </div>

        <div
          style={{
            fontFamily: 'Baloo Thambi 2',
            fontSize: '24px',
            fontWeight: '600',
            lineHeight: '32px',
            letterSpacing: '0em',
            textAlign: 'left',
            marginBottom: '0.75rem',
          }}
        >
          {t('update.title')}
        </div>

        <div
          style={{
            fontFamily: 'Baloo Thambi 2',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            textAlign: 'left',
            marginBottom: '0.75rem',
          }}
        >
          {t('update.body')}
        </div>

        <IonItem>
          {!missingRequiredUpdate && (
            <IonButton
              slot="start"
              fill="clear"
              color="secondary"
              onClick={cancelClick}
              style={{
                textTransform: 'uppercase',
              }}
            >
              {t('button.later')}
            </IonButton>
          )}

          <IonButton
            slot="end"
            onClick={updateClick}
            style={{
              '--border-radius': '5rem',
              textTransform: 'uppercase',
            }}
          >
            {t('button.update')}
          </IonButton>
        </IonItem>
      </div>
    </IonModal>
  );
};

export default SoftwareUpdateAvailable;
