import { IonAlert } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { addTimePunch } from '../utils/timePunchUtils';

export interface ClockOutConfirmationProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsClockedIn: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ClockOutConfirmation: React.FC<ClockOutConfirmationProps> = ({
  isOpen,
  setIsOpen,
  setIsClockedIn,
  setIsLoading,
}) => {
  const { t } = useTranslation();

  const onClockOut = async () => {
    setIsLoading(true);
    try {
      await addTimePunch(true);
      setIsClockedIn(false);
      setIsOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClockIn = async () => {
    setIsLoading(true);
    try {
      await addTimePunch(false);
      setIsClockedIn(true);
      setIsOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IonAlert
      mode="md"
      cssClass="clock-in-confirmation"
      header={'Wait!'}
      message={'You have been clocked in for more than 12 hours, did you miss a punch and mean to clock in?'}
      isOpen={isOpen}
      buttons={[
        {
          text: 'Yes, Clock Me In',
          role: 'cancel',
          handler: onClockIn,
        },
        {
          text: 'No, I’m Clocking Out',
          role: 'destructive',
          handler: onClockOut,
        },
      ]}
      onWillDismiss={() => setIsOpen(false)}
    />
  );
};

export default ClockOutConfirmation;
