import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { settingsState, SETTINGS_STORAGE_KEY } from './features/settings/settingsState';
import { currentListIdState } from './features/checklist/lists/state/useLists';
import { internalTasksState, usePostUpdateEffect } from './features/checklist/tasks/state/useTasks';
import { useLocalStorageEffect } from './utils/state/stateLocalPersistance';
import useIsFirstRender from './utils/useIsFirstRender';

/**
 * Effects that are triggered by global state are implemented here with useEffect hooks instead of in Recoil Atom Effects
 * because Atom Effects don't currently possess the ability to set other pieces of state (as of March 2022).
 * If this ever changes, these effects could likely be converted to Atom Effects.
 */
interface GlobalStateEffectsProps {
  children: React.ReactNode;
}

const GlobalStateEffects = ({ children }: GlobalStateEffectsProps) => {
  const isFirstRender = useIsFirstRender();
  const [tasks, setTasks] = useRecoilState(internalTasksState);
  const listId = useRecoilValue(currentListIdState);
  const { postUpdateEffect } = usePostUpdateEffect();
  const settings = useRecoilValue(settingsState);
  const { localStorageEffect } = useLocalStorageEffect();

  useEffect(() => {
    if (listId) {
      postUpdateEffect(listId);
    }
  }, [tasks]);

  useEffect(() => {
    if (!isFirstRender) {
      localStorageEffect(SETTINGS_STORAGE_KEY);
    }
  }, [settings]);

  useEffect(() => {
    if (!listId) {
      setTasks([]);
    }
  }, [listId]);

  return <>{children}</>;
};

export default GlobalStateEffects;
