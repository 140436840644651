import { useEffect } from 'react';

const useInputFocus = (
  inputRef: React.MutableRefObject<HTMLIonInputElement | null> | React.MutableRefObject<HTMLIonTextareaElement | null>,
  shouldNotFocus?: boolean
) => {
  useEffect(() => {
    if (shouldNotFocus) return;
    const internalInputChecker = setInterval(() => {
      // We need to make sure the internal `input` element is initialized before trying to focus on it
      inputRef.current?.getInputElement().then((element: any) => {
        if (element) {
          inputRef.current?.setFocus();
          element.setSelectionRange(element.value.length, element.value.length);
          clearInterval(internalInputChecker);
        }
      });
    }, 50);
    return () => {
      clearInterval(internalInputChecker);
    };
  }, []);
};

export default useInputFocus;
