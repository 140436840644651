import { IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react';
import { syncData } from '../../sync/sync';

interface PullToRefreshProps {
  listId?: string;
  taskId?: string;
}

const PullToRefresh: React.FC<PullToRefreshProps> = ({ listId, taskId }) => {
  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    // We use the setTimeout to give the user a better experience
    // and to show the spinner for a minimum amount of time
    setTimeout(() => {
      syncData({
        forceSyncLists: listId ? [listId] : [],
      }).then(() => {
        event.detail.complete();
      });
    }, 1000);
  }

  return (
    <IonRefresher class="custom-refresher" slot="fixed" onIonRefresh={handleRefresh}>
      <IonRefresherContent></IonRefresherContent>
    </IonRefresher>
  );
};

export default PullToRefresh;
