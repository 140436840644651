import { useEffect, useRef, useState } from 'react';
import { VirtuosoHandle } from 'react-virtuoso';
import { TaskDO, ListDO } from '../../../../utils/state/model/interfaces/displayObjects';
import { useRecoilState } from 'recoil';
import { listMultiSelectModeAtom } from '../../../../pages/ListsPage';

export enum VISIBLE_ITEM_SECTION {
  INCOMPLETE = 'Incomplete',
  COMPLETE = 'Complete',
  ARCHIVED = 'Archive',
}

export enum VIRTUAL_SCROLL_TYPE {
  TASKS = 'task',
  LISTS = 'list',
}

interface VisibleTaskHeader {
  header: VISIBLE_ITEM_SECTION;
}

interface VisibleTaskItems extends TaskDO {
  section: VISIBLE_ITEM_SECTION;
}

interface VisibleListItems extends ListDO {
  section: VISIBLE_ITEM_SECTION;
}

const completeSectionHeader = { header: VISIBLE_ITEM_SECTION.COMPLETE };
const incompleteSectionHeader = { header: VISIBLE_ITEM_SECTION.INCOMPLETE };
const archivedSectionHeader = { header: VISIBLE_ITEM_SECTION.ARCHIVED };

const useVirtualScroll = (
  incompleteItems: TaskDO[] | ListDO[],
  completeItems: TaskDO[] | ListDO[],
  showInput: boolean,
  listType: VIRTUAL_SCROLL_TYPE,
  isCompletedSectionOn: boolean,
  isArchivedSectionOn?: boolean,
  archiveItems?: ListDO[]
) => {
  const [isMultiSelectMode, setIsMultiSelectMode] = useRecoilState(listMultiSelectModeAtom);
  const [visibleItems, setVisibleItems] = useState<(VisibleTaskItems | VisibleListItems | VisibleTaskHeader)[]>([]);
  const [collapsedSections, setCollapsedSections] = useState({
    Incomplete: false,
    Complete: false,
    Archive: false,
  });

  const virtuosoListRef = useRef<VirtuosoHandle>(null);

  useEffect(() => {
    if (isMultiSelectMode)
      setCollapsedSections({
        Incomplete: false,
        Complete: false,
        Archive: false,
      });
  }, [isMultiSelectMode]);

  useEffect(() => {
    const incompleteItemsWithSection = processSections(incompleteItems, VISIBLE_ITEM_SECTION.INCOMPLETE);
    const completeItemsWithSection = processSections(completeItems, VISIBLE_ITEM_SECTION.COMPLETE);
    const archivedItemsWithSection = processSections(archiveItems || [], VISIBLE_ITEM_SECTION.ARCHIVED);

    let itemData = processVisibleSectionsHeaders(
      listType,
      isCompletedSectionOn,
      isArchivedSectionOn || false,
      collapsedSections,
      incompleteItemsWithSection,
      completeItemsWithSection,
      archivedItemsWithSection
    );

    setVisibleItems(itemData);
  }, [incompleteItems, completeItems, isCompletedSectionOn, collapsedSections, isArchivedSectionOn, archiveItems]);

  useEffect(() => {
    const incompleteItemsWithSection = processSections(incompleteItems, VISIBLE_ITEM_SECTION.INCOMPLETE);

    if (showInput)
      virtuosoListRef.current?.scrollToIndex({
        index:
          listType === VIRTUAL_SCROLL_TYPE.TASKS
            ? incompleteItemsWithSection.length
            : incompleteItemsWithSection.length + 1,
        align: 'end',
        behavior: 'smooth',
      });
  }, [showInput, incompleteItems]);

  const handleToggleCollapse = (section: VISIBLE_ITEM_SECTION) => {
    setCollapsedSections((prevState) => {
      const updatedState = { ...prevState, [section]: !prevState[section as keyof typeof prevState] };

      if (updatedState[section as keyof typeof updatedState]) {
        setVisibleItems((prevItems) =>
          prevItems.filter((item) => {
            if ('section' in item && item.section !== section) return true;
            if ('header' in item) return true;
            return false;
          })
        );
      }

      return updatedState;
    });
  };

  return { visibleItems, virtuosoListRef, handleToggleCollapse, collapsedSections };
};

export default useVirtualScroll;

const processSections = (items: TaskDO[] | ListDO[], section: VISIBLE_ITEM_SECTION) => {
  return items.map((item) => ({ ...item, section }));
};

const processVisibleSectionsHeaders = (
  listType: VIRTUAL_SCROLL_TYPE,
  isCompletedSectionOn: boolean,
  isArchivedSectionOn: boolean,
  collapsedSections: any,
  incompleteItemsWithSection: (VisibleListItems | VisibleTaskItems)[],
  completeItemsWithSection: (VisibleListItems | VisibleTaskItems)[],
  archivedItemsWithSection: (VisibleListItems | VisibleTaskItems)[]
) => {
  if (listType === VIRTUAL_SCROLL_TYPE.LISTS) {
    const visibleSections = [];

    const showIncomplete = !collapsedSections.Incomplete;
    const showComplete = !collapsedSections.Complete;
    const showArchive = !collapsedSections.Archive;

    showIncomplete
      ? visibleSections.push(incompleteSectionHeader, ...incompleteItemsWithSection)
      : visibleSections.push(incompleteSectionHeader);

    if (isCompletedSectionOn)
      showComplete
        ? visibleSections.push(completeSectionHeader, ...completeItemsWithSection)
        : visibleSections.push(completeSectionHeader);

    if (isArchivedSectionOn)
      showArchive
        ? visibleSections.push(archivedSectionHeader, ...archivedItemsWithSection)
        : visibleSections.push(archivedSectionHeader);

    return visibleSections;
  }

  if (listType === VIRTUAL_SCROLL_TYPE.TASKS) {
    if (isCompletedSectionOn) {
      if (collapsedSections.Complete) return [...incompleteItemsWithSection, completeSectionHeader];

      return [...incompleteItemsWithSection, completeSectionHeader, ...completeItemsWithSection];
    } else {
      if (collapsedSections.Complete) return [...incompleteItemsWithSection];

      return [...incompleteItemsWithSection];
    }
  }

  return [];
};
