import { parseISO } from 'date-fns';
import { COMPLETE, NOT_COMPLETE } from '../../constants/constants';
import {
  DexieTask,
  DexieCompletionCache,
  DexieList,
  DexieUser,
} from '../../utils/state/model/implementations/dexie/implementationModel/dexieObjects';
import { TaskDexieImplementation } from '../../utils/state/model/implementations/dexie/taskDexieImplementation';
import { UpdatedUserData } from './downloadUsers';
import { calculateCurrentResetTime } from '../../features/calendar/utils/calculateRecurrence';
import { DownloadListWithTasks, DownloadTaskWithListId } from './downloadChanges';

export const parseListsFromData = (updatedLists: DownloadListWithTasks[]): DexieList[] => {
  return updatedLists.map<DexieList>((list) => {
    return {
      listId: list.listId,
      listName: list.listName,
      updatedOn: parseISO(list.updatedOn),
      sortId: parseInt(list.sortId, 10),
      createdOn: parseISO(list.createdOn),

      owner: list.owner,
      sharedWith: list.sharedWith ?? undefined,

      notes: list.notes ?? undefined,

      isArchived: list.isArchived,
      qmInspectionId: list.qmInspectionId ?? undefined,

      syncedOn: parseISO(list.syncedOn),
    };
  });
};

export const parseTasksFromData = async (
  updatedTasks: DownloadTaskWithListId[]
): Promise<[DexieTask[], DexieCompletionCache[]]> => {
  const tasks: DexieTask[] = [];
  const completions: DexieCompletionCache[] = [];
  for (const task of updatedTasks) {
    if (!task.updatedOn) {
      throw new Error('updatedOn was not included in the task data downloaded from the server');
    }

    const dexieTask: DexieTask = {
      taskId: task.taskId,
      taskName: task.taskName,

      listId: task.listId,

      sortId: parseInt(task.sortId, 10),

      assignedTo: task.assignedTo ?? undefined,
      assignedBy: task.assignedBy ?? undefined,

      createdOn: parseISO(task.createdOn),
      updatedOn: parseISO(task.updatedOn),

      instructions: task.instructions ?? undefined,
      isPhotoRequired: task.isPhotoRequired,
      linkToInstructionsUrl: task.linkToInstructionsUrl ?? undefined,
      linkToInstructionsText: task.linkToInstructionsText ?? undefined,

      header: task.header ?? undefined,

      nonRecurringDueDate: task.nonRecurringDueDate ? parseISO(task.nonRecurringDueDate) : null,
      recurrence: task.recurrence ?? undefined,
      // resetTime: task.recurrence ? await calculateCurrentResetTime((task as DexieTask)) : undefined, //TODO: add this back in when resetTime is being finished
      timezone: task.timezone ?? undefined,

      syncedOn: parseISO(task.syncedOn),
    };
    tasks.push(dexieTask);

    const dexieCompletion: DexieCompletionCache = {
      taskId: task.taskId,
      listId: task.listId,

      cacheId: TaskDexieImplementation.calculateCurrentCacheIdForPreloadedTask(dexieTask),

      dueDate: task.dueDate ? parseISO(task.dueDate) : null,
      notes: task.notes ?? undefined,
      completedOn: task.completedOn ? parseISO(task.completedOn) : undefined,
      completedBy: task.completedBy ?? undefined,
      isCompleted: task.completedOn ? COMPLETE : NOT_COMPLETE,

      completionPhotoIds: task.completionPhotoIds ?? undefined,
      pendingPhotos: task.pendingPhotos ?? undefined,
    };
    completions.push(dexieCompletion);
  }
  return [tasks, completions];
};

export const parseUsersFromData = (data: UpdatedUserData): DexieUser[] => {
  return data.updatedUsers.map<DexieUser>(parseSingleUserFromData);
};

export const parseSingleUserFromData = (userData: any): DexieUser => {
  if (!userData) {
    console.warn('No user data provided to parse!  Expect errors');
  }
  return {
    ...userData,
    settings: userData.settings ? JSON.parse(userData.settings) : undefined,
    syncedOn: parseISO(userData.syncedOn),
  };
};
