import { handleNotificationTeardown } from '../../features/notifications/notificationUtils';
import { _datasource } from '../state/model/implementations/ImplementationFactory';
import { logOut } from '@otuvy/auth';

export const logOutWithTeardown = async (attemptLastSync: boolean = true) => {
  await logOut(() => handleAdditionalTeardown(attemptLastSync));
};

export const handleAdditionalTeardown = async (attemptLastSync: boolean = true) => {
  document.dispatchEvent(new CustomEvent('downloadedFirstBatch', { detail: { hasDownloadedFirstBatch: false } }));

  await handleNotificationTeardown();

  await _datasource.preSignOut(attemptLastSync);
  //TODO: release all DB watches

  /*
      While it is best practice to clear the local database on logout,
      it is recommended to do that in the Auth event listener
      (https://github.com/aws-amplify/amplify-js/discussions/9388#discussioncomment-1848743),
      rather than the actual logout code
    */
};
