import { TextareaChangeEventDetail } from '@ionic/core';
import {
  useIonLoading,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonLabel,
  IonFooter,
} from '@ionic/react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { AnalyticsEventType } from '../../analytics/analyticsConstants';
import { ValidationErrors, MaxFieldLengths } from '../../constants/constants';
import { _analytics } from '../../utils/state/model/implementations/ImplementationFactory';
import { AnalyticsEvent } from '../../utils/state/model/interfaces/analyticsInterface';
import { errorAlertMessageKeyState } from '../error/ErrorAlert';
import CustomTextarea from '../general/CustomTextarea';
import { showInputState } from './AddInputType';
import { GenerateTasksInput } from './listApi';
import useListGeneration from './useListGeneration';
import { close as closeIcon } from 'ionicons/icons';

interface AiModalProps {
  isOpen: boolean;
  close: () => void;
  name: string;
  focusInputEvent: () => void;
}

const AiModal: React.FC<AiModalProps> = ({ isOpen, close, name, focusInputEvent }) => {
  const setShowInput = useSetRecoilState(showInputState);
  const setErrorMessageKey = useSetRecoilState(errorAlertMessageKeyState);

  const [listName, setListName] = useState<string>('');
  const [typeOfTasks, setTypeOfTasks] = useState<string>('');
  const [listMainObjective, setListMainObjective] = useState<string>('');

  const { t } = useTranslation();
  const [presentLoading, dismissLoading] = useIonLoading();
  const history = useHistory();

  useEffect(() => {
    setListName(name);
  }, [name]);

  useEffect(() => {
    if (!isOpen) return;

    const event: Partial<AnalyticsEvent> = {
      eventType: AnalyticsEventType.CLICK_AI_LIST_GENERATION_BUTTON_1,
      payload: JSON.stringify({
        listNameWhenClicked: name,
      }),
    };
    _analytics.queueEvent(event);
  }, [isOpen]);

  const { generateList } = useListGeneration();

  const createListHandler = async () => {
    const data: GenerateTasksInput = {
      listName,
      typeOfTasks: typeOfTasks || undefined,
      listMainObjective: listMainObjective || undefined,
    };

    await presentLoading(t('loading.generateList') ?? '');

    generateList(data)
      .then((list) => {
        close();
        setShowInput(false);
        history.push(`/list/${list.listId}`);
      })
      .catch((error) => {
        if (error.message === ValidationErrors.OPEN_AI_CONTENT_FILTER) {
          console.warn('Content flagged as inappropriate');
          setErrorMessageKey('errorAlert.generateList.contentFilter.message');
        } else {
          console.error('Failed to generate list', error);
          setErrorMessageKey('errorAlert.generateList.generic.message');
        }
      })
      .finally(() => {
        dismissLoading();
      });

    const event: Partial<AnalyticsEvent> = {
      eventType: AnalyticsEventType.CLICK_AI_LIST_GENERATION_BUTTON_2,
      payload: JSON.stringify({
        input: data,
      }),
    };
    _analytics.queueEvent(event);
  };

  return (
    <IonModal
      isOpen={isOpen}
      keepContentsMounted={false}
      onDidDismiss={() => focusInputEvent()}
      className="h-100 no-border-radius"
    >
      <IonHeader className="ion-no-border ion-padding-horizontal">
        <IonToolbar>
          <IonTitle>{t('modal.generateList.header')}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={close}>
              <IonIcon icon={closeIcon} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLabel className="ion-padding-start">{t('modal.generateList.question1.label')}</IonLabel>
        <CustomTextarea
          name="addInputType"
          value={listName}
          accessibilityLabel={t('modal.generateList.question1.accessibilityLabel') ?? ''}
          placeholder={t('modal.generateList.question1.placeholder') ?? ''}
          onIonChange={(event: CustomEvent<TextareaChangeEventDetail>) => setListName(event.detail.value!)}
          maxlength={MaxFieldLengths.LIST_NAME}
          shouldNotFocus={name.length !== 0}
          shouldNotScroll
          doesEnterKeyBlur
        ></CustomTextarea>
        <IonLabel className="ion-padding-start">{t('modal.generateList.question2.label')}</IonLabel>
        <CustomTextarea
          name="addInputType"
          value={typeOfTasks}
          accessibilityLabel={t('modal.generateList.question2.label') ?? ''}
          placeholder={t('modal.generateList.question2.placeholder') ?? ''}
          onIonChange={(event: CustomEvent<TextareaChangeEventDetail>) => setTypeOfTasks(event.detail.value!)}
          maxlength={MaxFieldLengths.TASK_GENERATION_TYPE_OF_TASKS}
          shouldNotFocus={name.length < 1}
          shouldNotScroll
          doesEnterKeyBlur
        ></CustomTextarea>
        <IonLabel className="ion-padding-start">{t('modal.generateList.question3.label')}</IonLabel>
        <CustomTextarea
          marginBottom="0px"
          name="addInputType"
          value={listMainObjective}
          accessibilityLabel={t('modal.generateList.question3.label') ?? ''}
          placeholder={t('modal.generateList.question3.placeholder') ?? ''}
          onIonChange={(event: CustomEvent<TextareaChangeEventDetail>) => setListMainObjective(event.detail.value!)}
          maxlength={MaxFieldLengths.TASK_GENERATION_LIST_MAIN_OBJECTIVE}
          shouldNotFocus
          shouldNotScroll
          doesEnterKeyBlur
        ></CustomTextarea>
      </IonContent>
      <IonFooter className="ion-no-border d-flex ion-padding ion-align-items-bottom ion-justify-content-end">
        <IonButton
          className="btn-md"
          color="primary"
          shape="round"
          disabled={listName.length === 0 || typeOfTasks.length === 0}
          onClick={createListHandler}
        >
          {t('modal.generateList.button.accept')}
        </IonButton>
      </IonFooter>
    </IonModal>
  );
};

export default AiModal;
