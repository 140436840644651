import { IonDatetime, IonGrid, IonLabel, IonRow } from '@ionic/react';
import { formatISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { RRule } from 'rrule';
import { settingsState } from '../../settings/settingsState';
import { CustomValues } from './CustomRecurrenceSelect';

interface YearMenuProps {
  onCustomValueChange: (value: CustomValues) => void;
  recurrenceValue: RRule | null;
}

const initialDatetimeValue = (rule: RRule | null) => {
  if (!rule) return null;
  if (!rule.origOptions.interval) return null;
  if (rule.options.freq !== RRule.YEARLY) return null;
  return formatISO(rule.options.dtstart);
};

const YearMenu: React.FC<YearMenuProps> = ({ onCustomValueChange, recurrenceValue }) => {
  const [datetimeValue, setDatetimeValue] = useState<null | string>(() => initialDatetimeValue(recurrenceValue));

  const { language } = useRecoilValue(settingsState);
  const { t } = useTranslation();

  useEffect(() => {
    if (!datetimeValue) return onCustomValueChange(null);
    onCustomValueChange(datetimeValue);
  }, [datetimeValue]);

  return (
    <IonGrid class="ion-no-padding">
      <IonRow>
        <IonLabel class="font-32 font-weight-500">{t('repeatMenu.custom.on')}:</IonLabel>
      </IonRow>
      <IonDatetime
        value={datetimeValue}
        mode="ios"
        locale={language === undefined || language === 'en' ? 'en-US' : 'es-ES'}
        presentation="date"
        onIonChange={(event) => setDatetimeValue(event.target.value as string)}
      />
    </IonGrid>
  );
};

export default YearMenu;
