import { SyncedOn } from '../../../sync/syncFromServer/downloadChanges';
import { SyncedRecord } from './implementations/dexie/implementationModel/dexieObjects';

const futureDate: Date = new Date(new Date().valueOf() + 1000 * 60 * 60 * 24 * 30 * 12 * 50); // nothing will have been updated after a date in the future (roughly 50 years)
const veryEarlyDate: Date = new Date(0); // everything will have been updated after this very early date

export class syncedOnParams {
  recordsToConvert: SyncedRecord[] = [];
  excludedIds: string[] = [];
  requiredIds: string[] = [];
  requiredParentIds?: string[] = [];
  idExtractionMethod: (record: any) => string = (r) => {
    if (r && r.id) return r.id;
    return '';
  };
  parentIdExtractionMethod?: null | ((record: any) => string) = null;
}

export const convertToSyncedOns = (params: syncedOnParams): SyncedOn[] => {
  const foundIds: string[] = [];

  const convertedRecords = params.recordsToConvert.map((record: any) => {
    const recordId = params.idExtractionMethod(record);
    foundIds.push(recordId);

    const parentId = params.parentIdExtractionMethod ? params.parentIdExtractionMethod(record) : null;

    if (params.excludedIds.includes(recordId)) {
      return {
        id: recordId,
        syncedOn: futureDate,
      };
    } else if (params.requiredIds.includes(recordId)) {
      return {
        id: recordId,
        syncedOn: veryEarlyDate,
      };
    } else if (parentId && params.requiredParentIds?.includes(parentId)) {
      return {
        id: recordId,
        syncedOn: veryEarlyDate,
      };
    } else {
      return {
        id: recordId,
        syncedOn: record.syncedOn ?? veryEarlyDate,
      };
    }
  });

  //Make sure any deleted records are included in the "known state" so that the server does not think we know nothing about it and need to create it
  params.excludedIds.forEach((id) => {
    if (!foundIds.includes(id)) {
      convertedRecords.push({
        id,
        syncedOn: futureDate,
      });
    }
  });

  return convertedRecords;
};
