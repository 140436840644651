import { IonIcon, IonSpinner } from '@ionic/react';

interface PendingThumbnailProps {
  icon: string;
  title?: string;
  includeSpinner?: boolean;
}

const PendingThumbnail: React.FC<PendingThumbnailProps> = ({ icon, title, includeSpinner }) => {
  return (
    <div
      style={{ height: '120px', width: '100%', flexDirection: 'column', backgroundColor: '#404943' }}
      className="d-flex ion-align-items-center ion-justify-content-center"
    >
      <IonIcon style={{ fontSize: '48px', pointerEvents: 'none' }} color="light" icon={icon} title={title} />
      {includeSpinner && <IonSpinner name="dots"></IonSpinner>}
    </div>
  );
};

export default PendingThumbnail;
