import { CreateAnimation, IonContent, IonIcon, IonPopover } from '@ionic/react';
import { t } from 'i18next';
import { sync } from 'ionicons/icons';
import { useRecoilValue } from 'recoil';
import { syncingIndicatorState } from '../../App';

const SyncingIndicator: React.FC = () => {
  const isSyncing = useRecoilValue(syncingIndicatorState);
  const uniqueElementID = Date.now().toString();

  return (
    <>
      {/* Syncing Icon */}
      {isSyncing && (
        <>
          <CreateAnimation
            duration={1000}
            keyframes={[{ transform: 'rotate(0deg)' }, { transform: 'rotate(360deg)' }]}
            iterations={Infinity}
            play={true}
          >
            <IonIcon
              id={uniqueElementID}
              color="light"
              className="font-32"
              icon={sync}
              style={{ marginRight: '0.5rem' }}
            />
          </CreateAnimation>

          <IonPopover mode="md" trigger={uniqueElementID}>
            <IonContent class="ion-padding">
              <p>{t('syncIndicator.popover.text')}</p>
            </IonContent>
          </IonPopover>
        </>
      )}
    </>
  );
};

export default SyncingIndicator;
