import { createAnimation } from '@ionic/react';
import { RefObject } from 'react';

export const taskPreviewEnterAnimation = async (
  slidingElement: RefObject<HTMLIonItemSlidingElement>,
  itemElement: RefObject<HTMLIonItemElement>
) => {
  if (slidingElement.current === null || itemElement.current === null) return;
  const animation = createAnimation()
    .addElement(slidingElement.current)
    .addElement(itemElement.current)
    .duration(500)
    .fromTo('opacity', '0', '1')
    .fromTo('maxHeight', '1px', '500px');
  await animation.play();
};

export const taskPreviewExitAnimation = async (
  slidingElement: RefObject<HTMLIonItemSlidingElement>,
  itemElement: RefObject<HTMLIonItemElement>
) => {
  if (slidingElement.current === null || itemElement.current === null) return;
  const animation = createAnimation()
    .addElement(slidingElement.current)
    .addElement(itemElement.current)
    .duration(500)
    .fromTo('opacity', '1', '0')
    .fromTo('maxHeight', '500px', '1px');
  await animation.play();
};

export const taskPreviewCheckboxExplosionAnimation = async (
  checkboxElement: RefObject<HTMLIonCheckboxElement>,
  labelElement: RefObject<HTMLIonLabelElement>,
  isCompleted: boolean
) => {
  if (checkboxElement.current === null || isCompleted || labelElement.current === null) return;
  const animationSpeed = 350;

  const checkboxExplosionAnimation = checkboxElement.current.animate(
    [
      {
        opacity: 1,
        boxShadow:
          '0 0 0 -2px var(--ion-color-primary), 0 0 0 -2px var(--ion-color-primary), 0 0 0 -2px var(--ion-color-primary), 0 0 0 -2px var(--ion-color-primary), 0 0 0 -2px var(--ion-color-primary), 0 0 0 -2px var(--ion-color-primary)',
      },
      {
        opacity: 1,
      },
      {
        opacity: 0,
        boxShadow:
          '0 -26px 0 0px var(--ion-color-primary), 25px -19px 0 0px var(--ion-color-primary), 26px 19px 0 0px var(--ion-color-primary), 0 26px 0 0px var(--ion-color-primary), -25px 19px 0 0px var(--ion-color-primary), -25px -19px 0 0px var(--ion-color-primary)',
      },
    ],
    { duration: animationSpeed, pseudoElement: ':after', fill: 'forwards', iterations: 1 }
  );

  const labelAnimation = createAnimation()
    .addElement(labelElement.current)
    .duration(animationSpeed)
    .fromTo('color', labelElement.current.color as string, 'var(--ion-color-light)');
  labelAnimation.play();
  checkboxExplosionAnimation.play();

  const checkboxAnimationScale = createAnimation()
    .addElement(checkboxElement.current)
    .duration(animationSpeed / 2)
    .fromTo('scale', '1', '.8');
  await checkboxAnimationScale.play();

  const checkboxAnimation = createAnimation()
    .addElement(checkboxElement.current)
    .duration(animationSpeed / 2)
    .fromTo('scale', '.8', '1');
  await checkboxAnimation.play();
};
