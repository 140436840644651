import { IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonPopover, IonRow, IonText } from '@ionic/react';
import { t } from 'i18next';
import { chevronUpOutline, ellipsisHorizontal } from 'ionicons/icons';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { useRecoilValue } from 'recoil';
import { multiSelectModeAtom } from '../../../pages/ListDetailsPage';
import { HeaderDO, ListDO, TaskDO } from '../../../utils/state/model/interfaces/displayObjects';
import { showInputState } from '../AddInputType';
import SelectAllTaskCheckbox from '../components/SelectAllTaskCheckbox';
import TaskPreview from '../tasks/TaskPreview';
import { justTheTasks } from '../tasks/taskUtils';
import { useTogglePopover } from './ListsView';
import { useMultiSelectTasks } from './state/useMultiSelectTasks';
import { _task } from '../../../utils/state/model/implementations/ImplementationFactory';

interface ListDetailsViewProps {
  ionContentRef: HTMLElement;
  list: ListDO;
  completeTasks: (TaskDO | HeaderDO)[];
  incompleteTasks: (TaskDO | HeaderDO)[];
  isListOwner: boolean;
  isCompletedSectionOn: boolean;
  openAssignMenu: (list: string | null, task: string[] | null, mode: 'assign' | 'share') => void;
  setAreCompletedSortOptionsOpen: Dispatch<SetStateAction<boolean>>;
}

const ListDetailsView: React.FC<ListDetailsViewProps> = ({
  ionContentRef,
  list,
  completeTasks,
  incompleteTasks,
  isListOwner,
  isCompletedSectionOn,
  openAssignMenu,
  setAreCompletedSortOptionsOpen,
}) => {
  const incompleteTasksVirtuosoRef = React.useRef<VirtuosoHandle>(null);

  const justIncompleteTasksArr = justTheTasks(incompleteTasks);
  const justCompleteTasksArr = justTheTasks(completeTasks);

  const showInput = useRecoilValue(showInputState);
  const isMultiSelectModeActive = useRecoilValue(multiSelectModeAtom);

  const [isCompletedSectionCollapse, setIsCompletedSectionCollapse] = useState<boolean>(false);

  const { popoverOpen, setPopoverOpen, openPopover, popoverRef } = useTogglePopover();
  const { onSelectAllCheckboxHandler, onItemClickHandler, isTaskSelected, getAllTaskByCompletionStatus } =
    useMultiSelectTasks(justIncompleteTasksArr, justCompleteTasksArr);

  useEffect(() => {
    const scrollToTask = (taskIndex: number) => {
      if (incompleteTasksVirtuosoRef.current) {
        incompleteTasksVirtuosoRef.current.scrollToIndex({ index: taskIndex, align: 'end', behavior: 'smooth' });
      }
    };

    const checkForNewIncompleteTasks = async () => {
      try {
        const lastTask = await _task.getLastTaskCreatedInList(list.listId);

        if (!lastTask) return;
        if (new Date().getTime() - lastTask.createdOn.getTime() > 1000) return;
        const lastTaskIndex = incompleteTasks.findIndex((item) => {
          if ('taskId' in item) return item.taskId === lastTask.taskId;
          return false;
        });

        if (lastTaskIndex === -1) return;

        scrollToTask(lastTaskIndex);
      } catch (error) {
        console.error('Error in TaskPreview useEffect', error);
      }
    };

    checkForNewIncompleteTasks();
  }, [incompleteTasks]);

  const handleToggleCollapse = () => {
    setIsCompletedSectionCollapse((toggleCollapse) => !toggleCollapse);
  };

  return (
    <IonGrid
      style={{
        height: '100%',
        paddingInlineStart: 'var(--ion-grid-padding-xs, var(--ion-grid-padding, 5px))',
        paddingInlineEnd: 'var(--ion-grid-padding-xs, var(--ion-grid-padding, 5px))',
      }}
      className="ion-no-padding"
      fixed
    >
      <div className="d-flex" style={{ flexDirection: 'column', height: '100%' }}>
        {justCompleteTasksArr.length < 1 && justIncompleteTasksArr.length < 1 && !showInput && isListOwner ? (
          <MessageToAddFirstTask />
        ) : (
          // Task previews
          <IonRow style={{ height: 'auto' }} class={`ion-justify-content-center`}>
            <IonCol size="12">
              {/* Incompleted Tasks Header */}
              {isMultiSelectModeActive && (
                <SelectAllTaskCheckbox
                  isCompleted={false}
                  onClick={onSelectAllCheckboxHandler}
                  areAllSelected={getAllTaskByCompletionStatus(false).length === justIncompleteTasksArr.length}
                  sectionItemsCount={justIncompleteTasksArr.length}
                />
              )}{' '}
              {/* Incompleted Tasks List*/}
              <Virtuoso
                ref={incompleteTasksVirtuosoRef}
                useWindowScroll
                customScrollParent={ionContentRef}
                className="ion-content-window-scroll-host"
                style={{ height: incompleteTasks.length > 0 ? '100%' : '' }}
                increaseViewportBy={400}
                overscan={15}
                totalCount={incompleteTasks.length}
                itemContent={(index) => {
                  const item = incompleteTasks[index];
                  // If task is part of a QM list and has a header, display the header
                  // The header is part of the task object, but it is not a task itself
                  // This is so that the header can be displayed in the virtuoso list
                  // The same thing happens in the completed tasks list
                  // We could even make a seperate component for the virtuoso list since a lot of it has been simplified
                  if ('headers' in item)
                    return (
                      <IonItem>
                        <div
                          className="font-20 font-weight-500"
                          style={{ wordBreak: 'break-word', margin: '10px 8px 10px 0', lineHeight: '1.5' }}
                        >
                          {item.headers
                            .filter((item) => item !== '')
                            .map((header, index, arr) => {
                              return (
                                <span key={header + index}>
                                  {header}
                                  {index !== arr.length - 1 && <span style={{ padding: '0 4px' }}> | </span>}
                                </span>
                              );
                            })}
                        </div>
                      </IonItem>
                    );

                  if ('taskId' in item)
                    return (
                      <div className={`${showInput ? 'disable-pointer-events' : ''}`}>
                        <TaskPreview
                          key={item.taskId}
                          task={item}
                          isMultiSelectModeActive={isMultiSelectModeActive}
                          isSelected={isTaskSelected(item)}
                          onMultiSelectClick={onItemClickHandler}
                          openAssignMenu={openAssignMenu}
                          isListOwner={isListOwner}
                          isQMList={list.qmInspectionId ? true : false}
                        />
                      </div>
                    );
                }}
              />
              {isCompletedSectionOn && (
                <>
                  {/* Completed Tasks Header */}
                  <IonRow
                    className={`ion-align-items-center ion-justify-content-between ion-padding-horizontal ${
                      showInput ? 'disable-pointer-events' : ''
                    }`}
                  >
                    <div style={{ width: '85%' }} onClick={() => handleToggleCollapse()}>
                      <IonItem className="ion-no-padding" style={{ maxWidth: 'fit-content' }} lines="none">
                        <IonLabel class="font-32 font-weight-600 ion-margin-top">{t('completed')}</IonLabel>
                        {!isMultiSelectModeActive && (
                          <IonIcon
                            className={`font-32 transform-100 ${isCompletedSectionCollapse ? '-rotate180' : ''}`}
                            icon={chevronUpOutline}
                          />
                        )}
                      </IonItem>
                    </div>
                    {!isMultiSelectModeActive && !list.qmInspectionId && (
                      <IonIcon className="font-32" icon={ellipsisHorizontal} onClick={openPopover} />
                    )}
                    <IonPopover
                      className="popover-menu"
                      mode="md"
                      ref={popoverRef}
                      arrow={false}
                      isOpen={popoverOpen}
                      onClick={() => setPopoverOpen(false)}
                      onDidDismiss={() => setPopoverOpen(false)}
                    >
                      <IonContent>
                        <IonItem lines="none" onClick={() => setAreCompletedSortOptionsOpen(true)}>
                          <IonText>{t('menu.action.sort')}</IonText>
                        </IonItem>
                      </IonContent>
                    </IonPopover>
                  </IonRow>
                  {isMultiSelectModeActive && (
                    <SelectAllTaskCheckbox
                      isCompleted={true}
                      onClick={onSelectAllCheckboxHandler}
                      areAllSelected={getAllTaskByCompletionStatus(true).length === justCompleteTasksArr.length}
                      sectionItemsCount={justCompleteTasksArr.length}
                    />
                  )}{' '}
                  {/* Completed Tasks List */}
                  {(!isCompletedSectionCollapse || isMultiSelectModeActive) && (
                    <Virtuoso
                      useWindowScroll
                      customScrollParent={ionContentRef}
                      className="ion-content-window-scroll-host"
                      style={{
                        height:
                          completeTasks.length > 0 && isCompletedSectionOn && !isCompletedSectionCollapse ? '100%' : '',
                      }}
                      increaseViewportBy={400}
                      overscan={15}
                      totalCount={completeTasks.length}
                      itemContent={(index) => {
                        const item = completeTasks[index];
                        // If task is part of a QM list and has a header, display the header
                        // The header is part of the task object, but it is not a task itself
                        // This is so that the header can be displayed in the virtuoso list
                        // The same thing happens in the incomplete tasks list
                        // We could even make a seperate component for the virtuoso list since a lot of it has been simplified
                        if ('headers' in item)
                          return (
                            <IonItem>
                              <div
                                className="font-20 font-weight-500"
                                style={{ wordBreak: 'break-word', margin: '10px 8px 10px 0', lineHeight: '1.5' }}
                              >
                                {item.headers
                                  .filter((item) => item !== '')
                                  .map((header, index, arr) => {
                                    return (
                                      <span key={header + index}>
                                        {header}
                                        {index !== arr.length - 1 && <span style={{ padding: '0 4px' }}> | </span>}
                                      </span>
                                    );
                                  })}
                              </div>
                            </IonItem>
                          );
                        if ('taskId' in item)
                          return (
                            <div className={`${showInput ? 'disable-pointer-events' : ''}`}>
                              <TaskPreview
                                key={item.taskId}
                                task={item}
                                isMultiSelectModeActive={isMultiSelectModeActive}
                                isSelected={isTaskSelected(item)}
                                onMultiSelectClick={onItemClickHandler}
                                openAssignMenu={openAssignMenu}
                                isListOwner={isListOwner}
                                isQMList={list.qmInspectionId ? true : false}
                              />
                            </div>
                          );
                      }}
                    />
                  )}
                </>
              )}
            </IonCol>
          </IonRow>
        )}
      </div>
    </IonGrid>
  );
};

export default React.memo(ListDetailsView);

const MessageToAddFirstTask: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="p-absolute v-center h-center">
      <IonText color="light" class="ion-text-center">
        <h1 className="ion-no-margin" style={{ whiteSpace: 'pre-line' }}>
          {t('addFirstTask')}
        </h1>
      </IonText>
    </div>
  );
};
