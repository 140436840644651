export const hasConfirmationShownInLast24Hours = (): boolean => {
  const lastConfirmationTime = localStorage.getItem('lastConfirmationTime');
  const currentTime = new Date().getTime();

  if (!lastConfirmationTime) {
    return false;
  }

  const timeSinceLastConfirmation = currentTime - parseInt(lastConfirmationTime);
  return timeSinceLastConfirmation <= 24 * 60 * 60 * 1000;
};
