import { createAnimation } from '@ionic/react';
import { RefObject } from 'react';

export const calendarViewEnterAnimation = (baseEl: any) => {
  const root = baseEl.shadowRoot;

  const wrapperAnimation = createAnimation()
    .addElement(root.querySelector('.modal-wrapper')!)
    .fromTo('transform', 'translateY(200vh)', 'translateY(0vh)');

  return createAnimation().addElement(baseEl).easing('ease-out').duration(500).addAnimation([wrapperAnimation]);
};

export const calendarViewLeaveAnimation = (baseEl: any) => {
  return calendarViewEnterAnimation(baseEl).direction('reverse');
};

export const slideFromRightEnterAnimation = (baseEl: any) => {
  const root = baseEl.shadowRoot;
  const wrapperAnimation = createAnimation()
    .addElement(root.querySelector('.modal-wrapper')!)
    .fromTo('transform', 'translateX(200vw)', 'translateX(0vw)')
    .fromTo('opacity', '1', '1');

  return createAnimation().addElement(baseEl).easing('ease-out').duration(500).addAnimation([wrapperAnimation]);
};

export const slideFromRightLeaveAnimation = (baseEl: any) => {
  return slideFromRightEnterAnimation(baseEl).direction('reverse');
};

export const timePickerEnterAnimation = (element: RefObject<HTMLDivElement>) => {
  if (element.current === null) return;
  const animation = createAnimation()
    .addElement(element.current)
    .duration(1000)
    .fromTo('transform', 'translateX(100vh)', 'translateX(0)')
    .easing('ease-in-out');
  animation.play();
};

export const timePickerLeaveAnimation = (element: RefObject<HTMLDivElement>) => {
  if (element.current === null) return;
  const animation = createAnimation()
    .addElement(element.current)
    .duration(1000)
    .fromTo('transform', 'translateX(0)', 'translateX(100vh)')
    .easing('ease-in-out');
  animation.play();
};

export const timezonePickerDivStyle = {
  transition: `max-height 500ms linear`,
  maxHeight: '0px',
};

export const timezonePickerDivTransition: any = {
  entering: { maxHeight: '0px' },
  entered: { maxHeight: '335px' },
  exiting: { maxHeight: '335px' },
  exited: { maxHeight: '0px' },
};

export const timezonePickerRowStyle = {
  transition: `opacity 500ms linear`,
  opacity: 0,
};

export const timezonePickerRowTransition: any = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
};

export const timezoneToggleButtonStyle = {
  transition: '500ms linear',
  opacity: 0,
  transform: 'translate(50%)',
};

export const timezoneToggleButtonTransition: any = {
  entering: { opacity: 0, transform: 'translate(50%) rotate(45deg)' },
  entered: { opacity: 1, transform: 'translate(0) rotate(45deg)' },
  exiting: { opacity: 1, transform: 'translate(0) rotate(45deg)' },
  exited: { opacity: 0, transform: 'translate(50%) rotate(45deg)' },
};
