import { IonAvatar, IonIcon } from '@ionic/react';
import { person } from 'ionicons/icons';
import { _user } from '../../../utils/state/model/implementations/ImplementationFactory';
import useGetLocalData from '../../../hooks/useGetLocalData';

const AvatarSize = {
  small: '26px',
  medium: '32px',
  large: '40px',
};

const AvatarSvgs = {
  small: '12',
  medium: '16',
  large: '20',
};

interface UserAvatarProps {
  size: 'small' | 'medium' | 'large';
  img?: string;
  userId: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ img, size, userId }) => {
  const { data: userData } = useGetLocalData(getUser, [userId]);

  async function getUser() {
    try {
      const user = await _user.getById(userId);
      return user;
    } catch {
      console.log('User not found');
    }
  }

  return (
    <IonAvatar
      class="d-flex ion-justify-content-center ion-align-items-center"
      style={{
        width: AvatarSize[size],
        height: AvatarSize[size],
        border: !img && !userData?.profilePhoto ? '1px solid var(--ion-color-primary)' : '',
      }}
    >
      {img || userData?.profilePhoto ? (
        <img alt="Avatar" src={img ? img : userData?.profilePhoto} />
      ) : (
        <IonIcon icon={person} className={`font-${AvatarSvgs[size]}`} color="primary" />
      )}
    </IonAvatar>
  );
};

export default UserAvatar;
