import { Device } from '@capacitor/device';
import { EnvironmentConfig, getFlag } from '../../utils/environmentUtils';
import { AuthCache, UserPropertyKeys } from '@otuvy/auth';
import OneSignal from 'onesignal-cordova-plugin';

export const handleNotificationSetup = async () => {
  if (getFlag(EnvironmentConfig.NOTIFICATIONS)) {
    const device = await Device.getInfo();
    if (device && device.platform !== 'web') {
      const userId: string | undefined = AuthCache.getCurrentUserId();
      const orgId = AuthCache.getCachedUserAttribute(UserPropertyKeys.organizationId);

      console.log(`OneSignal - Setting OneSignal external user id to ${userId}`);
      OneSignal.provideUserConsent(true); //TODO: this is a GDPR thing, we need to make sure we have a way to get consent from the user
      if (userId) {
        OneSignal.setExternalUserId(userId, (result) => {
          console.log('Result of setting external UserID', result);
        });
      } else {
        console.error('No userId to set in OneSignal');
      }

      console.log(`Attempting to set OneSignal tags for userId ${userId} and orgId ${orgId}`);

      //Setting a tag a value of undefined/unknown removes it
      OneSignal.sendTags({
        userId: `${userId}`, //ID should always be known
        orgId: orgId ? orgId : 'Unknown', //Due to some bugs the org ID is not set on early adopters of the app so could be empty
      });
      OneSignal.disablePush(false);
    }
  }
};

export const handleNotificationTeardown = async () => {
  if (getFlag(EnvironmentConfig.NOTIFICATIONS)) {
    try {
      const device = await Device.getInfo();

      //To prevent issues accessing OneSignal from the web
      if (device && device.platform !== 'web') OneSignal.disablePush(true);
    } catch (error) {
      console.error('Error disabling push notifications', error);
    }
  }
};
