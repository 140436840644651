import { useState, useEffect } from 'react';
import { PhotoTag } from '../../../../constants/constants';
import { _photo } from '../../../../utils/state/model/implementations/ImplementationFactory';
import { TaskDO } from '../../../../utils/state/model/interfaces/displayObjects';

const useIsPhotoRequired = (task: TaskDO, isQMList: boolean | null = null) => {
  const [isPhotoRequiredToComplete, setIsPhotoRequiredToComplete] = useState(false);

  const displayPhotoCheckboxes = async (task: TaskDO) => {
    try {
      // If photo is not required, we don't display the photo checkbox
      if (!task.isPhotoRequired) return false;

      // If photo is required, and is not a QM list, and there are photos in the tasks
      if (!isQMList && task.completionPhotoIds && task.completionPhotoIds.length > 0) return false;

      // If photo is required, and there are photos in the tasks but it is a QM list
      if (task.completionPhotoIds && task.completionPhotoIds.length > 0) {
        const photoIds = task.completionPhotoIds;
        const photoTags: (PhotoTag | null)[] = [];

        for (const photoId of photoIds) {
          const photoThumbnail = await _photo.getThumbnail(photoId);

          // If photo is not found, download it
          // The API will return an empty object if the photo is not found
          if (photoThumbnail.photoId === '') {
            const downloadPhotoThumbnail = await _photo.downloadThumbnail(photoId);

            if (downloadPhotoThumbnail?.photoId === '') {
              const photo = await _photo.getPhoto(photoId);
              if (photo.photoId !== '') {
                photoTags.push(photo.tag ?? null);
              }
            }

            if (downloadPhotoThumbnail) {
              photoTags.push(downloadPhotoThumbnail.tag ?? null);
            }
            return false;
          }

          if (photoThumbnail.photoId !== '') {
            photoTags.push(photoThumbnail.tag ?? null);
          }
        }

        if (photoTags.length === 0) return false;

        console.log(photoTags, task.completionPhotoIds);
        const isEveryPhotoTaggedAsDeficiency = photoTags.every((tag) => tag === PhotoTag.DEFICIENCY);
        return isEveryPhotoTaggedAsDeficiency;
      }

      return true;
    } catch (e) {
      console.error('Error displaying photo checkboxes', e);
      return false;
    }
  };

  useEffect(() => {
    displayPhotoCheckboxes(task).then((isPhotoRequired) => {
      setIsPhotoRequiredToComplete(isPhotoRequired);
    });
  }, [task.completionPhotoIds, task.isPhotoRequired, isQMList]);

  return { isPhotoRequiredToComplete };
};

export default useIsPhotoRequired;
