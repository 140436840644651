import { getIDTokenAsString } from '@otuvy/auth';
import { TIME_TRACKING_API_BASE_URL } from '../../aws-exports';
import { EnvironmentConfig, getFlag } from '../../utils/environmentUtils';
import { logFriendlyObject } from '@otuvy/common-utils';
import { _timeTracking } from '../../utils/state/model/implementations/ImplementationFactory';
import isPermittedNetworkConnected from '../network/network';

export const uploadTimeTracking = async () => {
  if (!getFlag(EnvironmentConfig.TIME_TRACKING)) return;

  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('uploading time tracking');
  try {
    const eventIds: string[] = await _timeTracking.getEventIdsNeedingUploaded();

    if (eventIds.length === 0) {
      if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('No time tracking events found to upload');
      return;
    }

    await sendTimeTrackingEventsById(eventIds);
  } catch (error) {
    console.error('Error when sending time tracking to server: ', logFriendlyObject(error));
    throw new Error('Failed to send time tracking to server');
  }
};

//TODO: combine into one upload rather than an iteration over multiple uploads
export const sendTimeTrackingEventsById = async (eventIds: string[]) => {
  console.log('sendTimeTrackingEventsById', eventIds);
  eventIds.forEach(async (eventId) => {
    await sendTimeTrackingEventById(eventId);
  });
};

export const sendTimeTrackingEventById = async (eventId: string) => {
  console.log('sendTimeTrackingEventById', eventId);
  if (!(await isPermittedNetworkConnected())) {
    console.log('Not permitted to sync in this network state! (sendTimeTrackingEventsById)');
    return [];
  }

  const event = await _timeTracking.getEventById(eventId);
  if (!event) return;

  const idToken: string | undefined = await getIDTokenAsString();
  if (!idToken) {
    console.error('No ID Token');
    throw new Error('No ID Token');
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Id: idToken,
    },
    body: JSON.stringify({
      timeTrackingEvents: [event],
    }),
  };

  const url = TIME_TRACKING_API_BASE_URL + 'upload-work-log-data';
  const response: Response = await fetch(url, requestOptions);

  if (response.status !== 200) {
    console.error(`Error uploading work log (${eventId}): ${response.statusText}`);
  } else {
    await _timeTracking.markEventsAsUploaded([eventId]);
  }

  return [];
};
