import { useCallback, useReducer } from 'react';

type reducerState = {
  isOpen: boolean;
  list: string | string[] | null;
  task: string[] | null;
  mode: 'assign' | 'share' | null;
  key: string | null;
};

const initialState: reducerState = { isOpen: false, list: null, task: null, mode: null, key: null };

type ACTIONTYPE =
  | { type: 'OPEN_MENU'; payload: { list: string | string[] | null; task: string[] | null; mode: 'assign' | 'share' } }
  | { type: 'CLOSE_MENU' };

const assignMenuReducer = (state: typeof initialState, action: ACTIONTYPE) => {
  switch (action.type) {
    case 'OPEN_MENU':
      return {
        ...state,
        isOpen: true,
        list: action.payload.list,
        task: action.payload.task,
        mode: action.payload.mode,
        key: Date.now().toString(),
      };
    case 'CLOSE_MENU':
      return { ...state, isOpen: false, list: null, task: null, mode: null };
    default:
      return state;
  }
};

export const useAssignMenu = () => {
  const [state, dispatch] = useReducer(assignMenuReducer, initialState);

  const { isOpen, list, task, mode, key } = state;

  const openMenu = useCallback(
    (list: string | string[] | null, task: string[] | null, mode: 'assign' | 'share') => {
      dispatch({ type: 'OPEN_MENU', payload: { list, task, mode } });
    },
    [list, task, mode]
  );

  const closeMenu = () => {
    dispatch({ type: 'CLOSE_MENU' });
  };

  return { isOpen, list, task, mode, key, openMenu, closeMenu };
};
