import { WriteAccessRestriction } from '../../../../useCheckOrgGovernors';
import { SystemFlagInterface, SystemFlagKey } from '../../interfaces/systemFlagInterface';
import { db } from './db';

export class DexieSystemFlag implements SystemFlagInterface {
  private async getFlag(key: SystemFlagKey): Promise<any> {
    const { value } = (await db().systemFlag.get(key)) ?? { value: undefined };
    return value;
  }

  private async setFlag(key: SystemFlagKey, value: any) {
    await db().systemFlag.put({ key, value });
  }

  async getIsWriteAccessRestricted(): Promise<WriteAccessRestriction> {
    const isWriteAccessRestricted: WriteAccessRestriction | boolean | undefined = await this.getFlag(
      SystemFlagKey.IS_WRITE_ACCESS_RESTRICTED
    );
    if (!isWriteAccessRestricted) {
      // undefined or boolean false
      return {
        isRestricted: false,
        reason: null,
      };
    }

    // Since we don't have a reason, we will not restrict them, per requirements.
    // We will let the normal restriction check process see if there is a reason to restrict them.
    if (typeof isWriteAccessRestricted === 'boolean') {
      return {
        isRestricted: false,
        reason: null,
      };
    }

    return isWriteAccessRestricted;
  }

  async setIsWriteAccessRestricted(value: WriteAccessRestriction) {
    if (typeof value !== 'object' || !('isRestricted' in value) || !('reason' in value))
      throw new Error(
        `setIsWriteAccessRestricted - value must be an object of type WriteAccessRestriction (got a ${typeof value})`,
        value
      );
    await this.setFlag(SystemFlagKey.IS_WRITE_ACCESS_RESTRICTED, value);
  }
}
