import { debounce } from 'lodash';
import { useRef, useEffect, useMemo, useCallback } from 'react';

const useDebounce = <T extends (...args: any[]) => any>(callback: T, delay: number = 400): T => {
  const callbackRef = useRef<T>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = (...args: Parameters<T>) => {
      callbackRef.current?.(...args);
    };

    return debounce(func, delay);
  }, [delay]);

  return useCallback(debouncedCallback as unknown as T, []);
};

export default useDebounce;
