import { isPlatform } from '@ionic/core';
import { useCallback, useEffect } from 'react';

interface useAndroidBackButtonNavigationProps {
  pathname: string;
}

const useAndroidBackButtonNavigation = ({ pathname }: useAndroidBackButtonNavigationProps) => {
  const androidBackButtonHandler = useCallback(
    (ev: any) => {
      ev.detail.register(5, (processNextHandler: any) => {
        console.log('Pathname: ', pathname);
        if (pathname === '/list') {
          return;
        }
        if (pathname === '/reports') {
          return;
        }

        processNextHandler();
      });
    },
    [pathname]
  );

  useEffect(() => {
    if (isPlatform('android')) {
      document.addEventListener('ionBackButton', androidBackButtonHandler);
    }

    return () => {
      if (isPlatform('android')) {
        document.removeEventListener('ionBackButton', androidBackButtonHandler);
      }
    };
  }, [androidBackButtonHandler]);
};

export default useAndroidBackButtonNavigation;
