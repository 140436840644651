import Dexie, { Table } from 'dexie';
import {
  DexieList,
  DexieTask,
  DexiePhoto,
  DexieUser,
  DexieCompletionCache,
  DexieSetting,
} from './implementationModel/dexieObjects';
import { ChangeLog } from '../../interfaces/changeLoggerInterface';
import { AnalyticsEvent } from '../../interfaces/analyticsInterface';
import 'dexie-export-import';
import { TimeTrackingEvent } from '../../interfaces/timeTrackingInterface';
import { WorkLogData } from '../../../../../timetracking/timeTrackingConstants';

class VeriTaskDexie extends Dexie {
  // These fields are added by dexie when declaring the stores()
  // Since TypeScript doesn't know that, we'll use the null-assertion operator (!)
  list!: Table<DexieList>;
  task!: Table<DexieTask>;
  taskCompletionCache!: Table<DexieCompletionCache>;
  photo!: Table<DexiePhoto>;
  thumbnail!: Table<DexiePhoto>;
  changeLog!: Table<ChangeLog>;
  systemFlag!: Table<{ key: string; value: any }>;
  user!: Table<DexieUser>;
  analytics!: Table<AnalyticsEvent>;
  setting!: Table<DexieSetting>;
  timeTrackingChangeLog!: Table<TimeTrackingEvent>;
  timeTrackingWorkLog!: Table<WorkLogData>;

  constructor(dbName: string) {
    super(dbName, {});
    /*
        From the Dexie docs:
        | NOTE: Don’t declare all columns like in SQL.
        | You only declare properties you want to index,
        | that is properties you want to use in a where(…) query.

        From https://dexie.org/docs/API-Reference#quick-reference
        & means "unique"
        * is for columns with an "Array" type

        If you leave a trailing comma on these table definitions it will throw an error since it will think that you want to add an additional index and all indecies must have names
    */
    this.version(7)
      .stores({
        /**
         * Technically sortId should be unique, but we are allowing corrupt sorts rather than failing inserts/updates
         */
        list: '&listId, listName, sortId ',
        task: '&taskId, taskName, listId, sortId ', //, instructionalPhotos*
        /**
         * cacheId is due date for those tasks with a due date
         * taskCompletionCache is a VIEW of what the user thinks completion is given the current user's actions and whatever data was synced from the server.
         * It is not for syncing up to the server, but can be synced down
         */
        taskCompletionCache: '[taskId+cacheId], taskId, listId, cacheId ',

        photo: '&photoId ',

        user: '&userId ',
        changeLog: '&changeId, createdOn, uploaded',
        systemFlag: '&key',
      })
      .upgrade(async (tx) => {
        console.log('db upgrade v7 initiated');

        console.log('db v7 upgrade finished');
      });

    this.version(8)
      .stores({
        list: '&listId, listName, sortId, createdOn ',
      })
      .upgrade(async (tx) => {
        console.log('db upgrade v8 initiated');
        tx.table<DexieList>('list')
          .toCollection()
          .modify((list) => {
            if (!list.createdOn) list.createdOn = new Date(list.sortId);
          });
        console.log('db v8 upgrade finished');
      });

    this.version(9).stores({
      list: '&listId, listName, sortId, owner, *sharedWith',
      task: '&taskId, taskName, listId, sortId, assignedTo',
      user: '&userId, &email',
    });

    this.version(10).stores({
      thumbnail: '&photoId ',
    });

    this.version(11).stores({
      thumbnail: '&photoId, cacheTime ',
      photo: '&photoId, cacheTime ',
    });

    this.version(12).upgrade(async (tx) => {
      console.log('db upgrade v12 initiated');
      tx.table<DexieUser>('user')
        .toCollection()
        .modify((user) => {
          if (user.isDefaultProfilePhoto !== undefined) user.isDefaultProfilePhoto = true;
        });
      console.log('db v12 upgrade finished');
    });

    this.version(13).stores({
      analytics: '&eventId',
    });

    this.version(15).stores({
      report: null,
    });

    this.version(16).stores({
      setting: '&key',
    });

    /*
    this.version(17).stores({
      timeTracking: '&punchId',
    });
    */

    this.version(18).stores({
      /*
        This renames (technically creates a seperate table that should be used in palce of) 
        the "timeTracking" table from v17 to "timeTrackingChangeLog"
      */
      timeTrackingChangeLog: '&punchId',
    });

    this.version(19).stores({
      timeTrackingWorkLog: '&workLogId',
    });
  }
}

let currentDb: VeriTaskDexie | undefined;

export const initUserDb = (userId: string) => {
  if (!currentDb || currentDb.name !== userId) {
    currentDb = new VeriTaskDexie(userId);
  }
};

export const db = (): VeriTaskDexie => {
  if (!currentDb) {
    throw new Error('No current database');
  }
  return currentDb;
};

export function hasCurrentDb(): boolean {
  return currentDb !== undefined;
}

export const getCurrentDbName = (): string => {
  if (!currentDb) return 'noDB';
  return currentDb.name;
};

//TODO: move these export functions to their own class/API
export const exportWholeDb = async (): Promise<Blob | null> => {
  if (!currentDb) return null;
  return await await currentDb.export({
    prettyJson: true,
  });
};

//TODO: move these export functions to their own class/API
export const exportTextTables = async (): Promise<Blob | null> => {
  if (!currentDb) return null;
  return await await currentDb.export({
    prettyJson: true,
    filter: (table) => {
      return table !== 'photo' && table !== 'user' && table !== 'changeLog';
    },
  });
};

//TODO: move these export functions to their own class/API
export const exportChangeLog = async (): Promise<Blob | null> => {
  if (!currentDb) return null;
  return await currentDb.export({
    prettyJson: true,
    filter: (table) => {
      return table === 'changeLog';
    },
  });
};
