import { ListDO, TaskDO } from '../../utils/state/model/interfaces/displayObjects';
import { _user } from '../../utils/state/model/implementations/ImplementationFactory';
import { AuthCache } from '@otuvy/auth';

interface WithCreatedOn {
  createdOn: Date;
}
export const sortByCreatedOn = <T extends WithCreatedOn>(arr: T[]): T[] => {
  return [...arr].sort((a, b) => a.createdOn.getTime() - b.createdOn.getTime());
};

interface WithUpdatedOn {
  updatedOn: Date;
}
export const sortByUpdatedOn = <T extends WithUpdatedOn>(arr: T[]): T[] => {
  return [...arr].sort((a, b) => b.updatedOn.getTime() - a.updatedOn.getTime());
};

interface WithDueDate {
  dueDate?: Date | null;
}
export const sortByDueDate = <T extends WithDueDate>(
  arr: T[],
  sortOrder: 'ascending' | 'descending' = 'ascending'
): T[] => {
  const objectsWithDueDates: T[] = [];
  const objectsWithoutDueDates: T[] = [];

  arr.forEach((obj) => (obj.dueDate ? objectsWithDueDates.push(obj) : objectsWithoutDueDates.push(obj)));

  const sortedArr = [...objectsWithDueDates].sort((dateA, dateB) => Number(dateA.dueDate) - Number(dateB.dueDate));

  if (sortOrder === 'descending') {
    return [...objectsWithoutDueDates, ...sortedArr.reverse()];
  }
  return [...sortedArr, ...objectsWithoutDueDates];
};

export const isDateObject = (value: any): Date => {
  if (value instanceof Date) {
    return value;
  }
  return new Date(value);
};

export const sortListsByMostRecentActivity = (arr: ListDO[]): ListDO[] => {
  return [...arr].sort((a, b) => {
    // Use 'updatedOn' if available, otherwise use 'mostRecentActivity'
    let aDate = a.updatedOn ? isDateObject(a.updatedOn).getTime() : isDateObject(a.mostRecentActivity).getTime();
    let bDate = b.updatedOn ? isDateObject(b.updatedOn).getTime() : isDateObject(b.mostRecentActivity).getTime();

    return bDate - aDate;
  });
};

export const sortByAssignedTo = async (arr: TaskDO[]): Promise<TaskDO[]> => {
  const users = await _user.getAllUsersAsMap();
  const currentUserId = AuthCache.getCurrentUserId();

  const tasksSortedByTaskNameAndDueDate = sortByDueDate([
    ...arr.sort((task1, task2) => task1.taskName.localeCompare(task2.taskName)),
  ]);

  const taskWithCurrentUser = tasksSortedByTaskNameAndDueDate.filter((task) => task.assignedTo === currentUserId);
  const tasksWithAssignees = tasksSortedByTaskNameAndDueDate
    .filter((task) => task.assignedTo && task.assignedTo !== currentUserId)
    .sort((task1, task2) => {
      const user1Name = users.get(task1.assignedTo as string);
      const user2Name = users.get(task2.assignedTo as string);
      return user1Name?.firstName.localeCompare(user2Name?.firstName as string) || 0;
    });
  const tasksWithNoAssignees = tasksSortedByTaskNameAndDueDate.filter((task) => !task.assignedTo);

  return [...taskWithCurrentUser, ...tasksWithAssignees, ...tasksWithNoAssignees];
};
