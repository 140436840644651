// Make sure to keep this in sync among projects
export enum TimeTrackingEventType {
  UNKNOWN = 'unknown',
  CLOCK_IN = 'clockIn',
  CLOCK_OUT = 'clockOut',
}

export interface UserWorkLogData {
  userId: string;
  //  firstName: string
  //  lastName: string
  workLogs: WorkLogData[];
}

export interface WorkLogData {
  workLogId: string;
  punchIn: WorkLogDataTimePunch | undefined;
  punchOut: WorkLogDataTimePunch | undefined;
}

export interface ApiWorkLog {
  recordId: string;
  punchInTime: Date | undefined;
  punchOutTime: Date | undefined;
}

export interface WorkLogDataTimePunch {
  punchId: string | null;
  punchTime: Date | null;
  timeZoneOffset: number;
  punchType: TimeTrackingEventType;
  address: string | null;
}
