import { OrgSettingKey } from '../../../../../features/settings/orgSettings/orgSettingsConstants';
import { SettingInterface } from '../../interfaces/settingInterface';
import { db } from './db';
import { DexieSetting } from './implementationModel/dexieObjects';

export class DexieSettingImplementation implements SettingInterface {
  private async getSetting(key: OrgSettingKey): Promise<string | null | undefined> {
    const { value } = (await db().setting.get(key)) ?? { value: undefined };
    return value;
  }

  async setSettings(settings: DexieSetting[]): Promise<void> {
    //In a transaction so we make sure it doesn't delete without adding
    await db().transaction('rw', db().setting, async () => {
      await db().setting.toCollection().delete();
      await db().setting.bulkAdd(settings);
    });
  }

  async getRecurringTaskResetDaysWeekly(): Promise<number | null> {
    const resetDaysString = await this.getSetting(OrgSettingKey.RECURRING_TASK_RESET_DAYS_WEEKLY);
    return resetDaysString ? parseInt(resetDaysString) : null;
  }

  async getRecurringTaskResetDaysMonthly(): Promise<number | null> {
    const resetDaysString = await this.getSetting(OrgSettingKey.RECURRING_TASK_RESET_DAYS_MONTHLY);
    return resetDaysString ? parseInt(resetDaysString) : null;
  }

  async getRecurringTaskResetDaysYearly(): Promise<number | null> {
    const resetDaysString = await this.getSetting(OrgSettingKey.RECURRING_TASK_RESET_DAYS_YEARLY);
    return resetDaysString ? parseInt(resetDaysString) : null;
  }

  async isTimeTrackingEnabled(): Promise<boolean> {
    const timeTrackingEnabled = await this.getSetting(OrgSettingKey.TIME_TRACKING_ENABLED);
    return timeTrackingEnabled === 'true';
  }
}
