import { AuthCache } from '@otuvy/auth';

const useCurrentUser = () => {
  const currentUserId = AuthCache.getCurrentUserId();
  const currentUserFullName = AuthCache.getCurrentUserFullName();

  return {
    currentUserId,
    currentUserFullName,
  };
};

export default useCurrentUser;
