import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { ListDO } from '../../../../utils/state/model/interfaces/displayObjects';
import { VISIBLE_ITEM_SECTION } from '../../components/virtualScroll/useVirtualScroll';

export const multiSelectListItemsState = atom<ListDO[]>({
  key: 'multiSelectListItemsState',
  default: [],
});

const removeDuplicatesFromArr = (firstArr: ListDO[], secondArr: ListDO[]) => {
  return secondArr.reduce(
    (arr, item) => (arr.some((list) => list.listId === item.listId) ? arr : [...arr, item]),
    [...firstArr]
  );
};

const removeItemsFromArr = (arr: ListDO[], itemsToRemove: ListDO[]) => {
  return arr.filter((item) => !itemsToRemove.some((list) => list.listId === item.listId));
};

export const useMultiSelectLists = (incompleteLists: ListDO[], completedLists: ListDO[], archivedLists: ListDO[]) => {
  const [multiSelectItems, setMultiSelectItems] = useRecoilState(multiSelectListItemsState);

  const onSelectAllCheckboxHandler = (action: VISIBLE_ITEM_SECTION, isCheckboxChecked: boolean) => {
    const itemsToBeSelected =
      action === VISIBLE_ITEM_SECTION.INCOMPLETE
        ? incompleteLists
        : action === VISIBLE_ITEM_SECTION.COMPLETE
        ? completedLists
        : archivedLists;

    if (isCheckboxChecked) {
      setMultiSelectItems((prevState) => removeItemsFromArr(prevState, itemsToBeSelected));
      return;
    }

    if (!isCheckboxChecked) {
      setMultiSelectItems((prevState) => removeDuplicatesFromArr(prevState, itemsToBeSelected));
      return;
    }
  };

  const onItemClickHandler = useCallback(
    (item: ListDO, isCheckboxChecked: boolean) => {
      if (isCheckboxChecked) {
        setMultiSelectItems((prevState) => [...prevState, item]);
      }
      if (!isCheckboxChecked) {
        setMultiSelectItems((prevState) => removeItemsFromArr(prevState, [item]));
      }
    },
    [multiSelectItems]
  );

  const isListSelected = (item: ListDO) => {
    return multiSelectItems.some((list) => list.listId === item.listId);
  };

  const getAllListsByCompletionStatus = (section: VISIBLE_ITEM_SECTION) => {
    if (section === VISIBLE_ITEM_SECTION.ARCHIVED) {
      return {
        itemCount: archivedLists.length,
        areAllSelected: multiSelectItems.filter((item) => item.isArchived).length === archivedLists.length,
      };
    }

    if (section === VISIBLE_ITEM_SECTION.COMPLETE) {
      return {
        itemCount: completedLists.length,
        areAllSelected: multiSelectItems.filter((item) => item.isCompleted).length === completedLists.length,
      };
    }

    if (section === VISIBLE_ITEM_SECTION.INCOMPLETE) {
      return {
        itemCount: incompleteLists.length,
        areAllSelected: multiSelectItems.filter((item) => !item.isCompleted).length === incompleteLists.length,
      };
    }
  };

  return { onSelectAllCheckboxHandler, onItemClickHandler, isListSelected, getAllListsByCompletionStatus };
};
