import { format } from 'date-fns';

const STANDARD_TIME_FORMAT = 'h:mm a';

export const formatTime = (time: Date): string => {
  //console.log(`Time to format: ${time} is a ${typeof time}`)

  if (!time) {
    return '';
  }
  if (typeof time === 'string') {
    console.warn('formatTime was passed a string, not a date');
    time = new Date(time);
  }

  return format(time.getTime(), STANDARD_TIME_FORMAT);
};
