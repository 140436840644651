import { IonButton, IonContent, IonIcon, IonPopover, IonRow, IonText } from '@ionic/react';
import { informationCircleOutline, lockClosedOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { WriteAccessRestrictedReason } from '../../../utils/useCheckOrgGovernors';
import { goToDeviceAppropriateAppStore } from '../../updates/SoftwareUpdateAvailable';

interface WriteAccessRestrictedIndicatorProps {
  reason: WriteAccessRestrictedReason;
}

const WriteAccessRestrictedIndicator: React.FC<WriteAccessRestrictedIndicatorProps> = ({ reason }) => {
  const { t } = useTranslation();

  return (
    <>
      <IonRow className="ion-justify-content-center ion-align-items-center">
        <IonIcon size="large" icon={lockClosedOutline} />
        <IonText className="ion-padding-start">{t('banner.writeAccessRestricted.main')}</IonText>
        <IonIcon
          id="banner-restricted-info"
          className="ion-padding-start"
          size="large"
          icon={informationCircleOutline}
        />

        <IonPopover mode="md" trigger="banner-restricted-info">
          <IonContent className="ion-padding">
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonIcon size="large" icon={lockClosedOutline} />
              <IonText className="ion-padding-start font-24">{t('banner.writeAccessRestricted.info.header')}</IonText>
            </IonRow>
            <p>{t(`banner.writeAccessRestricted.info.body.${reason}.1`)}</p>
            <p>{t(`banner.writeAccessRestricted.info.body.${reason}.2`)}</p>
            {reason === WriteAccessRestrictedReason.MISSED_REQUIRED_UPDATE && (
              <div className="d-flex ion-justify-content-center">
                <IonButton onClick={goToDeviceAppropriateAppStore}>{t('button.update')}</IonButton>
              </div>
            )}
          </IonContent>
        </IonPopover>
      </IonRow>
    </>
  );
};

export default WriteAccessRestrictedIndicator;
