import { IonContent, IonIcon, IonLabel, IonPopover } from '@ionic/react';
import { cloudOfflineOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

export const OfflineIndicator: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <div
        id="offline-indicator"
        className="d-flex ion-align-items-center ion-justify-content-center"
        style={{ padding: '0.5rem' }}
      >
        <IonIcon icon={cloudOfflineOutline} style={{ marginRight: '0.5rem', fontSize: '1.5rem' }} />
        <IonLabel color="light">{t('offline')}</IonLabel>
      </div>
      <IonPopover trigger="offline-indicator" className="popover-menu offline-popover">
        <IonContent>
          <div style={{ padding: '0.5rem 1rem' }}>{t('offlineIndicator.popover.text')}</div>
        </IonContent>
      </IonPopover>
    </>
  );
};
