import { IonLabel, IonText } from '@ionic/react';
import { useEffect, useState } from 'react';
import CustomTextarea from '../../general/CustomTextarea';
import { _list } from '../../../utils/state/model/implementations/ImplementationFactory';
import { t } from 'i18next';
import { MaxFieldLengths } from '../../../constants/constants';

interface ListNotesProps {
  listId: string;
  value: string | undefined;
  isListOwner: boolean;
  isWriteAccessRestricted: boolean;
  onDismiss: React.Dispatch<React.SetStateAction<boolean>>;
  contentRef: React.RefObject<HTMLIonContentElement>;
  ionHeaderRef: React.RefObject<HTMLIonHeaderElement>;
}

const ListNotes: React.FC<ListNotesProps> = ({
  listId,
  value,
  isListOwner,
  isWriteAccessRestricted,
  onDismiss,
  contentRef,
  ionHeaderRef,
}) => {
  const [showInput, setShowInput] = useState(() => {
    if (!value && isListOwner && !isWriteAccessRestricted) return true;
    return false;
  });
  const [inputValue, setInputValue] = useState(() => value);

  useEffect(() => {
    if (showInput) {
      contentRef.current?.classList.add('disable-pointer-events');
      ionHeaderRef.current?.classList.add('disable-pointer-events');
    } else {
      contentRef.current?.classList.remove('disable-pointer-events');
      ionHeaderRef.current?.classList.remove('disable-pointer-events');
    }
  }, [showInput]);

  const onDoneClickHandler = async () => {
    setShowInput(false);
    if (inputValue !== value) {
      await _list.updateList(listId, { notes: inputValue });
    }
    onDismiss((prev) => !prev);
  };

  if (!isListOwner && !value) return <div></div>;

  return (
    <div>
      <div className="d-flex ion-justify-content-between ion-align-items-center" style={{ padding: '0 2rem' }}>
        <IonLabel className="ion-margin-top" style={{ fontWeight: '500', '--ion-label-font-size': '22px' }}>
          {t('listDetails.header')}:
        </IonLabel>
        {showInput && (
          <IonLabel
            color="primary"
            style={{ fontWeight: '500', '--ion-label-font-size': '22px' }}
            onClick={() => onDoneClickHandler()}
          >
            {t('done')}
          </IonLabel>
        )}
      </div>
      <div className="ion-margin-top" style={{ pointerEvents: 'auto', padding: '0 2rem' }}>
        {showInput && (
          <CustomTextarea
            name="addInputType"
            value={inputValue ?? ''}
            accessibilityLabel="List Notes"
            maxlength={MaxFieldLengths.LIST_NOTES}
            onIonBlur={() => onDoneClickHandler()}
            onIonChange={(event) => setInputValue(event.detail.value ?? '')}
          />
        )}
        {!showInput && (
          <div onClick={() => isListOwner && !isWriteAccessRestricted && setShowInput(true)}>
            <IonText style={{ whiteSpace: 'pre-wrap', fontSize: '18px' }}>{inputValue}</IonText>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListNotes;
