import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { TaskDO } from '../../../../utils/state/model/interfaces/displayObjects';

export const multiSelectItemsState = atom<TaskDO[]>({
  key: 'multiSelectItemsState',
  default: [],
});

export const isMultiSelectModeActiveTest = atom<boolean>({
  key: 'isMultiSelectModeActiveTest',
  default: false,
});

const removeDuplicatesFromArr = (firstArr: TaskDO[], secondArr: TaskDO[]) => {
  return secondArr.reduce(
    (arr, item) => (arr.some((task) => task.taskId === item.taskId) ? arr : [...arr, item]),
    [...firstArr]
  );
};

const removeItemsFromArr = (arr: TaskDO[], itemsToRemove: TaskDO[]) => {
  return arr.filter((item) => !itemsToRemove.some((task) => task.taskId === item.taskId));
};

export const useMultiSelectTasks = (incompleteTasks: TaskDO[], completedTasks: TaskDO[]) => {
  const [multiSelectItems, setMultiSelectItems] = useRecoilState(multiSelectItemsState);

  const onSelectAllCheckboxHandler = (isCompletedGroup: boolean, isCheckboxChecked: boolean) => {
    const itemsToBeSelected = isCompletedGroup ? completedTasks : incompleteTasks;

    if (!isCheckboxChecked) {
      setMultiSelectItems((prevState) => removeItemsFromArr(prevState, itemsToBeSelected));
      return;
    }

    if (isCheckboxChecked) {
      setMultiSelectItems((prevState) => removeDuplicatesFromArr(prevState, itemsToBeSelected));
      return;
    }
  };

  const onItemClickHandler = useCallback(
    (item: TaskDO, isCheckboxChecked: boolean) => {
      if (isCheckboxChecked) {
        setMultiSelectItems((prevState) => [...prevState, item]);
      }
      if (!isCheckboxChecked) {
        setMultiSelectItems((prevState) => removeItemsFromArr(prevState, [item]));
      }
    },
    [multiSelectItems]
  );

  const isTaskSelected = (item: TaskDO) => {
    return multiSelectItems.some((task) => task.taskId === item.taskId);
  };

  const getAllTaskByCompletionStatus = (isCompletedGroup: boolean) => {
    const completedCondition = isCompletedGroup ? true : false;
    return multiSelectItems.filter((item) => item.isCompleted === completedCondition).map((item) => item);
  };

  return { onSelectAllCheckboxHandler, onItemClickHandler, isTaskSelected, getAllTaskByCompletionStatus };
};
