import { useEffect, useRef, useState } from 'react';
import { IonIcon, IonLabel, useIonViewWillEnter } from '@ionic/react';
import { chevronDown } from 'ionicons/icons';
import { isPlatform } from '@ionic/core';

interface ExpandableLabelProps {
  value: string;
  color?: string;
  fontSize?: string;
  lineClamp?: number;
  onClick?: () => void;
  disableExpandIcon?: boolean;
}

const ExpandableLabel: React.FC<ExpandableLabelProps> = ({
  value,
  color,
  fontSize,
  lineClamp,
  onClick,
  disableExpandIcon,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const innerContainerRef = useRef<HTMLIonLabelElement>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isTextWrapped, setIsTextWrapped] = useState<boolean>(false);

  useIonViewWillEnter(() => {
    if (containerRef.current && innerContainerRef.current) {
      const container = containerRef.current;
      const innerContainer = innerContainerRef.current;
      setIsTextWrapped(container.clientHeight < innerContainer.scrollHeight);
    }
  }, []);

  useEffect(() => {
    if (containerRef.current && innerContainerRef.current) {
      const container = containerRef.current;
      const innerContainer = innerContainerRef.current;
      setTimeout(() => {
        setIsTextWrapped(container.clientHeight < innerContainer.scrollHeight);
      }, 200);
    }
  }, []);

  return (
    <div className="d-flex ion-aling-items-start">
      {/* Text Area */}
      <div onClick={onClick} style={{ flex: '1' }}>
        <div ref={containerRef} style={{ overflow: 'hidden' }}>
          <IonLabel
            ref={innerContainerRef}
            style={{ whiteSpace: 'pre-wrap', lineHeight: isPlatform('hybrid') && isPlatform('ios') ? '1.25' : '' }}
            className={`font-${fontSize ?? '20'} ${color ? `ion-color-${color}-custom` : ''} ${
              isExpanded ? '' : `text-clamp-${lineClamp ?? 2}`
            } ion-no-padding ion-no-margin`}
          >
            {value}
          </IonLabel>
        </div>
      </div>

      {/* Toggle button */}
      {!disableExpandIcon && isTextWrapped && (
        <div onClick={() => setIsExpanded((prev) => !prev)}>
          <IonIcon
            className={`transform-chevron ${isExpanded ? 'rotate180' : ''} ${color ? `ion-color-${color}-custom` : ''}`}
            icon={chevronDown}
          />
        </div>
      )}
    </div>
  );
};

export default ExpandableLabel;
