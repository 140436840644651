import { IonPage, useIonLoading } from '@ionic/react';
import { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { QrObjectTypes } from '../constants/constants';
import { useSetRecoilState } from 'recoil';
import { qrRequestShareList } from '../features/checklist/listApi';
import { errorAlertMessageKeyState } from '../features/error/ErrorAlert';
import { SyncAborted, syncData } from '../sync/sync';
import { _list } from '../utils/state/model/implementations/ImplementationFactory';
import { useTranslation } from 'react-i18next';
import isPermittedNetworkConnected from '../sync/network/network';

let qrLandingPageLoading = false;

interface QrLandingPageProps
  extends RouteComponentProps<{
    idType: string;
    id: string;
    action: string;
  }> {}

const QrLandingPage: React.FC<QrLandingPageProps> = ({
  match: {
    params: { idType, id, action },
  },
}) => {
  const setErrorAlertState = useSetRecoilState(errorAlertMessageKeyState);
  const history = useHistory();
  const { t } = useTranslation();
  const [presentLoading, dismissLoading] = useIonLoading();

  const handleError = () => {
    setErrorAlertState('errorAlert.list.unavailable');
    history.goBack();
  };

  useEffect(() => {
    console.log('QrLandingPage', idType, id, action);

    const processId = async () => {
      if (qrLandingPageLoading) {
        console.log('Already running so returning early');
        return;
      }
      qrLandingPageLoading = true;

      if (!idType || !id || !action) {
        console.error('Missing required route parameters');
        handleError();
      }

      // await the presentation of the loading message or it may not be loaded when we try to dismiss it
      await presentLoading({
        message: t('loading.generic'),
      });

      if (idType === QrObjectTypes.LIST) {
        // Check if the user is offline
        if (!(await isPermittedNetworkConnected())) {
          // Check if the user has the list downloaded
          const listExists = await _list.doesListExist(id);
          if (listExists) {
            console.log('List is on device and user is offline');
            history.replace(`/list/${id}`);
          } else {
            console.warn('List is not on device and user is offline');
            handleError();
          }
        } else {
          try {
            console.log('Requesting access to list');
            const success = await qrRequestShareList(id);
            if (success) {
              await syncList();

              history.replace(`/list/${id}`);
            } else {
              console.error('Failure response requesting list');
              handleError();
            }
          } catch (error) {
            console.error('Error requesting list', error);
            handleError();
          }
        }
      }
    };
    processId();

    return () => {
      dismissLoading();
      qrLandingPageLoading = false;
    };
  }, []);

  const syncList = async () => {
    const MAX_SYNC_ATTEMPTS = 10;
    const DELAY_BETWEEN_ATTEMPTS_IN_MS = 500;
    const SYNC_ABORTED_MESSAGE = 'sync was aborted';

    for (let attempts = 0; attempts < MAX_SYNC_ATTEMPTS; attempts++) {
      try {
        console.log(`Syncing list after scanning QR code. Attempt ${attempts + 1}/${MAX_SYNC_ATTEMPTS}`);
        const syncFinished = await syncData({
          forceSyncLists: id ? [id] : [],
        });

        if (syncFinished) {
          console.log('Finished syncing list after scanning QR code');
          return;
        } else {
          console.log('Sync was aborted');
          throw new SyncAborted(SYNC_ABORTED_MESSAGE);
        }
      } catch (error: any) {
        if (error.message !== SYNC_ABORTED_MESSAGE) {
          console.error('Error syncing data after scanning QR code', error);
        }

        if (attempts === MAX_SYNC_ATTEMPTS - 1) {
          console.warn('Max sync attempts reached');
          throw error;
        } else {
          console.log('Attempt syncing again after delay');
          await new Promise((resolve) => setTimeout(resolve, DELAY_BETWEEN_ATTEMPTS_IN_MS));
        }
      }
    }
  };

  return <IonPage>{/* just show the loading spinner */}</IonPage>;
};

export default QrLandingPage;
