import { useEffect, useState } from 'react';
import { IonCard } from '@ionic/react';
import { Link } from 'react-router-dom';
import { alertCircleOutline, cloudDownload, cloudOfflineOutline, imageOutline } from 'ionicons/icons';
import { useRecoilValue } from 'recoil';
import { isPermittedNetworkConnectedState } from '../../../sync/network/network';
import { _photo, _task } from '../../../utils/state/model/implementations/ImplementationFactory';
import { Photo } from '../../../utils/state/model/interfaces/objects';
import PhotoTagButton from './PhotoTagButton';
import { PhotoTag } from '../../../constants/constants';
import PendingThumbnail from './PendingThumbnail';

interface ThumbnailTilesProps {
  listId: string;
  taskId: string;
  photoId: string;
}

enum downloadStateType {
  notStarted,
  attempting,
  failed,
  completed,
}

export const ThumbnailTile: React.FC<ThumbnailTilesProps> = ({ listId, taskId, photoId }) => {
  const isOnline = useRecoilValue(isPermittedNetworkConnectedState);

  const [base64Data, setBase64Data] = useState<string>('');
  const [photoTag, setPhotoTag] = useState<PhotoTag | undefined>(undefined);
  const [downloadState, setDownloadState] = useState<downloadStateType>(downloadStateType.notStarted);

  const dataLoader = async () => {
    if (!base64Data || base64Data !== '') {
      let queriedData;
      let queriedTag;
      if (!queriedData) {
        try {
          const photo: Photo = await _photo.getPhoto(photoId);
          if (photo && photo.data) {
            queriedData = photo.data;
            queriedTag = photo.tag;
          }
        } catch (e) {
          console.error('Exception getting photo', e);
        }
      }

      if (!queriedData) {
        try {
          const thumbNail: Photo = await _photo.getThumbnail(photoId);
          if (thumbNail && thumbNail.data) {
            queriedData = thumbNail.data;
            queriedTag = thumbNail.tag;
          }
        } catch (e) {
          console.error('Exception getting Thumbnail', e);
        }
      }

      if (!queriedData && isOnline) {
        try {
          setDownloadState(downloadStateType.attempting);
          const thumbNail = await _photo.downloadThumbnail(photoId);

          if (thumbNail) {
            queriedData = thumbNail.data;
            queriedTag = thumbNail.tag;
            setDownloadState(downloadStateType.completed);
          } else {
            setDownloadState(downloadStateType.failed);
          }
        } catch (e) {
          console.error('Exception getting thumbnail from API', photoId, e);
          setDownloadState(downloadStateType.failed);
        }
      }

      if (queriedData) {
        setBase64Data(queriedData);
        setPhotoTag(queriedTag ?? undefined);
      }
    }
  };

  useEffect(() => {
    dataLoader();
    const subscription = _task.watchForChangesInTask(taskId, dataLoader, `Task changes SubscriptionTask: ${taskId}`);

    return () => {
      _task.unwatch(subscription, `Task changes SubscriptionTask: ${taskId}`);
    };
  }, [photoId, taskId]);

  if (base64Data !== '') {
    return (
      <IonCard key={photoId} style={{ borderRadius: '0px', boxShadow: 'none' }} className="ion-no-margin">
        <Link
          className="clickable"
          to={{
            pathname: `/list/${listId}/task/${taskId}/photos`,
            state: { photoId: photoId },
          }}
        >
          <img
            src={base64Data}
            alt="uploaded by user"
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '120px',
              overflowClipMargin: 'unset',
            }}
          />
        </Link>

        <div className="d-flex ion-justify-content-center">
          <PhotoTagButton tag={photoTag} photoId={photoId} taskId={taskId} />
        </div>
      </IonCard>
    );
  } else if (isOnline && downloadState === downloadStateType.attempting) {
    return <PendingThumbnail key={photoId} icon={cloudDownload} title="Attempting to download" includeSpinner />;
  } else if (!isOnline) {
    return <PendingThumbnail key={photoId} icon={cloudOfflineOutline} />;
  } else if (isOnline && downloadState === downloadStateType.failed) {
    return <PendingThumbnail key={photoId} icon={alertCircleOutline} />;
  } else {
    return <PendingThumbnail key={photoId} icon={imageOutline} />;
  }
};
