import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Dialog } from '@capacitor/dialog';
import { useSetRecoilState } from 'recoil';
import { errorPageState } from '../../../../pages/ErrorPage';
import { Photo } from '../../../../utils/state/model/interfaces/objects';
import { _photo } from '../../../../utils/state/model/implementations/ImplementationFactory';
import { logFriendlyObject } from '@otuvy/common-utils';
import { cameraJpegQuality, defaultImageMaxDimension } from '../../../../constants/constants';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';

/**
 * TODO: either unify or better distinguish the difference between useImage and useCamera
 */
const useCamera = (taskId: string) => {
  const setShowErrorPage = useSetRecoilState(errorPageState);

  const promptForCameraPermissions = async (): Promise<boolean> => {
    const { value } = await Dialog.confirm({
      title: 'Camera Disabled',
      message: 'To be able to take pictures in Frontline, tap Open Settings, then enable Camera permissions.',
    });
    if (value) {
      await NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App,
      });
      return true;
    }
    return false;
  };

  const takePhoto = async (): Promise<Photo | null> => {
    try {
      const permissions = await Camera.checkPermissions();
      if (permissions.camera === 'denied') {
        const openedSettings = await promptForCameraPermissions();
        return null;
      } else {
        const takenPhoto = await Camera.getPhoto({
          resultType: CameraResultType.DataUrl,
          source: CameraSource.Camera,
          quality: cameraJpegQuality,
          height: defaultImageMaxDimension,
          width: defaultImageMaxDimension,
        });
        if (!takenPhoto.dataUrl) {
          throw new Error('No dataUrl returned from getPhoto');
        }

        const photo: Photo = await _photo.addCompletionPhotoToTask(taskId, takenPhoto.dataUrl);
        return photo;
      }
    } catch (error: any) {
      if (error.message === 'User cancelled photos app') {
        return null;
      }
      if (error.message === 'User denied access to camera') {
        return null;
      }
      console.error('Failed to take photo or select an existing photo\n', logFriendlyObject(error));
      setShowErrorPage({
        showErrorPage: true,
        errorDetails: 'Failed to take photo or select an existing photo\n' + error,
      });
      throw new Error();
    }
  };

  return {
    takePhoto,
  };
};

export default useCamera;
