/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './style/theme.css'; //Global CSS Rules
import './style/variables.css'; //Ionic Variables
import './style/textClasses.css';
import './style/spacingClasses.css';
import './style/layoutClasses.css';
import './style/transformationsClasses.css';
import './style/interactionClasses.css';

import { EnvironmentConfig, getFlag } from './utils/environmentUtils';
import React from 'react';
import { createRoot } from 'react-dom/client';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { RecoilRoot } from 'recoil';
import './i18n';
import { ErrorBoundary } from 'react-error-boundary';
import SecureApp from './SecureApp';
import ErrorAlert from './features/error/ErrorAlert';
import ErrorFallback from './features/error/ErrorFallback';
import GlobalStateEffects from './GlobalStateEffects';
import { setupIonicReact } from '@ionic/react';
import OnDeviceConsole from './features/OnDeviceConsole';
import { setUpAnalyticsListeners } from './analytics/analyticsListeners';
import captureConsole from './captureConsole';

if (getFlag(EnvironmentConfig.CAPTURE_LOGS)) captureConsole();

const container = document.getElementById('root');
const root = createRoot(container!);

setupIonicReact({
  mode: 'md',
  innerHTMLTemplatesEnabled: true, //Allows including HTML in Ionic components using IonicSafeString
});

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <React.Suspense fallback={<></> /* could put a splash screen here or something */}>
        <GlobalStateEffects>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <OnDeviceConsole />
            <ErrorAlert />
            <SecureApp />
          </ErrorBoundary>
        </GlobalStateEffects>
      </React.Suspense>
    </RecoilRoot>
  </React.StrictMode>
);

defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (getFlag(EnvironmentConfig.ANALYTICS_TRACKING)) {
  setUpAnalyticsListeners();
}
