import { _changeLog } from '../../utils/state/model/implementations/ImplementationFactory';
import { ChangeLog } from '../../utils/state/model/interfaces/changeLoggerInterface';
import isPermittedNetworkConnected from '../network/network';
import { graphql } from '../../utils/api/apiUtils';
import { isCurrentUserAuthenticated } from '@otuvy/auth';
import { logFriendlyObject } from '@otuvy/common-utils';
import { EnvironmentConfig, getFlag } from '../../utils/environmentUtils';

interface UploadResult {
  changeId: string;
  recordId: string;
  message: string;
}

const sendChangeMutation = `
        mutation makeChange(
            $changeId: ID!
            $changeType: String!
            $recordId: ID!
            $payload: String
            $createdOn: String!
        ){
          uploadChange(
            changeInput:{
                changeId: $changeId
                changeType: $changeType
                recordId: $recordId
                payload: $payload
                createdOn: $createdOn
              }
          ) 
            {
            changeId
            recordId
            message          
          }        
        }
    `;

/**
 * We send the changes one at a time in order to handle devices with little memory by only loading one change at a time
 * This is especially important when the changes involve photos which ar large
 */
async function sendSingleChange(changeId: string): Promise<void> {
  const thisChange: ChangeLog | undefined = await _changeLog.getChangeById(changeId);

  if (!thisChange) return;

  const variables = { ...thisChange };
  try {
    //We explicitly use try/catch here so that one change failing will not prevent other changes from uploading
    const upload = await graphql<UploadResult>(sendChangeMutation, variables);

    if (upload && upload.changeId === thisChange.changeId) {
      await _changeLog.markChangeAsUploaded(thisChange.changeId);
    }
  } catch (e: any) {
    if (e && e.errorType && e.errorType === 'Lambda:LambdaException') {
      const variableSize = calculateSizeOfVariables(variables);
      console.error('When trying to upload change: Lambda exception', e.message, variableSize, variables);
    } else if (e && e.errorType && e.errorType === 'Lambda:IllegalArgument') {
      const variableSize = calculateSizeOfVariables(variables);
      console.error(
        'When trying to upload change: Lambda IllegalArgument exception',
        e.message,
        variableSize,
        variables
      );
    } else {
      console.error(`Unknown error trying to upload change ${changeId}`, logFriendlyObject(e));
    }
    //Lambda status 413 generally indicates that the uploaded data was too large
  }
}

function calculateSizeOfVariables(variables: any) {
  if (!variables) return 0;

  let variableSize = -1;

  try {
    variableSize = new TextEncoder().encode(JSON.stringify(variables)).length;
  } catch (e) {
    console.warn('Error calculating size of variables', e);
  }
  return variableSize;
}

export async function uploadChanges(): Promise<void> {
  if (!(await isPermittedNetworkConnected())) {
    console.log('Not permitted to sync in this network state! (uploadChanges)');
    return;
  }
  const isUserAuthenticated: boolean = await isCurrentUserAuthenticated();

  if (!isUserAuthenticated) {
    return;
  }

  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('uploading changes - start');

  const changeIds: string[] = await _changeLog.getChangeIdsNeedingUploaded();

  if (changeIds.length === 0) {
    if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('No changes found to upload');
    return;
  }

  for (const changeId of changeIds) {
    try {
      //try around single event so that one failure does not affect all uploads, just the one
      await sendSingleChange(changeId);
    } catch (error) {
      console.error('Error when sending changes to server: ', logFriendlyObject(error));
    }
  }
}
