import { graphql } from '../../utils/api/apiUtils';
import { _analytics } from '../../utils/state/model/implementations/ImplementationFactory';
import { logFriendlyObject } from '@otuvy/common-utils';
import { EnvironmentConfig, getFlag } from '../../utils/environmentUtils';

export const uploadAnalytics = async () => {
  if (!getFlag(EnvironmentConfig.ANALYTICS_TRACKING)) return;

  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('uploading analytics');
  try {
    const eventIds: string[] = await _analytics.getEventIdsNeedingUploaded();

    if (eventIds.length === 0) {
      if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('No analytics events found to upload');
      return;
    }

    await sendAnalyticsEventsById(eventIds);
  } catch (error) {
    console.error('Error when sending analytics to server: ', logFriendlyObject(error));
    throw new Error('Failed to send analytics to server');
  }
};

/**
 * TODO: convert to a send single change
 */
const sendAnalyticsEventsById = async (eventIds: string[]) => {
  const uploadAnalyticsMutation = `
    mutation uploadAnalytics($events: [AnalyticsEvent!]!) {
      uploadAnalytics (analyticsEvents: $events)
    }
  `;

  for (const eventId of eventIds) {
    const event = await _analytics.getEventById(eventId);
    if (event) {
      const bareEvent: any = { ...event };
      delete bareEvent.eventId;
      const variables = { events: [bareEvent] };
      const success = await graphql<boolean>(uploadAnalyticsMutation, variables);

      if (success) {
        await _analytics.markEventsAsUploaded([eventId]);
      }
    }
  }
};
