import { Preferences } from '@capacitor/preferences';
import { _list, _task } from '../../../utils/state/model/implementations/ImplementationFactory';
import { ListDO, UserDO } from '../../../utils/state/model/interfaces/displayObjects';

export const savePinnedUsers = async (pinnedUsers: string[]) => {
  await Preferences.set({ key: 'pinnedUsers', value: JSON.stringify(pinnedUsers) });
};

export const loadPinnedUsers = async () => {
  const { value } = await Preferences.get({ key: 'pinnedUsers' });
  return value ? JSON.parse(value) : [];
};

export const loadAssignInitialSelectedUsersData = async (
  listId: string | string[] | null | undefined,
  taskIds: string[],
  menuType: 'share' | 'assign' | null
): Promise<string[]> => {
  if (menuType === 'assign') {
    if (!taskIds) {
      const tasks = await getAllTasksByListIds(listId);
      const tasksAssigned = (await Promise.all(tasks.map((taskId) => _task.getTaskById(taskId)))).filter(
        (task) => task.assignedTo && !task.isCompleted
      );
      const uniqueUsersId = new Set(tasksAssigned.map((task) => task.assignedTo!));
      if (!tasks || !tasksAssigned || !uniqueUsersId) return [];
      if (tasksAssigned.length > 0 && tasksAssigned.length !== tasks.length)
        return ['error', ...Array.from(uniqueUsersId)];

      return Array.from(uniqueUsersId);
    }
    if (taskIds.length > 1) {
      const tasksAssigned = (await Promise.all(taskIds.map((taskId) => _task.getTaskById(taskId)))).filter(
        (task) => task.assignedTo && !task.isCompleted
      );
      const uniqueUsersId = new Set(tasksAssigned.map((task) => task.assignedTo!));
      if (!tasksAssigned || !uniqueUsersId || tasksAssigned.length !== taskIds.length) return [];
      return Array.from(uniqueUsersId);
    }
    const task = await _task.getTaskById(taskIds![0]);
    return task.assignedTo ? [task.assignedTo] : [];
  }
  return [];
};

type shareInitialSelectedUserData = {
  owners: string[];
  sharedWith: string[];
};

export const loadShareInitialSelectedUsersData = async (
  listId: string | string[] | null | undefined
): Promise<shareInitialSelectedUserData> => {
  const lists: ListDO[] = [];
  if (typeof listId === 'string') {
    const list = await _list.getListById(listId);
    lists.push(list);
  } else {
    for (const id of listId!) {
      const list = await _list.getListById(id);
      lists.push(list);
    }
  }
  const uniqueUsersIdShare = new Set(lists.map((list) => list.sharedWith ?? []).flat());
  const uniqueUsersIdOwners = new Set(lists.map((list) => list.owner ?? []).flat());
  if (!lists || (!uniqueUsersIdShare && !uniqueUsersIdOwners)) return { owners: [], sharedWith: [] };
  return { owners: Array.from(uniqueUsersIdOwners), sharedWith: Array.from(uniqueUsersIdShare) };
};

export const sortUsersByName = (users: UserDO[]): UserDO[] => {
  return users.sort((a, b) => {
    const firstNameComparison = a.firstName.localeCompare(b.firstName);
    if (firstNameComparison !== 0) {
      return firstNameComparison;
    }

    // If first names are equal, compare by last name
    return a.lastName.localeCompare(b.lastName);
  });
};

export const getAllTasksByListIds = async (listIds: string | string[] | null | undefined) => {
  const allTaskIds = [];

  try {
    if (typeof listIds === 'string') {
      const tasks = await _task.getTasksByListId(listIds);
      const taskIds = tasks.map((task) => task.taskId);
      return taskIds;
    }

    for (const listId of listIds!) {
      const tasks = await _task.getTasksByListId(listId);
      const taskIds = tasks.map((task) => task.taskId);
      allTaskIds.push(...taskIds);
    }

    return allTaskIds;
  } catch (error) {
    console.error('Error fetching tasks:', error);
    throw error;
  }
};
