import { IonCol, IonRow } from '@ionic/react';

interface FormFieldProps {
  lessMarginBottom?: boolean;
  moreMarginBottom?: boolean;
  children: React.ReactNode;
}

const FormField: React.FC<FormFieldProps> = ({ children, lessMarginBottom, moreMarginBottom }) => {
  return (
    <IonRow class={`ion-align-items-start ${moreMarginBottom ? 'ion-margin-bottom' : ''}`}>
      <IonCol class={`${lessMarginBottom ? '' : 'ion-margin-bottom'}`}>{children}</IonCol>
    </IonRow>
  );
};

export default FormField;
