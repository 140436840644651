import { useSetRecoilState } from 'recoil';
import { errorPageState } from './ErrorPage';
import { useLocation } from 'react-router';

const NoPageFound: React.FC = () => {
  const setCurrentErrorState = useSetRecoilState(errorPageState);
  const { pathname } = useLocation();

  console.error(`No Page found - Bad route: ${pathname}`);
  setCurrentErrorState({
    showErrorPage: true,
    errorDetails: `No page found - Bad route: ${pathname}`,
  });

  return <></>;
};

export default NoPageFound;
