import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonModal,
  IonDatetimeButton,
} from '@ionic/react';
import { add, chevronForwardOutline, globeOutline } from 'ionicons/icons';
import { useRef, useState } from 'react';
import { parseISO, format, formatISO, addYears } from 'date-fns';
import RecurrenceSelect, { getRecurrenceRuleByOption } from './RecurrenceSelect';
import { RRule } from 'rrule';
import { _task } from '../../../utils/state/model/implementations/ImplementationFactory';
import { TaskDO } from '../../../utils/state/model/interfaces/displayObjects';
import DeleteConfirmation from '../../general/DeleteConfirmation';
import { isCalendarInteractive, setDefaultTime } from '../utils/calendarUtils';
import { createRecurrenceRule } from '../utils/recurrenceUtils';
import { useCalendarView } from '../hooks/useCalendarView';
import { useRecoilValue } from 'recoil';
import { settingsState } from '../../settings/settingsState';
import { recurrenceRuleToNaturalLanguage } from '../utils/naturalLanguage';
import { t } from 'i18next';
import TimezoneSelect from './TimezoneSelect';
import { DateTime } from 'luxon';
import { Transition } from 'react-transition-group';
import {
  timePickerEnterAnimation,
  timePickerLeaveAnimation,
  timezonePickerDivStyle,
  timezonePickerDivTransition,
  timezonePickerRowStyle,
  timezonePickerRowTransition,
  timezoneToggleButtonStyle,
  timezoneToggleButtonTransition,
} from '../utils/calendarAnimation';
import { writeAccessRestrictedState } from '../../../App';

interface CalendarViewProps {
  onCancel: () => void;
  tasks: TaskDO[];
}

type CalendarISODate = string | undefined | null | string[];

const CalendarView: React.FC<CalendarViewProps> = ({ onCancel, tasks }) => {
  const { isRestricted: isWriteAccessRestricted } = useRecoilValue(writeAccessRestrictedState);

  const [
    {
      calendarValue: calendarDate,
      multiCalendarValue: multiCalendarDate,
      isTimePickerOn,
      recurrence,
      recurrenceOption,
      timezone,
      isTimezonePickerOn: isTimezoneEnable,
    },
    dispatch,
  ] = useCalendarView(tasks);
  const [isTimezoneMenuOpen, setIsTimezoneMenuOpen] = useState(false);
  const [isRepeatMenuOpen, setIsRepeatMenuOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { language } = useRecoilValue(settingsState);

  const timePickerRef = useRef<HTMLDivElement>(null);
  const timezonePickerRef = useRef(null);
  const timezoneToggleRef = useRef(null);

  const calendarChangeHandler = (value: CalendarISODate) => {
    if (!value || value.length > 27) return;
    if (Array.isArray(value)) return;
    dispatch({ type: 'SET_CALENDAR', payload: { calendarValue: value } });
  };

  const doneClickHandler = () => {
    if (isWriteAccessRestricted || !calendarDate) return onCancel();
    if (tasks.length > 1 && isConfirmationOpen === false) return setIsConfirmationOpen(true);

    // const dueDate = calendarDate as string; // we're not allowing multiple dates here, so no string[]
    const dueDate = DateTime.fromISO(calendarDate as string)
      .setZone(timezone, { keepLocalTime: true })
      .toISO();
    console.log('Due Date', dueDate);

    tasks.forEach((task) => {
      _task.updateTaskRecurrence(task.taskId, parseISO(dueDate as string), recurrence ?? null, timezone);
    });

    onCancel();
  };

  const clearClickHandler = () => {
    tasks.forEach((task) => {
      _task.updateTaskRecurrence(task.taskId, null, null, undefined);
    });

    onCancel();
  };

  const cancelClickHandler = () => {
    onCancel();
  };

  const selectRecurrenceHandler = (value: string | RRule) => {
    if (!calendarDate) {
      dispatch({ type: 'SET_CALENDAR', payload: { calendarValue: setDefaultTime(new Date()) } });
    }

    if (typeof value !== 'string') {
      const rule = createRecurrenceRule(
        value,
        'custom',
        parseISO((calendarDate as string) ?? setDefaultTime(new Date())),
        timezone
      );
      dispatch({ type: 'SET_RRULE', payload: { recurrence: rule, recurrenceOption: 'custom' } });
      return;
    }
    const option = getRecurrenceRuleByOption(value);
    if (!option) {
      dispatch({ type: 'SET_RRULE', payload: { recurrence: null, recurrenceOption: 'none' } });
      return;
    }

    const rule = createRecurrenceRule(
      option,
      value,
      parseISO((calendarDate as string) ?? setDefaultTime(new Date())),
      timezone
    );
    dispatch({ type: 'SET_RRULE', payload: { recurrence: rule, recurrenceOption: value } });
  };

  const timePickerToggleHandler = () => {
    dispatch({ type: 'TOGGLE_TIMEPICKER', payload: {} });
    if (isTimePickerOn) timePickerLeaveAnimation(timePickerRef);
    if (!isTimePickerOn) timePickerEnterAnimation(timePickerRef);
  };

  // only way we can get to shadowroot when it isnt exposed is through js
  const getToolbarShadowRoot = () => {
    const toolbar = document.querySelector('ion-datetime');
    if (toolbar !== null && toolbar.shadowRoot !== null) {
      const shadowRootToolbar = toolbar.shadowRoot.querySelectorAll('.calendar-month-grid');
      if (shadowRootToolbar !== null) {
        shadowRootToolbar.forEach((node) => {
          if (!multiCalendarDate) return node.setAttribute('style', '');
          node.setAttribute('style', 'pointer-events: none');
        });
      }
    }
  };
  setTimeout(() => {
    getToolbarShadowRoot();
  }, 100);

  return (
    <>
      {/* Modal Header */}
      <IonHeader className="ion-padding-top ion-no-border" style={{ background: 'var(--toolbar-background)' }}>
        <IonToolbar style={{ '--background': 'var(--toolbar-background)', '--ion-safe-area-top': '0px' }}>
          <IonGrid>
            <IonRow className="ion-justify-content-around">
              <IonCol>
                <IonButtons>
                  <IonButton
                    onClick={clearClickHandler}
                    fill="clear"
                    disabled={
                      isWriteAccessRestricted ||
                      (tasks.length < 2 && !calendarDate) ||
                      (tasks.length > 1 && !tasks.some((task) => task.dueDate !== null))
                    }
                    color="error"
                  >
                    <IonText className="font-20">{t('calendarView.clear')}</IonText>
                  </IonButton>
                </IonButtons>
              </IonCol>
              <IonCol>
                <IonTitle style={{ textAlign: 'center' }}>{t('dueDate')}</IonTitle>
              </IonCol>
              <IonCol>
                <IonTitle class="ion-text-end" style={{ width: 'content' }}>
                  {calendarDate && format(parseISO(calendarDate as string), 'M/d/yy')}
                </IonTitle>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>

      {/* Modal Content */}
      <IonContent className="ion-padding-horizontal" style={{ '--background': 'var(--toolbar-background)' }}>
        {/* Content Grid Wrapper */}
        <IonGrid>
          {/* Calendar */}
          <IonDatetime
            mode="ios"
            locale={language === undefined || language === 'en' ? 'en-US' : 'es-ES'}
            value={multiCalendarDate || calendarDate}
            min="2022"
            max={formatISO(addYears(new Date(), 5))}
            isDateEnabled={isCalendarInteractive}
            style={{ maxWidth: 'none' }}
            presentation={'date'}
            multiple={multiCalendarDate ? true : false}
            onIonChange={(e) => calendarChangeHandler(e.detail.value)}
            disabled={isWriteAccessRestricted}
          />

          {/* Time Picker */}
          <IonRow class="ion-margin-top ion-align-items-center">
            <IonTitle color={!isWriteAccessRestricted && calendarDate ? 'white' : 'light'}>
              {t('calendarView.time')}
            </IonTitle>
            <div
              ref={timePickerRef}
              className={`d-flex ion-align-items-center ${isTimePickerOn ? 'visible' : 'hidden'}`}
            >
              {/* TimezoneButton */}
              <IonIcon
                style={{
                  fontSize: '1.5rem',
                  background: isTimezoneEnable ? 'var(--ion-color-overlay)' : '',
                  color: isTimezoneEnable ? 'var(--ion-color-dark)' : 'var(--ion-color-medium)',
                  padding: '6px 12px',
                  borderRadius: '0.5rem',
                  transition: '500ms ease-in-out 500ms',
                  transform: isTimezoneEnable ? 'translateX(0)' : 'translateX(50%)',
                }}
                color="light"
                icon={globeOutline}
                onClick={() => dispatch({ type: 'TOGGLE_TIMEZONE_PICKER', payload: {} })}
              />
              <Transition nodeRef={timezoneToggleRef} in={isTimezoneEnable} timeout={500}>
                {(state) => (
                  <IonIcon
                    ref={timezoneToggleRef}
                    style={{ ...timezoneToggleButtonStyle, ...timezoneToggleButtonTransition[state] }}
                    icon={add}
                    className={`ion-no-padding font-32`}
                    onClick={() => dispatch({ type: 'TOGGLE_TIMEZONE_PICKER', payload: {} })}
                  />
                )}
              </Transition>

              {/* DatePickerButton */}
              {isTimePickerOn && (
                <>
                  <IonDatetimeButton
                    datetime="datetime"
                    disabled={!isWriteAccessRestricted && calendarDate ? false : true}
                  />
                  <IonModal keepContentsMounted={true} style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <IonDatetime
                      minuteValues={'0, 5, 10, 15,20, 25, 30, 35, 40, 45, 50, 55'}
                      mode="ios"
                      value={calendarDate}
                      locale={'en-US'}
                      presentation="time"
                      id="datetime"
                      onIonChange={(event) => {
                        dispatch({ type: 'SET_TIMEPICKER', payload: { timePickerValue: event.target.value } });
                      }}
                    />
                  </IonModal>
                </>
              )}
            </div>
            <IonItem
              className="ion-no-padding"
              disabled={!isWriteAccessRestricted && calendarDate ? false : true}
              lines="none"
              color="background-color"
              onClick={() => timePickerToggleHandler()}
            >
              <IonIcon
                icon={add}
                className={`transform-100 font-32 ${isTimePickerOn ? 'rotate45' : ''}`}
                style={{ cursor: 'pointer' }}
              />
            </IonItem>
          </IonRow>

          {/* Timezone Picker */}
          <Transition nodeRef={timezonePickerRef} in={isTimezoneEnable} timeout={500}>
            {(state) => (
              <div ref={timezonePickerRef} style={{ ...timezonePickerDivStyle, ...timezonePickerDivTransition[state] }}>
                <IonRow
                  ref={timezonePickerRef}
                  style={{ ...timezonePickerRowStyle, ...timezonePickerRowTransition[state] }}
                  className="ion-align-items-center ion-justify-content-between ion-nowrap ion-padding-start"
                  onClick={() => (isTimezoneEnable ? setIsTimezoneMenuOpen(true) : null)}
                >
                  <IonLabel
                    style={{ fontWeight: '500' }}
                    class="font-22 ion-padding-start ion-text-nowrap"
                    color={!isWriteAccessRestricted && calendarDate ? 'white' : 'light'}
                  >
                    {t('timeZone')}
                  </IonLabel>
                  <IonCol class="ion-padding-start" size="6">
                    {/* TODO: Handle long natural language text */}
                    <IonLabel class="font-16">
                      {`${DateTime.local().setZone(timezone).offsetNameShort} - ${
                        DateTime.local().setZone(timezone).offsetNameLong
                      }`}
                    </IonLabel>
                  </IonCol>
                  <IonIcon className="font-28 ion-padding-end" icon={chevronForwardOutline} />
                </IonRow>
              </div>
            )}
          </Transition>

          {/* Repeat Selection Picker*/}
          <IonRow
            onClick={() => !isWriteAccessRestricted && setIsRepeatMenuOpen(true)}
            class="ion-align-items-center ion-justify-content-between ion-nowrap ion-padding-start"
          >
            <IonCol>
              <IonLabel style={{ fontWeight: '500' }} color={!isWriteAccessRestricted ? 'white' : 'light'}>
                {t('calendarView.repeat')}
              </IonLabel>
            </IonCol>
            {recurrence && (
              <IonCol class="ion-text-right" size="7">
                {/* TODO: Handle long natural language text */}
                <IonLabel class="ion-text-wrap">
                  {recurrenceRuleToNaturalLanguage(recurrence, (language ?? 'en') as 'en' | 'es')}
                </IonLabel>
              </IonCol>
            )}
            <IonIcon
              color={!isWriteAccessRestricted ? 'white' : 'light'}
              className="font-28 ion-text-right ion-padding-end"
              icon={chevronForwardOutline}
            />
          </IonRow>
        </IonGrid>
      </IonContent>

      {/* Footer btns */}
      <IonFooter class="ion-no-border">
        <div
          className="d-flex ion-justify-content-between"
          style={{ background: 'var(--ion-color-custom-overlay)', minHeight: '56px' }}
        >
          <IonButton fill="clear" onClick={cancelClickHandler}>
            {t('cancel')}
          </IonButton>
          <IonButton fill="clear" onClick={doneClickHandler}>
            {t('done')}
          </IonButton>
        </div>
      </IonFooter>

      <DeleteConfirmation
        isOpen={isConfirmationOpen}
        setIsOpen={setIsConfirmationOpen}
        deleteMe={doneClickHandler}
        confirmButtonText="Yes, apply the due date"
        header="Replace due dates?"
        message="Any existing due date will be replaced."
      />

      <TimezoneSelect
        isOpen={isTimezoneMenuOpen}
        setIsOpen={setIsTimezoneMenuOpen}
        timezone={timezone as string}
        setTimezone={dispatch}
      />

      <RecurrenceSelect
        isOpen={isRepeatMenuOpen}
        setIsOpen={setIsRepeatMenuOpen}
        onSelect={selectRecurrenceHandler}
        recurrenceOption={recurrenceOption as string}
        recurrenceValue={recurrence as RRule | null}
        calendarDate={calendarDate ? parseISO(calendarDate as string) : null}
      />
    </>
  );
};

export default CalendarView;
