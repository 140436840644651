import { IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { VISIBLE_ITEM_SECTION } from './virtualScroll/useVirtualScroll';

interface SelectAllSectionButtonProps {
  section: VISIBLE_ITEM_SECTION;
  onClick: (section: VISIBLE_ITEM_SECTION, isCheckboxChecked: boolean) => void;
  isSectionSelected: boolean;
  sectionItemsCount?: number;
}

// TODO: Use this component instead of SelectAllTaskCheckbox used in the ListDetailsView
// To do this we also need to change the returned value of the getAllTaskByCompletionStatus function in the useMultiSelectTasks hook
const SelectAllSectionButton: React.FC<SelectAllSectionButtonProps> = ({
  section,
  isSectionSelected,
  onClick,
  sectionItemsCount,
}) => {
  const { t } = useTranslation();

  const text = () => {
    if (section === VISIBLE_ITEM_SECTION.COMPLETE && isSectionSelected) return t('multiSelectMenu.deselect.completed');
    if (section === VISIBLE_ITEM_SECTION.COMPLETE && !isSectionSelected) return t('multiSelectMenu.select.completed');
    if (section === VISIBLE_ITEM_SECTION.INCOMPLETE && isSectionSelected)
      return t('multiSelectMenu.deselect.incomplete');
    if (section === VISIBLE_ITEM_SECTION.INCOMPLETE && !isSectionSelected)
      return t('multiSelectMenu.select.incomplete');
    if (section === VISIBLE_ITEM_SECTION.ARCHIVED && isSectionSelected) return t('multiSelectMenu.deselect.archived');
    if (section === VISIBLE_ITEM_SECTION.ARCHIVED && !isSectionSelected) return t('multiSelectMenu.select.archived');
    return '';
  };

  if (sectionItemsCount === 0) return null;

  return (
    <IonLabel
      color="primary"
      class="ion-padding-start font-16 font-weight-500"
      onClick={() => onClick(section, isSectionSelected)}
    >
      {text()}
    </IonLabel>
  );
};

export default SelectAllSectionButton;
