import { IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { copyBasicDebug } from '../../utils/debug/basicDebug';

interface VersionStampProps {
  authenticated: boolean;
}

const VersionStamp: React.FC<VersionStampProps> = ({ authenticated }) => {
  const [clickCount, setClickCount] = useState<number>(0);

  const p = require('../../../package.json');
  const productVersion = p.version;
  const { t } = useTranslation();
  const history = useHistory();

  const handleMouseEvent = (e: React.MouseEvent<HTMLIonTextElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    if (clickCount >= 2) {
      if (authenticated) {
        history.push(`/debug`);
        //TODO: programmatically close the menu
      } else {
        copyBasicDebug();
      }
    }
    setClickCount(clickCount + 1);

    setTimeout(function () {
      setClickCount(0);
    }, 1000);
  };

  return (
    <>
      <IonText color="medium" onClick={handleMouseEvent}>
        {t('product.version')}
        {productVersion}
      </IonText>
    </>
  );
};

export default VersionStamp;
