import ErrorPage from "../../pages/ErrorPage"

const ErrorFallback: React.FC = () => {

    return (
        <>
            <ErrorPage />
        </>
    )
}

export default ErrorFallback