import { Geolocation, Position } from '@capacitor/geolocation';

// This is a hack to get around the fact that the geolocation plugin using the getCurrentPosition method takes a long time to resolve on iOS and Android.
export const getDeviceCurrentPosition = () =>
  new Promise<Position>(async (resolve, reject) => {
    const id = await Geolocation.watchPosition({ enableHighAccuracy: true, maximumAge: 6000 }, (position, err) => {
      Geolocation.clearWatch({ id });
      if (err) {
        reject(err);
      } else if (position) {
        resolve(position);
      }
    });
  });
