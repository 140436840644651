import { IonImg, IonSpinner } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DeleteConfirmation from '../../general/DeleteConfirmation';
import { Photo } from '../../../utils/state/model/interfaces/objects';
import { fullscreenModeState } from '../../menu/MenuToolbar';
import { _photo } from '../../../utils/state/model/implementations/ImplementationFactory';
import { isPermittedNetworkConnectedState } from '../../../sync/network/network';
import { errorPageState } from '../../../pages/ErrorPage';

interface TaskPhotoViewProps {
  taskId: string;
  photoId: string;
  isDeleteConfirmationOpen: boolean;
  setIsDeleteConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

enum downloadStateType {
  notStarted,
  attempting,
  failed,
  completed,
}

const TaskPhotoView: React.FC<TaskPhotoViewProps> = ({
  taskId,
  photoId,
  isDeleteConfirmationOpen,
  setIsDeleteConfirmationOpen,
}) => {
  const isOnline = useRecoilValue(isPermittedNetworkConnectedState);
  const setIsFullscreenMode = useSetRecoilState(fullscreenModeState);
  const [base64Data, setBase64Data] = useState<string>('');
  const [downloadState, setDownloadState] = useState<downloadStateType>(downloadStateType.notStarted);
  const setShowErrorPage = useSetRecoilState(errorPageState);

  const history = useHistory();

  const dataLoader = async () => {
    if (!base64Data || base64Data !== '') {
      let queriedData;
      if (!queriedData)
        try {
          const photo: Photo = await _photo.getPhoto(photoId);
          if (photo && photo.data) {
            queriedData = photo.data;
          }
        } catch (e) {
          console.error('Exception getting photo', e);
        }

      if (!queriedData && isOnline)
        try {
          setDownloadState(downloadStateType.attempting);

          const photo = await _photo.downloadPhoto(photoId);

          if (photo) {
            queriedData = photo.data;
          }
          setDownloadState(downloadStateType.completed);
        } catch (e) {
          console.error('Exception getting API', e);
          setDownloadState(downloadStateType.failed);
        }
      if (queriedData) {
        setBase64Data(queriedData);
      } else {
        setDownloadState(downloadStateType.failed);
      }
    }
  };

  useEffect(() => {
    dataLoader().then(() => {});
  }, [photoId]);

  useEffect(() => {
    setIsFullscreenMode(true);
    return () => {
      setIsFullscreenMode(false);
    };
  }, []);

  const deleteMe = () => {
    _photo.removeCompletionPhotoFromTask(taskId, photoId);
    history.goBack();
  };

  if (base64Data && base64Data !== '') {
    return (
      <>
        <IonImg src={base64Data} />
        <DeleteConfirmation
          isOpen={isDeleteConfirmationOpen}
          setIsOpen={setIsDeleteConfirmationOpen}
          deleteMe={deleteMe}
          confirmButtonText="confirmation.delete.image.confirm"
          header="confirmation.delete.image.header"
          message="confirmation.delete.image.message"
        />
      </>
    );
  } else if (downloadState === downloadStateType.failed) {
    console.error('Failed to download photo');
    setShowErrorPage({
      showErrorPage: true,
      errorDetails: `Failed to download photo: taskId = ${taskId}, photoId = ${photoId}`,
    }); //We were told to show them the error page, but due to the content on it, specifically the "lets get back into it" link, does not fit well in this place

    throw new Error();
  } else {
    //TODO: style this to make it more prominent
    return <IonSpinner name="dots"></IonSpinner>;
  }
};

export default TaskPhotoView;
