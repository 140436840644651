import isPermittedNetworkConnected from '../network/network';
import { AuthCache, isCurrentUserAuthenticated } from '@otuvy/auth';
import { SyncAborted, forceRecords } from '../sync';
import { EnvironmentConfig, getFlag, getNumber } from '../../utils/environmentUtils';
import { ListLastUpdated, getKnownListIdsFromServer } from './downloadLists';
import { downloadProfilePictures, syncUsers } from './downloadUsers';
import { NonReportedError, sendErrorEmail } from '../../features/error/errorApi';
import { downloadListsWithTasks } from './downloadListsWithTasks';
import { _changeLog, _list } from '../../utils/state/model/implementations/ImplementationFactory';
import { replaceUpdatedTasks } from '../../utils/state/model/implementations/dexie/taskDexieImplementation';
import { replaceUpdatedLists } from '../../utils/state/model/implementations/dexie/listDexieImplementation';
import { parseListsFromData, parseTasksFromData } from './parsers';
import { getUserSyncedOns } from '../../utils/state/model/implementations/dexie/userDexieImplementation';

export interface DownloadList {
  listId: string;
  listName: string;
  updatedOn: string;
  sortId: string;
  createdOn: string;

  owner: string;
  sharedWith?: string[] | null;

  notes?: string | null;

  isArchived: boolean;
  qmInspectionId?: number | null;
  status: number;
  syncedOn: string;
}

export interface DownloadListWithTasks extends DownloadList {
  tasks: DownloadTask[];
}

export interface DownloadTask {
  taskId: string;
  taskName: string;
  instructions?: string | null;
  linkToInstructionsUrl?: string | null;
  linkToInstructionsText?: string | null;
  isPhotoRequired: boolean;
  sortId: string;
  recurrence?: string | null;
  nonRecurringDueDate?: string | null;
  timezone?: string | null;
  dueDate?: string | null;
  assignedTo?: string | null;
  assignedBy?: string | null;
  completedOn?: string | null;
  completedBy?: string | null;
  notes?: string | null;
  completionPhotoIds?: string[] | null;
  pendingPhotos?: string[] | null;
  updatedOn: string;
  syncedOn: string;
  createdOn: string;
  header?: string[] | null;
}

export interface DownloadTaskWithListId extends DownloadTask {
  listId: string;
}

export class ListResult {
  public listsToUpdate: DownloadListWithTasks[] = [];
  public listsToDelete: string[] = [];
  public deletedTaskIds: string[] = [];
}

export interface SyncedOn {
  id: string;
  syncedOn: Date;
}

export const updateDataFromServer = async (params?: forceRecords) => {
  if (!(await isPermittedNetworkConnected())) {
    console.log('Not permitted to sync in this network state! (updateDataFromServer)');
    return;
  }
  const isUserAuthenticated: boolean = await isCurrentUserAuthenticated();

  if (!isUserAuthenticated) {
    return;
  }

  await fetchAndReplace(params);
};

const fetchAndReplace = async (params?: forceRecords) => {
  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log(`fetchAndReplace - start`);

  const isUserAuthenticated: boolean = await isCurrentUserAuthenticated();

  if (!isUserAuthenticated) {
    if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS))
      console.log(`fetchAndReplace - early end since the user is not authenticated`);
    return;
  }

  await syncListsAndTasks(params);

  const updatedUsers = await syncUsers([]);
  downloadProfilePictures(updatedUsers);

  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log(`fetchAndReplace - end`);
};

const syncListsAndTasks = async (params?: forceRecords) => {
  try {
    const listIdsToUpdate = await getListIdsToUpdate(params);
    if (listIdsToUpdate.length === 0) {
      if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('No lists to update');
      document.dispatchEvent(new CustomEvent('downloadedFirstBatch', { detail: { hasDownloadedFirstBatch: true } }));
      return;
    }

    const batchSize = getNumber(EnvironmentConfig.SYNC_DOWNLOAD_LIST_BATCH_SIZE, 100);
    const estimatedBatches = Math.ceil(listIdsToUpdate.length / batchSize);

    for (let i = 0; i < listIdsToUpdate.length; i += batchSize) {
      const batchId = i / batchSize + 1;

      /*
        In order to deal with the situation where a user logs out in the middle of a sync, 
        we need to check if the user is still authenticated before starting on the next batch
      */
      const isUserAuthenticated: boolean = await isCurrentUserAuthenticated();
      if (!isUserAuthenticated) {
        console.log(`syncData - no authenticated user when attempting batch ${batchId}/${estimatedBatches}.  Aborting`);
        throw new SyncAborted('No authenticated user');
      }

      const batch = listIdsToUpdate.slice(i, i + batchSize);
      if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS))
        console.log(`Downloading batch ${batchId}/${estimatedBatches}`, batch.length);

      try {
        const downloadedLists: ListResult = await downloadListsWithTasks(batch);
        if (!downloadedLists) {
          if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS))
            console.log(`No lists found in batch ${batchId}/${estimatedBatches}`, downloadedLists);
          continue;
        }

        if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS))
          console.log(`Batch ${batchId}/${estimatedBatches} downloaded:`, downloadedLists);

        await replaceBatch(downloadedLists);

        document.dispatchEvent(new CustomEvent('downloadedFirstBatch', { detail: { hasDownloadedFirstBatch: true } }));
      } catch (batchError) {
        console.error(`Error downloading batch ${batchId}/${estimatedBatches}`, batchError);
        if (getFlag(EnvironmentConfig.CAPTURE_LOGS) && !(batchError instanceof NonReportedError)) {
          sendErrorEmail('Error downloading lists and tasks for batch');
        }
      }
    }
  } catch (e) {
    if (e instanceof SyncAborted) {
      console.log('syncListsAndTasks - sync aborted', e.message);
      return;
    }

    console.error('downloading lists and tasks failed', e);
    if (getFlag(EnvironmentConfig.CAPTURE_LOGS) && !(e instanceof NonReportedError)) {
      sendErrorEmail('Error downloading lists and tasks');
    }
  }
};

const getListIdsToUpdate = async (params?: forceRecords): Promise<string[]> => {
  const remoteListUpdateTimes: ListLastUpdated[] = await getKnownListIdsFromServer();
  const localListSyncTimes: SyncedOn[] = await _list.getAllVisibleListIdsWithSyncedOn();
  const remoteListUpdateTimesMap: Map<string, Date> = new Map(
    remoteListUpdateTimes.map((l) => [l.list_id, l.last_updated])
  );
  const localListSyncTimesMap: Map<string, Date> = new Map(localListSyncTimes.map((l) => [l.id, l.syncedOn]));

  const listIdsToUpdate: string[] = await getCombinedListIdsToUpdate(
    remoteListUpdateTimesMap,
    localListSyncTimesMap,
    params
  );
  return listIdsToUpdate;
};

const getCombinedListIdsToUpdate = async (
  remoteLists: Map<string, Date>,
  localLists: Map<string, Date>,
  params?: forceRecords
): Promise<string[]> => {
  if (localLists.size === 0) {
    return Array.from(remoteLists.keys());
  }

  var userSyncedOn: number = 0;

  const userId = AuthCache.getCurrentUserId();
  if (userId) {
    const userSyncedOns: SyncedOn[] = await getUserSyncedOns([], []);
    if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('userSyncedOns:', userSyncedOns);
    const currentUserSyncedOns: SyncedOn[] = userSyncedOns.filter((u) => u.id === userId);
    if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('currentUserSyncedOns:', currentUserSyncedOns);
    if (currentUserSyncedOns.length !== 1) {
      console.error('No/Too many user found in userSyncedOns!');
    }
    //console.log(`Attempting to set userSyncedOn for user ${userId} to ${currentUserSyncedOns[0].syncedOn}`);
    userSyncedOn = currentUserSyncedOns[0].syncedOn.valueOf();
  } else {
    console.error('No current user ID found!');
  }

  if (userSyncedOn === 0) console.warn('No userSyncedOn found!');

  const allIds: string[] = Array.from(new Set([...Array.from(remoteLists.keys()), ...Array.from(localLists.keys())]));

  if (params?.forceSyncAllLists) return allIds;

  return allIds.filter((id) => {
    if (params?.forceSyncLists?.includes(id)) {
      //If it is a list we are forcing an update on we should always update it
      return true;
    }

    const localSyncedOn = localLists.get(id);
    const remoteLastUpdated = remoteLists.get(id);
    if (!localSyncedOn || !remoteLastUpdated) {
      // If it wasn't returned from the server, we'll request it to make sure it needs to be deleted since we could be
      // doing a partial update or something in which not all available list IDs are returned
      return true;
    }

    const remote: number = remoteLastUpdated.valueOf();
    const local: number = localSyncedOn.valueOf();

    return remote > local || userSyncedOn > local;
  });
};

const replaceBatch = async (downloadedLists: ListResult) => {
  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('replaceBatch - start', downloadedLists);

  //We do all tasks for all lists in one big bulk insert to prevent UI refreshes
  const deletedTaskIds: string[] = downloadedLists.deletedTaskIds ?? [];
  const updatedTasks: DownloadTaskWithListId[] = downloadedLists.listsToUpdate.flatMap<DownloadTaskWithListId>(
    (list) => {
      //readd the listId property that we did not get from the server
      const tasksWithListId = list.tasks.map<DownloadTaskWithListId>((task) => {
        return { ...task, listId: list.listId };
      });
      return tasksWithListId;
    }
  );

  const tasksWithPendingChanges: string[] = await _changeLog.getTaskIdsWithPendingChanges();
  const tasksWithNoPendingChanges = updatedTasks.filter((task) => !tasksWithPendingChanges.includes(task.taskId));
  const parsedTasks = await parseTasksFromData(tasksWithNoPendingChanges);
  const taskIdsToDelete = deletedTaskIds.filter((taskId) => !tasksWithPendingChanges.includes(taskId));
  await replaceUpdatedTasks(taskIdsToDelete, parsedTasks);

  //insert lists after tasks so that the tasks are already in the database
  const listsWithPendingChanges: string[] = await _changeLog.getListIdsWithPendingChanges();
  const listsWithNoPendingChanges = downloadedLists.listsToUpdate.filter(
    (list) => !listsWithPendingChanges.includes(list.listId)
  );
  const parsedLists = parseListsFromData(listsWithNoPendingChanges);
  const listIdsToDelete = downloadedLists.listsToDelete.filter((listId) => !listsWithPendingChanges.includes(listId));
  await replaceUpdatedLists(listIdsToDelete, parsedLists);

  if (getFlag(EnvironmentConfig.VERBOSE_SYNC_LOGS)) console.log('replaceBatch - end', downloadedLists);
};
