import { AnalyticsEvent, AnalyticsInterface } from '../../interfaces/analyticsInterface';
import { v4 as uuid } from 'uuid';
import { AnalyticsEventType } from '../../../../../analytics/analyticsConstants';
import { db } from './db';
import { logFriendlyObject } from '@otuvy/common-utils';
import { EnvironmentConfig, getFlag } from '../../../../environmentUtils';

export class DexieAnalytics implements AnalyticsInterface {
  async queueEvent({ eventType = AnalyticsEventType.UNKNOWN, payload }: Partial<AnalyticsEvent>) {
    if (!getFlag(EnvironmentConfig.ANALYTICS_TRACKING)) return;

    try {
      const fullEvent: AnalyticsEvent = {
        eventId: uuid(),
        eventType,
        payload,
        eventTime: new Date(),
      };
      await db().analytics.add(fullEvent);
    } catch (error) {
      console.warn('Error in attempting to queue analytics event', logFriendlyObject(error));
    }
  }

  async getEventIdsNeedingUploaded(): Promise<string[]> {
    let eventIds: string[] = [];
    await db().analytics.each((r) => {
      eventIds.push(r.eventId);
    });

    return eventIds;
  }

  async getEventById(eventId: string): Promise<AnalyticsEvent | undefined> {
    const events: AnalyticsEvent[] = await db().analytics.where('eventId').equals(eventId).toArray();

    if (!events || events.length === 0) {
      console.warn(`No events matching EventID ${eventId}`);
      return undefined;
    }

    if (events && events.length > 1) {
      console.warn(`Muliple events matching EventID ${eventId}`);
      return undefined;
    }

    return events[0];
  }

  async markEventsAsUploaded(eventIds: string[]) {
    await db().analytics.where('eventId').anyOf(eventIds).delete();
  }
}
