import { DataSourceInterface } from '../../interfaces/DataSourceInterface';
import { db, initUserDb } from './db';
import { syncData } from '../../../../../sync/sync';
import { replaceUpdatedUsers } from './userDexieImplementation';
import { AuthCache } from '@otuvy/auth';
import { downloadCurrentDexieUser } from '../../../../useCheckOrgGovernors';
import { DexieUser } from './implementationModel/dexieObjects';
import { logFriendlyObject } from '@otuvy/common-utils';
import { EnvironmentConfig, getFlag } from '../../../../environmentUtils';

export class DataSourceDexieImplementation implements DataSourceInterface {
  async init(): Promise<boolean> {
    const userId: string | undefined = AuthCache.getCurrentUserId();
    if (!userId) return false;

    initUserDb(userId);

    if (!db().isOpen()) {
      await db().open();
    }

    return true;
  }

  async downloadCurrentUser() {
    try {
      const user: DexieUser = await downloadCurrentDexieUser();

      //Now write it to the DB
      await replaceUpdatedUsers([], [user]);
    } catch (e) {
      console.error('Error fetching current user', logFriendlyObject(e));
    }
  }

  async onOffline(): Promise<void> {
    console.log('Processing DB offlineHint');
  }

  async onOnline(): Promise<void> {
    console.log('Processing DB onlineHint');

    if (getFlag(EnvironmentConfig.SERVER_SYNC)) {
      const initialized = await this.init();
      if (initialized) {
        syncData();
      }
    }
  }

  async preSignIn(): Promise<void> {
    console.log('preSignIn');
  }

  async postSignIn(): Promise<void> {
    console.log('postSignIn');
    syncData();
  }

  async preSignOut(allowLastSync: boolean = true): Promise<void> {
    console.log('preSignOut');
    if (allowLastSync && getFlag(EnvironmentConfig.SERVER_SYNC) && db().isOpen()) {
      try {
        //syncing changes here can cause an infinite loop if it was syncing changes that caused the logout in the first place
        //probably best if whatever causes the logout is handled in a separate place than syncData
        await syncData({ confirmProcessed: false });
      } catch (error) {
        // continue
      }
    }
  }

  async postSignOut(): Promise<void> {
    console.log('postSignOut');
    if (db() && db().isOpen()) db().close();
  }
}
