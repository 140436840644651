import { WriteAccessRestriction } from '../../../useCheckOrgGovernors';

export enum SystemFlagKey {
  IS_WRITE_ACCESS_RESTRICTED = 'writeAccessRestricted',
}

export interface SystemFlagInterface {
  getIsWriteAccessRestricted(): Promise<WriteAccessRestriction>;
  setIsWriteAccessRestricted(value: WriteAccessRestriction): Promise<void>;
}
