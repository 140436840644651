import { useInterval } from '../utils/useInterval';
import { syncData } from './sync';
import { EnvironmentConfig, getFlag, getNumber } from '../utils/environmentUtils';
import useCheckOrgGovernors from '../utils/useCheckOrgGovernors';
import { App, AppState } from '@capacitor/app';
import { useEffect } from 'react';
import useIsAndroidDeviceScanning from '../hooks/useIsAndroidDeviceScanning';
import { updateOrganizationSettingsFromServer } from '../features/settings/orgSettings/organizationSettingsFromServer';

const usePollForChanges = () => {
  const { checkOrgGovernors } = useCheckOrgGovernors();
  const { isScanning } = useIsAndroidDeviceScanning();

  const poll = () => {
    syncData();
    //TODO: poll the governors and other things less frequently than the syncing
    checkOrgGovernors();
    if (getFlag(EnvironmentConfig.ORG_SETTINGS)) {
      updateOrganizationSettingsFromServer();
    }
  };

  const syncInterval: number = getNumber(EnvironmentConfig.SYNC_INTERVAL_IN_MS, 30);
  useInterval(() => {
    poll();
  }, syncInterval);

  useEffect(() => {
    const listenerPromise = App.addListener('appStateChange', (state: AppState) => {
      if (state.isActive && !isScanning) {
        poll();
      }
    });
    return () => {
      listenerPromise.then((resolvedListener) => resolvedListener.remove());
    };
  }, [poll]);
};

export default usePollForChanges;
