import { ChangeLog, ChangeLoggerInterface, ChangeStatus } from '../../interfaces/changeLoggerInterface';
import { db } from './db';
import { v4 as uuid } from 'uuid';
import { syncData } from '../../../../../sync/sync';
import { logFriendlyObject } from '@otuvy/common-utils';

export class DexieChangeLogger implements ChangeLoggerInterface {
  /**
   * WARNING: attempting to call this from inside a transaction can cause errors
   * if the transaction does not explicitly include the tables this function uses!
   */
  async queueChange(
    { changeType, recordId, payload }: Pick<ChangeLog, 'changeType' | 'recordId' | 'payload'>,
    triggerSync: boolean = true
  ) {
    try {
      /*
        TODO: once things are working we can come back and so some "smart" logging of changes,
        by deleting or editing un-uploaded changes in some circumstances rather than adding new ones
        For example list created and list deleted.  We can just remove the un-uploaded creation rather than insert a second change
       */
      await db().changeLog.add({
        changeId: uuid(),
        changeType,
        recordId,
        payload,
        createdOn: Date.now(),
        uploaded: ChangeStatus.UN_UPLOADED,
      });
    } catch (e) {
      console.error('Error attempting to queue change', logFriendlyObject(e));
    }

    if (triggerSync) {
      syncData();
    }
  }

  async queueMultipleChanges(changes: Partial<ChangeLog>[]) {
    let fullChanges: ChangeLog[] = [];

    for (var change of changes) {
      if (change && change.changeType) {
        fullChanges.push({
          changeId: uuid(),
          changeType: change.changeType,
          recordId: change.recordId ? change.recordId : '',
          payload: change.payload,
          createdOn: Date.now(),
          uploaded: ChangeStatus.UN_UPLOADED,
        });
      }
    }

    try {
      await db().changeLog.bulkAdd(fullChanges);
    } catch (e) {
      console.error('Error attempting to queue change', logFriendlyObject(e));
    }

    syncData();
  }

  async getChangeById(id: string): Promise<ChangeLog | undefined> {
    const changes: ChangeLog[] = await db().changeLog.where('changeId').equals(id).toArray();

    if (!changes || changes.length === 0) {
      console.warn(`No changes found matching ID ${id}`);
      return undefined;
    }

    if (changes && changes.length > 1) {
      console.warn(`Multiple changes found matching ID ${id}`);
      return undefined;
    }

    return changes[0];
  }

  async hasUnuploadedChanges(): Promise<boolean> {
    const matchingChangeCount = await db().changeLog.where('uploaded').equals(ChangeStatus.UN_UPLOADED).count();

    return matchingChangeCount > 0;
  }

  async hasUnconfirmedChanges(): Promise<boolean> {
    const matchingChangeCount = await db().changeLog.where('uploaded').equals(ChangeStatus.UPLOADED).count();

    return matchingChangeCount > 0;
  }

  /**
   * Checks both uploaded and unconfirmed to see if we need to upload
   */
  async hasChanges(): Promise<boolean> {
    const unuploaded = await this.hasUnuploadedChanges();
    if (unuploaded) {
      //No need to check confirmed if there are unuploaded changes
      console.log(`Detected unuploaded changes so there was no need to check unconfirmed changes`);
      return true;
    }

    const unconfirmed = await this.hasUnconfirmedChanges();
    //console.log(`Has changes? unuploaded: ${unuploaded} uncomfirmed: ${unconfirmed}`);
    return unconfirmed; //Since we checked unuploaded and exited early above we can just focus on unconfirmed here
  }

  async markChangeAsUnUploaded(id: string) {
    await db().changeLog.update(id, { uploaded: ChangeStatus.UN_UPLOADED });
  }

  async markChangeAsUploaded(id: string) {
    await db().changeLog.update(id, { uploaded: ChangeStatus.UPLOADED });
  }

  async getChangesNeedingUploaded(): Promise<ChangeLog[]> {
    const matchingChanges = await db().changeLog.where('uploaded').equals(ChangeStatus.UN_UPLOADED).toArray();

    return matchingChanges;
  }

  async getChangeIdsNeedingUploaded(): Promise<string[]> {
    let changeIds: string[] = [];

    const unsortedResults = await db().changeLog.where('uploaded').equals(ChangeStatus.UN_UPLOADED);

    //Attempt to upload them in the same order they were created on
    const sortedResults = await unsortedResults.sortBy('createdOn');

    for (var change of sortedResults) {
      changeIds.push(change.changeId);
    }

    return changeIds;
  }

  async getChangeIdsNeedingConfirmed(): Promise<string[]> {
    let changeIds: string[] = [];
    await db()
      .changeLog.where('uploaded')
      .equals(ChangeStatus.UPLOADED)
      .each((r) => {
        changeIds.push(r.changeId);
      });

    return changeIds;
  }

  async markChangeAsConfirmed(id: string) {
    await db().changeLog.delete(id);
  }

  async getAllPendingChanges(): Promise<ChangeLog[]> {
    //TODO: Considering this includes all pending image changes we may want to use an abbreviated version of ChangeLog, rather than the whole object with its payload :P
    return db().changeLog.toArray();
  }

  async getListIdsWithPendingChanges(): Promise<string[]> {
    const allChanges = await db().changeLog.toArray();
    return allChanges.filter((change) => change.changeType.includes('list')).map((change) => change.recordId);
  }

  async getTaskIdsWithPendingChanges(): Promise<string[]> {
    const allChanges = await db().changeLog.toArray();
    return allChanges
      .filter((change) => change.changeType.includes('task') || change.changeType.includes('completion'))
      .map((change) => change.recordId);
  }
}
