import { ToastOptions } from "@ionic/react"
import { atom, useRecoilState } from "recoil"

export const toastQueueState = atom<ToastOptions[]>({
    key: 'toastQueueState',
    default: []
})

const useToastQueue = () => {
    const [queue, setQueue] = useRecoilState(toastQueueState)

    // https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads
    function presentToast(message: string, duration?: number): void;
    function presentToast(options: ToastOptions): void;
    function presentToast(messageOrOptions: string | ToastOptions, duration?: number) {
        if (typeof messageOrOptions === 'string') {
            const message: string = messageOrOptions
            setQueue([...queue, { message, duration }])
        } else {
            const options: ToastOptions = messageOrOptions
            setQueue([...queue, options])
        }
    }

    return [
        presentToast
    ]
}

export default useToastQueue