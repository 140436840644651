import './ClockMeInCompleteConfirmation.css';

import { IonAlert } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { addTimePunch } from '../utils/timePunchUtils';

export interface ClockMeInCompleteConfirmationProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirmationDismiss: () => void;
}

const ClockMeInCompleteConfirmation: React.FC<ClockMeInCompleteConfirmationProps> = ({
  isOpen,
  setIsOpen,
  onConfirmationDismiss,
}) => {
  const { t } = useTranslation();

  const onClockMeIn = async () => {
    try {
      addTimePunch(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <IonAlert
      mode="md"
      cssClass="clock-in-confirmation"
      header={'Wait!'}
      message={
        'It looks like you are trying to complete a task but are not clocked in. Would you like to clock in now?'
      }
      isOpen={isOpen}
      buttons={[
        {
          text: 'Yes, Clock Me In',
          handler: onClockMeIn,
        },
        {
          text: 'No, Stay Clocked Out',
          role: 'cancel',
          cssClass: 'clock-me-in-danger-color',
        },
      ]}
      onWillDismiss={() => {
        setIsOpen(false);
        onConfirmationDismiss();
      }}
    />
  );
};

export default ClockMeInCompleteConfirmation;
